
function fromOldestToNewest(collectionSelected) {
    for (let pageObj of collectionSelected.pages) {
        const parsedDateArray = pageObj.date.split('-').map(item => parseInt(item));
        pageObj['dateDetail'] = parsedDateArray;
    }
    const sortedPages = collectionSelected.pages.sort((a, b) => {
        if (a.dateDetail[2] === b.dateDetail[2]) {
            if (a.dateDetail[1] === b.dateDetail[1]) {
                return a.dateDetail[0] - b.dateDetail[0]
            } else {
                return a.dateDetail[1] - b.dateDetail[1]
            }
        } else {
            return a.dateDetail[2] - b.dateDetail[2]
        }
    });
    let index = 0;
    for (let page of sortedPages) {
        delete page.dateDetail;
        page.index = index;
        index += 1;
    }
    collectionSelected.pages = sortedPages
    return collectionSelected;
}


function fromNewestToOldest(collectionSelected) {
    for (let pageObj of collectionSelected.pages) {
        const parsedDateArray = pageObj.date.split('-').map(item => parseInt(item));
        pageObj['dateDetail'] = parsedDateArray;
    }
    const sortedPages = collectionSelected.pages.sort((a, b) => {
        if (a.dateDetail[2] === b.dateDetail[2]) {
            if (a.dateDetail[1] === b.dateDetail[1]) {
                return b.dateDetail[0] - a.dateDetail[0]
            } else {
                return b.dateDetail[1] - a.dateDetail[1]
            }
        } else {
            return b.dateDetail[2] - a.dateDetail[2]
        }
    });
    let index = 0;
    for (let page of sortedPages) {
        delete page.dateDetail;
        page.index = index;
        index += 1;
    }
    collectionSelected.pages = sortedPages
    return collectionSelected;
}


function changeOnePhotoPosition(oldIndex, newIndex, collectionSelected)  {
    const pageToMove = collectionSelected.pages[oldIndex];
    const reorderedPages = collectionSelected.pages.filter(item => item.index !== oldIndex);
    reorderedPages.splice(newIndex, 0, pageToMove);
    let index = 0;
    for (let photo of reorderedPages) {
        photo.index = index;
        index += 1;
    }
    collectionSelected.pages = reorderedPages
    return collectionSelected;
}


export { fromOldestToNewest, fromNewestToOldest, changeOnePhotoPosition }