export const SHOW_COLLECTIONS = 'SHOW_COLLECTIONS';
export const DELETE_COLLECTION = 'DELETE_COLLECTION';
export const SELECT_COLLECTION = 'SELECT_COLLECTION';
export const SHOW_ALBUMS = 'SHOW_ALBUMS';
export const SELECT_ALBUM = 'SELECT_ALBUM';
export const SHOW_ALBUM = 'SHOW_ALBUM';
export const SELECT_PHOTO = 'SELECT_PHOTO';
export const SHOW_DETAIL_PHOTO = 'SHOW_DETAIL_PHOTO';
export const UPDATE_DETAIL_PHOTO = 'UPDATE_DETAIL_PHOTO';
export const UPDATE_DETAIL_COLLECTION = 'UPDATE_DETAIL_COLLECTION';
export const SELECTED_INDEX_BOOK_DETAIL_PHOTO = 'SELECTED_INDEX_BOOK_DETAIL_PHOTO';
export const INDEX_PHOTO_BOOK_DETAIL = 'INDEX_PHOTO_BOOK_DETAIL';
export const ADD_SELECTED_PHOTO_BOOK = 'SELECTED_PHOTO_BOOK';
export const REMOVE_SELECTED_PHOTO_BOOK = 'REMOVE_SELECTED_PHOTO_BOOK';
export const DELETE_PHOTOS_FROM_ALBUM = 'DELETE_PHOTOS_FROM_ALBUM';
export const RESET_PHOTOS_SELECTED_BOOK = 'RESET_PHOTOS_SELECTED_BOOK';
export const VALIDATE_FORM = 'VALIDATE_FORM';
export const VALIDATE_FORM_SUCCESS = 'VALIDATE_FORM_SUCCESS';
export const VALIDATE_FORM_ERROR = 'VALIDATE_FORM_ERROR';
export const EDIT_NAME_COLLECTION = 'EDIT_NAME_COLLECTION';
export const RESET_VALIDATE_FORM = 'RESET_VALIDATE_FORM';
export const RESET_COLLECTIONS_STATE = 'RESET_COLLECTIONS_STATE';
export const COVER_SELECTED_FOR_CHANGE = 'COVER_SELECTED_FOR_CHANGE';
export const LOAD_USER_CARDS = 'LOAD_USER_CARDS';
export const START_LOADING_USER_CARDS = 'START_LOADING_USER_CARDS';
export const FINISH_LOADING_USER_CARDS = 'FINISH_LOADING_USER_CARDS';
export const LOAD_USER_ADDRESSES = 'LOAD_USER_ADDRESSES';
export const START_LOADING_USER_ADDRESSES = 'START_LOADING_USER_ADDRESSES';
export const FINISH_LOADING_USER_ADDRESSES = 'FINISH_LOADING_USER_ADDRESSES';
export const LOAD_CART = 'LOAD_CART';
export const UPDATE_USER_DATA = 'UPDATE_USER_DATA';
export const CREATE_CUSTOMER_ADDRESS = 'CREATE_CUSTOMER_ADDRESS';
export const CREATE_CUSTOMER_ADDRESS_ON_SERVER = 'CREATE_CUSTOMER_ADDRESS_ON_SERVER';
export const SHOW_CREATE_ADDRESS = 'SHOW_CREATE_ADDRESS';
export const RELOAD_USER_ADDRESSES = 'RELOAD_USER_ADDRESSES'
export const SHOW_HIDE_CREATE_CARD_TOKEN = 'SHOW_CREATE_CARD_TOKEN';
export const CREATE_CUSTOMER_CARD_TOKEN_ON_SERVER = 'CREATE_CUSTOMER_CARD_TOKEN_ON_SERVER';
export const RELOAD_USER_CARDS = 'RELOAD_USER_CARDS';
export const TOGGLE_UPLOADING_COVER_FLAG = 'TOGGLE_UPLOADING_COVER_FLAG';
export const TOGGLE_SHOW_CHANGE_COVER_MODAL = 'TOGGLE_SHOW_CHANGE_COVER_MODAL';
export const TOGGLE_SHOW_CHANGE_COVER_SELECT_SOURCE_MODAL = 'TOGGLE_SHOW_CHANGE_COVER_SELECT_SOURCE_MODAL';
export const SELECT_COVER_FOR_EDIT = 'SELECT_COVER_FOR_EDIT';
export const SET_CUSTOMER_DETAIL = 'SET_CUSTOMER_DETAIL';
export const SET_CUSTOMER_WALLET = 'SET_CUSTOMER_WALLET';
export const LOAD_WALLET_TRANSACTIONS = 'LOAD_WALLET_TRANSACTIONS';
export const LOAD_CUSTOMER_ORDERS = 'LOAD_CUSTOMER_ORDERS';
export const UPDATE_CUSTOMER_DETAIL = 'UPDATE_CUSTOMER_DETAIL';
export const REMOVE_USER_CARD = 'REMOVE_USER_CARD';
export const REMOVE_USER_ADDRESS = 'REMOVE_USER_ADDRESS';
export const START_LOADING_USER_ORDERS = 'START_LOADING_USER_ORDERS';
export const FINISH_LOADING_USER_ORDERS = 'FINISH_LOADING_USER_ORDERS';
export const LOAD_COVER_CATEGORIES = 'LOAD_COVER_CATEGORIES';
export const LOAD_COVERS = 'LOAD_COVERS';
export const UPDATE_CART_QUANTITY = 'UPDATE_CART_QUANTITY';
export const UPDATE_COLLECTION = 'UPDATE_COLLECTION';
export const UPDATE_ALBUM_CONFIGURATION = 'UPDATE_ALBUM_CONFIGURATION';
export const SAVE_PARAMS_FOR_ALBUM_CONFIGURATION = 'SAVE_PARAMS_FOR_ALBUM_CONFIGURATION';
export const SAVE_PAGES_DIFERENCE = 'SAVE_PAGES_DIFERENCE';

// Create project
export const SELECT_SOURCE = 'SELECT_SOURCE';
export const LOAD_SOCIAL_ALBUMS = 'LOAD_SOCIAL_ALBUMS';
export const LOAD_SOCIAL_ALBUM_DETAIL = 'LOAD_SOCIAL_ALBUM_DETAIL';
export const LOAD_SOCIAL_PHOTOS = 'LOAD_SOCIAL_PHOTOS';
export const ADD_PHOTO_TO_CREATE = 'ADD_PHOTO_TO_CREATE';
export const REMOVE_PHOTO_FROM_CREATE = 'REMOVE_PHOTO_FROM_CREATE';
export const CREATE_ALBUM = 'CREATE_ALBUM';
export const SELECT_SOCIAL_ALBUM = 'SELECT_SOCIAL_ALBUM';
export const SET_SOCIAL_TOKEN = 'SET_SOCIAL_TOKEN';
export const RESET_PHOTOS_SELECTION = 'RESET_PHOTOS_SELECTION';
export const SET_GOOGLE_LOGGED_IN = 'SET_GOOGLE_LOGGED_IN';
export const REMOVE_DEFAULT_ALBUM_FOR_QUOTES = 'REMOVE_DEFAULT_ALBUM_FOR_QUOTES';
export const LOAD_QUOTES_ALBUMS = 'LOAD_QUOTES_ALBUMS';
export const LOAD_QUOTES_ALBUM_DETAIL = 'LOAD_QUOTES_ALBUM_DETAIL';
export const TOGGLE_IS_ADDING_PHOTOS = 'TOGGLE_IS_ADDING_PHOTOS';
export const RESET_CREATE_PROJECT_STATE = 'RESET_CREATE_PROJECT_STATE';
export const BACK_TO_ALBUM_DETAIL_AFTER_ADD_PHOTOS = 'BACK_TO_ALBUM_DETAIL_AFTER_ADD_PHOTOS';
export const LOAD_AVAILABLE_PRODUCTS = 'LOAD_AVAILABLE_PRODUCTS';
export const UPDATE_ALBUM_CONFIGURATION_IDS = 'UPDATE_ALBUM_CONFIGURATION_IDS';
export const UPDATE_ALBUM_SELECTED_PAGES = 'UPDATE_ALBUM_SELECTED_PAGES';
export const UPDATE_ALBUM_PRINT_DATES = 'UPDATE_ALBUM_PRINT_DATES';
export const SET_DEFAULT_ALBUM = 'SET_DEFAULT_ALBUM';

// General
export const RESET_STATE = 'RESET_STATE';
export const START_LOADING_INFO = 'START_LOADING_INFO';
export const FINISH_LOADING_INFO = 'FINISH_LOADING_INFO';
export const UPDATE_LOADING_INFO = 'UPDATE_LOADING_INFO';
export const SHOW_ERROR = 'SHOW_ERROR';
export const HIDE_ERROR = 'HIDE_ERROR';
export const CREATING_USER = 'CREATING_USER';
export const SHOW_ALERT_CREATE_PROJECT = 'SHOW_ALERT_CREATE_PROJECT';
export const HIDE_ALERT_CREATE_PROJECT = 'HIDE_ALERT_CREATE_PROJECT';
export const SET_ALBUM_CONFIGURATIONS = 'SET_ALBUM_CONFIGURATIONS';

// Checkout
export const ADD_ALBUM_TO_CART = 'ADD_ALBUM_TO_CART';
export const ADD_PRODUCTS_TO_CHECKOUT = 'ADD_PRODUCTS_TO_CHECKOUT';
export const CALCULATE_ORDER = 'CALCULATE_ORDER';
export const PLACE_ORDER = 'PLACE_ORDER';
export const SELECT_PAYMENT_METHOD = 'SELECT_PAYMENT_METHOD';
export const RESET_CHECKOUT_STATE = 'RESET_CHECKOUT_STATE';
export const SELECT_SHIPPING_ADDRESS = 'SELECT_SHIPPING_ADDRESS';
export const SHOW_GO_TO_CART_ALERT = 'SHOW_GO_TO_CART_ALERT';
export const ADD_LOCAL_ALBUM_TO_CART = 'ADD_LOCAL_ALBUM_TO_CART';
export const REMOVE_LOCAL_PRODUCT_FROM_CART = 'REMOVE_LOCAL_PRODUCT_FROM_CART';
export const CHANGE_ALBUM_QUANTITY = 'CHANGE_ALBUM_QUANTITY';
export const UPDATE_CUSTOMER_INFO = 'UPDATE_CUSTOMER_INFO';

//Sortable Photos
export const LOAD_LIST_TO_ORDER = 'LOAD_LIST_TO_ORDER';
export const REORDER_LIST = 'REORDER_LIST';
export const UPDATE_REORDER_PHOTOS = 'UPDATE_REORDER_PHOTOS';
export const CREATE_SORTABLE_DATA_SOURCE = 'CREATE_SORTABLE_DATA_SOURCE';
export const UPDATE_PHOTOS_OF_SELECTED_COLLECTION = 'UPDATE_PHOTOS_OF_SELECTED_COLLECTION';

//Navigation 
export const ROUTE_CHANGE = 'ROUTE_CHANGE';
export const CHANGE_PROJECT_TYPE = 'CHANGE_PROJECT_TYPE';
export const SELECT_MAGNET_PROJECT = 'SELECT_MAGNET_PROJECT';
export const SELECT_ORNAMENT_PROJECT = 'SELECT_ORNAMENT_PROJECT';
export const SELECT_CANVAS_PROJECT = 'SELECT_CANVAS_PROJECT';
export const CHANGE_DATA_FOR_ADDING_TITLE = 'CHANGE_DATA_FOR_ADDING_TITLE';
export const UPDATE_IS_NEW_CUSTOMER = 'UPDATE_IS_NEW_CUSTOMER';
