import React from 'react';
import './CreateProductCard.scss';


const CreateProductCard = (props) => {
  return (
    props.fromHome ? (
      <div className="product-card_container d-flex justify-content-center cursor-pointer" onClick={() => props.handleCreateClick(props.item.product_type)}>
        <div className="product-card_container-inside d-flex flex-column align-items-center justify-content-between">
          <img src={props.image} alt=""  />
          <div className="product-card_text d-flex justify-content-center align-items-center">
            <p className="gotham-md-20">{props.title}</p>
          </div>
        </div>
      </div>
      // <div className="product-card_container mx-4 cursor-pointer" onClick={() => props.handleCreateClick(props.item.product_type)}>
      //   <img src={props.image} alt="" height="auto" width="250" />
      //   <div className="py-2 d-flex justify-content-center">
      //     <p className="futura-bold-20">{props.title}</p>
      //   </div>
      // </div>
    ) : (
        <div className="product-card_clean mx-4 cursor-pointer" onClick={() => props.handleCreateClick(props.item.product_type)}>
          <img src={props.image} alt="" height="300" width="300" />
          <div className="py-2 d-flex flex-column align-items-center">
            <p className="futura-bold-20">{props.title}</p>
            <p className="futura-ltbt-15">{props.subtitle}</p>
          </div>
        </div>
      )
  );
}

export default CreateProductCard;