import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import logo from '../../images/newDesignImages/logo-pink.png';
import insta_logo from '../../images/newDesignImages/footer-instagram.png';
import facebook_logo from '../../images/newDesignImages/footer-facebook.png';
import twitter_logo from '../../images/newDesignImages/footer-twitter.png';
import tiktok_logo from '../../images/newDesignImages/footer-tiktok.png';
import printerest_logo from '../../images/newDesignImages/footer-printerest.png';
import './Footer.scss';


class Footer extends Component {

  handleCreateClick = (productType) => {
    if (productType === "item") {
      this.props.history.push('/accessories')
    } else {
      this.props.history.push({
        pathname: '/product/info',
        state: this.props.availableProductsGlobal.filter(item => item.product_type === productType)
      });
    }
  }

  render() {
    return (
      <div className="footer_main-container d-flex flex-column">
        <div className="footer_top-container"></div>
        <div className="footer_bottom-container d-flex justify-content-between align-items-start">
          <div className="d-flex flex-column">
            <p className="footer_section-title">Pixyalbum</p>
            <a className="footer_item" href="/">Home</a>
            <a className="footer_item" href="/">Nosotros</a>
            <a className="footer_item" href="/account">Mi cuenta</a>
          </div>
          <div className="d-flex flex-column">
            <p className="footer_section-title">Legales</p>
            <a className="footer_item" href="https://www.pixyalbum.com/faqs" target="_blank">Preguntas frecuentes</a>
            <a className="footer_item" href="https://www.pixyalbum.com/terms" target="_blank">Términos y condiciones</a>
            <a className="footer_item" href="https://www.pixyalbum.com/policy" target="_blank">Aviso de privacidad</a>
          </div>
          <div className="d-flex flex-column">
            <p className="footer_section-title">Productos</p>
            <p className="footer_item cursor-pointer" onClick={() => this.handleCreateClick('album')}>Fotolibros</p>
            <p className="footer_item cursor-pointer" onClick={() => this.handleCreateClick('magnet')}>Imanes</p>
            <p className="footer_item cursor-pointer" onClick={() => this.handleCreateClick('picture')}>Pixycuadros</p>
            <p className="footer_item cursor-pointer" onClick={() => this.handleCreateClick('ornament')}>Esferas</p>
            <p className="footer_item cursor-pointer" onClick={() => this.handleCreateClick('giftcard')}>Tarjeta de regalo</p>
            <p className="footer_item cursor-pointer" onClick={() => this.handleCreateClick('item')}>Accesorios</p>
          </div>
          <div className="d-flex flex-column">
            <p className="footer_section-title">Contacto</p>
            <p className="footer_item">ayuda@pixyalbum.com</p>
            <p className="footer_item">5578311330</p>
          </div>
          <div className="d-flex flex-column">
            <p className="footer_section-title">Síguenos en:</p>
            <div>
              <a href="https://www.facebook.com/pixyalbum" target="_blank">
                <img src={facebook_logo} height="22" alt="" />
              </a>
              <a href="https://www.instagram.com/pixyalbum/?hl=es-la" target="_blank">
                <img className="mx-2" src={insta_logo} height="22" alt="" />
              </a>
              <a href="https://twitter.com/pixyalbum?lang=es" target="_blank">
                <img className="mx-1" src={twitter_logo} height="22" alt="" />
              </a>
              <a href="https://vm.tiktok.com/ZMLwRkYgg/" target="_blank">
                <img className="mx-1" src={tiktok_logo} height="22" alt="" />
              </a>
              <a href="https://pin.it/6ZEQpxx" target="_blank">
                <img src={printerest_logo} height="22" alt="" />
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  availableProductsGlobal: state.collections.availableProductsGlobal
});


export default compose(
  connect(mapStateToProps)
)(Footer);

