import axios from 'axios';

class ApiService {
    constructor() {
        let service = axios.create({
            baseURL: window._env_.API_URL,
            responseType: "json"
        });
        service.interceptors.response.use(this.handleSuccess, this.handleError);
        this.service = service;
    }

    handleSuccess(response) {
        return response;
    }

    handleError(error) {
        let errorMessage = 'Hubo un error, por favor intenta más tarde.';
        try {
            if (error.response.status === 404) {
                errorMessage = 'Hubo un error, por favor intenta nuevamente.';
            }

            if (error.response.status > 500 && error.response.status < 599) {
                errorMessage = 'La respuesta no pudo ser recuperada debido a un error en el servicio.';
            }

            if (error.code === 'ECONNABORTED') {
                errorMessage = 'El servicio está tardando en responder.';
            }
        }
        catch (e) {
            // console.log('Axios handling error');
        }
        let errorResponse = {
            data: {
                success: false,
                message: errorMessage
            }
        }
        return Promise.reject(errorResponse);
    }

    makeRequest(path, payload) {
        return this.service.post(path, payload);
    }
}

export default new ApiService();
