import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';

class TokenHelper {

  getUserToken = () => {
    return new Promise((resolve, reject ) => {
      firebase.auth().onAuthStateChanged(user => {
        if (user) {
          return user.getIdToken(true)
            .then(idToken => {
              resolve(idToken);
            })
            .catch(e => {
              reject('Error getting user token');
            });
        } else {
          reject('User is no longer logged in');
        }
      })
    });
  }
}

export default new TokenHelper();
