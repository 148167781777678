import React from 'react';
import PricesTable from './PricesTable';
import close_gray from '../../images/newDesignImages/close-gray.png';
import './AlbumPrices.scss';

function AlbumPrices(props) {
  const { albumConfigurations } = props;
  const price16soft60 = albumConfigurations[0].price;
  const price16soft80 = price16soft60 + albumConfigurations[0].configurations.filter(item => item.description === '80')[0].price;
  const price16soft100 = price16soft60 + albumConfigurations[0].configurations.filter(item => item.description === '100')[0].price;

  const price16hard60 = price16soft60 + albumConfigurations[0].configurations.filter(item => item.description === 'dura')[0].price;
  const price16hard80 = price16soft60 + 
    albumConfigurations[0].configurations.filter(item => item.description === 'dura')[0].price + 
    albumConfigurations[0].configurations.filter(item => item.description === '80')[0].price;
  const price16hard100 = price16soft60 + 
    albumConfigurations[0].configurations.filter(item => item.description === 'dura')[0].price + 
    albumConfigurations[0].configurations.filter(item => item.description === '100')[0].price;


  const price21soft60 = albumConfigurations[1].price;
  const price21soft80 = price21soft60 + albumConfigurations[1].configurations.filter(item => item.description === '80')[0].price;
  const price21soft100 = price21soft60 + albumConfigurations[1].configurations.filter(item => item.description === '100')[0].price;

  const price21hard60 = price21soft60 + albumConfigurations[1].configurations.filter(item => item.description === 'dura')[0].price;
  const price21hard80 = price21soft60 + 
    albumConfigurations[1].configurations.filter(item => item.description === 'dura')[0].price + 
    albumConfigurations[1].configurations.filter(item => item.description === '80')[0].price;
  const price21hard100 = price21soft60 + 
    albumConfigurations[1].configurations.filter(item => item.description === 'dura')[0].price + 
    albumConfigurations[1].configurations.filter(item => item.description === '100')[0].price;

  return (
    <div className="albumprices_main d-flex justify-content-center align-items-center">
      <div className="albumprices_inside d-flex flex-column justify-content-center align-items-center">
        <img className="albumprices_close-img cursor-pointer" onClick={props.handleShowPrices} src={close_gray} height="20" alt="" />
        <h1 className="gotham-bold-darkgray-20">Precios de Fotolibros</h1>
        <div className="d-flex justify-content-center align-items-center" style={{width: '600px'}}>
          <div className="d-flex flex-wrap justify-content-between">
            <PricesTable
              title={'16x16 cm Pasta suave'}
              price1={price16soft60}
              price2={price16soft80}
              price3={price16soft100}
            />
            <PricesTable
              title={'21x21 cm Pasta suave'}
              price1={price21soft60}
              price2={price21soft80}
              price3={price21soft100}
            />
            <PricesTable
              title={'16x16 cm Pasta dura'}
              price1={price16hard60}
              price2={price16hard80}
              price3={price16hard100}
            />
            <PricesTable
              title={'21x21 cm Pasta dura'}
              price1={price21hard60}
              price2={price21hard80}
              price3={price21hard100}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AlbumPrices;