import React, { Component } from 'react';
import ApiQuotes from '../../utils/Api/ApiQuotes';
import TokenHelper from '../../utils/Firebase/TokenHelper';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { showErrorAction } from '../../actions/GlobalActions';
import { toggleShowChangeCoverSelectSourceAction } from '../../actions/CustomerActions';
import SelectQuotesImageItem from './SelectQuotesImageItem';
import Scrollable from '../Scrollable';
import Loading from '../Loading';
import close_img from '../../images/newDesignImages/close-white.png';
import back_img from '../../images/newDesignImages/back-arrow-blue.png';
import './SelectQuotesImage.scss';


class SelectQuotesImage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      quotesAlbums: [],
      quotesAlbum: [],
      showQuotesCathegory: false,
      selectedAlbumName: null
    }
  }

  componentDidMount() {
    this.getQuotesAlbums();
  }

  getQuotesAlbums = () => {
    TokenHelper.getUserToken().then(idToken => {
      ApiQuotes.getQuotesAlbums({ token: idToken }).then(response => {
        if (response.data.success) {
          this.setState({
            quotesAlbums: response.data.data,
            isLoading: false
          });
        } else {
          this.props.showErrorAction('Ocurrió un error, por favor intenta mas tarde.');
          this.props.toggleShowChangeCoverSelectSourceAction(false);
        }
      }).catch(error => {
        this.props.showErrorAction('Ocurrió un error, por favor intenta mas tarde.');
        this.props.toggleShowChangeCoverSelectSourceAction(false);
      })
    }).catch(error => {
      this.props.showErrorAction('Ocurrió un error, por favor intenta mas tarde.');
      this.props.toggleShowChangeCoverSelectSourceAction(false);
    })
  }

  getQuotesAlbum = (id) => {
    TokenHelper.getUserToken().then(idToken => {
      ApiQuotes.getQuotesAlbumDetail({
        token: idToken,
        album_id: id
      }).then(response => {
        if (response.data.success) {
          this.setState({
            quotesAlbum: response.data.data,
            isLoading: false
          });
        } else {
          this.props.showErrorAction('Ocurrió un error, por favor intenta mas tarde.');
          this.props.toggleShowChangeCoverSelectSourceAction(false);
        }
      }).catch(error => {
        this.props.showErrorAction('Ocurrió un error, por favor intenta mas tarde.');
        this.props.toggleShowChangeCoverSelectSourceAction(false);
      })
    }).catch(error => {
      this.props.showErrorAction('Ocurrió un error, por favor intenta mas tarde.');
      this.props.toggleShowChangeCoverSelectSourceAction(false);
    })
  }

  handleSelectAlbum = (id) => {
    const albumName = this.state.quotesAlbums.filter(item => item.id === id)[0].name;
    this.getQuotesAlbum(id);
    this.setState({
      isLoading: true,
      showQuotesCathegory: true,
      selectedAlbumName: albumName
    });
  }

  render() {
    const { quotesAlbums, quotesAlbum, isLoading, showQuotesCathegory, selectedAlbumName } = this.state;
    return (
      isLoading ? <Loading/> : 
      <div className="changecover-selectsource_main">
        <div className="changecover-selectsource_header d-flex">
          <div className="changecover-selectsource_close d-flex justify-content-center align-items-center cursor-pointer" onClick={this.props.handleCloseQuotes}>
            <img src={close_img} height="23" alt="" />
          </div>
          <div className="changecover-selectsource_header-right d-flex justify-content-between align-items-center">
            <p className="gotham-bold-18 ml-3">Portada > Frases {showQuotesCathegory && `> ${selectedAlbumName}`}</p>
            <button className="generic-button-blue mr-3" onClick={this.props.handleCloseQuotes}>
              Cancelar
            </button>
          </div>
        </div>

        <div className="changecover-selectsource_content d-flex flex-column justify-content-center align-items-center">
          <div className="changecover-selectsource_back-container d-flex justify-content-start align-items-center">
            <img className="cursor-pointer" src={back_img} height="20" alt="" onClick={this.props.handleCloseQuotes} />
          </div>
          
          {showQuotesCathegory ?
            <div className="changecover-selectsource_photos pb-5 d-flex flex-wrap">
              {quotesAlbum.map((item, i) => (
                <SelectQuotesImageItem
                  key={i}
                  item={item}
                  handleSelectAlbum={this.handleSelectAlbum}
                  showQuotesCathegory={showQuotesCathegory}
                  selectPhotoForEditHandler={this.props.selectPhotoForEditHandler}
                />
              ))}
            </div> :
            <div className="changecover-selectsource_photos pb-5 d-flex flex-wrap">
              {quotesAlbums.map((item, i) => (
                <SelectQuotesImageItem
                  key={i}
                  item={item}
                  handleSelectAlbum={this.handleSelectAlbum}
                  showQuotesCathegory={showQuotesCathegory}
                />
              ))}
            </div>
          }
        
          </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  albums: state.createProject.albums
});

const mapDispatchToProps = {
  showErrorAction: showErrorAction,
  toggleShowChangeCoverSelectSourceAction: toggleShowChangeCoverSelectSourceAction
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(SelectQuotesImage);
