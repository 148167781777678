import React from 'react';
import giftcard_img from '../../images/newDesignImages/exchange-card-img.png'
import './OrderDetailProductItem.scss';


const OrderDetailProductItem = (props) => {
  const { product, giftcardCategories } = props;
  const categoryName = product.product_type === 'giftcard' ? giftcardCategories.filter(item => item.id === product.gift_card_design_id)[0].category_name : null;
  const frameBackground = (product.product_type !== 'item' && product.configurations.length > 1) && {
    backgroundImage: 'url(' + product.configurations[1].frame_url + ')',
    backgroundSize: 'cover',
    height: '100px',
    width: '100px',
  }
  switch (product.product_type) {
    case 'giftcard':
      return (
        <div className="row order-product_item d-flex d-flex align-items-center justify-content-between mx-5">
          <div className="col-4 d-flex align-items-center">
            <img src={giftcard_img} height="60" alt="cover" />
          </div>
          <div className="col-8">
            <p className="futura-bkbt-17"><strong>Para:</strong> {product.recipient_email}</p>
            <p className="futura-bold-17"><strong>{categoryName}</strong></p>
            <p className="futura-md-blue-17">$ {product.configurations[0].price} MXN</p>
            <p className="futura-md-17">Cantidad: 1</p>
          </div>
        </div>
      );
    case 'magnet':
      return (
        <div className="row order-product_item d-flex align-items-center justify-content-center mx-5">
          <div className="col-4">
            <div className="order-product_magnet-background d-flex align-items-end justify-content-start">
              <img src={product.cover_url} height="87" width="87" alt="cover" />
            </div>
          </div>
          <div className="col-8">
            <p className="futura-bold-17"><strong>{product.name}</strong></p>
            <p className="futura-md-17">{product.configurations[0].title}</p>
            <p className="futura-md-blue-17">$ {product.total} MXN</p>
            <p className="futura-md-17">Cantidad: {product.quantity}</p>
          </div>
        </div>
      );
    case 'picture':
      return (
        <div className="row order-product_item d-flex align-items-center justify-content-center mx-5">
          <div className="col-4">
            {product.configurations.length > 1 ? 
              <div className="d-flex align-items-center justify-content-center" style={frameBackground}>
                <img className="order-product_canvas-fame-img" src={product.cover_url} height="57" width="57" alt=""/>
              </div> :
              <div className="order-product_canvas-background d-flex align-items-start justify-content-start">
                <img src={product.cover_url} height="95" width="95" alt="cover" />
              </div>
            }
          </div>
          <div className="col-8">
            <p className="futura-bold-17"><strong>{product.name}</strong></p>
            <p className="futura-md-15">{product.configurations.length > 1 ? product.configurations[1].title : product.configurations[0].title}</p>
            <p className="futura-md-blue-17">$ {product.total} MXN</p>
            <p className="futura-md-17">Cantidad: {product.quantity}</p>
          </div>
        </div>
      );
    case 'ornament':
      return (
        <div className="row order-product_item d-flex align-items-center justify-content-center mx-5">
          <div className="col-4">
            <div className="order-product_ornament-background d-flex align-items-end justify-content-start">
              <img src={product.cover_url} height="81%" width="90%" alt="cover" />
            </div>
          </div>
          <div className="col-8">
            <p className="futura-bold-17"><strong>{product.name}</strong></p>
            <p className="futura-md-blue-17">$ {product.total} MXN</p>
            <p className="futura-md-17">Cantidad: {product.quantity}</p>
          </div>
        </div>
      );
    case 'item':
      return (
        <div className="row order-product_item d-flex align-items-center justify-content-center mx-5">
          <div className="col-4 d-flex align-items-center justify-content-center">
            <img className="order-product_accessory-img" src={product.image} height="90%" width="90%" alt="cover" />
          </div>
          <div className="col-8">
            <p className="futura-bold-17"><strong>{product.name}</strong></p>
            <p className="futura-md-blue-17">$ {product.total} MXN</p>
            <p className="futura-md-17">Cantidad: {product.quantity}</p>
          </div>
        </div>
      );
    default:
      return (
        <div className="row order-product_item d-flex align-items-center justify-content-center mx-5">
          <div className="col-4">
            {product.predesign_cover ?
              <div className="order-product_album-background d-flex align-items-center justify-content-end">
                <img src={product.cover_url} height="100" width="107" alt="cover" />
              </div> :
              <div className="order-product_album-background d-flex align-items-center justify-content-end pr-1">
                <img src={product.cover_url} height="93" width="93" alt="cover" />
              </div>
            }
          </div>
          <div className="col-8">
            <p className="futura-bold-17"><strong>{product.name}</strong></p>
            <p className="futura-md-17">{product.hard_cover ? 'Pasta rígida' : 'Pasta suave'} - {product.configurations[0].title}</p>
            <p className="futura-md-blue-17">$ {product.total} MXN</p>
            <p className="futura-md-17">Cantidad: {product.quantity}</p>
          </div>
        </div>
      );
  }
}

export default OrderDetailProductItem;