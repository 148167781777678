import { ROUTE_CHANGE, CHANGE_PROJECT_TYPE, SELECT_MAGNET_PROJECT, SELECT_CANVAS_PROJECT, SELECT_ORNAMENT_PROJECT ,CHANGE_DATA_FOR_ADDING_TITLE, UPDATE_IS_NEW_CUSTOMER } from '../actions/ActionTypes';

const initialState = {
    routeName: 'projects',
    projectType: '',
    selectedMagnetProject: null,
    selectedCanvasProject: null,
    addTitleToCoverData: null,
    isNewCustomer: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case ROUTE_CHANGE:
            return {
                ...state,
                routeName: action.payload
            }
        case CHANGE_PROJECT_TYPE:
            return {
                ...state,
                projectType: action.payload
            }
        case SELECT_MAGNET_PROJECT:
            return {
                ...state,
                selectedMagnetProject: action.payload
            }
        case SELECT_CANVAS_PROJECT:
            return {
                ...state,
                selectedCanvasProject: action.payload
            }
        case SELECT_ORNAMENT_PROJECT:
            return {
                ...state,
                selectedOrnamentProject: action.payload
            }
        case CHANGE_DATA_FOR_ADDING_TITLE:
            return {
                ...state,
                addTitleToCoverData: action.payload
            }
        case UPDATE_IS_NEW_CUSTOMER:
            return {
                ...state,
                isNewCustomer: action.payload
            }
        default:
            return state;
    }
}