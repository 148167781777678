import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { changeRouteAction } from '../../actions/NavigationActions';
import ApiCustomer from '../../utils/Api/ApiCustomer';
import TokenHelper from '../../utils/Firebase/TokenHelper';
import Scrollable from '../Scrollable';
import Footer from '../Footer';
import Loading from '../Loading';
import ExchangeGiftcard from './ExchangedGiftcard';
import Modal from '../Modal';
import AlertView from '../AlertView';
import Moment from 'moment';

import check_active from '../../images/newDesignImages/check-active.png';
import check_inactive from '../../images/newDesignImages/check-inactive.png';
import giftcard_frame_inactive from '../../images/newDesignImages/giftcard-frame-inactive.png';
import giftcard_frame_active from '../../images/newDesignImages/giftcard-frame-active.png';
import name_img from '../../images/newDesignImages/name-img.png';
import email_img from '../../images/newDesignImages/email-img.png';
import message_img from '../../images/newDesignImages/message-img.png';
import exchange_card from '../../images/newDesignImages/exchange-card-img.png';
import exchange_code_img from '../../images/newDesignImages/exchange-code-img.png';
import './GiftCards.scss';

class GiftCards extends Component {
  constructor(props) {
    super(props);
    this.state = {
      giftcards: [],
      giftcardConfigurations: [],
      selectedDesignId: null,
      selectedPriceConfigutation: null,
      recipientName: '',
      recipientEmail: '',
      message: '',
      exchangeCode: '',
      exchangeSuccess: false,
      exchangedCodeQuantity: 0,
      isLoading: true,
      showError: false,
      errorMessage: ''
    }
  }

  componentDidMount() {
    this.getGiftcards();
    this.getConfigurations();
    this.props.changeRouteAction('giftcard');
  }

  getGiftcards = () => {
    TokenHelper.getUserToken().then(idToken => {
      ApiCustomer.getAllGiftcards({ token: idToken }).then(response => {
        if (response.data.success) {
          this.setState({
            giftcards: response.data.data,
            isLoading: false
          });
        } else {
          this.setState({
            showError: true,
            errorMessage: 'Ocurrió un error, intenta más tarde.',
            isLoading: false
          });
        }
      }).catch(error => {
        this.setState({
          showError: true,
          errorMessage: 'Ocurrió un error, intenta más tarde.',
          isLoading: false
        });
      });
    }).catch(error => {
      this.setState({
        showError: true,
        errorMessage: 'Ocurrió un error, intenta más tarde.',
        isLoading: false
      });
    });
  }

  getConfigurations = () => {
    TokenHelper.getUserToken().then(idToken => {
      ApiCustomer.getGiftcardConfigurations({ token: idToken }).then(response => {
        if (response.data.success) {
          this.setState({ giftcardConfigurations: response.data.data.configurations })
        } else {
          this.setState({
            showError: true,
            errorMessage: 'Ocurrió un error, intenta más tarde.',
            isLoading: false
          });
        }
      }).catch(error => {
        this.setState({
          showError: true,
          errorMessage: 'Ocurrió un error, intenta más tarde.',
          isLoading: false
        });
      })
    }).catch(error => {
      this.setState({
        showError: true,
        errorMessage: 'Ocurrió un error, intenta más tarde.',
        isLoading: false
      });
    })
  }

  addGiftcardToCart = (data) => {
    this.setState({ isLoading: true });
    TokenHelper.getUserToken().then(idToken => {
      ApiCustomer.addProductToCart({
        token: idToken,
        product_type: 'giftcard',
        data: [data]
      }).then(response => {
        if (response.data.success) {
          this.props.history.push('/cart')
        } else {
          this.setState({
            isLoading: false,
            showError: true,
            errorMessage: response.data.message
          })
        }
      }).catch(error => {
        this.setState({
          showError: true,
          errorMessage: 'Ocurrió un error, intenta más tarde.',
          isLoading: false
        });
      })
    }).catch(error => {
      this.setState({
        showError: true,
        errorMessage: 'Ocurrió un error, intenta más tarde.',
        isLoading: false
      });
    })
  }

  handleInput = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  exchangeGiftcard = () => {
    TokenHelper.getUserToken().then(idToken => {
      ApiCustomer.giftcardRedeem({
        token: idToken,
        code: this.state.exchangeCode
      }).then(response => {
        if (response.data.success) {
          this.setState({
            exchangedCodeQuantity: response.data.data.message,
            exchangeSuccess: true
          });
        } else {
          this.setState({
            showError: true,
            errorMessage: response.data.message
          })
        }
      }).catch(error => {
        this.setState({
          showError: true,
          errorMessage: error.data.message
        })
      })
    }).catch(error => {
      this.setState({
        showError: true,
        errorMessage: 'Ocurrió un error, intenta más tarde.',
        isLoading: false
      });
    })
  }

  handleSelectDesign = (id) => {
    this.setState({ selectedDesignId: parseInt(id) });
  }

  handleSelectPriceConfiguration = (config) => {
    this.setState({ selectedPriceConfigutation: config })
  }

  handleAddToCart = () => {
    const { selectedDesignId, recipientName, recipientEmail, message, selectedPriceConfigutation } = this.state;
    const reducer = (accumulator, currentValue) => accumulator + currentValue;
    const totalPrice = this.props.cartProducts.map(item => item.price).reduce(reducer, 0) + parseInt(this.state.selectedPriceConfigutation.title);
    if (!selectedDesignId) {
      this.setState({
        showError: true,
        errorMessage: 'Debes seleccionar un diseño de tarjeta.'
      });
    } else if (!selectedPriceConfigutation) {
      this.setState({
        showError: true,
        errorMessage: 'Debes seleccionar un precio de tarjeta.'
      });
    } else if (!recipientName) {
      this.setState({
        showError: true,
        errorMessage: 'Debes agregar un nombre de destinatario.'
      });
    } else if (!recipientEmail) {
      this.setState({
        showError: true,
        errorMessage: 'Debes agregar el email del destinatario.'
      });
    } else if (totalPrice > 10000) {
      this.setState({
        showError: true,
        errorMessage: 'No es posible agregar más de $10,000 al carrito.'
      });
    }

    else {
      // Check the timezone of date
      const data = {
        gift_card_design_id: selectedDesignId,
        recipient_name: recipientName,
        recipient_email: recipientEmail,
        message: message,
        send_date: Moment().format('DD-MM-YYYY'),
        configurations: [selectedPriceConfigutation]
      }
      this.addGiftcardToCart(data)
    }
  }

  okErrorHandler = () => {
    this.setState({
      showError: false,
      errorMessage: ''
    });
  }

  render() {
    const {
      giftcards,
      selectedDesignId,
      giftcardConfigurations,
      selectedPriceConfigutation,
      recipientName,
      recipientEmail,
      message,
      exchangeCode,
      exchangeSuccess,
      exchangedCodeQuantity,
      showError,
      errorMessage,
      isLoading
    } = this.state;
    return (
      isLoading ? <Loading /> : exchangeSuccess ?
        <ExchangeGiftcard
          exchangedCodeQuantity={exchangedCodeQuantity}
          history={this.props.history}
        /> :
        <Scrollable scrollableContent={
          <div >
            {showError &&
              <Modal>
                <AlertView
                  title={'¡Ups!'}
                  message={errorMessage}
                  okHandler={this.okErrorHandler}
                  cancel={true} />
              </Modal>
            }
            <div className="giftcard_buy d-flex align-items-center justify-content-center">
              <div className="row giftcard_buy-inside d-flex justify-content-around">
                <div className="col-6 giftcard_buy-left">
                  <p className="futura-md-20 py-3 pl-3">Escoge el monto de tu tarjeta de regalo:</p>
                  <div className="giftcard_prices-container d-flex justify-content-around">
                    {giftcardConfigurations.slice(0, 3).map(item => (
                      <div key={item.id} className="giftcard_price d-flex justify-content-around align-items-center cursor-pointer px-4" onClick={() => this.handleSelectPriceConfiguration(item)} style={{ color: item === selectedPriceConfigutation ? '#00baf1' : '#999999' }}>
                        <img src={item === selectedPriceConfigutation ? check_active : check_inactive} height="25" alt="" /> {item.title}
                      </div>
                    ))}
                  </div>

                  <p className="futura-md-20 py-3 pl-3">Ocasión de regalo:</p>
                  <div className="giftcard_category-container d-flex justify-content-around">
                    {giftcards.map(giftcard =>
                      <div key={giftcard.id}>
                        <div
                          className="giftcard_design-card d-flex justify-content-center align-items-end cursor-pointer m-1"
                          style={{
                            background: parseInt(giftcard.id) === selectedDesignId ? 'url(' + giftcard_frame_active + ') center no-repeat' : 'url(' + giftcard_frame_inactive + ') center no-repeat',
                            backgroundSize: 'cover',
                            border: parseInt(giftcard.id) === selectedDesignId ? '2px solid #00baf1' : null,
                            height: '115px',
                            width: '110px'
                          }}
                          onClick={() => this.handleSelectDesign(giftcard.id)}
                        >
                          <img className="mb-3" src={giftcard.thumbnail_url} height="65px" width="100px" />
                        </div>
                        <div className="giftcard_category-name mt-3 d-flex justify-content-center">
                          <p className={parseInt(giftcard.id) === selectedDesignId ? 'futura-bold-15' : 'futura-bkbt-15'}>{giftcard.category_name}</p>
                        </div>
                      </div>

                    )}
                  </div>
                </div>

                <div className="col-6 giftcard_buy-right d-flex flex-column">
                  <p className="futura-bold-20 pt-3 pb-2 pl-3">Para:</p>
                  <div className="px-3 giftcard_destinatary-container d-flex justify-content-between">
                    <div className="giftcard_input-container">
                      <img src={name_img} alt="" />
                      <input type="text" placeholder="Nombre del destinatario" name="recipientName" value={recipientName} onChange={this.handleInput} />
                    </div>
                    <div className="pl-1 giftcard_input-container d-flex justify-conten-between align-items-center">
                      <img src={email_img} height="15" alt="" />
                      <input type="text" placeholder="E-mail del destinatario" name="recipientEmail" value={recipientEmail} onChange={this.handleInput} />
                    </div>
                  </div>

                  <div className="py-3 px-3 d-flex justify-content-between">
                    <p ><strong>Añande un mensaje:</strong></p>
                    <p className="giftcard_characters">{message.length}/200</p>
                  </div>
                  <div className="mb-3 mx-3 giftcard_message d-flex">
                    <div className="d-flex justify-content-center mt-2" style={{ width: '40px' }}>
                      <img src={message_img} height="18" alt="" />
                    </div>
                    <textarea type="text" placeholder="Escribe un mensaje" rows="5" maxLength="200" name="message" value={message} onChange={this.handleInput} ></textarea>
                  </div>
                  <button className="mx-3 giftcard_add-button" onClick={() => this.handleAddToCart()}>
                    <p className="futura-bkbt-15">Añadir al carrito</p>
                  </button>
                </div>
              </div>

            </div>

            <div className="row giftcard_exchange">
              <div className="col-6 d-flex justify-content-center align-items-center">
                <div className="d-flex flex-column align-items-center">
                  <p className="futura-bold-25">Canjear tarjeta de regalo</p>
                  <p className="futura-ltbt-15">El monto de tu tarjeta será abonado a tu cartera para que sea descontado en tu próxima compra.</p>
                  <div className="my-3 giftcard_exchange-input d-flex justify-content-center align-items-center">
                    <img src={exchange_code_img} alt="" />
                    <input name="exchangeCode" value={exchangeCode} placeholder=" Ingresa tu código" onChange={this.handleInput} />
                  </div>
                  <button className="giftcard_exchange-button" onClick={() => this.exchangeGiftcard()}>
                    <p className="futura-bkbt-15">Canjear ahora</p>
                  </button>
                </div>
              </div>
              <div className="col-6 giftcard_exchange-right d-flex justify-content-center align-items-center">
                <img src={exchange_card} height="220" alt="card" />
              </div>
            </div>
            <Footer history={this.props.history}/>

          </ div >
        } />

    );
  }
}

const mapStateToProps = state => ({
  cartProducts: state.collections.cartProducts
});

const mapDispatchToProps = {
  changeRouteAction: changeRouteAction
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(GiftCards)