import ApiService from './ApiService';

class ApiQuotes {

  constructor() {
    this.quotesAlbumsPath = 'quotes/albums/';
    this.quotesAlbumDetailPath = 'quotes/album/';
  }

  getQuotesAlbums = (payload) => {
    return ApiService.makeRequest(this.quotesAlbumsPath, payload);
  }

  getQuotesAlbumDetail = (payload) => {
    return ApiService.makeRequest(this.quotesAlbumDetailPath, payload);
  }
}

export default new ApiQuotes();