export const createCollectionData = (collection) => {
    // Sort the photos before create the books
    if (collection.photos.length > 0) {
        // The array is sorted by its index
        collection.photos.sort(function(a, b) {
            if (a.index > b.index) {
                return 1;
            }
            if (a.index < b.index) {
                return -1;
            }
            return 0;
        });
    }
    let collectionBooks = [];
    // Deep clone to update the collection after create the books
    const photos = JSON.parse(JSON.stringify(collection.photos));
    if (photos.length > 0) {
        const chunkSize = 60;
        let bookIndex = 0;
        while (photos.length > 0) {
            let photosOfBook = photos.splice(0, chunkSize);
            let completeBook = {
                cover_url: photosOfBook[0].print_url,
                photos: photosOfBook,
                index: bookIndex
            }
            collectionBooks.push(completeBook);
            bookIndex += 1;
        }
    }
    const covers = collection.covers;
    if (covers.length > 0) {
        for (let cover of covers) {
            if (collectionBooks.length > cover.index) {
                collectionBooks[cover.index].cover_url = (
                    cover.image_url.length > 0 ? cover.image_url : cover.predesign_data.thumbnail_square_url
                );
                collectionBooks[cover.index].predesign_data = cover.predesign_data;
            }
        }
    }
    // We update the selected collection in order to have all data from the server
    localStorage.setItem('SELECTED_COLLECTION', JSON.stringify(collection));
    return { collection: collection, collectionBooks: collectionBooks }
}