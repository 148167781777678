import React, { Component } from 'react';
import SelectOptions from '../../components/SelectOptions';
import Cropper from 'react-easy-crop';
import Slider from 'rc-slider';
import { compose } from 'redux';
import { firebaseConnect } from 'react-redux-firebase';
import { getCroppedImgV2 } from '../../utils/CropImage';
import ApiCustomer from '../../utils/Api/ApiCustomer';
import TokenHelper from '../../utils/Firebase/TokenHelper';
import firebase from 'firebase/app';
import "firebase/app";
import "firebase/storage";

import close_gray from '../../images/newDesignImages/close-gray.png';
import arrow_left from '../../images/newDesignImages/slider-left.png';
import arrow_right from '../../images/newDesignImages/slider-right.png';
import crop_inactive from '../../images/newDesignImages/crop-inactive.png';
import crop_active from '../../images/newDesignImages/crop-active.png';
import duplicate_inactive from '../../images/newDesignImages/duplicate-inactive-2.png';
import duplicate_active from '../../images/newDesignImages/duplicate-active-2.png';
import delete_inactive from '../../images/newDesignImages/delete-inactive.png';
import delete_active from '../../images/newDesignImages/delete-active.png';
import minus_img from '../../images/newDesignImages/-black.png';
import plus_img from '../../images/newDesignImages/+black.png';
import minus_inactive from '../../images/newDesignImages/minus-inactive.png';
import minus_active from '../../images/newDesignImages/minus-active.png';
import plus_active from '../../images/newDesignImages/plus-active.png';
import warning_icon from '../../images/newDesignImages/warning-icon.png';
import './PhotoDetailCanvas.scss';


const selectOptionsArray = [
  {
    title: 'Cortar',
    img_inactive: crop_inactive,
    img_active: crop_active,
    name: 'crop'
  },
  {
    title: 'Duplicar',
    img_inactive: duplicate_inactive,
    img_active: duplicate_active,
    name: 'duplicate'
  },
  {
    title: 'Eliminar',
    img_inactive: delete_inactive,
    img_active: delete_active,
    name: 'delete'
  }
]

class PhotoDetailCanvas extends Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedPhotoForEdit: null,
      optionToUpdate: '',
      crop: { x: 0, y: 0 },
      zoom: 1,
      aspect: 1,
      croppedAreaPixels: null,
      croppedImage: null,
      duplicateQuantity: 1
    }
  }

  componentDidMount() {
    this.setState({selectedPhotoForEdit: this.props.selectedPhotoForEdit})
  }

  onClickPhotoNext = () => {
    const nextPhoto = this.props.photos[this.state.selectedPhotoForEdit.index + 1];
    this.setState({
      selectedPhotoForEdit: nextPhoto,
      optionToUpdate: '',
      crop: { x: 0, y: 0 },
      zoom: 1,
      aspect: 1,
      croppedAreaPixels: null,
      croppedImage: null,
      duplicateQuantity: 1
    });
  }

  onClickPhotoPrevious = () => {
    const nextPhoto = this.props.photos[this.state.selectedPhotoForEdit.index - 1];
    this.setState({
      selectedPhotoForEdit: nextPhoto,
      optionToUpdate: '',
      crop: { x: 0, y: 0 },
      zoom: 1,
      aspect: 1,
      croppedAreaPixels: null,
      croppedImage: null,
      duplicateQuantity: 1
    });
  }

  selectOptionToUpdate = (selectedOption) => {
    if (selectedOption === 'delete') {
      const { handleDeleteImages, handleClosePhotoDetail, photos, showDeleteConfirmationHandler } = this.props;
      const { selectedPhotoForEdit } = this.state;
      if (photos.length - 1 === 1) {
        return;
      } else {
        showDeleteConfirmationHandler(selectedPhotoForEdit);
        handleClosePhotoDetail();
      }
    }
    this.setState({ optionToUpdate: selectedOption });
  }

  onCropChange = crop => {
    this.setState({ crop });
  }

  onCropComplete = (croppedArea, croppedAreaPixels) => {
    this.setState({ croppedAreaPixels });
  }

  onZoomChange = zoom => {
    this.setState({ zoom });
  }

  handleSlider = (value) => {
    let zoom = value / 100;
    this.setState({ zoom });
  }

  handleSaveButton = () => {
    const { optionToUpdate, croppedAreaPixels, selectedPhotoForEdit,  duplicateQuantity } = this.state;
    if (optionToUpdate === 'crop') {
      const image = this.refs.image;
      this.handleCroppedImage(image, croppedAreaPixels);
    } else if (optionToUpdate === 'duplicate') {
      let photos = [];
      let index = this.props.photos.length - 1;
      let counter = 0;
      while (counter < duplicateQuantity) {
        let newPhoto = {
          ...selectedPhotoForEdit,
          image_url: selectedPhotoForEdit.image_url.slice(0, selectedPhotoForEdit.image_url.indexOf('?')),
          print_url: selectedPhotoForEdit.print_url.slice(0, selectedPhotoForEdit.print_url.indexOf('?')),
          index: index + counter
        }
        delete newPhoto.id;
        photos.push(newPhoto);
        counter += 1;
      }
      this.props.addImagesToCanvas(this.props.canvasId, true, photos);
    }
  }

  handleCroppedImage = async(image, croppedAreaPixels) => {
    this.props.handleClosePhotoDetail('crop')
    const file = await getCroppedImgV2(image, croppedAreaPixels)
    await this.uploadCoverToS3(file, croppedAreaPixels.width, croppedAreaPixels.height);
  }

  uploadCoverToS3 = (file, width, height) => {
    return new Promise(resolve => {
      const updatePhoto = this.updatePhoto;
      let r = (Math.random() + 1).toString(36).substring(5);
      var path = this.props.userId + '/image_' + Date.now() + "_" + r + '.jpg';
      const storage = firebase.storage();
      const uploadRef = storage.ref().child(path);
      const uploadTask = uploadRef.put(file);
      
      uploadTask.on('state_changed', 
        (snapshot) => {
          switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED:
              break;
            case firebase.storage.TaskState.RUNNING:
              break;
          }
        }, 
        (error) => {
          this.props.handleCropImageError('Ocurrió un error, intenta más tarde - ' + error);
          resolve();
        }, 
        () => {
          // Handle successful uploads on complete
          const baseUrl = window._env_.GCS_BUCKET_BASE_URL;
          const imageUrl = baseUrl + path;
          updatePhoto(imageUrl, width, height);
          resolve();
        }
      );
    })
  }

  updatePhoto = (imageUrl, width, height) => {
    this.updatePhotoCanvas(this.props.canvasId, this.state.selectedPhotoForEdit.id, imageUrl, width, height);
  }

  updatePhotoCanvas = (canvasProjectId, imageId, imageUrl, width, height) => {
    TokenHelper.getUserToken().then(idToken => {
      ApiCustomer.updatePhotoCanvas({
        token: idToken,
        picture_project_id: canvasProjectId,
        image_id: imageId,
        image_url: imageUrl,
        width: width,
        height: height
      }).then(response => {
        if (response.data.success) {
          this.props.handleCropImageSuccess();
        } else {
          this.props.handleCropImageError('Ocurrió un error, intenta más tarde');
        }
      })
      .catch(error => this.props.handleCropImageError('Ocurrió un error, intenta más tarde'));
    })
    .catch(error => this.props.handleCropImageError('Ocurrió un error, intenta más tarde'));
  }

  handleDuplicateQuantity = (plusOrMinus) => {
    const { duplicateQuantity } = this.state;
    if (plusOrMinus === 'plus') {
      this.setState({duplicateQuantity: duplicateQuantity + 1});
    } else {
      this.setState({duplicateQuantity: duplicateQuantity - 1});
    }
  }

  render() {
    const { photos, handleClosePhotoDetail, canvasSelectedFrame } = this.props;
    const { selectedPhotoForEdit, optionToUpdate, duplicateQuantity } = this.state;
    const frameStyles = {
      backgroundImage: 'url(' + canvasSelectedFrame + ')',
      backgroundSize: 'cover'
    }
    const renderEditOptions = (selectedOption) => {
      switch (selectedOption) {
        case 'crop':
          return (
            <div className="photo-detail-canvas_edit py-1 d-flex flex-column align-items-center">
              <div className="w-50 d-flex justify-content-around align-items-center">
                <img className="mr-2" src={minus_img} height="5" alt="" />
                <Slider
                  min={100}
                  max={200}
                  onChange={this.handleSlider}
                />
                <img className="ml-2" src={plus_img} height="10" alt="" />
              </div>
              <p className="gotham-book-15 pt-2">Haz zoom y mueve tu foto</p>
            </div>
          );
        case 'duplicate':
          return (
            <div className="photo-detail-canvas_edit d-flex justify-content-center align-items-center">
              <div className={duplicateQuantity !== 1 ? "photo-detail_duplicate-btn d-flex justify-content-center align-items-center cursor-pointer" : "photo-detail_duplicate-btn-opaque d-flex justify-content-center align-items-center"}   onClick={() => (duplicateQuantity !== 1 ? this.handleDuplicateQuantity('minus') : null)}>
                <img src={duplicateQuantity !== 1 ? minus_active : minus_inactive} height="5" alt=""/>
              </div>
              <p className="mx-5 gotham-book-15">{duplicateQuantity}</p>
              <div className="photo-detail_duplicate-btn d-flex justify-content-center align-items-center cursor-pointer" onClick={() => this.handleDuplicateQuantity('plus')}>
                <img src={plus_active} height="10" alt=""/>
              </div>
            </div>
          );
        default:
          return (
            <div className="photo-detail-canvas_edit"></div>
          );
      }
    }
    return (
      selectedPhotoForEdit &&
      <div className="photo-detail-canvas_container d-flex justify-content-center align-items-center">
        {
          selectedPhotoForEdit.index !== 0 &&
          <div className="pr-4 cursor-pointer" onClick={this.onClickPhotoPrevious}>
            <img src={arrow_left} alt="" />
          </div>
        }
        <div className="photo-detail-canvas_inside-container">
          <div className="photo-detail-canvas_top pr-4 d-flex flex-column justify-content-center align-items-center">
            <p className="gotham-book-18">Pixycuadro: {selectedPhotoForEdit.index + 1} de {photos.length - 1}</p>
            <img className="photo-detail-canvas_close-img cursor-pointer" src={close_gray} height="20" alt="" onClick={() => handleClosePhotoDetail(null)}/>
          </div>

          {optionToUpdate === 'crop' ? (
            <div className="photo-detail-canvas_center d-flex justify-content-center align-items-center">
              <div className="photo-detail_crop-container">
                <img ref="image" className="photo-detail_img" crossOrigin="anonymous" style={{display: 'none'}} src={selectedPhotoForEdit.print_url} alt=""/>
                <Cropper
                  image={selectedPhotoForEdit.print_url}
                  crop={this.state.crop}
                  zoom={this.state.zoom}
                  minZoom={1}
                  maxZoom={2}
                  aspect={this.state.aspect}
                  onCropChange={this.onCropChange}
                  onCropComplete={this.onCropComplete}
                  onZoomChange={this.onZoomChange}
                  disableAutomaticStylesInjection={true}
                />
              </div>
            </div>
            ) :(
            <div className="photo-detail-canvas_center d-flex justify-content-center align-items-center">
              <div style={frameStyles} className="photo-detail-canvas_img-container d-flex justify-content-center align-items-center">
                <img className="photo-detail-canvas_img" crossOrigin="anonymous" src={selectedPhotoForEdit.print_url} width="59%" height="59%" alt=""/>
              </div>
            </div>
          )}

          <div className="photo-detail-canvas_bottom">
            {(selectedPhotoForEdit.width < 1200 && selectedPhotoForEdit.height < 1200) ?
              <div className="photo-detail-canvas_waning d-flex align-items-center justify-content-center px-2 pb-2">
                <img src={warning_icon} height="25" alt=""/>
                <p className="gotham-md-gray-15 ml-2">Baja resolución</p>
              </div> : 
              <div className="photo-detail-canvas_waning"></div>
            }
            {(selectedPhotoForEdit && selectedPhotoForEdit.source !== 'quotes') &&
              <SelectOptions 
                selectOptionsArray={selectOptionsArray}
                selectOptionToUpdate={this.selectOptionToUpdate}
                optionToUpdate={optionToUpdate}
                fromCanvas
              />
            }
            {renderEditOptions(optionToUpdate)}
            {(selectedPhotoForEdit && selectedPhotoForEdit.source !== 'quotes') &&
              <div className="photo-detail-canvas_save-btn d-flex justify-content-center align-items-center">
                <button className="generic-button-blue" type="submit" onClick={this.handleSaveButton}>Guardar</button>
              </div>
            }
          </div>
        </div>
        {
          selectedPhotoForEdit.index < photos.length - 2 &&
          <div className="pl-4 cursor-pointer" onClick={this.onClickPhotoNext}>
            <img src={arrow_right} alt="" />
          </div>
        }
      </div>
    );
  }
}

export default compose(firebaseConnect())(PhotoDetailCanvas);