import React from 'react';
import './AlbumInfoTable.scss';


export default function AlbumInfoTable(props) {
  return (
    <div className="albuminfo_main">
      <div className="albuminfo_title d-flex justify-content-center align-items-center">
        <p className="gotham-bold-white-15">Tipos de fotolibros</p>
      </div>
      <div className="w-100 mt-2 d-flex justify-content-between">
        <div className="albuminfo_left-odd d-flex justify-content-center align-items-center">
          <p className="gotham-bold-darkgray-15">Precio</p>
        </div>
        <div className="albuminfo_right-odd d-flex justify-content-center align-items-center">
          <p className="gotham-book-gray-15">$329 - $739</p>
        </div>
      </div>

      <div className="w-100 mt-2 d-flex justify-content-between">
        <div className="albuminfo_left-even d-flex justify-content-center align-items-center">
          <p className="gotham-bold-darkgray-15">Tamaño</p>
        </div>
        <div className="albuminfo_right-even d-flex justify-content-center align-items-center">
          <p className="gotham-book-gray-15">16x16 cm / 21x21 cm</p>
        </div>
      </div>

      <div className="w-100 mt-2 d-flex justify-content-between">
        <div className="albuminfo_left-odd d-flex justify-content-center align-items-center">
          <p className="gotham-bold-darkgray-15">Pasta</p>
        </div>
        <div className="albuminfo_right-odd d-flex justify-content-center align-items-center">
          <p className="gotham-book-gray-15">Dura / Suave</p>
        </div>
      </div>

      <div className="w-100 mt-2 d-flex justify-content-between">
        <div className="albuminfo_left-even d-flex justify-content-center align-items-center">
          <p className="gotham-bold-darkgray-15">Interiores</p>
        </div>
        <div className="albuminfo_right-even d-flex justify-content-center align-items-center">
          <p className="gotham-book-gray-15">Couche 150gr</p>
        </div>
      </div>

      <div className="w-100 mt-2 d-flex justify-content-between">
        <div className="albuminfo_left-odd d-flex justify-content-center align-items-center">
          <p className="gotham-bold-darkgray-15">Páginas</p>
        </div>
        <div className="albuminfo_right-odd d-flex justify-content-center align-items-center">
          <p className="gotham-book-gray-15">60/ 80/ 100</p>
        </div>
      </div>

      <div className="w-100 mt-2 d-flex justify-content-between">
        <div className="albuminfo_left-even d-flex justify-content-center align-items-center">
          <p className="gotham-bold-darkgray-15">Envío</p>
        </div>
        <div className="albuminfo_right-even d-flex justify-content-center align-items-center">
          <p className="gotham-book-gray-15">7 a 10 días hábiles</p>
        </div>
      </div>
      <div className="d-flex flex-column">
        {props.pricesBtnDisabled ? 
          <button className="mt-3 mb-3 albuminfo_prices-btn" disabled onClick={props.handleShowPrices}>
            Consulta precios
          </button> :
          <button className="mt-3 mb-3 albuminfo_prices-btn" onClick={props.handleShowPrices}>
            Consulta precios
          </button>
        }
        <button className="mb-3 albuminfo_start-btn" onClick={() => props.handleStartProject(props.productType)}>
          Comenzar
        </button>
      </div>

    </div>
  );
}