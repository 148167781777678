import React, { Component } from 'react';
import './PhotoDetail.scss';

// Redux
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firebaseConnect } from 'react-redux-firebase';
import { selectPhotoAction, showPhotoDetailAction, updatePhotoDetailAction } from '../../actions/CustomerActions';
import { validateFormAction, validationSuccess, validationError, resetValidationFormAction } from '../../actions/ValidateFormsActions';
import Cropper from 'react-easy-crop';
import Slider from 'rc-slider';
import { getCroppedImgV2, getRotatedImg } from '../../utils/CropImage';
import { formattedDate } from '../../utils/Parsers';
import firebase from 'firebase/app';
import "firebase/app";
import "firebase/storage";

//Components
import Scrollable from '../../components/Scrollable';
import SelectOptions from '../../components/SelectOptions';
import Loading from '../../components/Loading';

import img_arrow_left from '../../images/arrow-left.png';
import img_arrow_right from '../../images/arrow-right.png';
import arrow_left from '../../images/newDesignImages/slider-left.png';
import arrow_right from '../../images/newDesignImages/slider-right.png';
import back_arrow from '../../images/newDesignImages/slider-left.png';
import close_img from '../../images/newDesignImages/close-createproject.png';
import calendar_img from '../../images/newDesignImages/calendar-img.png';
import message_img from '../../images/newDesignImages/message-img.png';

import close_gray from '../../images/newDesignImages/close-gray.png';
import rotate_img from '../../images/newDesignImages/rotate-img.png';
import crop_inactive from '../../images/newDesignImages/crop-inactive.png';
import crop_active from '../../images/newDesignImages/crop-active.png';
import rotate_inactive from '../../images/newDesignImages/rotate-inactive.png';
import rotate_active from '../../images/newDesignImages/rotate-active.png';
import calendar_inactive from '../../images/newDesignImages/calendar-inactive.png';
import calendar_active from '../../images/newDesignImages/calendar-active.png';
import comment_inactive from '../../images/newDesignImages/comment-inactive.png';
import comment_active from '../../images/newDesignImages/comment-active.png';
import delete_inactive from '../../images/newDesignImages/delete-inactive.png';
import delete_active from '../../images/newDesignImages/delete-active.png';
import minus_img from '../../images/newDesignImages/-black.png';
import plus_img from '../../images/newDesignImages/+black.png';
import warning_icon from '../../images/newDesignImages/warning-icon.png';


const selectOptionsArray = [
  {
    title: 'Cortar',
    img_inactive: crop_inactive,
    img_active: crop_active,
    name: 'crop'
  },
  {
    title: 'Rotar',
    img_inactive: rotate_inactive,
    img_active: rotate_active,
    name: 'rotate'
  },
  {
    title: 'Fecha',
    img_inactive: calendar_inactive,
    img_active: calendar_active,
    name: 'date'
  },
  {
    title: 'Comentario',
    img_inactive: comment_inactive,
    img_active: comment_active,
    name: 'comment'
  },
  {
    title: 'Eliminar',
    img_inactive: delete_inactive,
    img_active: delete_active,
    name: 'delete'
  },
]


class PhotoDetail extends Component {

  constructor(props) {
    super(props);
    this.state = {
      detailPhoto: {
        id: '',
        image_url: '',
        print_url: '',
        album_id: '',
        index: null,
        title: '',
        date: '',
        editable: '',
        source: '',
        source_id: '',
        is_truncated_text: null,
        width: null,
        height: null,
        download: null
      },
      initailTitle: '',
      initialDate: '',
      initialImageUrl: '',
      countCharacters: null,
      error: false,
      show: false,
      optionToUpdate: '',
      crop: { x: 0, y: 0 },
      zoom: 1,
      aspect: 1,
      croppedAreaPixels: null,
      croppedImage: null,
      imgFile: null,
      isLoading: false,
      clickOnPreviusOrNext: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSaveButton = this.handleSaveButton.bind(this);
    this.validateAndAutoSubmit = this.validateAndAutoSubmit.bind(this);
  }

  componentDidMount() {
    const { photoDetail, collectionSelected } = this.props;
    if (collectionSelected === null) {
      this.props.history.push('/collection/albums/detail');
    } else {
      this.setState({
        detailPhoto: {
          id: photoDetail.id,
          image_url: photoDetail.image_url,
          print_url: photoDetail.print_url,
          album_id: collectionSelected.id,
          index: photoDetail.index,
          title: photoDetail.title,
          date: photoDetail.date,
          editable: photoDetail.editable,
          source: photoDetail.source,
          source_id: photoDetail.source_id,
          is_truncated_text: photoDetail.is_truncated_text,
          width: photoDetail.width,
          height: photoDetail.height,
          download: photoDetail.download
        },
        initailTitle: photoDetail.title,
        initialDate: photoDetail.date,
        initialImageUrl: photoDetail.print_url,
        countCharacters: (this.props.photoDetail.title) ? this.props.photoDetail.title.length : 0
      });
    }
  }

  updateStateWithSelectedPhoto = (selectedPhoto) => {
    const { collectionSelected } = this.props;
    this.setState({
      detailPhoto: {
        id: selectedPhoto.id,
        image_url: selectedPhoto.image_url,
        print_url: selectedPhoto.print_url,
        album_id: collectionSelected.id,
        index: selectedPhoto.index,
        title: selectedPhoto.title,
        date: selectedPhoto.date,
        editable: selectedPhoto.editable,
        source: selectedPhoto.source,
        source_id: selectedPhoto.source_id,
        is_truncated_text: selectedPhoto.is_truncated_text,
        width: selectedPhoto.width,
        height: selectedPhoto.height,
        download: selectedPhoto.download
      },
      initailTitle: selectedPhoto.title,
      initialDate: selectedPhoto.date,
      initialImageUrl: selectedPhoto.print_url,
      countCharacters: (selectedPhoto.title) ? selectedPhoto.title.length : 0,
      error: false,
      show: false,
      crop: { x: 0, y: 0 },
      zoom: 1,
      aspect: 1,
      croppedAreaPixels: null,
      croppedImage: null,
      optionToUpdate: '',
      imgFile: null
    });
  }

  handleChange(e) {
    const name = e.target.name;
    const value = e.target.value;
    if (name === 'date') {
      if (this.isValidateDate(value.split("-").reverse().join("-"))) {
        this.setState({
          detailPhoto: {
            ...this.state.detailPhoto,
            [name]: value.split("-").reverse().join("-"),
          },
          countCharacters: (name === 'title') ? value.length : this.state.countCharacters
        })
      }
    } else {
      this.setState({
        detailPhoto: {
          ...this.state.detailPhoto,
          [name]: value,
        },
        countCharacters: (name === 'title') ? value.length : this.state.countCharacters
      });
    }
  };

  handleSaveButton(e) {
    e.preventDefault();
    this.props.validateFormAction();
    const { detailPhoto, imgFile, optionToUpdate, croppedAreaPixels } = this.state;
    const { collectionSelected } = this.props;
    if (detailPhoto.date === '') {
      this.props.validationError();
      this.sleep();
      return;
    } else {
      if (this.isValidateDate(detailPhoto.date)) {
        this.props.validationSuccess();
        if (imgFile && optionToUpdate !== 'crop') {
          const width = this.refs.image.width;
          const height = this.refs.image.height;
          this.uploadCoverToS3(imgFile, width, height);
        } else if (optionToUpdate === 'crop') {
          const image = this.refs.image;
          this.handleCroppedImage(image, croppedAreaPixels);
        } else {
          this.setState({isLoading: true})
          this.updatePhoto(null, null, null)
        }
      } else {
        this.props.validationError();
        this.sleep();
        return;
      }
    }
  }

  sleep = () => {
    this.timeoutId = setTimeout(function () {
      this.setState({
        ...this.state,
        show: true
      });
      this.props.resetValidationFormAction();
    }.bind(this), 1500);
  }

  onClickPhotoNext = async() => {
    const { collectionSelected, showPhotoDetailAction } = this.props;
    const collectionPhotos = collectionSelected.photos;
    await this.validateAndAutoSubmit();
    if (collectionPhotos.length - 1 > this.state.detailPhoto.index) {
      const nextPhoto = collectionPhotos[this.state.detailPhoto.index + 1];
      showPhotoDetailAction(nextPhoto);
      this.updateStateWithSelectedPhoto(nextPhoto);
    }
  }

  onClickPhotoPrevious = async() => {
    const { collectionSelected, showPhotoDetailAction } = this.props;
    const collectionPhotos = collectionSelected.photos;
    await this.validateAndAutoSubmit();
    if (this.state.detailPhoto.index > 0) {
      const previousPhoto = collectionPhotos[this.state.detailPhoto.index - 1];
      showPhotoDetailAction(previousPhoto);
      this.updateStateWithSelectedPhoto(previousPhoto);
    }
  }

  // Validates if form has changes and sends data to API if so
  validateAndAutoSubmit = async() => {
    const { collectionSelected } = this.props;
    const { initailTitle, initialDate, detailPhoto, crop, imgFile, optionToUpdate, croppedAreaPixels } = this.state;
    if (initailTitle !== detailPhoto.title || initialDate !== detailPhoto.date || crop.x !== 0 || crop.y !== 0 || imgFile) {
      this.setState({clickOnPreviusOrNext: true});
      if (imgFile && optionToUpdate !== 'crop') {
        const width = this.refs.image.width;
        const height = this.refs.image.height;
        await this.uploadCoverToS3(imgFile, width, height);
      } else if (optionToUpdate === 'crop') {
        const image = this.refs.image;
        await this.handleCroppedImage(image, croppedAreaPixels)
      } else {
        await this.props.updatePhotoDetailAction(collectionSelected, detailPhoto);
      }
    }
  }

  continueAlbumDetail = () => {
    this.props.history.push('/collection/albums/detail');
  }

  isValidateDate = (date) => {
    if (!/^\d{1,2}-\d{1,2}-\d{4}$/.test(date))
      return false;

    var parts = date.split("-");
    var day = parseInt(parts[0], 10);
    var month = parseInt(parts[1], 10);
    var year = parseInt(parts[2], 10);

    if (year < 1000 || year > 3000 || month === 0 || month > 12)
      return false;

    var monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0))
      monthLength[1] = 29;

    return day > 0 && day <= monthLength[month - 1];
  }

 
  //////////////////

  selectOptionToUpdate = (selectedOption) => {
    const {handleClosePhotoDetail, showDeleteConfirmationHandler, selectPhotoForDeleteHandler, handleDeletePage, selectedPageId} = this.props;
    if (selectedOption === 'delete') {
      
      // const selectPhotoAndCloseModal = async () => {
      //   await selectPhotoForDeleteHandler(this.state.detailPhoto);
      //   await handleClosePhotoDetail('');
      // }
      // selectPhotoAndCloseModal().then(() => showDeleteConfirmationHandler());

      handleDeletePage(selectedPageId);
      handleClosePhotoDetail('');
    } else if (this.state.optionToUpdate === 'crop' && selectedOption !== 'crop') {
      this.validateChangesOnCrop();
    } else if (selectedOption === 'rotate') {
      this.handleRotation();
    }
    this.setState({optionToUpdate: selectedOption});
  }

  validateChangesOnCrop = () => {
    const {crop, detailPhoto, croppedAreaPixels} = this.state;
    if (crop.x !== 0 || crop.y !== 0) {
      const imageTest = this.refs.image;
      getCroppedImgV2(imageTest, croppedAreaPixels).then(file => {
        const newImage = window.URL.createObjectURL(file)
        this.setState({
          detailPhoto: {
            ...detailPhoto,
            print_url: newImage
          },
          crop: { x: 0, y: 0 },
          zoom: 1,
          aspect: 1,
          croppedAreaPixels: null,
          croppedImage: null,
          imgFile: file
        });
      }).catch(error => console.log('err', error));
    } 
  }

  handleCroppedImage = async(image, croppedAreaPixels) => {
    const file = await getCroppedImgV2(image, croppedAreaPixels)
    await this.uploadCoverToS3(file, croppedAreaPixels.width, croppedAreaPixels.height);
    return;
  }

  onCropChange = crop => {
    this.setState({ crop });
  }

  onCropComplete = (croppedArea, croppedAreaPixels) => {
    this.setState({ croppedAreaPixels });
  }

  onZoomChange = zoom => {
    this.setState({ zoom });
  }

  handleSlider = (value) => {
    let zoom = value / 100;
    this.setState({ zoom });
  }

  handleRotation = () => {
    const { detailPhoto } = this.state;
    const image = this.refs.image;
    getRotatedImg(image).then(file => {
      const newImage = window.URL.createObjectURL(file)
      this.setState({
        detailPhoto: {
          ...detailPhoto,
          print_url: newImage
        },
        imgFile: file
      });
    })
  }

  handleRestore = () => {
    this.setState({
      detailPhoto: {
        ...this.state.detailPhoto,
        print_url: this.state.initialImageUrl
      },
      crop: { x: 0, y: 0 },
      zoom: 1,
      aspect: 1,
      croppedAreaPixels: null,
      imgFile: null
    });
  }

  uploadCoverToS3 = (file, width, height) => {
    this.setState({isLoading: true})
    return new Promise(resolve => {
      const updatePhoto = this.updatePhoto;
      let r = (Math.random() + 1).toString(36).substring(5);
      var path = this.props.firebase.auth.uid + '/image_' + Date.now() + "_" + r + '.jpg';
      const storage = firebase.storage();
      const uploadRef = storage.ref().child(path);
      const uploadTask = uploadRef.put(file);
      
      uploadTask.on('state_changed', 
        (snapshot) => {
          switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED:
              break;
            case firebase.storage.TaskState.RUNNING:
              break;
          }
        }, 
        (error) => {
          console.log('err', error)
          this.setState({isLoading: false})
          resolve();
        }, 
        () => {
          // Handle successful uploads on complete
          const baseUrl = window._env_.GCS_BUCKET_BASE_URL;
          const imageUrl = baseUrl + path;
          updatePhoto(imageUrl, width, height);
          resolve();
        }
      );
    })
  }


  updatePhoto = async(imageUrl, width, height) => {
    const { collectionSelected, loadCollectionData, selectedPageId} = this.props;
    const { detailPhoto, clickOnPreviusOrNext } = this.state;
    if (imageUrl && width && height) {
      await this.props.updatePhotoDetailAction(collectionSelected, detailPhoto, selectedPageId, imageUrl, width, height);
      if (clickOnPreviusOrNext) {
        loadCollectionData();
        this.setState({
          clickOnPreviusOrNext: false,
          isLoading: false
        });
      } else {
        this.props.handleClosePhotoDetail('update');
      }
    } else {
      await this.props.updatePhotoDetailAction(collectionSelected, detailPhoto, selectedPageId);
      clickOnPreviusOrNext ? this.setState({clickOnPreviusOrNext: false}) : this.props.handleClosePhotoDetail('update');
    }    
  }


  render() {
    const { collectionSelected, photoDetail, form_error, handleClosePhotoDetail } = this.props;
    const numberOfPhotos = collectionSelected ? collectionSelected.photos.length : 0;
    const parsedDate = this.state.detailPhoto.date.split("-").reverse().join("-");
    const photoPage = (this.state.detailPhoto.index + 1) % 60 === 0 ? 60 : (this.state.detailPhoto.index + 1) % 60;
    const datePosition = this.state.detailPhoto.index % 2 === 0 ? 'end' : 'start';
    const renderEditOption = (selectedOption) => {
      switch (selectedOption) {
        case 'crop':
          return (
            <div className="photo-detail_edit py-3 d-flex flex-column align-items-center">
              <div className="w-50 d-flex justify-content-around align-items-center">
                <img className="mr-2" src={minus_img} height="5" alt="" />
                <Slider
                  min={100}
                  max={200}
                  onChange={this.handleSlider}
                />
                <img className="ml-2" src={plus_img} height="10" alt="" />
              </div>
              <p className="gotham-book-18 pt-2">Haz zoom y mueve tu foto</p>
            </div>
          );
        case 'date':
          return (
            <div className="photo-detail_edit d-flex justify-content-center align-items-center">
              <p className="mr-2">Fecha:</p>
              <input className="photo-detail_edit-date d-flex justify-content-center" type="date" name="date" value={parsedDate} onChange={this.handleChange} />
            </div>
          );
        case 'comment':
          return (
            <div className="photo-detail_edit d-flex justify-content-center">
              <div className="photo-detail_edit-comment-container">
                <div className="mr-3 mb-1 d-flex justify-content-end">
                  <p className="gotham-md-blue-12">{this.state.detailPhoto.title.length}/60</p>
                </div>
                <div className="px-2 photo-detail_comment">
                  <textarea type="text" placeholder="Agrega un comentario" rows="5" name="title" maxLength="60" value={this.state.detailPhoto.title} onChange={this.handleChange}></textarea>
                </div>
              </div>
            </div>
          );

        default:
          return (
            <div className="photo-detail_edit"></div>
          );
      }
    }
    return (
      (photoDetail !== null) && 
      this.state.isLoading ?  <Loading alternativeLoading /> :
      <div className="photo-detail_container d-flex justify-content-center align-items-center">
        {
          this.state.detailPhoto.index !== 0 &&
          <div className="pr-4 cursor-pointer" onClick={this.onClickPhotoPrevious}>
            <img src={arrow_left} alt="" />
          </div>
        }
        <div className="photo-detail_inside-container">
          <img className="photo-detail_close-img cursor-pointer" src={close_gray} height="20" alt="" onClick={() => handleClosePhotoDetail('')}/>
          
          {this.state.optionToUpdate === 'crop' ? (
            <div className="photo-detail_center d-flex flex-column justify-content-end align-items-center">
              <div className="photo-detail_restore pb-1 d-flex justify-content-end align-items-center">
                <button onClick={this.handleRestore}>Restaurar</button>
              </div>
              <div className="photo-detail_crop-container">
              {/* <img className="photo-detail_rotate-btn cursor-pointer" src={rotate_img} onClick={this.handleRotation} alt=""/> */}
                <img ref="image" className="photo-detail_img" crossOrigin="anonymous" style={{display: 'none'}} src={this.state.detailPhoto.print_url} alt=""/>
                <Cropper
                  image={this.state.detailPhoto.print_url}
                  crop={this.state.crop}
                  zoom={this.state.zoom}
                  minZoom={1}
                  maxZoom={2}
                  aspect={this.state.aspect}
                  onCropChange={this.onCropChange}
                  onCropComplete={this.onCropComplete}
                  onZoomChange={this.onZoomChange}
                  disableAutomaticStylesInjection={true}
                />
              </div>
            </div> 
          ) : (
            <div className="photo-detail_center d-flex flex-column justify-content-center align-items-center">
              <div className="photo-detail_restore pb-1 d-flex justify-content-end align-items-center">
                {this.state.detailPhoto.source !== 'quotes' && <button onClick={this.handleRestore}>Restaurar</button>}
              </div>
              <form onSubmit={this.handleSaveButton}>
                <div className="photo-detail_photo-frame d-flex flex-column align-items-center justify-content-center shadow">
                  <div className={`photo-detail_date-container d-flex justify-content-${datePosition} align-items-end`} >
                    {(collectionSelected.print_dates && this.state.detailPhoto.editable) && <p className="gotham-md-12">{formattedDate(parsedDate)}</p>}
                  </div>
                  <div className="photo-detail_img-container">
                    <img ref="image" crossOrigin="anonymous" style={{display: 'none'}} src={this.state.detailPhoto.print_url} alt=""/>
                    <img className="photo-detail_img" crossOrigin="anonymous" src={this.state.detailPhoto.print_url} width="100%" height="100%" alt=""/>
                  </div>
                  <div className="photo-detail_comment-container">
                    <p className="photo-detail_underimagetext pt-1">{this.state.detailPhoto.title}</p>
                  </div>
                </div>
              </form>
            </div>
            )
          }

          <div className="photo-detail_bottom">
            {(this.state.detailPhoto.width < 640 && this.state.detailPhoto.height < 640 && this.state.optionToUpdate !== 'crop') ?
              <div className="photo-detail_warning d-flex align-items-center justify-content-center px-2">
                <img src={warning_icon} height="20" alt=""/>
                <p className="gotham-md-gray-15 ml-2">Baja resolución</p>
              </div> : 
              <div className="photo-detail_warning"></div>
            }
            {this.state.detailPhoto.source !== 'quotes' &&
              <SelectOptions 
                selectOptionsArray={selectOptionsArray}
                selectOptionToUpdate={this.selectOptionToUpdate}
                optionToUpdate={this.state.optionToUpdate}
              />
            }            
            {renderEditOption(this.state.optionToUpdate)}
            {this.state.detailPhoto.source !== 'quotes' &&
              <div className="photo-detail_save-cancel-buttons d-flex justify-content-center align-items-center">
                <button className="generic-button-blue" type="submit" onClick={this.handleSaveButton}>Guardar</button>
              </div>
            }
          </div>

        </div>
        {
          this.state.detailPhoto.index < numberOfPhotos - 1 &&
          <div className="pl-4 cursor-pointer" onClick={this.onClickPhotoNext}>
            <img src={arrow_right} alt="" />
          </div>
        }
      </div> 
    );
  }
}

const mapStateToProps = state => ({
  photoDetail: state.collections.photoDetail,
  collectionSelected: state.collections.collectionSelected,
  form_error: state.validateForm.form_error,
  firebase: state.firebase
})

const mapDispatchToProps = {
  validateFormAction: validateFormAction,
  validationSuccess: validationSuccess,
  validationError: validationError,
  selectPhotoAction: selectPhotoAction,
  showPhotoDetailAction: showPhotoDetailAction,
  updatePhotoDetailAction: updatePhotoDetailAction,
  resetValidationFormAction: resetValidationFormAction
}

export default compose(
  firebaseConnect(),
  connect(mapStateToProps, mapDispatchToProps)
)(PhotoDetail);