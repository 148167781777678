import React from 'react';
import ApiCustomer from '../../utils/Api/ApiCustomer';
import TokenHelper from '../../utils/Firebase/TokenHelper';
import Cropper from 'react-easy-crop'
import Slider from 'rc-slider';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { changeAlbumCoverAction, toggleUploadinCoverAction, toggleShowChangeCoverAction, startLoadingAction } from '../../actions/CustomerActions';
import { changeDataForAddTitlteAction } from '../../actions/NavigationActions';
import { hideLoadingAction } from '../../actions/GlobalActions';
import Loading from '../../components/Loading';
import getCroppedImg, { getCroppedImgV2 } from '../../utils/CropImage';
import firebase from 'firebase/app';
import "firebase/app";
import "firebase/storage";

import back_arrow from '../../images/newDesignImages/slider-left.png';
import close_img from '../../images/newDesignImages/close-gray.png';
import minus_img from '../../images/newDesignImages/-black.png';
import plus_img from '../../images/newDesignImages/+black.png';
import add_title from '../../images/newDesignImages/add-title.png';
import add_title_gray from '../../images/newDesignImages/add-title-gray.png';
import 'rc-slider/assets/index.css';
import './EditPhoto.scss';



class EditPhoto extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      imageSrc: null,
      crop: { x: 0, y: 0 },
      zoom: 1,
      aspect: 1,
      croppedAreaPixels: null,
      croppedImage: null,
      isLoading: false,
      loadedImg: false
    }
  }

  componentDidMount() {
    const { selectedImageForEdit } = this.props;
    if (this.props.productType === 'album') {
      this.setState({ imageSrc: this.props.selectedCoverForEdit });
    } else {
      this.setState({ imageSrc: selectedImageForEdit.print_url });
    }
  }

  onCropChange = crop => {
    this.setState({ crop });
  }

  onCropComplete = (croppedArea, croppedAreaPixels) => {
    this.setState({ croppedAreaPixels });
  }

  onZoomChange = zoom => {
    this.setState({ zoom });
  }

  handleCroppedImage = async () => {
    const image = this.refs.image;
    this.props.handleCloseImageForEdit(true);
    this.props.startLoadingAction();
    const file = await getCroppedImgV2(image, this.state.croppedAreaPixels)
    await this.uploadCoverToGCS(file);
    this.props.hideLoadingAction();
  }


  uploadCoverToGCS = (file) => {
    return new Promise(resolve => {
      const updatePhoto = this.updatePhoto;
      var path = '';
      let r = (Math.random() + 1).toString(36).substring(5);
      switch (this.props.productType) {
        case 'album':
          path = this.props.firebase.auth.uid + '/covers/cover_' + Date.now() + "_" + r + '.jpg';
          break;
        default:
          path = this.props.firebase.auth.uid + '/' + Date.now() + "_" + r + '.jpg';
          break;
      }
      const storage = firebase.storage();
      const uploadRef = storage.ref().child(path);
      const uploadTask = uploadRef.put(file);
      
      uploadTask.on('state_changed', 
        (snapshot) => {
          switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED:
              break;
            case firebase.storage.TaskState.RUNNING:
              break;
          }
        }, 
        (error) => {
          this.props.handleCropImageError(error);
          resolve();
        }, 
        () => {
          // Handle successful uploads on complete
          const baseUrl = window._env_.GCS_BUCKET_BASE_URL;
          const coverUrl = baseUrl + path;
          updatePhoto(coverUrl);
          resolve();
        }
      );
    })
  }

  updatePhoto = (coverUrl) => {
    const { selectedImageForEdit, magnetId, canvasId, ornamentId, changeAlbumCoverAction, collectionSelected, selectedCoverIndexForEdit } = this.props;
    switch (this.props.productType) {
      case 'magnet':
        this.updatePhotoMagnet(magnetId, selectedImageForEdit.id, coverUrl);
        break;
      case 'picture':
        this.updatePhotoCanvas(canvasId, selectedImageForEdit.id, coverUrl);
        break;
      case 'album':
        changeAlbumCoverAction(coverUrl, collectionSelected, selectedCoverIndexForEdit, '');
        break;
      case 'ornament':
        this.updatePhotoOrnament(ornamentId, selectedImageForEdit.id, coverUrl);
        break;
      default:
        break;
    }
  }

  updatePhotoMagnet = (magnetProjectId, imageId, imageUrl) => {
    const { croppedAreaPixels } = this.state;
    TokenHelper.getUserToken().then(idToken => {
      ApiCustomer.updatePhotoMagnet({
        token: idToken,
        magnet_project_id: magnetProjectId,
        image_id: imageId,
        image_url: imageUrl,
        width: croppedAreaPixels.width,
        height: croppedAreaPixels.height
      }).then(response => {
        if (response.data.success) {
          this.props.handleCropImageSuccess(response.data.data);
        } else {
          this.props.handleCropImageError('Ocurrió un error, intenta más tarde');
        }
      }).catch(error => this.props.handleCropImageError('Ocurrió un error, intenta más tarde'));
    }).catch(error => this.props.handleCropImageError('Ocurrió un error, intenta más tarde'));
  }

  updatePhotoCanvas = (canvasProjectId, imageId, imageUrl) => {
    const { croppedAreaPixels } = this.state;
    TokenHelper.getUserToken().then(idToken => {
      ApiCustomer.updatePhotoCanvas({
        token: idToken,
        picture_project_id: canvasProjectId,
        image_id: imageId,
        image_url: imageUrl,
        width: croppedAreaPixels.width,
        height: croppedAreaPixels.height
      }).then(response => {
        if (response.data.success) {
          this.props.handleCropImageSuccess(response.data.data);
        } else {
          this.props.handleCropImageError('Ocurrió un error, intenta más tarde');
        }
      }).catch(error => this.props.handleCropImageError('Ocurrió un error, intenta más tarde'));
    }).catch(error => this.props.handleCropImageError('Ocurrió un error, intenta más tarde'));
  }

  updatePhotoOrnament = (ornamentProjectId, imageId, imageUrl) => {
    const { croppedAreaPixels } = this.state;
    TokenHelper.getUserToken().then(idToken => {
      ApiCustomer.updatePhotoFromCustomerOrnamentProject({
        token: idToken,
        ornament_project_id: ornamentProjectId,
        image_id: imageId,
        image_url: imageUrl,
        width: croppedAreaPixels.width,
        height: croppedAreaPixels.height
      }).then(response => {
        if (response.data.success) {
          this.props.handleCropImageSuccess(response.data.data);
        } else {
          this.props.handleCropImageError('Ocurrió un error, intenta más tarde');
        }
      }).catch(error => this.props.handleCropImageError('Ocurrió un error, intenta más tarde'));
    }).catch(error => this.props.handleCropImageError('Ocurrió un error, intenta más tarde'));
  }

  handleClose = () => {
    this.setState({ croppedImage: null });
    if (this.props.productType === 'album') {
      this.props.toggleShowChangeCoverAction(false);
    } else {
      this.props.handleCloseImageForEdit(false)
    }
  }

  handleSlider = (value) => {
    let zoom = value / 100;
    this.setState({ zoom });
  }

  handleAddTitle = () => {
    if (this.state.loadedImg) {
      const image = this.refs.image;
      this.setState({ isLoading: true })
      getCroppedImgV2(image, this.state.croppedAreaPixels)
        .then(file => {
          this.props.changeDataForAddTitlteAction({
            selectedCoverForEdit: file,
            collectionSelected: this.props.collectionSelected,
            selectedCoverIndexForEdit: this.props.selectedCoverIndexForEdit,
            croppedArea: this.state.croppedAreaPixels
          });
        })
        .then(() => this.handleClose())
        .then(() => this.props.handleOpenAddTitle());
    }
  }

  handleLoadImg = () => {
    this.setState({loadedImg: true});
  }

  render() {
    return (
      <div className="edit-photo_container d-flex justify-content-center align-items-center">
        {this.state.isLoading ? <Loading alternativeLoading /> :
          <div className="edit-photo_inside-container d-flex flex-column justify-content-end align-items-center">
            <img className="edit-name_close cursor-pointer" src={close_img} height="20" alt=""  onClick={this.handleClose}/>
            <div className="edit-photo_crop-container">
              <img ref="image" className="photo-detail_img" onLoad={this.handleLoadImg} crossOrigin="anonymous" style={{display: 'none'}} src={this.state.imageSrc} alt=""/>
              <Cropper
                image={this.state.imageSrc}
                crop={this.state.crop}
                zoom={this.state.zoom}
                minZoom={1}
                maxZoom={2}
                aspect={this.state.aspect}
                onCropChange={this.onCropChange}
                onCropComplete={this.onCropComplete}
                onZoomChange={this.onZoomChange}
                disableAutomaticStylesInjection={true}
              />
            </div>
            {this.props.productType === 'album' &&
              <div className="d-flex flex-column align-items-center">
                <img className="cursor-pointer" src={add_title_gray} height="25" alt="" onClick={this.handleAddTitle}/>
                <p className="gotham-md-gray-12 mt-1">Agrega texto en la</p>
                <p className="gotham-md-gray-12">portada</p>
              </div>
            }
            <div className="edit-photo_slider mt-2 d-flex justify-content-center align-items-center">
              <img src={minus_img} height="5" alt="" />
              <div className="edit-photo_slider-container mx-3">
                <Slider
                  min={100}
                  max={200}
                  onChange={this.handleSlider}
                />
              </div>
              <img src={plus_img} height="15" alt="" />
            </div>
            <p className="gotham-book-15 pt-2">Haz zoom y mueve tu foto</p>
            <div className="py-3">
              <button className="edit-photo_save-btn" onClick={this.handleCroppedImage}>Guardar</button>
            </div>
          </div>






          // <div className="edit-photo_inside-container d-flex flex-column justify-content-center align-items-center">
          //   <div className="edit-photo_top-container d-flex align-items-center justify-content-between w-100" >
          //     {this.props.productType === 'album' ?
          //       <div className="d-flex align-items-center cursor-pointer" onClick={this.handleAddTitle}>
          //         <img src={add_title} alt="" height="18" />
          //         <p className="futura-md-white-18 pl-2">Agregar título</p>
          //       </div> :
          //       <div className="d-flex align-items-center cursor-pointer" onClick={this.handleClose}>
          //         <img src={back_arrow} alt="" height="18" />
          //         <p className="futura-md-white-18 pl-2">Regresar</p>
          //       </div>
          //     }
          //     <img className="cursor-pointer" src={close_img} alt="" onClick={this.handleClose} />
          //   </div>
          //   <div className="edit-photo_inner-inside">
          //     <div className="edit-photo_crop-container">
          //       <img ref="image" className="photo-detail_img" crossOrigin="anonymous" style={{display: 'none'}} src={this.state.imageSrc} alt=""/>
          //       <Cropper
          //         image={this.state.imageSrc}
          //         crop={this.state.crop}
          //         zoom={this.state.zoom}
          //         minZoom={1}
          //         maxZoom={2}
          //         aspect={this.state.aspect}
          //         onCropChange={this.onCropChange}
          //         onCropComplete={this.onCropComplete}
          //         onZoomChange={this.onZoomChange}
          //       />
          //     </div>
          //   </div>

          //   <div className="w-75 my-4">
          //     <div className="d-flex justify-content-between align-items-center">
          //       <img src={minus_img} alt="" />
          //       <div className="edit-photo_slider-container">
          //         <Slider
          //           min={100}
          //           max={200}
          //           onChange={this.handleSlider}
          //         />
          //       </div>
          //       <img src={plus_img} alt="" />
          //     </div>
          //     <div className="edit-photo_save d-flex justify-content-center">
          //       <button onClick={this.handleCroppedImage}>Guardar edición</button>
          //     </div>
          //   </div>

          // </div>
        }
      </div>
    )
  }
}

const mapStateToProps = state => ({
  collectionSelected: state.collections.collectionSelected,
  isUploadingCover: state.collections.isUploadingCover,
  closeUploadCoverModal: state.collections.closeUploadCoverModal,
  selectedCoverForEdit: state.collections.selectedCoverForEdit,
  selectedCoverIndexForEdit: state.collections.selectedCoverIndexForEdit,
  firebase: state.firebase
});

const mapDispatchToProps = {
  changeAlbumCoverAction: changeAlbumCoverAction,
  toggleUploadinCoverAction: toggleUploadinCoverAction,
  toggleShowChangeCoverAction: toggleShowChangeCoverAction,
  startLoadingAction: startLoadingAction,
  hideLoadingAction: hideLoadingAction,
  changeDataForAddTitlteAction: changeDataForAddTitlteAction
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(EditPhoto);