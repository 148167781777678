import React from 'react';
import elem_unchecked from '../../images/newDesignImages/elem-unchecked.png';
import elem_checked from '../../images/newDesignImages/magnet-elem-checked.png';
import add_icon from '../../images/newDesignImages/add-icon-blue.png';
import hover_img from '../../images/newDesignImages/hover.png';
import edit_icon from '../../images/newDesignImages/edit-circle.png';
import warning_icon from '../../images/newDesignImages/warning-icon.png';
import './CanvasDetailItem.scss';

function CanvasDetailItem(props) {
  const { photoData, canvas, frames } = props;
  const frameStyles = {
    width: '90%',
    height: '90%',
    backgroundImage: 'url(' + canvas.frame_url + ')',
    backgroundSize: 'cover',
    paddingBottom: '1.5%',
    paddingRight: '1.5%',
  }
  return (
    photoData === 'addPhoto' ?
      <div className="canvas-item_container pt-5 d-flex flex-column align-items-center justify-content-center cursor-pointer" onClick={props.handleAddImageClick}>
        <img src={add_icon} height="25%" alt="" />
        <p className="gotham-md-gray-15 pt-2">Agregar fotos</p>
      </div> :
      (canvas.frame_url === frames[2].frame_url ? (
        <div className="canvas-item_container d-flex justify-content-center align-items-center">      
          <div style={frameStyles} className="canvas-item_photo d-flex justify-content-center align-items-center" onMouseEnter={() => props.handleMouseEnter(photoData)} onMouseLeave={() => props.handleMouseEnter(null)}>
            {photoData.source !== 'quotes' && <img className="canvas-item_edit-btn cursor-pointer" src={edit_icon} height="15%" alt="" onClick={() => props.selectPhotoHandler(photoData, photoData.source)}/>}
            <img className="canvas-item_img" src={photoData.image_url} height="58%" width="58%" alt=""/>
            {(photoData.width < 1200 && photoData.height < 1200) &&
            <div className="canvas-item_lowresolution-noframe d-flex align-items-center px-2 py-1">
              <img src={warning_icon} height="22" alt=""/>
              <p className="gotham-md-gray-15 ml-2">Baja resolución</p>
            </div>
            }
          </div>
        </div>
      ) : (
      <div className="canvas-item_container d-flex justify-content-center align-items-center">      
        <div style={frameStyles} className="canvas-item_photo d-flex justify-content-center align-items-center" onMouseEnter={() => props.handleMouseEnter(photoData)} onMouseLeave={() => props.handleMouseEnter(null)}>
          {photoData.source !== 'quotes' && <img className="canvas-item_edit-btn cursor-pointer" src={edit_icon} height="15%" alt="" onClick={() => props.selectPhotoHandler(photoData, photoData.source)}/>}
          <img className="canvas-item_img" src={photoData.image_url} height="58%" width="58%" alt=""/>
        </div>
        {(photoData.width < 1200 && photoData.height < 1200) &&
          <div className="canvas-item_lowresolution d-flex align-items-center px-2 py-1">
            <img src={warning_icon} height="22" alt=""/>
            <p className="gotham-md-gray-15 ml-2">Baja resolución</p>
          </div>
        }
      </div>
      ))
  );
}

export default CanvasDetailItem;