import {
  ADD_PRODUCTS_TO_CHECKOUT,
  CALCULATE_ORDER,
  PLACE_ORDER,
  SELECT_PAYMENT_METHOD,
  RESET_CHECKOUT_STATE,
  SELECT_SHIPPING_ADDRESS,
  SHOW_GO_TO_CART_ALERT,
  CHANGE_ALBUM_QUANTITY,
  UPDATE_CUSTOMER_INFO
} from '../actions/ActionTypes';

const initialState = {
  albums: [],
  products: [],
  shippingAddress: null,
  paymentCard: null,
  voucherString: '',
  voucherValueString: '',
  shipping: 0.0,
  subTotal: 0.0,
  total: 0.0,
  pixycoins: 0,
  orderSummary: {
    shipping: 0.0,
    sub_total: 0.0,
    total: 0.0,
    voucher_code: '',
    voucher_value: '',
    error_message: '',
    wallet_discount: 0
  },
  orderId: '',
  paymentReference: '',
  cashPaymentReference: '',
  showGoToCart: false, 
  customerInfo: null
}

function checkoutReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_PRODUCTS_TO_CHECKOUT:
      return {
        ...state,
        products: state.products.concat(action.payload)
      };
    case CALCULATE_ORDER:
      return {
        ...state,
        orderSummary: action.payload
      };
    case PLACE_ORDER:
      if (action.payload.cash_payment_reference) {
        return {
          ...initialState,
          orderId: action.payload.order_id,
          paymentReference: action.payload.payment_reference,
          cashPaymentReference: action.payload.cash_payment_reference
        };
      } else {
        return {
          ...initialState,
          orderId: action.payload.order_id,
          paymentReference: action.payload.payment_reference
        };
      }
    case SELECT_PAYMENT_METHOD:
      return {
        ...state,
        paymentCard: action.payload
      };
    case RESET_CHECKOUT_STATE:
      return {
        ...initialState
      };
    case SELECT_SHIPPING_ADDRESS:
      return {
        ...state,
        shippingAddress: action.payload
      };
    case SHOW_GO_TO_CART_ALERT:
      return {
        ...state,
        showGoToCart: action.payload
      }
    case CHANGE_ALBUM_QUANTITY:
      let updatedAlbums = [...state.albums];
      for (let album of updatedAlbums) {
        if (album.id === action.payload.album.id) {
          album.quantity += action.payload.quantity;
          break;
        }
      }
      return {
        ...state,
        albums: updatedAlbums
      }
    case UPDATE_CUSTOMER_INFO:
      return {
        ...state,
        customerInfo: action.payload
      }

    default:
      return state;
  }
}

export default checkoutReducer;