import React from 'react';
import Scrollable from '../Scrollable';
import SelectFromAlbumItem from './SelectFromAlbumItem';
import close_img from '../../images/newDesignImages/close-white.png';
import back_img from '../../images/newDesignImages/back-arrow-blue.png';
import './ChangeCoverSelectSource.scss';


export default function SelectFromAlbum(props) {
  return (
    <Scrollable scrollableContent={
      <div className="changecover-selectsource_main">
        <div className="changecover-selectsource_header d-flex">
          <div className="changecover-selectsource_close d-flex justify-content-center align-items-center cursor-pointer" onClick={() => props.toggleShowChangeCoverSelectSourceAction(false)}>
            <img src={close_img} height="23" alt="" />
          </div>
          <div className="changecover-selectsource_header-right d-flex justify-content-between align-items-center">
            <p className="gotham-bold-18 ml-3">Portada > Imágenes desde álbum</p>
            <button className="generic-button-blue mr-3" onClick={() => props.toggleShowChangeCoverSelectSourceAction(false)}>
              Cancelar
            </button>
          </div>
        </div>
        <div className="changecover-selectsource_content d-flex flex-column justify-content-center align-items-center">
          <div className="changecover-selectsource_back-container d-flex justify-content-start align-items-center">
            <img className="cursor-pointer" src={back_img} height="20" alt="" onClick={() => props.unselectFromAlbumHandler()} />
          </div>
          <div className="changecover-selectsource_photos pb-5 d-flex flex-wrap">
            {
              props.photos.map((page, i) => (
                <SelectFromAlbumItem
                  key={i}
                  page={page}
                  selectPhotoForEditCallback={props.selectPhotoForEditHandler}
                />
              ))            
            }
          </div>
        </div>
      </div>
    } />
  );
}