import React from 'react';
import './ImagePickerPhotoCounter.scss';


function ImagePickerPhotoCounter(props) {
  const { projectType, isAddingPhotos, collectionSelected, photosForCreate, selectedMagnetProject, selectedCanvasProject, selectedOrnamentProject, albumSelectedPagesQuantity, selectedAlbumNumPages } = props;
  var selectedNumOrnaments = 10;
  if (selectedOrnamentProject != null) {
    selectedOrnamentProject.configurations.configurations.forEach(config => {
      if (config.type === "size") {
        selectedNumOrnaments = parseInt(config.description);
      }
    });
  }
  const totalPhotosText = (type) => {
    switch (type) {
      case 'magnet':
        return 'Elige 15 fotografías para completar tu proyecto';
      case 'picture':
        return;
      case 'ornament':
        return 'Elige ' + selectedNumOrnaments + ' fotografías para completar tu proyecto';
      default:
        return `Elige ${isAddingPhotos ? selectedAlbumNumPages : albumSelectedPagesQuantity} fotografías para completar tu Pixyalbum`;
    }
  }
  const photosCounterHandler = (type) => {
    switch (type) {
      case "magnet":
        if (isAddingPhotos) {
          let totalPhotos = selectedMagnetProject.photos.length + photosForCreate.length;
          return (
            <div>
              <p className="gotham-book-gray-17">{totalPhotos}/15 imágenes</p>
            </div>
          );
        } else {
          return (
            <div>
              <p className="gotham-book-gray-17">{photosForCreate.length}/15 imágenes</p>
            </div>
          );
        }
      case "picture":
        if (isAddingPhotos) {
          let totalPhotos = selectedCanvasProject.photos.length + photosForCreate.length;
          return (
            <div>
              <p className="gotham-book-gray-17">{totalPhotos} imágenes</p>
            </div>
          );
        } else {
          return (
            <div>
              <p className="gotham-book-gray-17">{photosForCreate.length} imágenes</p>
            </div>
          );
        }
      case "ornament":
        if (isAddingPhotos) {
          let totalPhotos = selectedOrnamentProject.photos.length + photosForCreate.length;
          return (
            <div>
              <p className="gotham-book-gray-17">{totalPhotos}/{selectedNumOrnaments} imágenes</p>
            </div>
          );
        } else {
          return (
            <div>
              <p className="gotham-book-gray-17">{photosForCreate.length}/{selectedNumOrnaments} imágenes</p>
            </div>
          );
        }
      default:
        if (isAddingPhotos) {
          let totalPhotos = (collectionSelected.pages.length % selectedAlbumNumPages) + photosForCreate.length;
          return totalPhotos <= selectedAlbumNumPages ?
            <div>
              <p className="gotham-book-gray-17">{totalPhotos}/{selectedAlbumNumPages} imágenes</p>
            </div> :
            <div>
              <p className="gotham-book-gray-17">{totalPhotos} imágenes</p>
            </div>
        } else {
          return photosForCreate.length <= albumSelectedPagesQuantity ?
            <div>
              <p className="gotham-book-gray-17">{photosForCreate.length}/{albumSelectedPagesQuantity} imágenes</p>
            </div> :
            <div>
              <p className="gotham-book-gray-17">{photosForCreate.length} imágenes</p>
            </div>
        }
    }
  }
  const maximumImages = (type) => {
    let maxNum = 0;
    switch (type) {
      case "album": maxNum = isAddingPhotos ? selectedAlbumNumPages : albumSelectedPagesQuantity; break;
      case "magnet": maxNum = 15; break;
      case "picture": maxNum = 3; break;
      case "ornament": maxNum = selectedNumOrnaments; break;
      default: maxNum = isAddingPhotos ? selectedAlbumNumPages : albumSelectedPagesQuantity; break;
    }
    return maxNum;
  }
  const widthPercentage = () => {
    if (isAddingPhotos && projectType === 'album') {
      const totalPhotos = (collectionSelected.pages.length % maximumImages(projectType)) + photosForCreate.length;
      return totalPhotos < maximumImages(projectType) ? totalPhotos * (100 / maximumImages(projectType)) : 100;
    } else if (isAddingPhotos && projectType === 'magnet') {
      const totalPhotos = (selectedMagnetProject.photos.length % maximumImages(projectType)) + photosForCreate.length;
      return totalPhotos < maximumImages(projectType) ? totalPhotos * (100 / maximumImages(projectType)) : 100;
    } else if (isAddingPhotos && projectType === 'picture') {
      const totalPhotos = selectedCanvasProject.photos.length + photosForCreate.length;
      return totalPhotos < maximumImages(projectType) ? totalPhotos * (100 / maximumImages(projectType)) : 100;
    } else if (isAddingPhotos && projectType === 'ornament') {
      const totalPhotos = (selectedOrnamentProject.photos.length % maximumImages(projectType)) + photosForCreate.length;
      return totalPhotos < maximumImages(projectType) ? totalPhotos * (100 / maximumImages(projectType)) : 100;
    } else {
      return photosForCreate.length < maximumImages(projectType) ? photosForCreate.length * (100 / maximumImages(projectType)) : 100;
    }
  }

  return (
    <div className="image-counter_counter py-4 d-flex flex-column align-items-center">
      <p className="gotham-book-gray-17 mb-2">{totalPhotosText(projectType)}</p>
      {photosCounterHandler(projectType)}
      {
        projectType !== 'picture' &&
        <div className="image-counter_bar mt-3">
          <div className="image-counter_bar-inside" style={{ width: `${widthPercentage()}%` }}></div>
        </div>
      }
    </div>
  );

}

export default ImagePickerPhotoCounter;