import {
    SELECT_SOURCE,
    LOAD_SOCIAL_PHOTOS,
    ADD_PHOTO_TO_CREATE,
    REMOVE_PHOTO_FROM_CREATE,
    RESET_CREATE_PROJECT_STATE,
    CREATE_ALBUM,
    SET_SOCIAL_TOKEN,
    LOAD_SOCIAL_ALBUMS,
    SELECT_SOCIAL_ALBUM,
    LOAD_SOCIAL_ALBUM_DETAIL,
    RESET_PHOTOS_SELECTION,
    SET_GOOGLE_LOGGED_IN,
    REMOVE_DEFAULT_ALBUM_FOR_QUOTES,
    LOAD_QUOTES_ALBUMS,
    LOAD_QUOTES_ALBUM_DETAIL,
    TOGGLE_IS_ADDING_PHOTOS,
    BACK_TO_ALBUM_DETAIL_AFTER_ADD_PHOTOS,
    UPDATE_ALBUM_CONFIGURATION_IDS,
    UPDATE_ALBUM_SELECTED_PAGES,
    UPDATE_ALBUM_PRINT_DATES,
    SET_DEFAULT_ALBUM
} from '../actions/ActionTypes';

const initialState = {
    source: '',
    socialToken: '',
    albums: [{
        id: 'all_photos',
        image_url: '',
        name: 'Todas las fotos',
        count: 0
    }],
    albumsNextPage: '',
    selectedAlbum: null,
    photos: [],
    nextPage: '',
    photosForCreate: [],
    albumCreatedId: '',
    googleLoggedIn: false,
    isAddingPhotos: false,
    backToAlbumDetailAfterAddPhotos: false,
    albumConfigurationIds: [],
    albumSelectedPagesQuantity: 0,
    albumPrintDates: true
}

function createProjectReducer(state = initialState, action) {
    switch (action.type) {
        case SELECT_SOURCE:
            return {
                ...state,
                source: action.payload
            };
        case LOAD_SOCIAL_PHOTOS:
            return {
                ...state,
                photos: state.photos.concat(action.payload.images),
                nextPage: action.payload.next_page || ''
            };
        case ADD_PHOTO_TO_CREATE:
            return {
                ...state,
                photosForCreate: state.photosForCreate.concat(action.payload)
            };
        case REMOVE_PHOTO_FROM_CREATE:
            return {
                ...state,
                photosForCreate: state.photosForCreate.filter(item => item.id !== action.payload.id)
            };
        case CREATE_ALBUM:
            return {
                ...state,
                albumCreatedId: action.payload.id
            };
        case SET_SOCIAL_TOKEN:
            return {
                ...state,
                socialToken: action.payload
            };
        case LOAD_SOCIAL_ALBUMS:
            return {
                ...state,
                albums: state.albums.concat(action.payload.albums),
                albumsNextPage: action.payload.next_page || ''
            };
        case SELECT_SOCIAL_ALBUM:
            return {
                ...state,
                selectedAlbum: action.payload
            };
        case LOAD_SOCIAL_ALBUM_DETAIL:
            return {
                ...state,
                photos: state.photos.concat(action.payload.images),
                nextPage: action.payload.next_page || ''
            };
        case RESET_PHOTOS_SELECTION:
            return {
                ...state,
                photos: [],
                nextPage: '',
                photosForCreate: [],
                backToAlbumDetailAfterAddPhotos: false
            };
        case SET_GOOGLE_LOGGED_IN:
            return {
                ...state,
                googleLoggedIn: action.payload
            };
        case REMOVE_DEFAULT_ALBUM_FOR_QUOTES:
            return {
                ...state,
                albums: []
            };
        case SET_DEFAULT_ALBUM:
            return {
                ...state,
                albums: [{
                    id: 'all_photos',
                    image_url: '',
                    name: 'Todas las fotos',
                    count: 0
                }]
            }
        case LOAD_QUOTES_ALBUMS:
            return {
                ...state,
                albums: state.albums.concat(action.payload)
            };
        case LOAD_QUOTES_ALBUM_DETAIL:
            return {
                ...state,
                photos: state.photos.concat(action.payload)
            };
        case RESET_CREATE_PROJECT_STATE:
            return {
                ...initialState,
                isAddingPhotos: state.isAddingPhotos
            }
        case TOGGLE_IS_ADDING_PHOTOS:
            return {
                ...state,
                isAddingPhotos: action.payload
            }
        case BACK_TO_ALBUM_DETAIL_AFTER_ADD_PHOTOS:
            return {
                ...state,
                backToAlbumDetailAfterAddPhotos: action.payload
            }
        case UPDATE_ALBUM_CONFIGURATION_IDS:
            return {
                ...state,
                albumConfigurationIds: action.payload
            }
        case UPDATE_ALBUM_SELECTED_PAGES:
            return {
                ...state,
                albumSelectedPagesQuantity: action.payload
            }
        case UPDATE_ALBUM_PRINT_DATES:
            return {
                ...state,
                albumPrintDates: action.payload
            }
        default:
            return state;
    }
}

export default createProjectReducer;