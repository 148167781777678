import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import TokenHelper from '../../utils/Firebase/TokenHelper';
import ApiCustomer from '../../utils/Api/ApiCustomer';
import {
  loadPhotosFromSourceAction,
  addPhotoToCreateAlbumAction,
  removePhotoFromCreateAlbumAction,
  resetProjectReducerAction,
  createAlbumAction,
  resetPhotosSelectionAction,
  addPhotosToAlbumAction,
  createProductAction,
  updateAlbumConfigurationDataAction
} from '../../actions/CreateProjectActions';
import { showErrorAction, hideErrorAction } from '../../actions/GlobalActions';
import { selectCollectionAction } from '../../actions/CustomerActions';

import Loading from '../Loading';
import ImagePickerSelectableImage from '../ImagePickerSelectableImage';
import Modal from '../../components/Modal';
import AlertView from '../AlertView';
import ImagePickerPhotoCounter from '../ImagePickerPhotoCounter';

import back_arrow from '../../images/newDesignImages/back-arrow-blue.png';
import create_album_img from '../../images/newDesignImages/create-album-img.png';
import './ImagePickerWithSource.scss';




class ImagePickerWithSource extends Component {

  constructor(props) {
    super(props);
    this.state = {
      canvasConfigurations: [],
      ornamentConfigurations: null
    }
  }

  componentDidMount() {
    if (this.props.source === '') {
      this.props.history.push('/projects/');
    } else {
      this.loadData();
    }
  }

  componentWillUnmount() {
    this.props.resetPhotosSelectionAction();
  }

  componentDidUpdate() {
    const { isAddingPhotos, collectionSelected, selectedMagnetProject } = this.props;
    if (isAddingPhotos && collectionSelected === null) {
      const collectionSelected = JSON.parse(localStorage.getItem('SELECTED_COLLECTION'));
      this.props.selectCollectionAction(collectionSelected);
    }
  }

  loadData = async() => {
    this.loadAlbumConfigurations();
    await this.props.loadPhotosFromSourceAction(this.props.source, this.props.socialToken, null, this.props.projectType);
    this.addOrderIndex(this.props.photos);
    this.getCanvasConfigurations();
    this.getOrnamentConfigurations();
  }

  loadAlbumConfigurations = () => {
    const albumConfigurationIds = JSON.parse(localStorage.getItem('album_config_ids'));
    const albumSelectedPagesQuantity = JSON.parse(localStorage.getItem('album_config_pages'));
    const albumPrintDates = JSON.parse(localStorage.getItem('album_config_dates'));
    this.props.updateAlbumConfigurationDataAction(albumConfigurationIds, albumSelectedPagesQuantity, albumPrintDates)
  }

  addOrderIndex = (photos) => {
    photos.map((photo, i) => photo.index = i)
    return photos;
  }

  selectImage = (photo) => {
    const { photosForCreate, projectType, isAddingPhotos, selectedMagnetProject, selectedOrnamentProject, collectionSelected, albumSelectedPagesQuantity } = this.props;
    const selectedAlbumNumPages = (projectType === 'album' && isAddingPhotos) ? parseInt(collectionSelected.configurations.configurations[1].description) : null;
    const ornamentConfigurations = this.state.ornamentConfigurations;
    var selectedNumOrnaments = 10;
    if (selectedOrnamentProject != null) {
      selectedOrnamentProject.configurations.configurations.forEach(config => {
        if (config.type === "size") {
          selectedNumOrnaments = parseInt(config.description);
        }
      });
    } else {
      ornamentConfigurations[0].configurations.forEach(config => {
        if (config.type === "size") {
          selectedNumOrnaments = parseInt(config.description);
        }
      });
    }
    if (this.isPhotoAlreadyAdded(photo)) {
      this.props.removePhotoFromCreateAlbumAction(photo);
    } else {
      if ((projectType === 'magnet' && photosForCreate.length >= 15) || (projectType === 'magnet' && isAddingPhotos && selectedMagnetProject.photos.length + photosForCreate.length >= 15)) {
        this.props.showErrorAction('No puedes agregar más de 15 fotos.')
      } else if ((projectType === 'ornament' && photosForCreate.length >= selectedNumOrnaments) || (projectType === 'ornament' && isAddingPhotos && selectedOrnamentProject.photos.length + photosForCreate.length >= selectedNumOrnaments)) {
        this.props.showErrorAction('No puedes agregar más de ' + selectedNumOrnaments + ' fotos.')
      } else if (projectType === 'album' && !isAddingPhotos && photosForCreate.length >= albumSelectedPagesQuantity) {
        this.props.showErrorAction(`No puedes agregar más de ${albumSelectedPagesQuantity} fotos.`)
      } else if (projectType === 'album' && isAddingPhotos && collectionSelected.pages.length + photosForCreate.length >= albumSelectedPagesQuantity) {
        this.props.showErrorAction(`No puedes agregar más de ${selectedAlbumNumPages} fotos.`);
      } else {
        this.props.addPhotoToCreateAlbumAction(photo);
      }
    }
  }

  isPhotoAlreadyAdded = (photo) => {
    for (let photoElement of this.props.photosForCreate) {
      if (photo.id === photoElement.id) {
        return true;
      }
    }
    return false;
  }

  createButtonClicked = e => {
    e.preventDefault();
    if (this.props.photosForCreate.length === 0) {
      this.props.showErrorAction('Por favor selecciona al menos una foto para cotinuar');
    } else {
      const { isAddingPhotos, addPhotosToAlbumAction, collectionSelected, projectType, selectedMagnetProject, selectedCanvasProject, selectedOrnamentProject, albumConfigurationIds, albumPrintDates } = this.props;
      if (isAddingPhotos && projectType === 'album') {
        let photosDataForAPI = [];
        let sortedPhotosForCreate = this.props.photosForCreate.sort((a, b) => b.index - a.index);
        let index = collectionSelected.pages.length;
        for (let photoElement of sortedPhotosForCreate) {
          delete photoElement.id;
          photoElement.index = index;
          photosDataForAPI.push(photoElement);
          index += 1;
        }
        let addPhotosToAlbum = new Promise((resolve, reject) => resolve(addPhotosToAlbumAction(collectionSelected, photosDataForAPI)));
        addPhotosToAlbum.then(() => this.props.history.push({
          pathname: '/collection/albums/detail',
          state: collectionSelected
        }));
      } else if (isAddingPhotos && projectType === 'magnet') {
        let photosDataForAPI = [];
        let sortedPhotosForCreate = this.props.photosForCreate.sort((a, b) => b.index - a.index);
        let index = selectedMagnetProject.photos.length;
        for (let photoElement of sortedPhotosForCreate) {
          delete photoElement.id;
          photoElement.index = index;
          photosDataForAPI.push(photoElement);
          index += 1;
        }
        this.addImagesToMagnet(selectedMagnetProject.id, photosDataForAPI);
      } else if (isAddingPhotos && projectType === 'picture') {
        let photosDataForAPI = [];
        let sortedPhotosForCreate = this.props.photosForCreate.sort((a, b) => b.index - a.index);
        let index = selectedCanvasProject.photos.length;
        for (let photoElement of sortedPhotosForCreate) {
          delete photoElement.id;
          photoElement.index = index;
          photosDataForAPI.push(photoElement);
          index += 1;
        }
        this.addImagesToCanvas(selectedCanvasProject.id, photosDataForAPI);
      } else if (isAddingPhotos && projectType === 'ornament') {
        let photosDataForAPI = [];
        let sortedPhotosForCreate = this.props.photosForCreate.sort((a, b) => b.index - a.index);
        let index = selectedOrnamentProject.photos.length;
        for (let photoElement of sortedPhotosForCreate) {
          delete photoElement.id;
          photoElement.index = index;
          photosDataForAPI.push(photoElement);
          index += 1;
        }
        this.addImagesToOrnament(selectedOrnamentProject.id, photosDataForAPI);
      } else {
        let photosDataForAPI = [];
        let sortedPhotosForCreate = this.props.photosForCreate.sort((a, b) => b.index - a.index);
        let index = 0;
        for (let photoElement of sortedPhotosForCreate) {
          delete photoElement.id;
          photoElement.index = index;
          photosDataForAPI.push(photoElement);
          index += 1;
        }
        const createProduct = async () => {
          if (projectType === 'picture') {
            const canvasConfigurations = this.canvasConfigValidaton();
            await this.props.createProductAction(photosDataForAPI, this.props.projectType, canvasConfigurations);
          } else if (projectType === 'album') {
            const albumConfigurations = {
              "albumPrintDates": albumPrintDates,
              "albumConfigurationIds": albumConfigurationIds
            }
            await this.props.createProductAction(photosDataForAPI, projectType, albumConfigurations);
          } else {
            await this.props.createProductAction(photosDataForAPI, this.props.projectType);
          }
        }
        createProduct().then(() => {
          switch (projectType) {
            case 'magnet':
              this.props.history.push({
                pathname: '/magnet/detail',
                state: {
                  id: this.props.selectedMagnetProject.id,
                  productType: 'magnet'
                }
              });
              break;
            case 'picture':
              this.props.history.push({
                pathname: '/canvas/detail',
                state: {
                  id: this.props.selectedCanvasProject.id,
                  productType: 'picture'
                }
              });
              break;
            case 'ornament':
              this.props.history.push({
                pathname: '/ornament/detail',
                state: {
                  id: this.props.selectedOrnamentProject.id,
                  productType: 'ornament'
                }
              });
              break;
            default:
              this.props.history.push({
                pathname: '/collection/albums/detail',
                state: this.props.collectionSelected
              });
              break;
          }
        });
      }
    }
  }

  canvasConfigValidaton = () => {
    const { isAddingPhotos, photosForCreate, selectedCanvasProject } = this.props;
    if (isAddingPhotos) {
      if (selectedCanvasProject.photos.length + photosForCreate.length === 2) {
        return this.state.canvasConfigurations.find(element => (element.items_for_standard_cost === 2 && element.type === 'size'))
      } else if (selectedCanvasProject.photos.length + photosForCreate.length >= 3) {
        return this.state.canvasConfigurations.find(element => (element.items_for_standard_cost === 3 && element.type === 'size'))
      }
    } else {
      if (photosForCreate.length === 1) {
        return this.state.canvasConfigurations.find(element => (element.items_for_standard_cost === 1 && element.type === 'size'))
      } else if (photosForCreate.length === 2) {
        return this.state.canvasConfigurations.find(element => (element.items_for_standard_cost === 2 && element.type === 'size'))
      } else if (photosForCreate.length >= 3) {
        return this.state.canvasConfigurations.find(element => (element.items_for_standard_cost === 3 && element.type === 'size'))
      }
    }
  }

  getCanvasConfigurations = () => {
    TokenHelper.getUserToken().then(idToken => {
      ApiCustomer.getProductConfigurations({
        token: idToken,
        product_type: 'picture'
      }).then(response => {
        if (response.data.success) {
          this.setState({ canvasConfigurations: response.data.data })
        }
      }).catch(error => this.props.showErrorAction('Hubo un error, por favor intenta más tarde.'))
    }).catch(error => this.props.showErrorAction('Hubo un error, por favor intenta más tarde.'))
  }

  getOrnamentConfigurations = () => {
    TokenHelper.getUserToken().then(idToken => {
      ApiCustomer.getCustomerOrnamentConfigurations({
        token: idToken,
        product_type: 'ornament'
      }).then(response => {
        if (response.data.success) {
          this.setState({ ornamentConfigurations: response.data.data })
        }
      }).catch(error => this.props.showErrorAction('Hubo un error, por favor intenta más tarde.'))
    }).catch(error => this.props.showErrorAction('Hubo un error, por favor intenta más tarde.'))
  }

  addImagesToMagnet = (id, photos) => {
    TokenHelper.getUserToken().then(idToken => {
      ApiCustomer.addMagnetImage({
        token: idToken,
        magnet_project_id: id,
        photos: photos
      }).then(response => {
        if (response.data.success) {
          this.props.history.push({
            pathname: '/magnet/detail',
            state: {
              id: id,
              productType: 'magnet'
            }
          });
        }
      }).catch(error => this.props.showErrorAction(error.data.message))
    }).catch(error => this.props.showErrorAction('Hubo un error, por favor intenta más tarde.'))
  }

  addImagesToCanvas = (id, photos) => {
    TokenHelper.getUserToken().then(idToken => {
      ApiCustomer.addCanvasImage({
        token: idToken,
        picture_project_id: id,
        photos: photos
      }).then(response => {
        if (response.data.success) {
          this.updateCanvasConfigurations(id);
        }
      }).catch(error => this.props.showErrorAction(error.data.message))
    }).catch(error => this.props.showErrorAction('Hubo un error, por favor intenta más tarde.'))
  }

  addImagesToOrnament = (id, photos) => {
    TokenHelper.getUserToken().then(idToken => {
      ApiCustomer.addPhotosToCustomerOrnamentProject({
        token: idToken,
        ornament_project_id: id,
        photos: photos
      }).then(response => {
        if (response.data.success) {
          this.props.history.push({
            pathname: '/ornament/detail',
            state: {
              id: id,
              productType: 'ornament'
            }
          });
        }
      }).catch(error => this.props.showErrorAction(error.data.message))
    }).catch(error => this.props.showErrorAction('Hubo un error, por favor intenta más tarde.'))
  }

  updateCanvasConfigurations = (id) => {
    const { selectedCanvasProject } = this.props;
    const sizeConfigurations = this.canvasConfigValidaton();
    let configurations = [sizeConfigurations];
    if (selectedCanvasProject.configurations.length > 1) {
      configurations.push(selectedCanvasProject.configurations[1]);
    }
    TokenHelper.getUserToken().then(idToken => {
      ApiCustomer.updateCanvasSettings({
        token: idToken,
        picture_project_id: id,
        configurations: configurations
      }).then(response => {
        if (response.data.success) {
          this.props.history.push({
            pathname: '/canvas/detail',
            state: {
              id: id,
              productType: 'picture'
            }
          });
        }
      }).catch(error => this.props.showErrorAction('Hubo un error, por favor intenta más tarde.'))
    }).catch(error => this.props.showErrorAction('Hubo un error, por favor intenta más tarde.'))
  }

  handleScroll = (event) => {
    var node = event.target;
    // const bottom = node.scrollHeight - node.scrollTop === node.clientHeight;
    const bottom = Math.abs(node.scrollHeight - node.clientHeight - node.scrollTop) < 1;
    if (bottom) {
      if (!this.props.isLoading && this.props.nextPage !== '') {
        const loadPhotosFromServer = new Promise((resolve, reject) => {
          resolve(this.props.loadPhotosFromSourceAction(this.props.source, this.props.socialToken, this.props.nextPage, this.props.projectType))
        });
        loadPhotosFromServer.then(() => this.addOrderIndex(this.props.photos));
      }
    }
  }

  okErrorHandler = () => {
    this.props.hideErrorAction();
  }



  render() {
    const { 
      isAddingPhotos, 
      photosForCreate, 
      collectionSelected, 
      projectType, 
      selectedMagnetProject, 
      selectedCanvasProject,
      selectedOrnamentProject,
      albumConfigurationIds, 
      albumSelectedPagesQuantity
    } = this.props;
    const parseProjectType = (type) => {
      switch (type) {
        case 'magnet':
          return 'imanes';
        case 'picture':
          return 'pixycuadros';
        case 'ornament':
          return 'esferas';
        default:
          return 'album';
      }
    }
    const selectedAlbumNumPages = (projectType === 'album' && isAddingPhotos) ? parseInt(collectionSelected.configurations.configurations[1].description) : null;
    return (
      <div>
        {this.props.showingError &&
          <Modal>
            <AlertView
              title={'¡Ups!'}
              message={this.props.errorMessage}
              okHandler={this.okErrorHandler}
              cancel={true} />
          </Modal>
        }


        <div className="image-picker_header px-5 py-3 d-flex justify-content-between align-items-center">
          <div>
            <div className="d-flex align-items-center mr-5 cursor-pointer" onClick={() => this.props.history.push('/')}>
              <img src={back_arrow} height="20" alt="" />
            </div>
          </div>
          <div className="generic-button-blue d-flex align-items-center justify-content-center cursor-pointer" onClick={this.createButtonClicked}>
            <p className="gotham-book-white-18"> {isAddingPhotos ? 'Agregar' : `Crear ${parseProjectType(projectType)}`}</p>
          </div>
        </div>

        <ImagePickerPhotoCounter
          isAddingPhotos={isAddingPhotos}
          collectionSelected={collectionSelected}
          photosForCreate={photosForCreate}
          selectedMagnetProject={selectedMagnetProject}
          selectedCanvasProject={selectedCanvasProject}
          selectedOrnamentProject={selectedOrnamentProject}
          projectType={projectType}
          albumSelectedPagesQuantity={albumSelectedPagesQuantity}
          selectedAlbumNumPages={selectedAlbumNumPages}
        />
        {this.props.isLoading ?
          <div className="image-picker_loading">
            <Loading />
          </div> :


          <div className="row mx-0 px-5 py-2 images-container" onScroll={this.handleScroll}>
            {
              this.props.photos.map((photo, i) => React.createElement(ImagePickerSelectableImage, { key: i, photo: photo, callback: this.selectImage, isPhotoAlreadyAdded: this.isPhotoAlreadyAdded }))
            }
          </div>
        }
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isLoading: state.globalReducer.isLoading,
  source: state.createProject.source,
  photos: state.createProject.photos,
  nextPage: state.createProject.nextPage,
  photosForCreate: state.createProject.photosForCreate,
  collectionSelected: state.collections.collectionSelected,
  socialToken: state.createProject.socialToken,
  showingError: state.globalReducer.showingError,
  errorMessage: state.globalReducer.errorMessage,
  isAddingPhotos: state.createProject.isAddingPhotos,
  backToAlbumDetailAfterAddPhotos: state.createProject.backToAlbumDetailAfterAddPhotos,
  projectType: state.navigationReducer.projectType,
  selectedMagnetProject: state.navigationReducer.selectedMagnetProject,
  selectedCanvasProject: state.navigationReducer.selectedCanvasProject,
  selectedOrnamentProject: state.navigationReducer.selectedOrnamentProject,
  albumConfigurationIds: state.createProject.albumConfigurationIds,
  albumSelectedPagesQuantity: state.createProject.albumSelectedPagesQuantity,
  albumPrintDates: state.createProject.albumPrintDates,
})

const mapDispatchToProps = {
  loadPhotosFromSourceAction: loadPhotosFromSourceAction,
  addPhotoToCreateAlbumAction: addPhotoToCreateAlbumAction,
  removePhotoFromCreateAlbumAction: removePhotoFromCreateAlbumAction,
  resetPhotosSelectionAction: resetPhotosSelectionAction,
  resetProjectReducerAction: resetProjectReducerAction,
  createAlbumAction: createAlbumAction,
  hideErrorAction: hideErrorAction,
  showErrorAction: showErrorAction,
  selectCollectionAction: selectCollectionAction,
  addPhotosToAlbumAction: addPhotosToAlbumAction,
  createProductAction: createProductAction,
  updateAlbumConfigurationDataAction: updateAlbumConfigurationDataAction
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(ImagePickerWithSource);