import React, { Component } from 'react';
import { firebaseConnect } from 'react-redux-firebase';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import ApiCustomer from '../../utils/Api/ApiCustomer';
import TokenHelper from '../../utils/Firebase/TokenHelper';
import { updateUserDataAction } from '../../actions/CustomerActions';
import { showLoadingAction, creatingUserAction, hideLoadingAction, showErrorAction, hideErrorAction } from '../../actions/GlobalActions';
import { updateIsNewCustomer } from '../../actions/NavigationActions';

import Scrollable from '../../components/Scrollable';
import Loading from '../../components/Loading';
import Modal from '../../components/Modal';
import AlertView from '../../components/AlertView';
import Email from 'react-email-autocomplete';

import './SignUp.scss';
import img_logo_pixyalbum from '../../images/logo_pixyalbum.png';
import logo_pixyalbum from '../../images/newDesignImages/pixyalbum-logo.png';
import fb_logo from '../../images/newDesignImages/fb-logo.png';

class SignUp extends Component {

  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      password: '',
      repeatPassword: '',
      signingin: false,
      termsAccepted: false
    }
  }

  componentDidMount() {
    this.initializeFacebookLogin();
    this.initializePixelFacebook();
    this.getGooglAnalythicsData();
    this.props.hideLoadingAction();
  }

  componentDidUpdate() {
    const { auth, creatingUser, history } = this.props;
    const { signingin } = this.state;
    if ((auth.uid && signingin === false) || (auth.uid && creatingUser === false)) {
      history.replace('/projects');
    }
  }

  getGooglAnalythicsData = () => {
    // const url = "https://web.pixyalbum.com/?utm_source=rappi&utm_medium=banner&utm_campaign=rappi_nuevos"
    const url = window.location.href;
    if (url.indexOf('?') !== -1) {
      const index = url.indexOf('?') + 1;
      let dataObj = {};
      url.slice(index, url.length).split('&').forEach(item => {
        const dataArr = item.split('=');
        dataObj[dataArr[0]] = dataArr[1];
      });
      if (!dataObj.redirect) {
        localStorage.setItem('google_adds', JSON.stringify(dataObj))
      } 
    }
  }

  readData = e => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  initializeFacebookLogin = () => {
    this.FB = window.FB;
  }

  initializePixelFacebook = () => {
    this.fbq = window.fbq;
  }

  signUpWithEmailAndPassword = e => {
    e.preventDefault();
    const { password, repeatPassword } = this.state;
    if (password !== repeatPassword) {
      this.props.showErrorAction('Las contraseñas no coinciden')
      return;
    }
    if (!this.state.termsAccepted) {
      this.props.showErrorAction('Debes aceptar los términos y condiciones para continuar.');
      return;
    }
    this.setState({ signingin: true });
    const { firebase, updateUserDataAction, showLoadingAction, creatingUserAction, hideLoadingAction } = this.props;
    this.props.updateIsNewCustomer(true);
    creatingUserAction(true);
    showLoadingAction();
    const newUser = this.state;
    firebase.createUser(newUser).then((userCreatedData) => {
      this.logRegisterEvent(firebase.auth().currentUser.uid, newUser.email, newUser.name);
      this.completeRegistrationPixel(firebase.auth().currentUser.uid, newUser.name)
      updateUserDataAction(newUser.name, newUser.email, null);
    }).catch(error => {
      this.props.showErrorAction(error.message)
      hideLoadingAction();
    });
  }

  logRegisterEvent = (userId, email, name) => {
    var params = {};
    params['userId'] = userId;
    params['source'] = 'Email';
    params['email'] = email;
    params['name'] = name;
    this.FB.AppEvents.logEvent('Register', null, params);
  }

  // Pixel Facebook - CompleteRegistration
  completeRegistrationPixel = (userId, name) => {
    window.fbq('track', 'CompleteRegistration', {
      user_id: userId,
      name: name,
      origin: 'web'
    });
  }

  handleCheckBoxClick = () => {
    this.state.termsAccepted ? this.setState({ termsAccepted: false }) : this.setState({ termsAccepted: true });
  }

  okErrorHandler = () => {
    this.props.hideErrorAction();
  }

  loginWithFacebook = e => {
    e.preventDefault();
    this.FB.login(this.facebookLoginHandler, { scope: 'public_profile' })
  }

  facebookLoginHandler = response => {
    const { firebase } = this.props;
    if (response.status === 'connected') {
      var provider = firebase.auth.FacebookAuthProvider.credential(response.authResponse.accessToken);
      firebase.auth().signInWithCredential(provider)
        .then(result => {
          let user = result.user
          let email = ''
          let name = ''
          if (user.email) {
            email = user.email;
          }
          if (user.displayName) {
            name = user.displayName;
          }

          const userReference = this.props.firebase.ref('users/' + user.uid);
          userReference.once('value', (snapshot) => {
            if (!snapshot.exists()) {
              this.logRegisterEvent(user.uid, email, name);
            }
          })
          this.updateCustomer(email, name);
        })
        .catch(e => {
          // console.log('Facebook login error');
        });
    } else {
      // TODO: Show error
    }
  }

  updateCustomer = (email, name) => {
    // TODO: Change this method to actions
    TokenHelper.getUserToken()
      .then(userToken => {
        // Send update to server
        const params = {
          token: userToken,
          email: email,
          name: name
        }
        ApiCustomer.updateCustomer(params)
          .then(response => {
            if (response.data.success) {
              // console.log('Go to collections...');
            } else {
              // console.log('Show error');
            }
          });
      })
      .catch(e => {
        // console.log('Get token error');
      });
  }

  logRegisterEvent = (userId, email, name) => {
    var params = {};
    params['userId'] = userId;
    params['source'] = 'Facebook';
    params['email'] = email;
    params['name'] = name;
    this.FB.AppEvents.logEvent('Register', null, params);
  }

  render() {
    return (
      <React.Fragment>
        {this.props.showingError &&
          <Modal>
            <AlertView
              title={'¡Ups!'}
              message={this.props.errorMessage}
              okHandler={this.okErrorHandler} />
          </Modal>
        }
        {this.props.isLoading && <Loading />}
        {(this.props.auth.isEmpty && this.props.auth.isLoaded) &&
          <div className="signpu_main d-flex">
            <div className="signup_left"></div>
            <div className="signup_right d-flex flex-column align-items-center justify-content-center">
              <div className="signup_logo-container d-flex flex-column justify-content-center align-items-center">
                <img height="60" src={logo_pixyalbum} alt="" />
                <p className="gotham-bold-green-16 mt-2" style={{textAlign: 'center'}}>Regístrate ahora y obtén</p>
                <div className="d-flex">
                  <p className="gotham-bold-pink-16">10% OFF</p>
                  <p className="gotham-bold-green-16 ml-2">en tu primera compra.</p>
                </div>   
              </div>
              <form onSubmit={this.signUpWithEmailAndPassword} className="signup_form d-flex flex-column align-items-center">
                <p className="gotham-bold-gray-14">Crea una cuenta y comienza tu proyecto.</p>
                <input
                  className="signup_input"
                  type="text"
                  name="name"
                  placeholder="Nombre completo"
                  required
                  value={this.state.name}
                  onChange={this.readData}
                />
                <input
                  className="signup_input"
                  type="email"
                  name="email"
                  placeholder="Correo"
                  autoComplete="email"
                  required
                  value={this.state.email}
                  onChange={this.readData} 
                />
                {/* <Email
                  className="signup_input"
                  type="email"
                  name="email"
                  placeholder="Correo"
                  autoComplete="email"
                  required
                  value={this.state.email}
                  onChange={this.readData} 
                /> */}
                <input
                  className="signup_input"
                  type="password"
                  name="password"
                  placeholder="Contraseña"
                  required
                  value={this.state.password}
                  onChange={this.readData} 
                />
                <input
                  className="signup_input"
                  type="password"
                  name="repeatPassword"
                  placeholder="Repite tu contraseña"
                  required
                  value={this.state.repeatPassword}
                  onChange={this.readData} 
                />
                <div className="signup_terms mb-3 d-flex justify-content-start align-items-center">
                  <input
                    type="checkbox"
                    checked={this.state.termsAccepted}
                    onChange={this.handleCheckBoxClick}
                  /><a className="signup_terms-link ml-2" href="https://www.pixyalbum.com/terms" target="_blank">Aceptar términos y condiciones.</a>
                </div>
                <button className="signup_register-btn" type="submit">
                  Regístrate
                </button>
                <div className="mt-2 mb-2">
                  <p className="gotham-book-gray-15">ó</p>
                </div>
                <div className="signup_alt-register d-flex flex-column align-items-center">
                  <button className="signup_fb-register d-flex justify-content-center align-items-center" onClick={this.loginWithFacebook}>
                    <img src={fb_logo} height="25" alt="" />
                    <p className="gotham-book-18 ml-2">Regístrate con Facebook</p>
                  </button>
                </div>
                <div className="mt-1 d-flex align-items-center">
                  <p className="gotham-book-18">¿Ya estás registrado?</p>
                  <Link className="signup_link ml-2" to="/login">Inicia sesión</Link>
                </div>
                
              </form>
            </div>
          </div>
          


          // <Scrollable scrollableContent={
          //   <div className="signup-main-wrapper">
          //     <div className="container d-flex min-vh-100">
          //       <div className="row align-items-center mx-auto">
          //         <div className="col-12">
          //           <div className="card login-card">
          //             <div className="card-body">
          //               <div className="d-flex justify-content-center">
          //                 <img src={img_logo_pixyalbum} alt="img" />
          //               </div><br />
          //               <h2 className="card-title text-center">CREAR CUENTA</h2>
          //               {
          //                 (errorMessage !== '') && <div className="text-danger">{errorMessage}</div>
          //               }
          //               <form className="form" onSubmit={this.signUpWithEmailAndPassword}>
          //                 <div className="form-group">
          //                   <input
          //                     className="form-control rounded-input"
          //                     type="text"
          //                     name="name"
          //                     placeholder="Nombre"
          //                     required
          //                     value={this.state.name}
          //                     onChange={this.readData} />
          //                 </div>
          //                 <div className="form-group">
          //                   <Email
          //                     className="form-control rounded-input"
          //                     type="email"
          //                     name="email"
          //                     placeholder="Correo"
          //                     autoComplete="email"
          //                     required
          //                     value={this.state.email}
          //                     onChange={this.readData} />
          //                 </div>
          //                 <div className="form-group">
          //                   <input
          //                     className="form-control rounded-input"
          //                     type="password"
          //                     name="password"
          //                     placeholder="Contraseña"
          //                     required
          //                     value={this.state.password}
          //                     onChange={this.readData} />
          //                 </div>
          //                 <div className="form-group">
          //                   <input
          //                     className="form-control rounded-input"
          //                     type="password"
          //                     name="repeatPassword"
          //                     placeholder="Repetir Contraseña"
          //                     required
          //                     value={this.state.repeatPassword}
          //                     onChange={this.readData} />
          //                 </div>
          //                 <div className="form-group mx-2">
          //                   <input
          //                     type="checkbox"
          //                     checked={this.state.termsAccepted}
          //                     onChange={this.handleCheckBoxClick}
          //                   /><a className="ml-2" href="https://www.pixyalbum.com/terms" target="_blank">Acepto términos y condiciones</a>
          //                 </div>
          //                 <input
          //                   id="signup"
          //                   className="btn-pixyalbum-primary btn-block rounded-button"
          //                   type="submit"
          //                   value="Regístrate" />
          //               </form>
          //               <div className="text-center mt-3">
          //                 <small>¿Ya tienes una cuenta? &nbsp;<Link id="go-login" to='/login'>Inicia sesión</Link></small>
          //               </div>
          //             </div>
          //           </div>
          //         </div>
          //       </div>
          //     </div>
          //   </div>
          // } />
        }
      </React.Fragment>
    );
  }
}


const mapStateToProps = state => ({
  isLoading: state.globalReducer.isLoading,
  showingError: state.globalReducer.showingError,
  errorMessage: state.globalReducer.errorMessage,
  auth: state.firebase.auth,
  creatingUser: state.globalReducer.creatingUser
})

const mapDispatchToProps = {
  updateUserDataAction: updateUserDataAction,
  showLoadingAction: showLoadingAction,
  creatingUserAction: creatingUserAction,
  hideLoadingAction: hideLoadingAction,
  showErrorAction: showErrorAction,
  hideErrorAction: hideErrorAction,
  updateIsNewCustomer: updateIsNewCustomer
}

export default compose(
  firebaseConnect(),
  connect(mapStateToProps, mapDispatchToProps)
)(SignUp);