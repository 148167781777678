function formattedDate(date) {
    const months = ["enero", "febrero", "marzo", "abril", "mayo", "junio", "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"];
    let dateSplit = date.split("-");
    let monthInt = parseInt(dateSplit[1]);
    let formattedDate = dateSplit[2] + " " + months[monthInt - 1] + ", " + dateSplit[0];
    return formattedDate;
}

function parseAlbumConfiguration(configurations) {
    const coverConfiguration = configurations.configurations.filter(item => item.type === 'cover')[0]
    const pageConfiguration = configurations.configurations.filter(item => item.type === 'page')[0]
    const parsedAlbumConfigurations = [
        {
            "id": configurations.id,
            "type": configurations.type
        },
        {
            "id": coverConfiguration.id,
            "type": coverConfiguration.type
        },
        {
            "id": pageConfiguration.id,
            "type": pageConfiguration.type
        }
    ]
    return parsedAlbumConfigurations;
}

function parseOrnamentConfiguration(configurations) {
    const sizeConfiguration = configurations.configurations.filter(item => item.type === 'size')[0]
    const materialConfiguration = configurations.configurations.filter(item => item.type === 'material')[0]
    const parsedOrnamentConfigurations = [
        {
            "id": configurations.id,
            "type": configurations.type
        },
        {
            "id": sizeConfiguration.id,
            "type": sizeConfiguration.type
        },
        {
            "id": materialConfiguration.id,
            "type": materialConfiguration.type
        }
    ]
    return parsedOrnamentConfigurations;
}

export { formattedDate, parseAlbumConfiguration, parseOrnamentConfiguration };