import ApiCustomer from '../utils/Api/ApiCustomer';
import TokenHelper from '../utils/Firebase/TokenHelper';
import {
    SHOW_COLLECTIONS,
    SELECT_COLLECTION,
    SHOW_ALBUMS,
    SHOW_ALBUM,
    SELECT_PHOTO,
    SHOW_DETAIL_PHOTO,
    UPDATE_DETAIL_PHOTO,
    UPDATE_DETAIL_COLLECTION,
    SELECTED_INDEX_BOOK_DETAIL_PHOTO,
    INDEX_PHOTO_BOOK_DETAIL,
    ADD_SELECTED_PHOTO_BOOK,
    REMOVE_SELECTED_PHOTO_BOOK,
    RESET_PHOTOS_SELECTED_BOOK,
    COVER_SELECTED_FOR_CHANGE,
    START_LOADING_INFO,
    FINISH_LOADING_INFO,
    SHOW_ERROR,
    DELETE_COLLECTION,
    EDIT_NAME_COLLECTION,
    RESET_COLLECTIONS_STATE,
    LOAD_USER_CARDS,
    START_LOADING_USER_CARDS,
    FINISH_LOADING_USER_CARDS,
    LOAD_USER_ADDRESSES,
    START_LOADING_USER_ADDRESSES,
    FINISH_LOADING_USER_ADDRESSES,
    LOAD_CART,
    CREATING_USER,
    CREATE_CUSTOMER_ADDRESS,
    CREATE_CUSTOMER_ADDRESS_ON_SERVER,
    SHOW_CREATE_ADDRESS,
    RELOAD_USER_ADDRESSES,
    SHOW_HIDE_CREATE_CARD_TOKEN,
    CREATE_CUSTOMER_CARD_TOKEN_ON_SERVER,
    RELOAD_USER_CARDS,
    SHOW_GO_TO_CART_ALERT,
    ADD_LOCAL_ALBUM_TO_CART,
    REMOVE_LOCAL_PRODUCT_FROM_CART,
    CREATE_SORTABLE_DATA_SOURCE,
    TOGGLE_UPLOADING_COVER_FLAG,
    TOGGLE_SHOW_CHANGE_COVER_MODAL,
    TOGGLE_SHOW_CHANGE_COVER_SELECT_SOURCE_MODAL,
    SELECT_COVER_FOR_EDIT,
    SET_CUSTOMER_DETAIL,
    SET_CUSTOMER_WALLET,
    LOAD_WALLET_TRANSACTIONS,
    LOAD_CUSTOMER_ORDERS,
    REMOVE_USER_CARD,
    REMOVE_USER_ADDRESS,
    START_LOADING_USER_ORDERS,
    FINISH_LOADING_USER_ORDERS,
    LOAD_COVER_CATEGORIES,
    LOAD_COVERS,
    LOAD_AVAILABLE_PRODUCTS,
    UPDATE_CART_QUANTITY,
    UPDATE_COLLECTION,
    UPDATE_ALBUM_CONFIGURATION,
    SAVE_PARAMS_FOR_ALBUM_CONFIGURATION,
    SAVE_PAGES_DIFERENCE
} from './ActionTypes';
import { createSortableDataSourceHelper } from './SortableListPhotoActions';

export const loadCollectionsAction = () => async (dispatch) => {
    dispatch({
        type: START_LOADING_INFO,
        payload: true
    });
    const param = await TokenHelper.getUserToken().then(idToken => {
        return ({ token: idToken });
    });
    const response = await ApiCustomer.getCustomerCollections(param).then(response => { return response.data; }).catch(error => { return error.data; });
    if (response.success) {
        dispatch({
            type: SHOW_COLLECTIONS,
            payload: response.data
        })
    } else {
        dispatch({
            type: SHOW_ERROR,
            payload: response.message
        })
    }
    dispatch({
        type: FINISH_LOADING_INFO,
        payload: false
    });
}

export const selectCollectionAction = collection => async (dispatch) => {
    localStorage.setItem('SELECTED_COLLECTION', JSON.stringify(collection));
    dispatch({
        type: SELECT_COLLECTION,
        payload: collection
    })
}

export const loadCollectionAlbumsAction = collectionId => async (dispatch) => {
    dispatch({
        type: START_LOADING_INFO,
        payload: true
    });
    const params = await TokenHelper.getUserToken().then(idToken => {
        return {
            token: idToken,
            product_id: collectionId,
            product_type: 'album'
        };
    }); 
    const response = await ApiCustomer.getCustomerProductDetail(params).then(response => { return response.data; }).catch(error => { return error.data; });
    if (response.success) {
        const numPages = parseInt(response.data.configurations.configurations[1].description);
        const { collection, collectionBooks } = createCollectionData(response.data, numPages);
        localStorage.setItem("originalPagesOrder", JSON.stringify(response.data.pages));
        dispatch({
            type: SELECT_COLLECTION,
            payload: collection
        });
        dispatch({
            type: SHOW_ALBUMS,
            payload: collectionBooks
        });
        dispatch({
            type: UPDATE_ALBUM_CONFIGURATION,
            payload: response.data.configurations
        });
    } else {
        dispatch({
            type: SHOW_ERROR,
            payload: response.message
        });
    }
    dispatch({
        type: FINISH_LOADING_INFO,
        payload: false
    });
}

export const updateCollectionSelectedAction = (newCollection, numPages) => (dispatch) => {
    const { collection, collectionBooks } = createCollectionData(newCollection, numPages);
    dispatch({
        type: UPDATE_COLLECTION,
        payload: collection
    });
    dispatch({
        type: SHOW_ALBUMS,
        payload: collectionBooks
    });
}

export const addSelectedPhotoBookAction = (photo) => async (dispatch) => {
    dispatch({
        type: ADD_SELECTED_PHOTO_BOOK,
        payload: photo
    })
}

export const removeSelectedPhotoBookAction = (photo) => async (dispatch) => {
    dispatch({
        type: REMOVE_SELECTED_PHOTO_BOOK,
        payload: photo
    })
}

export const deletePhotosAlbumAction = (photosIdsToDelete, collection) => async (dispatch) => {
    dispatch({
        type: START_LOADING_INFO,
        payload: true
    });

    const param = await TokenHelper.getUserToken().then(idToken => {
        return {
            token: idToken,
            album_id: collection.id,
            photos_ids: photosIdsToDelete
        }
    });
    const response = await ApiCustomer.deleteCustomerPhotoAlbum(param).then(response => { return response.data; }).catch(error => { return error.data; });
    if (response.success) {
        const collectionForUpdate = JSON.parse(JSON.stringify(collection));
        const photos = collectionForUpdate.photos;
        const remainPhotos = photos.filter(item => { return !photosIdsToDelete.includes(item.id) });
        for (var x = 0; x < remainPhotos.length; x++) {
            // Recalculate the correct index for the remain photos
            remainPhotos[x].index = x;
        }
        collectionForUpdate.photos = remainPhotos;
        dispatch({
            type: SELECT_COLLECTION,
            payload: collectionForUpdate
        });
        localStorage.setItem('SELECTED_COLLECTION', JSON.stringify(collectionForUpdate));
        const { sortableDataSource } = createSortableDataSourceHelper(collectionForUpdate, 60);
        dispatch({
            type: CREATE_SORTABLE_DATA_SOURCE,
            payload: sortableDataSource
        });
    } else {
        dispatch({
            type: SHOW_ERROR,
            payload: response.message
        })
    }
    dispatch({
        type: FINISH_LOADING_INFO,
        payload: false
    });
}

export const showAlbumAction = (photosBook) => async (dispatch) => {
    dispatch({
        type: SHOW_ALBUM,
        payload: photosBook
    })
}

export const deleteCollectionAction = (collection) => async (dispatch) => {
    dispatch({
        type: START_LOADING_INFO,
        payload: true
    });
    const params = await TokenHelper.getUserToken().then(idToken => {
        let params = {
            token: idToken,
            album_id: collection.id
        };
        return params;
    });
    const response = await ApiCustomer.deleteCustomerCollection(params).then(response => { return response.data; }).catch(error => { return error.data; });
    if (response.success) {
        dispatch({
            type: DELETE_COLLECTION,
            payload: collection
        })
    } else {
        dispatch({
            type: SHOW_ERROR,
            payload: response.message
        })
    }
    dispatch({
        type: FINISH_LOADING_INFO,
        payload: false
    });
}

export const showPhotoDetailAction = (photoDetail) => async (dispatch) => {
    dispatch({
        type: SHOW_DETAIL_PHOTO,
        payload: photoDetail
    })
}

export const selectPhotoAction = (idPhoto) => async (dispatch) => {
    dispatch({
        type: SELECT_PHOTO,
        payload: idPhoto
    })
}

export const updatePhotoDetailAction = (collection, photoDetail, selectedPageId, imageUrl, width, height) => async (dispatch) => {
    if (imageUrl && width && height) {
        const param = await TokenHelper.getUserToken().then(idToken => {
            return ({
                token: idToken,
                album_id: photoDetail.album_id,
                photo_id: photoDetail.id,
                title: photoDetail.title,
                date: photoDetail.date,
                image_url: imageUrl,
                width: width,
                height: height
            });
        });
    
        const response = await ApiCustomer.updatePhotoData(param).then(response => { return response.data; });
        if (response.success) {
            dispatch({
                type: UPDATE_DETAIL_PHOTO,
                payload: photoDetail
            });
            // Update the photo data on the collection and sortable data source
            const collectionForUpdate = JSON.parse(JSON.stringify(collection));
            collectionForUpdate.photos[photoDetail.index] = photoDetail;
            dispatch({
                type: SELECT_COLLECTION,
                payload: collectionForUpdate
            })
            const { sortableDataSource } = createSortableDataSourceHelper(collectionForUpdate, 60);
            dispatch({
                type: CREATE_SORTABLE_DATA_SOURCE,
                payload: sortableDataSource
            });
        }
    } else {
        const param = await TokenHelper.getUserToken().then(idToken => {
            return ({
                token: idToken,
                album_id: photoDetail.album_id,
                title: photoDetail.title,
                date: photoDetail.date,
                page_id: selectedPageId
            });
        });
    
        const response = await ApiCustomer.updatePageData(param).then(response => { return response.data; });
        if (response.success) {
            dispatch({
                type: UPDATE_DETAIL_PHOTO,
                payload: photoDetail
            });
            // Update the photo data on the collection and sortable data source
            const collectionForUpdate = JSON.parse(JSON.stringify(collection));
            collectionForUpdate.photos[photoDetail.index] = photoDetail;
            dispatch({
                type: SELECT_COLLECTION,
                payload: collectionForUpdate
            })
            const { sortableDataSource } = createSortableDataSourceHelper(collectionForUpdate, 60);
            dispatch({
                type: CREATE_SORTABLE_DATA_SOURCE,
                payload: sortableDataSource
            });
        }
    }
}

export const selectedIndexBookDetailPhotoAction = (indexBook) => async (dispatch) => {
    dispatch({
        type: SELECTED_INDEX_BOOK_DETAIL_PHOTO,
        payload: indexBook
    })
}

export const selectedIndexPhotoBookDetailAction = (photo) => async (dispatch) => {
    dispatch({
        type: INDEX_PHOTO_BOOK_DETAIL,
        payload: photo
    })
}


export const editNameCollectionAction = (collection) => async (dispatch) => {
    const param = await TokenHelper.getUserToken().then(idToken => {
        return ({
            token: idToken,
            album_id: collection.id,
            print_dates: collection.print_dates,
            hard_cover: collection.hard_cover,
            title: collection.name
        });
    });
    const response = await ApiCustomer.updateCollectionSettings(param).then(response => { return response.data; });
    if (response.success) {
        dispatch({
            type: EDIT_NAME_COLLECTION,
            payload: collection
        })
    }
}

export const updateCollectionDetailAction = (collection) => async (dispatch) => {
    dispatch({
        type: START_LOADING_INFO,
        payload: true
    });
    const param = await TokenHelper.getUserToken().then(idToken => {
        return ({
            token: idToken,
            album_id: collection.id,
            print_dates: collection.print_dates,
            hard_cover: collection.hard_cover,
            title: collection.title
        });
    });
    const response = await ApiCustomer.updateCollectionSettings(param).then(response => { return response.data; });
    if (response.success) {
        dispatch({
            type: UPDATE_DETAIL_COLLECTION,
            payload: collection
        })
    }
    dispatch({
        type: START_LOADING_INFO,
        payload: false
    });
}

export const updateCollectionSettingsAction = (collection, title, hardCover, printDates, configurations) => async (dispatch) => {
    dispatch({
        type: START_LOADING_INFO,
        payload: true
    });
    const params = await TokenHelper.getUserToken().then(idToken => {
        let params = {
            token: idToken,
            album_id: collection.id
        }
        if (title !== null) {
            params.title = title;
        }
        if (hardCover !== null) {
            params.hard_cover = hardCover;
        }
        if (printDates !== null) {
            params.print_dates = printDates;
        }
        if (configurations !== null) {
            params.configurations = configurations;
        }
        return params;
    });
    const response = await ApiCustomer.updateCollectionSettings(params).then(response => { return response.data; });
    if (response.success) {
        const { collection, collectionBooks } = createCollectionData(response.data.result, 60);
        dispatch({
            type: SELECT_COLLECTION,
            payload: collection
        });
        dispatch({
            type: SHOW_ALBUMS,
            payload: collectionBooks
        });
    }
    dispatch({
        type: START_LOADING_INFO,
        payload: false
    });
}

export const resetCollectionStateActions = () => async (dispatch) => {
    dispatch({
        type: RESET_COLLECTIONS_STATE,
        payload: null
    });
}

export const resetPhotosSelectBookAction = () => (dispatch) => {
    dispatch({
        type: RESET_PHOTOS_SELECTED_BOOK,
        payload: null
    })
}

export const coverSelectedForChangeAction = (idBook) => (dispatch) => {
    dispatch({
        type: COVER_SELECTED_FOR_CHANGE,
        payload: idBook
    })
}

export const loadUserCardsAction = () => async (dispatch) => {
    dispatch({
        type: START_LOADING_USER_CARDS,
        payload: null
    });
    const params = await TokenHelper.getUserToken().then(idToken => { return ({ token: idToken }); });
    const response = await ApiCustomer.loadUserCards(params).then(response => { return response.data; }).catch(error => { return error.data; });
    if (response.success) {
        dispatch({
            type: LOAD_USER_CARDS,
            payload: response.data
        });
    } else {
        dispatch({
            type: SHOW_ERROR,
            payload: response.message
        })
    }
    dispatch({
        type: FINISH_LOADING_USER_CARDS,
        payload: null
    });
}

export const loadUserAddressesAction = () => async (dispatch) => {
    dispatch({
        type: START_LOADING_USER_ADDRESSES,
        payload: null
    });
    const params = await TokenHelper.getUserToken().then(idToken => { return ({ token: idToken }); });
    const response = await ApiCustomer.loadUserAddresses(params).then(response => { return response.data; }).catch(error => { return error.data; });
    if (response.success) {
        dispatch({
            type: LOAD_USER_ADDRESSES,
            payload: response.data
        });
    } else {
        dispatch({
            type: SHOW_ERROR,
            payload: response.message
        })
    }
    dispatch({
        type: FINISH_LOADING_USER_ADDRESSES,
        payload: null
    });
}

export const loadCartAction = (showLoadingScreen) => async (dispatch) => {
    if (showLoadingScreen) {
        dispatch({
            type: START_LOADING_INFO,
            payload: true
        });
    }

    let makeRequest = true;
    const params = await TokenHelper.getUserToken().then(idToken => {
        let params = {
            token: idToken
        };
        return params;
    }).catch(error => {
        // This must be refactored in order to handle in a better way if the user is logged in or not before making the cart api call
        // When you have no session and refresh the sign up screen, the loading view is visible because we try to load the cart on navigation component
        makeRequest = false;
    });
    if (makeRequest) {
        const response = await ApiCustomer.getCart(params).then(response => { return response.data; }).catch(error => { return error.data; });
        if (response.success) {
            for (let album of response.data.cart.albums) {
                album['product_type'] = 'album';
            }
            for (let giftcard of response.data.cart.giftcards) {
                giftcard['product_type'] = 'giftcard';
            }
            for (let magnet of response.data.cart.magnets) {
                magnet['product_type'] = 'magnet';
            }
            for (let picture of response.data.cart.pictures) {
                picture['product_type'] = 'picture';
            }
            for (let ornament of response.data.cart.ornaments) {
                ornament['product_type'] = 'ornament';
            }
            let products = response.data.cart.albums.concat(response.data.cart.giftcards).concat(response.data.cart.magnets).concat(response.data.cart.pictures).concat(response.data.cart.ornaments);

            dispatch({
                type: LOAD_CART,
                payload: products
            })
        } else {
            dispatch({
                type: SHOW_ERROR,
                payload: response.message
            })
        }
    }

    if (showLoadingScreen) {
        dispatch({
            type: FINISH_LOADING_INFO,
            payload: false
        });
    }
}

export const getCartProductsQuantityAction = () => async(dispatch) => {
    try {
        const idToken = await TokenHelper.getUserToken();
        const response = await ApiCustomer.getCartItemsNumber({ token: idToken });
        if (response.data.success) {
            dispatch({
                type: UPDATE_CART_QUANTITY,
                payload: response.data.data.count
            })
        } else {
            dispatch({
                type: SHOW_ERROR,
                payload: response.message
            })
        }
    } catch(e) {
        dispatch({
            type: SHOW_ERROR,
            payload: 'Ha ocurrido un error, por favor intenta mas tarde.'
        })
    }
}

export const updateCartProductsAction = (products) => (dispatch) => {
    dispatch({
        type: LOAD_CART,
        payload: products
    });
    dispatch({
        type: UPDATE_CART_QUANTITY,
        payload: products.length
    })
}

export const updateUserDataAction = (name, email, phone, profile_image) => async (dispatch) => {
    dispatch({
        type: START_LOADING_INFO,
        payload: true
    });
    const params = await TokenHelper.getUserToken().then(idToken => {
        let params = {
            token: idToken
        };
        if (name) {
            params['name'] = name
        }
        if (email) {
            params['email'] = email
        }
        if (phone) {
            params['phone'] = phone
        }
        if (profile_image) {
            params['profile_image'] = profile_image
        }
        return params;
    });
    const response = await ApiCustomer.updateData(params).then(response => { return response.data; }).catch(error => { return error.data; });
    if (response.success) {
        dispatch({
            type: CREATING_USER,
            payload: false
        })
        dispatch({
            type: SET_CUSTOMER_DETAIL,
            payload: response.data
        })
    } else {
        dispatch({
            type: SHOW_ERROR,
            payload: response.message
        })
    }
    dispatch({
        type: FINISH_LOADING_INFO,
        payload: false
    });
}

export const createAddressAction = (addressData) => async (dispatch) => {
    dispatch({
        type: START_LOADING_INFO,
        payload: true
    });
    dispatch({
        type: CREATE_CUSTOMER_ADDRESS_ON_SERVER,
        payload: true
    });
    const params = await TokenHelper.getUserToken().then(idToken => {
        let params = {
            token: idToken,
            data: addressData
        };
        return params;
    });
    const response = await ApiCustomer.createAddress(params).then(response => { return response.data; }).catch(error => { return error.data; });
    if (response.success) {
        dispatch({
            type: CREATE_CUSTOMER_ADDRESS,
            payload: response.data
        });
        dispatch(reloadUserAddressesAction(true));
        dispatch(toggleCreateAddressAction(false));
    } else {
        dispatch({
            type: SHOW_ERROR,
            payload: response.message
        });
    }
    dispatch({
        type: CREATE_CUSTOMER_ADDRESS_ON_SERVER,
        payload: false
    });
    dispatch({
        type: FINISH_LOADING_INFO,
        payload: false
    });
}

export const reloadUserAddressesAction = (reload) => {
    return {
        type: RELOAD_USER_ADDRESSES,
        payload: reload
    };
}

export const toggleCreateAddressAction = (show) => {
    return {
        type: SHOW_CREATE_ADDRESS,
        payload: show
    }
}

export const toggleCreateCardTokenAction = (show) => {
    return {
        type: SHOW_HIDE_CREATE_CARD_TOKEN,
        payload: show
    }
}

export const addCardTokenToUserAction = (cardToken) => async (dispatch) => {
    dispatch({
        type: START_LOADING_INFO,
        payload: true
    });
    dispatch({
        type: CREATE_CUSTOMER_CARD_TOKEN_ON_SERVER,
        payload: true
    });
    const params = await TokenHelper.getUserToken().then(idToken => {
        let params = {
            token: idToken,
            card_token: cardToken
        };
        return params;
    });
    const response = await ApiCustomer.createCardToken(params).then(response => { return response.data; }).catch(error => { return error.data; });
    if (response.success) {
        dispatch(reloadUserCardsAction(true));
        dispatch(toggleCreateCardTokenAction(false));
    } else {
        dispatch({
            type: SHOW_ERROR,
            payload: response.message
        });
    }
    dispatch({
        type: CREATE_CUSTOMER_CARD_TOKEN_ON_SERVER,
        payload: false
    });
    dispatch({
        type: FINISH_LOADING_INFO,
        payload: false
    });
}

export const reloadUserCardsAction = (reload) => {
    return {
        type: RELOAD_USER_CARDS,
        payload: reload
    };
}

export const addAlbumToCartAction = (album) => async (dispatch) => {
    dispatch({
        type: START_LOADING_INFO,
        payload: true
    });
    const params = await TokenHelper.getUserToken().then(idToken => {
        let params = {
            token: idToken,
            data: album,
            product_type: "album"
        };
        return params;
    });
    const response = await ApiCustomer.addProductToCart(params).then(response => { return response.data; }).catch(error => { return error.data; });
    if (response.success) {
        dispatch({
            type: ADD_LOCAL_ALBUM_TO_CART,
            payload: response.data
        });
        dispatch({
            type: SHOW_GO_TO_CART_ALERT,
            payload: true
        });
    } else {
        dispatch({
            type: SHOW_ERROR,
            payload: response.message
        })
    }
    dispatch({
        type: FINISH_LOADING_INFO,
        payload: false
    });
}

export const deleteProductFromCartAction = (product) => async (dispatch) => {
    dispatch({
        type: START_LOADING_INFO,
        payload: true
    });
    const params = await TokenHelper.getUserToken().then(idToken => {
        let params = {
            token: idToken,
            product_id: product.id,
            product_type: product.product_type
        };
        return params;
    });
    const response = await ApiCustomer.deleteProductFromCart(params).then(response => { return response.data; }).catch(error => { return error.data; });
    if (response.success) {
        dispatch({
            type: REMOVE_LOCAL_PRODUCT_FROM_CART,
            payload: product
        });
    } else {
        dispatch({
            type: SHOW_ERROR,
            payload: response.message
        })
    }
    dispatch({
        type: FINISH_LOADING_INFO,
        payload: false
    });
}

export const changeAlbumCoverAction = (coverUrl, selectedCollection, index, predesignId) => async (dispatch) => {
    dispatch({
        type: TOGGLE_UPLOADING_COVER_FLAG,
        payload: true
    });
    const params = await TokenHelper.getUserToken().then(idToken => {
        let params = {
            token: idToken,
            album_id: selectedCollection.id,
            index: index,
            image_url: coverUrl,
            predesign_cover_id: predesignId
        };
        return params;
    });
    const response = await ApiCustomer.changeAlbumCover(params).then(response => { return response.data; }).catch(error => { return error.data; });
    if (response.success) {
        // OBS: response.data.result is the signed image from server
        const collectionForUpdate = JSON.parse(JSON.stringify(selectedCollection));
        const { covers } = collectionForUpdate;
        let coverChanged = false;
        // We try to change the cover if already exists
        for (let cover of covers) {
            if (cover.index === index) {
                cover.image_url = response.data.image_url;
                coverChanged = true;
            }
        }
        // If the cover is new, we need to put it on the collection covers
        if (!coverChanged) {
            covers.push({
                index: index,
                image_url: response.data.image_url,
                predesign_data: response.data.predesign_data
            });
        }
        collectionForUpdate.covers = covers;
        // Recreate data with the updated collection
        const { collection, collectionBooks } = createCollectionData(collectionForUpdate, 60);
        dispatch({
            type: SELECT_COLLECTION,
            payload: collection
        });
        dispatch({
            type: SHOW_ALBUMS,
            payload: collectionBooks
        });
        // Recreate sortable in order to have the correct covers
        const { sortableDataSource } = createSortableDataSourceHelper(collectionForUpdate, 60);
        dispatch({
            type: CREATE_SORTABLE_DATA_SOURCE,
            payload: sortableDataSource
        });
        dispatch({
            type: TOGGLE_SHOW_CHANGE_COVER_MODAL,
            payload: false
        });
    } else {
        dispatch({
            type: START_LOADING_INFO,
            payload: false
        });
        dispatch({
            type: SHOW_ERROR,
            payload: response.message
        });
    }
    dispatch({
        type: TOGGLE_UPLOADING_COVER_FLAG,
        payload: false
    });
    dispatch({
        type: START_LOADING_INFO,
        payload: false
    });
}

export const toggleUploadinCoverAction = (uploading) => {
    return {
        type: TOGGLE_UPLOADING_COVER_FLAG,
        payload: uploading
    }
}

export const toggleShowChangeCoverAction = (show) => {
    return {
        type: TOGGLE_SHOW_CHANGE_COVER_MODAL,
        payload: show
    }
}

export const toggleShowChangeCoverSelectSourceAction = (show) => {
    return {
        type: TOGGLE_SHOW_CHANGE_COVER_SELECT_SOURCE_MODAL,
        payload: show
    }
}

export const selectCoverForEditAction = (coverUrl, index) => {
    return {
        type: SELECT_COVER_FOR_EDIT,
        payload: { cover: coverUrl, index: index }
    }
}

export const loadUserDetailAction = () => async (dispatch) => {
    const params = await TokenHelper.getUserToken().then(idToken => {
        let params = {
            token: idToken,
        };
        return params;
    });
    const response = await ApiCustomer.userDetail(params).then(response => { return response.data; }).catch(error => { return error.data; });
    if (response.success) {
        dispatch({
            type: SET_CUSTOMER_DETAIL,
            payload: response.data
        });
    }
}

export const loadWalletCustomerAction = () => async (dispatch) => {
    const params = await TokenHelper.getUserToken().then(idToken => {
        let params = {
            token: idToken,
        };
        return params;
    });
    const response = await ApiCustomer.walletDetail(params).then(response => { return response.data; }).catch(error => { return error.data; });
    if (response.success) {
        dispatch({
            type: SET_CUSTOMER_WALLET,
            payload: response.data
        });
    } else {
        dispatch({
            type: SHOW_ERROR,
            payload: response.message
        });
    }
}

export const loadWalletTransactionsCustomerAction = () => async (dispatch) => {
    const params = await TokenHelper.getUserToken().then(idToken => {
        let params = {
            token: idToken,
        };
        return params;
    });
    const response = await ApiCustomer.walletTransactions(params).then(response => { return response.data; }).catch(error => { return error.data; });
    if (response.success) {
        dispatch({
            type: LOAD_WALLET_TRANSACTIONS,
            payload: response.data
        });
    } else {
        dispatch({
            type: SHOW_ERROR,
            payload: response.message
        });
    }
}


export const updateCustomerDetailAction = (customerName, customerEmail, customerProfileImage) => {
    let data = {
        name: customerName,
        email: customerEmail,
        profile_image: customerProfileImage
    }
    return ({
        type: SET_CUSTOMER_DETAIL,
        payload: data
    });
}

export const loadCustomerOrdersActions = () => async (dispatch) => {
    dispatch({
        type: START_LOADING_USER_ORDERS,
        payload: null
    });
    const params = await TokenHelper.getUserToken().then(idToken => {
        let params = {
            token: idToken,
        };
        return params;
    });
    const response = await ApiCustomer.custumerOrders(params).then(response => { return response.data; }).catch(error => { return error.data; });
    if (response.success) {
        dispatch({
            type: LOAD_CUSTOMER_ORDERS,
            payload: response.data
        });
    } else {
        dispatch({
            type: SHOW_ERROR,
            payload: response.message
        });
    }
    dispatch({
        type: FINISH_LOADING_USER_ORDERS,
        payload: null
    });
}


export const createCollectionData = (collection, chunkSize) => {
    // Sort the photos before create the books
    if (collection.pages.length > 0) {
        // The array is sorted by its index
        collection.pages.sort(function (a, b) {
            if (a.index > b.index) {
                return 1;
            }
            if (a.index < b.index) {
                return -1;
            }
            return 0;
        });
    }
    let collectionBooks = [];
    // Deep clone to update the collection after create the books
    const pages = JSON.parse(JSON.stringify(collection.pages));
    if (pages.length > 0) {
        // const chunkSize = 60;
        let bookIndex = 0;
        while (pages.length > 0) {
            let photosOfBook = pages.splice(0, chunkSize);
            let completeBook = {
                cover_url: photosOfBook[0].photos[0].print_url,
                photos: photosOfBook,
                index: bookIndex
            }
            collectionBooks.push(completeBook);
            bookIndex += 1;
        }
    }
    const covers = collection.covers;
    if (covers.length > 0) {
        for (let cover of covers) {
            if (collectionBooks.length > cover.index) {
                collectionBooks[cover.index].cover_url = (
                    cover.image_url.length > 0 ? cover.image_url : cover.predesign_data.thumbnail_square_url
                );
                collectionBooks[cover.index].predesign_data = cover.predesign_data;
            }
        }
    }
    // We update the selected collection in order to have all data from the server
    localStorage.setItem('SELECTED_COLLECTION', JSON.stringify(collection));
    return { collection: collection, collectionBooks: collectionBooks }
}


export const deleteCustomerCardAction = (cardToken) => async (dispatch) => {
    dispatch({
        type: START_LOADING_USER_CARDS,
        payload: null
    });
    const params = await TokenHelper.getUserToken().then(idToken => {
        let params = {
            token: idToken,
            card_token: cardToken
        };
        return params;
    });
    const response = await ApiCustomer.deleteCustomerCard(params).then(response => { return response.data; }).catch(error => { return error.data; });
    if (response.success) {
        dispatch({
            type: REMOVE_USER_CARD,
            payload: cardToken
        });
    } else {
        dispatch({
            type: SHOW_ERROR,
            payload: response.message
        });
    }
    dispatch({
        type: FINISH_LOADING_USER_CARDS,
        payload: null
    });
}


export const deleteCustomerAddressAction = (addressId) => async (dispatch) => {
    dispatch({
        type: START_LOADING_USER_ADDRESSES,
        payload: null
    });
    const params = await TokenHelper.getUserToken().then(idToken => {
        let params = {
            token: idToken,
            address_id: addressId
        };
        return params;
    });
    const response = await ApiCustomer.deleteCustomerAddress(params).then(response => { return response.data; }).catch(error => { return error.data; });
    if (response.success) {
        dispatch({
            type: REMOVE_USER_ADDRESS,
            payload: addressId
        })
    } else {
        dispatch({
            type: SHOW_ERROR,
            payload: response.message
        });
    }
    dispatch({
        type: FINISH_LOADING_USER_ADDRESSES,
        payload: null
    });
}


export const loadCoverCategoriesAction = () => async (dispatch) => {
    const params = await TokenHelper.getUserToken().then(idToken => { return ({ token: idToken }); });
    const response = await ApiCustomer.loadCoverCategories(params).then(response => { return response.data; }).catch(error => { return error.data; });
    if (response.success) {
        dispatch({
            type: LOAD_COVER_CATEGORIES,
            payload: response.data
        })
    }
}


export const loadCoversAction = (categoryId) => async (dispatch) => {
    const params = await TokenHelper.getUserToken().then(idToken => {
        let params = {
            token: idToken,
            category_id: categoryId
        }
        return params
    });
    const response = await ApiCustomer.loadCovers(params).then(response => { return response.data; }).catch(error => { return error.data; });
    if (response.success) {
        dispatch({
            type: LOAD_COVERS,
            payload: response.data
        })
    }
}

export const startLoadingAction = () => (dispatch) => {
    dispatch({
        type: START_LOADING_INFO,
        payload: true
    });
}

export const loadAvailableProductsAction = (products) => (dispatch) => {
    dispatch({
        type: LOAD_AVAILABLE_PRODUCTS,
        payload: products
    })
}


export const saveParamsForUpdateAlbumConfigurationAction = (params, pagesDiference) => (dispatch) => {
    dispatch({
        type: SAVE_PARAMS_FOR_ALBUM_CONFIGURATION,
        payload: params
    })
    dispatch({
        type: SAVE_PAGES_DIFERENCE,
        payload: pagesDiference
    })
}