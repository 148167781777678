const ConektaHelper = {
  initConekta: () => {
    window.Conekta.setPublicKey(window._env_.CONEKTA_PUBLIC_KEY);
  },
  getCardBrand: (cardNumber) => {
    return window.Conekta.card.getBrand(cardNumber);
  },
  validateCardNumber: (cardNumber) => {
    return window.Conekta.card.validateNumber(cardNumber);
  },
  validateCvc: (cvc) => {
    return window.Conekta.card.validateCVC(cvc);
  },
  validateExpirationDate: (expiryMonth, expiryYear) => {
    return window.Conekta.card.validateExpirationDate(expiryMonth, `20${expiryYear}`);
  },
  tokenize: (cardNumber, cardHolder, expiryMonth, expiryYear, cvc, successCallback, errorCallback) => {
    const tokenParams = {
      card: {
        number: cardNumber,
        name: cardHolder,
        exp_year: expiryYear,
        exp_month: expiryMonth,
        cvc: cvc
      }
    }
    window.Conekta.Token.create(tokenParams, successCallback, errorCallback);
  }
}
  
export default ConektaHelper;