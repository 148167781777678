import React from 'react';
import './PricesTable.scss';


function PricesTable(props) {
  return (
    <div className="my-3 d-flex flex-column align-items-center" style={{width: '280px'}}>
      <p className="gotham-bold-darkgray-15">{props.title}</p>
      <div className="w-100 d-flex justify-content-between">
        <div className="pricestable_odd d-flex justify-content-center align-items-center">
          <p className="gotham-book-gray-15">60 páginas</p>
        </div>
        <div className="pricestable_odd d-flex justify-content-center align-items-center">
          <p className="gotham-book-gray-15">$ {props.price1}</p>
        </div>
      </div>
      <div className="w-100 d-flex justify-content-between">
        <div className="pricestable_even d-flex justify-content-center align-items-center">
          <p className="gotham-book-gray-15">80 páginas</p>
        </div>
        <div className="pricestable_even d-flex justify-content-center align-items-center">
          <p className="gotham-book-gray-15">$ {props.price2}</p>
        </div>
      </div>
      <div className="w-100 d-flex justify-content-between">
        <div className="pricestable_odd d-flex justify-content-center align-items-center">
          <p className="gotham-book-gray-15">100 páginas</p>
        </div>
        <div className="pricestable_odd d-flex justify-content-center align-items-center">
          <p className="gotham-book-gray-15">$ {props.price3}</p>
        </div>
      </div>
    </div>
  );
}

export default PricesTable;