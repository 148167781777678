import React, { Component } from 'react';
import './MyAccount.scss';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { firebaseConnect } from 'react-redux-firebase';
import firebase from 'firebase/app';
import "firebase/app";
import "firebase/storage";
import { updateUserDataAction } from '../../actions/CustomerActions';
import { updateCustomerDetailAction, loadCustomerOrdersActions } from '../../actions/CustomerActions';
import { hideErrorAction, showErrorAction } from '../../actions/GlobalActions';
import { changeRouteAction } from '../../actions/NavigationActions';

import no_image_profile from '../../images/no_image_profile.png';

//Components
import Scrollable from '../../components/Scrollable';
import SelectShippingAddress from '../../components/SelectShippingAddress';
import SelectPaymentMethod from '../../components/SelectPaymentMethod';
import Modal from '../../components/Modal';
import AlertView from '../../components/AlertView';
import AlertViewCustomizable from '../../components/AlertViewCustomizable';
import UpdatePassword from '../../components/UpdatePassword';
import UpdateEmail from '../../components/UpdateEmail';
import PasswordForgetComponent from '../../components/PasswordForgetComponent';
import CreateCardToken from '../../components/CreateCardToken';
import CreateAddress from '../../components/CreateAddress';
import Loading from '../../components/Loading';
import Orders from '../Orders';
import Footer from '../../components/Footer';

import edit_img from '../../images/newDesignImages/edit-img.png';
import my_addresses from '../../images/newDesignImages/my-addresses.png';
import my_wallet from '../../images/newDesignImages/my-wallet-img.png';
import add_new from '../../images/newDesignImages/add-new.png';


class MyAccount extends Component {
  constructor(props) {
    super(props);
    this.submitUpdateDataCustomer = this.submitUpdateDataCustomer.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.openAlertViewAddCard = this.openAlertViewAddCard.bind(this);
    this.openAlertViewAddAddress = this.openAlertViewAddAddress.bind(this);
    this.openAlertUpdatePassword = this.openAlertUpdatePassword.bind(this);
    this.hideAlertUpdatePassword = this.hideAlertUpdatePassword.bind(this);
    this.openAlertPasswordForget = this.openAlertPasswordForget.bind(this);
    this.hideAlertPasswordForget = this.hideAlertPasswordForget.bind(this);
    this.userLoggedWithEmailAndPassword = this.userLoggedWithEmailAndPassword.bind(this);
    const { customerName } = this.props;
    this.state = {
      customerNameNow: customerName,
      showAlertAddCard: false,
      showAlertAddAddress: false,
      showAlertUpdatePassword: false,
      showAlertPasswordForget: false,
      isUserLoggedWithEmailAndPassword: false,
      isUploadingImage: false,
      showAlertUpdateEmail: false
    }
  }

  componentDidMount() {
    this.userLoggedWithEmailAndPassword();
    this.props.loadCustomerOrdersActions();
    this.props.changeRouteAction('account')
  }

  userLoggedWithEmailAndPassword() {
    const provider = this.props.firebase.auth.providerData[0].providerId
    if (provider === "password") {
      this.setState({
        isUserLoggedWithEmailAndPassword: true
      });
    } else {
      this.setState({
        isUserLoggedWithEmailAndPassword: false
      });
    }
  }

  handleChange(e) {
    const name = e.target.name;
    const value = e.target.value;

    this.setState({
      [name]: value
    });
  };

  submitUpdateDataCustomer(e) {
    e.preventDefault();
    const { updateUserDataAction, updateCustomerDetailAction, customerEmail, customerProfileImage } = this.props;
    updateUserDataAction(this.state.customerNameNow, null, null, null);
    updateCustomerDetailAction(this.state.customerNameNow, customerEmail, customerProfileImage);
  }

  openAlertUpdatePassword() {
    this.setState({
      showAlertUpdatePassword: true
    });
  }

  openAlertUpdateEmail = () => {
    this.setState({ showAlertUpdateEmail: true })
  }

  closeAlertUpdateEmail = () => {
    this.setState({ showAlertUpdateEmail: false })
  }

  hideAlertUpdatePassword() {
    this.setState({
      showAlertUpdatePassword: false
    });
  }

  openAlertPasswordForget() {
    this.setState({
      showAlertPasswordForget: true
    });
  }

  hideAlertPasswordForget() {
    this.setState({
      showAlertPasswordForget: false
    });
  }

  openAlertViewAddCard() {
    this.setState({
      showAlertAddCard: true
    });
  }

  hideAlertViewAddCard = () => {
    this.setState({
      showAlertAddCard: false
    });
  }

  openAlertViewAddAddress() {
    this.setState({
      showAlertAddAddress: true
    });
  }

  hideAlertViewAddAddress = () => {
    this.setState({
      showAlertAddAddress: false
    });
  }


  loadImageProfile = (e) => {
    var file = e.target.files[0];
    this.setState({ isUploadingImage: true });
    this.uploadProfileImageToGCS(file);
  }

  uploadProfileImageToGCS = (file) => {
    const { updateUserDataAction, updateCustomerDetailAction, customerEmail } = this.props;
    const { customerNameNow } = this.state;
    let r = (Math.random() + 1).toString(36).substring(5);

    const path = this.props.firebase.auth.uid + '/profile/profile_image_' + Date.now() + "_" + r + '.jpg';
    const storage = firebase.storage();
    const uploadRef = storage.ref().child(path);
    const uploadTask = uploadRef.put(file);
    
    uploadTask.on('state_changed', 
      (snapshot) => {
        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED:
            break;
          case firebase.storage.TaskState.RUNNING:
            break;
        }
      }, 
      (error) => {
        this.setState({ isUploadingImage: false });
        this.props.showErrorAction(error)
      }, 
      () => {
        // Handle successful uploads on complete
        const baseUrl = window._env_.GCS_BUCKET_BASE_URL;
        const coverUrl = baseUrl + path;
        updateUserDataAction(null, null, null, coverUrl);
        updateCustomerDetailAction(customerNameNow, customerEmail, coverUrl);
        this.setState({ isUploadingImage: false });
      }
    );
  }

  okErrorHandler = () => {
    this.props.hideErrorAction();
  }

  handleCardError = (error) => {
    this.props.showErrorAction(error.message_to_purchaser)
  }

  render() {
    const { customerName, customerEmail, customerProfileImage, customerOrders } = this.props;
    const { showAlertAddCard, showAlertAddAddress, showAlertUpdatePassword, showAlertPasswordForget, showAlertUpdateEmail, isUserLoggedWithEmailAndPassword } = this.state;
    return (
      <Scrollable scrollableContent={
        <React.Fragment>
          {
            showAlertAddCard &&
            <Modal>
              <CreateCardToken 
                handleShowCreateCard={this.hideAlertViewAddCard} 
                handleCardError={this.handleCardError}
              />
            </Modal>
          }
          {
            showAlertAddAddress &&
            <Modal>
              <CreateAddress handleShowCreateAddress={this.hideAlertViewAddAddress} />
            </Modal>
          }
          {
            showAlertUpdatePassword &&
            <Modal>
              <AlertViewCustomizable
                content={
                  <React.Fragment>
                    <UpdatePassword
                      hideAlertUpdatePassword={this.hideAlertUpdatePassword}
                      openAlertPasswordForget={this.openAlertPasswordForget}
                    />
                  </React.Fragment>
                }
                cancelHandler={this.hideAlertUpdatePassword}
                cancelTitle="Cancelar" />
            </Modal>
          }
          {
            showAlertPasswordForget &&
            <Modal>
              <AlertViewCustomizable
                content={
                  <React.Fragment>
                    <PasswordForgetComponent />
                  </React.Fragment>
                }
                cancelHandler={this.hideAlertPasswordForget}
                cancelTitle="Cancelar" />
            </Modal>
          }
          {this.props.showingError &&
            <Modal>
              <AlertView
                title={'¡Ups!'}
                message={this.props.errorMessage}
                okHandler={this.okErrorHandler}
                cancel={true} />
            </Modal>
          }
          {
            showAlertUpdateEmail &&
            <Modal>
              <UpdateEmail closeUpdateEmail={this.closeAlertUpdateEmail} />
            </Modal>
          }
          {
            this.state.isUploadingImage ? <Loading title="Actualizando foto de perfil ..." /> : (
              <div className="myaccount_main-container">
                <div className="myaccount_personal-info py-5">

                  <div className="d-flex flex-column align-items-center justify-content-start ">

                    <div className="d-flex flex-column align-items-center ">
                      <img className="avatar" src={!customerProfileImage ? no_image_profile : customerProfileImage} alt="profile" />
                      <div className="text-center cursor-pointer">
                        <label htmlFor="file-upload" className="cursor-pointer">
                          <div className="myaccoun_edit-container d-flex align-items-center justify-content-center cursor-pointer my-3">
                            <input id="file-upload" type="file" className="hide-input" onChange={this.loadImageProfile} />
                            <img src={edit_img} height="23" alt="edit" />
                          </div>
                        </label>
                      </div>
                      {/* <div className="card_delete-container d-flex align-items-center justify-content-center cursor-pointer my-3">
                        <input id="file-upload" type="file" className="hide-input" onChange={this.loadImageProfile} />
                        <img src={edit_img} height="23" alt="edit" />
                      </div> */}
                    </div>
                    <p className="futura-bold-25">{customerName}</p>
                    <p className="futura-bkbt-15 pb-2">{customerEmail}</p>
                    <hr className="myaccount_hr"></hr>
                    {
                      isUserLoggedWithEmailAndPassword &&
                      <div className="myaccount_change-password-btn d-flex justify-content-center align-items-center">
                        <p className="futura-bkbt-15 cursor-pointer" onClick={this.openAlertUpdatePassword}>Cambiar contraseña</p>
                        <p className="futura-bkbt-15 ml-2 cursor-pointer" onClick={this.openAlertUpdateEmail}>Cambiar email</p>
                        {/* <p className="futura-bkbt-15 cursor-pointer" onClick={this.openAlertUpdatePassword}>Cambiar nombre o contraseña</p> */}
                      </div>
                    }

                  </div>
                  <div className="row py-3" >

                    <div className="col-6 d-flex justify-content-center">
                      <div className="d-flex flex-column w-75">
                        <div className="myaccount_items d-flex align-items-center py-2">
                          <img src={my_addresses} height="30" alt="" />
                          <p className="futura-bold-gray-25 ml-5">Mis direcciones</p>
                        </div>
                        <SelectShippingAddress isViewMyAccount={true} />
                        <div className="gray-btn my-3 d-flex justify-content-center align-items-center cursor-pointer" onClick={this.openAlertViewAddAddress}>
                          <img src={add_new} alt="" height="18" />
                          <p className="futura-md-15 ml-4">Añadir nueva dirección</p>
                        </div>
                      </div>
                    </div>

                    <div className="col-6 d-flex justify-content-center">
                      <div className="d-flex flex-column w-75">
                        <div className="myaccount_items d-flex align-items-center py-2" >
                          <img src={my_wallet} height="25" alt="" />
                          <p className="futura-bold-gray-25 ml-5">Mi cartera</p>
                        </div>
                        <SelectPaymentMethod isViewMyAccount={true} />
                        <div className="gray-btn my-3 d-flex justify-content-center align-items-center cursor-pointer" onClick={this.openAlertViewAddCard}>
                          <img src={add_new} alt="" height="18" />
                          <p className="futura-md-15 ml-4">Añadir nueva tarjeta</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* {customerOrders.length > 0 && <Orders history={this.props.history} />} */}
                <Footer history={this.props.history}/>
              </div>)
          }
        </React.Fragment>
      }>
      </Scrollable>
    )
  }
}

const mapStateToProps = state => ({
  customerName: state.collections.customerName,
  customerEmail: state.collections.customerEmail,
  customerProfileImage: state.collections.customerProfileImage,
  showingError: state.globalReducer.showingError,
  errorMessage: state.globalReducer.errorMessage,
  customerOrders: state.collections.customerOrders,
  firebase: state.firebase
})

const mapDispatchToProps = {
  updateUserDataAction: updateUserDataAction,
  updateCustomerDetailAction: updateCustomerDetailAction,
  hideErrorAction: hideErrorAction,
  loadCustomerOrdersActions: loadCustomerOrdersActions,
  changeRouteAction: changeRouteAction,
  showErrorAction: showErrorAction
}

export default compose(
  firebaseConnect(),
  connect(mapStateToProps, mapDispatchToProps)
)(MyAccount);