import { 
    LOAD_LIST_TO_ORDER, 
    REORDER_LIST, 
    UPDATE_REORDER_PHOTOS, 
    CREATE_SORTABLE_DATA_SOURCE, 
    START_LOADING_INFO,
    FINISH_LOADING_INFO,
    SHOW_ERROR,
    SELECT_COLLECTION,
    SHOW_ALBUMS } from '../actions/ActionTypes';
import TokenHelper from '../utils/Firebase/TokenHelper';
import ApiCustomer from '../utils/Api/ApiCustomer';

export const loadListPhotosAction = (toList) => async(dispatch) => {
    dispatch({
        type: LOAD_LIST_TO_ORDER,
        payload: toList
    })
}

export const createSortableDataSourceAction = (collection, numPages) => async(dispatch) => {
    const { sortableDataSource } = createSortableDataSourceHelper(collection, numPages);
    dispatch({
        type: CREATE_SORTABLE_DATA_SOURCE,
        payload: sortableDataSource
    });
}

export function orderListPhotosAction(oldIndex, newIndex) {
    return {
        type: REORDER_LIST,
        payload: { oldIndex, newIndex }
    }
}

export const updateReorderPhotosAction = (photos, oldIndex, newIndex, selectAlbum) => async(dispatch) => {
    var indexInitial = 0;
    var indexFinal = 0;
    var arrayUpdate = [];

    if (oldIndex < newIndex) {
        indexInitial = oldIndex;
        indexFinal = newIndex
    } else {
        indexInitial = newIndex;
        indexFinal = oldIndex
    }
    for (var index = indexInitial; index <= indexFinal; index++) {
        arrayUpdate.push({ 'id': photos[index].id, 'index': index });
    }

    const param = await TokenHelper.getUserToken().then(idToken => { return ({ token: idToken, album_id: selectAlbum, photos: arrayUpdate }); });
    const response = await ApiCustomer.updatePhotosOrder(param).then(response => { return response.data; }).catch(error => { return error.data; });

    if (response.success) {
        dispatch({
            type: UPDATE_REORDER_PHOTOS,
            payload: arrayUpdate
        })
    }
}

export const updatePhotosOrderAction = (oldIndex, newIndex, collection) => async(dispatch) => {

    dispatch({
        type: START_LOADING_INFO,
        payload: true
    });
    
    // Deep clone the array in order to keep the original in case of server failure
    let photosToUpdate = JSON.parse(JSON.stringify(collection.photos));
    let photoToSwap = {...photosToUpdate[oldIndex]};
    photosToUpdate.splice(oldIndex, 1);
    photosToUpdate.splice(newIndex, 0, photoToSwap);
    let startIndex = Math.min(oldIndex, newIndex);
    let finishIndex = Math.max(oldIndex, newIndex);
    if (startIndex === -1 || finishIndex === -1) {
        return {
            type: FINISH_LOADING_INFO,
            payload: null
        }
    }

    let arrayForUpdate = []
    for (var x = startIndex; x <= finishIndex; x++) {
        arrayForUpdate.push({ 'id': photosToUpdate[x].id, 'index': x });
        photosToUpdate[x].index = x;
    }

    const params = await TokenHelper.getUserToken().then(idToken => { 
        return { 
            token: idToken, 
            album_id: collection.id, 
            photos: photosToUpdate 
        }; 
    });
    const response = await ApiCustomer.updatePhotosOrder(params).then(response => { return response.data; }).catch(error => { return error.data; });

    if (response.success) {
        collection.photos = photosToUpdate;
        localStorage.setItem('SELECTED_COLLECTION', JSON.stringify(collection));
        dispatch({
            type: SELECT_COLLECTION,
            payload: collection
        })
        const { sortableDataSource, collectionBooks } = createSortableDataSourceHelper(collection, 60);
        dispatch({
            type: SHOW_ALBUMS,
            payload: collectionBooks
        });
        dispatch({
            type: CREATE_SORTABLE_DATA_SOURCE,
            payload: sortableDataSource
        });
    } else {
        dispatch({
            type: SHOW_ERROR,
            payload: response.message
        });
    }

    dispatch({
        type: START_LOADING_INFO,
        payload: false
    });
}

export const createSortableDataSourceHelper = (collection, chunkSize) => {
    let createdDataSource = []
    let collectionBooks = [];
    const photosForCreateSortable = collection.pages;
    // Deep copy in order to create the data source
    const pages = JSON.parse(JSON.stringify(photosForCreateSortable));
    // Create books of the collection
    // const chunkSize = 60;
    let bookIndex = 0;
    while (pages.length > 0) {
        let pagesOfBook = pages.splice(0, chunkSize);
        let completeBook = {
            cover_url: pagesOfBook[0].photos[0].print_url,
            pages: pagesOfBook,
            index: bookIndex
        }
        collectionBooks.push(completeBook);
        bookIndex += 1;
    }

    const covers = collection.covers;
    if (covers.length > 0) {
        for (let cover of covers) {
            if (collectionBooks.length > cover.index) {
                collectionBooks[cover.index].cover_url = cover.image_url;
                collectionBooks[cover.index].predesign_data = cover.predesign_data
            }
        }
    }

    // Create data source with the books
    for (let collectionBook of collectionBooks) {
        let coverElement = {
            type: 'cover',
            data: { 
                index: collectionBook.index,
                image_url: collectionBook.cover_url, 
                predesign_cover: collectionBook.predesign_data 
            }
        }
        createdDataSource.push(coverElement);
        let insideCoverOne = {
            type: 'inside_cover_one',
            data: null
        }
        createdDataSource.push(insideCoverOne);
        for (let photo of collectionBook.pages) {
            let photoElement = {
                type: 'photo',
                data: photo
            }
            createdDataSource.push(photoElement);
        }
        if (collectionBook.pages.length === chunkSize) {
            let insideCoverTwo = {
                type: 'inside_cover_two',
                data: null
            }
            createdDataSource.push(insideCoverTwo);
        }
    }
    return { sortableDataSource: createdDataSource, collectionBooks: collectionBooks };
}