import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { toggleShowChangeCoverSelectSourceAction, toggleShowChangeCoverAction, selectCoverForEditAction } from '../../actions/CustomerActions';
import Scrollable from '../Scrollable';
import SelectQuotesImage from '../SelectQuotesImage';
import SelectCoverFromSourceButtons from './SelectCoverFromSourceButtons';
import SelectFromAlbum from './SelectFromAlbum';
import CoverCategories from '../../routes/CoverCategories'

import img_logo_gray from '../../images/logogris-28.png';
import img_logo_gray_text from '../../images/logogris-27.png';
import close_img from '../../images/newDesignImages/close-white.png';
import './ChangeCoverSelectSource.scss';


class ChangeCoverSelectSource extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showAlbum: false,
      predesignCover: '',
      showQuotes: false,
      showCovers: false
    }
  }

  componentDidMount() {
    this.handleCovers();
  }

  editButtonHandler = () => {
    const { toggleShowChangeCoverSelectSourceAction, toggleShowChangeCoverAction } = this.props;
    toggleShowChangeCoverSelectSourceAction(false);
    toggleShowChangeCoverAction(true);
  }

  showSourceHandler = () => {
    this.setState({ ...this.state, showSource: true });
  }

  selectFromAlbumHandler = () => {
    this.setState({ ...this.state, showAlbum: true });
  }

  unselectFromAlbumHandler = () => {
    this.setState({showAlbum: false});
  }

  selectFromFileHandler = () => {
    this.fileInput.click();
  }

  onFileChange = async e => {
    if (e.target.files && e.target.files.length > 0) {
      const imageDataUrl = await this.readFile(e.target.files[0])
      const { toggleShowChangeCoverSelectSourceAction, toggleShowChangeCoverAction, selectCoverForEditAction, selectedCoverIndexForEdit } = this.props;
      selectCoverForEditAction(imageDataUrl, selectedCoverIndexForEdit);
      toggleShowChangeCoverSelectSourceAction(false);
      toggleShowChangeCoverAction(true);
    }
  }

  readFile = (file) => {
    return new Promise(resolve => {
      const reader = new FileReader()
      reader.addEventListener('load', () => resolve(reader.result), false)
      reader.readAsDataURL(file)
    })
  }

  selectPhotoForEditHandler = (photo) => {
    const { toggleShowChangeCoverSelectSourceAction, toggleShowChangeCoverAction, selectCoverForEditAction, selectedCoverIndexForEdit } = this.props;
    selectCoverForEditAction(photo.print_url, selectedCoverIndexForEdit);
    toggleShowChangeCoverSelectSourceAction(false);
    toggleShowChangeCoverAction(true);
  }

  handleCovers = () => {
    let covers = this.props.sortableDataSource.filter(item => item.type === 'cover');
    if (covers.length > 0) {
      let selectedCover = covers.filter(item => item.data.index === this.props.selectedCoverIndexForEdit);
      if (selectedCover[0].data.predesign_cover && !selectedCover[0].data.image_url) {
        this.setState({
          predesignCover: selectedCover[0].data.predesign_cover.thumbnail_url
        });
      }
      return;
    }
    return;
  }

  selectPhrasesHandler = () => {
    this.setState({ showQuotes: true });
  }

  selectCoversHandler = () => {
    this.setState({ showCovers: true });
  }

  handleCloseQuotes = () => {
    this.setState({ showQuotes: false });
  }

  handleCloseCovers = () => {
    this.setState({ showCovers: false })
  }

  render() {
    const { toggleShowChangeCoverSelectSourceAction, selectedCoverForEdit, collectionBooks, selectedCoverIndexForEdit, nameCollection } = this.props;
    const { showAlbum, showQuotes, showCovers } = this.state;
    const { photos } = collectionBooks[selectedCoverIndexForEdit];
    // TODO: Change classes square-page-cover square-page-content-cover and fluid-contain-cover
    // Because we don't know if this could change the behaviour later
    return (
      <React.Fragment>
        {
          showAlbum ? (
            <SelectFromAlbum 
              toggleShowChangeCoverSelectSourceAction={toggleShowChangeCoverSelectSourceAction}
              photos={photos}
              selectPhotoForEditHandler={this.selectPhotoForEditHandler}
              unselectFromAlbumHandler={this.unselectFromAlbumHandler}
            />
          ) : (
              <Scrollable scrollableContent={
                showQuotes ?
                  <SelectQuotesImage
                    handleCloseQuotes={this.handleCloseQuotes}
                    selectPhotoForEditHandler={this.selectPhotoForEditHandler}
                  />
                  : showCovers ? 
                    <CoverCategories
                      handleCloseCovers={this.handleCloseCovers}
                      reloadData={this.props.reloadData}
                    /> :
                      <div className="changecover-selectsource_main">
                        <div className="changecover-selectsource_header d-flex">
                          <div className="changecover-selectsource_close d-flex justify-content-center align-items-center cursor-pointer" onClick={() => toggleShowChangeCoverSelectSourceAction(false)}>
                            <img src={close_img} height="23" alt="" />
                          </div>
                          <div className="changecover-selectsource_header-right d-flex justify-content-between align-items-center">
                            <p className="gotham-bold-18 ml-3">Portada</p>
                            <button className="generic-button-blue mr-3" onClick={() => toggleShowChangeCoverSelectSourceAction(false)}>
                              Listo
                          </button>
                          </div>
                        </div>
                        {this.state.predesignCover.length > 0 ? (
                          <div className="col-10 book py-5">
                            <div className="container d-flex justify-content-center">
                              <div className="change-cover-container shadow-sm">
                                <img src={this.state.predesignCover} height="400" />
                              </div>
                            </div>
                          </div>
                        ) : (
                            <div className="col-10 book pt-5 pb-2">
                              <div className="container">
                                <div className="row">
                                  <div className="col-12">
                                    <div className="row d-flex justify-content-center">
                                      <div className="col-5 d-flex align-items-end justify-content-center p-0 shadow">
                                        <img src={img_logo_gray_text} className="d-flex mt-2 img-logo" alt="img" />
                                      </div>
                                      <div className="spine-portada">
                                        <div className="vertical-spine-text-change">
                                          <p>{nameCollection}</p>
                                        </div>
                                      </div>

                                      <div className="border bg-spine">
                                        <div className="d-flex justify-content-center">
                                          <img src={img_logo_gray} className=" mt-2 d-flex vertical-logo" width="25" height="20" alt="img" />
                                        </div>
                                      </div>
                                      <div className="col-5 p-0 shadow">
                                        <div className="row mx-0">
                                          <div className="col p-0 m-0">
                                            <div className="square-page-cover">
                                              <div className="square-page-content-cover">
                                                <img src={selectedCoverForEdit} className="fluid-contain-cover p-2" alt="img" />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        <hr className="mx-5"></hr>
                        <div className="py-2">
                          <input id="cover-file" type="file" accept=".jpg,.gif,.jpeg" onChange={this.onFileChange} ref={(fileInput) => this.fileInput = fileInput} style={{ display: 'none' }} />
                          <SelectCoverFromSourceButtons
                            selectFromAlbumCallback={this.selectFromAlbumHandler}
                            selectFromFileCallback={this.selectFromFileHandler}
                            selectPhrasesCallback={this.selectPhrasesHandler}
                            selectCoversHandler={this.selectCoversHandler}
                          />
                        </div>
                      </div>
              }></Scrollable >
            )
        }
      </React.Fragment >
    )
  }
}




const mapStateToProps = state => ({
  selectedCoverForEdit: state.collections.selectedCoverForEdit,
  selectedCoverIndexForEdit: state.collections.selectedCoverIndexForEdit,
  collectionBooks: state.collections.collectionBooks,
  selectedCoverIndexForEdit: state.collections.selectedCoverIndexForEdit,
  collectionSelected: state.collections.collectionSelected,
  sortableDataSource: state.collections.sortableDataSource,
})

const mapDispatchToProps = {
  toggleShowChangeCoverSelectSourceAction: toggleShowChangeCoverSelectSourceAction,
  toggleShowChangeCoverAction: toggleShowChangeCoverAction,
  selectCoverForEditAction: selectCoverForEditAction
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(ChangeCoverSelectSource);