import React from 'react';
import ReactDOM from "react-dom";

import './FullScreenView.scss';

const FullScreenView = ({children}) => {
  return ReactDOM.createPortal(
    <div className="full-screen-container d-flex">
      {children}
    </div>
    , document.querySelector("#fullscreen-root"));
}

export default FullScreenView;