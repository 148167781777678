import React from 'react';

//Redux
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firebaseConnect } from 'react-redux-firebase';

// Components
import CardOrderItem from './CardOrderItem';
import Loading from '../../components/Loading';
import Scrollable from '../../components/Scrollable';
import Footer from '../../components/Footer';

import { loadCustomerOrdersActions } from '../../actions/CustomerActions';
import box from '../../images/newDesignImages/box-img.png';
import './Orders.scss';

class Orders extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      screenWidth: 0
    }
  }
  async componentDidMount() {
    await this.props.loadCustomerOrdersActions();
    if (this.props.customerOrders.length === 0) {
      this.props.history.push('/projects');
    } else {
      this.setState({ screenWidth: window.innerWidth });
    }
  }

  handleGoToOrderDetails = (id) => {
    this.props.history.push({
      pathname: '/order/detail',
      id: id,
      orders: this.props.customerOrders
    });
  }

  render() {
    const { customerOrders, loadingOrders } = this.props;
    const { screenWidth } = this.state
    return (
      <React.Fragment>
        {loadingOrders ? <Loading /> : (
          customerOrders.length <= 2 ? (
            <div>
              <div className="orders_sm-container py-5 d-flex justify-content-center">
                <div className="orders_inside-container">
                  <div className="orders_title py-4 pl-5 d-flex align-items-center">
                    <img src={box} alt="" />
                    <p className="futura-bold-gray-25 ml-4">Mis ordenes</p>
                  </div>
                  <div>
                    {customerOrders.map(order =>
                      <CardOrderItem
                        key={order.id}
                        order={order}
                        goToDetails={this.handleGoToOrderDetails}
                      />
                    )}
                  </div>
                </div>
              </div>
              <Footer history={this.props.history}/>
            </div>
          ) :
          <Scrollable scrollableContent={
            <div>
              <div className="orders_container py-5 d-flex justify-content-center">
                <div className="orders_inside-container">
                  <div className="orders_title py-4 pl-5 d-flex align-items-center">
                    <img src={box} alt="" />
                    <p className="futura-bold-gray-25 ml-4">Mis ordenes</p>
                  </div>
                  <div>
                    {customerOrders.map(order =>
                      <CardOrderItem
                        key={order.id}
                        order={order}
                        goToDetails={this.handleGoToOrderDetails}
                      />
                    )}
                  </div>
                </div>
              </div>
              <Footer history={this.props.history}/>
            </div>

          }></Scrollable>
        )}
      </React.Fragment>
    )
  }
}


const mapStateToProps = state => ({
  customerOrders: state.collections.customerOrders,
  loadingOrders: state.collections.loadingOrders
});

const mapDispatchToProps = {
  loadCustomerOrdersActions: loadCustomerOrdersActions
};

export default compose(
  firebaseConnect(),
  connect(mapStateToProps, mapDispatchToProps)
)(Orders);
