import React from "react";
import ReactDOM from "react-dom";

import './Modal.scss';

const Modal = (props) => {
  return ReactDOM.createPortal(
    <div className="general-modal d-flex" onClick={() => props.dismissHandler && props.dismissHandler()}>
      {props.children}
    </div>
    , document.querySelector("#modal-root"));
}

export default Modal;