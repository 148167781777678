import React, { Component } from 'react';

//Redux
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firebaseConnect } from 'react-redux-firebase';
import {
  showPhotoDetailAction,
  deletePhotosAlbumAction,
  toggleShowChangeCoverAction,
  toggleShowChangeCoverSelectSourceAction,
  selectCoverForEditAction,
  loadCollectionAlbumsAction,
  updateCollectionSelectedAction,
  startLoadingAction,
  addAlbumToCartAction,
  loadCartAction,
} from '../../actions/CustomerActions';
import { createSortableDataSourceAction } from '../../actions/SortableListPhotoActions';
import { toggleIsAddingPhotosAction } from '../../actions/CreateProjectActions';
import { hideErrorAction, showErrorAction, hideLoadingAction } from '../../actions/GlobalActions';
import { changeProjectTypeAction } from '../../actions/NavigationActions';
import { loadAlbumConfigurationsAction } from '../../actions/GlobalActions';
import { fromOldestToNewest, fromNewestToOldest, changeOnePhotoPosition } from '../../utils/ReorderImages';
import { parseAlbumConfiguration } from '../../utils/Parsers';
import ApiCustomer from '../../utils/Api/ApiCustomer';
import TokenHelper from '../../utils/Firebase/TokenHelper';

import './SortableListBook.scss';

//Components
import Loading from '../../components/Loading';
import Modal from '../../components/Modal';
import AlertView from '../../components/AlertView';
import FullScreenView from '../../components/FullScreenView';
import ChangeCoverSelectSource from '../../components/ChangeCoverSelectSource';
import CreateProject from '../../components/CreateProject';
import SortableList from './SortableList';
import PhotoDetail from '../../routes/PhotoDetail';
import EditPhoto from '../EditPhoto';
import AlbumConfigurations from '../AlbumConfigurations';
import Footer from '../Footer';
import EditProductName from '../EditProductName';
import ConfigurationsBar from './ConfigurationsBar';
import RegularPopup from '../RegularPopup';
import SuccessPopup from '../SuccessPopup';
import FailurePopup from '../FailurePopup';

import albums_icon from '../../images/newDesignImages/albums-icon.png';
import add_icon_active from '../../images/newDesignImages/add-icon-2-active.png';
import add_icon_inactive from '../../images/newDesignImages/add-icon-2-inactive.png';
import trash_active from '../../images/newDesignImages/trash-2-active.png';
import trash_inactive from '../../images/newDesignImages/trash-2-inactive.png';
import AddTitleToCover from '../AddTitleToCover/AddTitleToCover';
import down_arrow from '../../images/newDesignImages/down-white-arrow.png';
import up_arrow from '../../images/newDesignImages/up-white-arrow.png';
import edit_img from '../../images/newDesignImages/edit-blue-img.png';



class SortableListBook extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showDeleteConfirmation: false,
      selectedPhotosForDelete: [],
      showAlertSource: false,
      mouseOverItem: '',
      showPhotoDetail: false,
      showAddTitle: false,
      showConfigurations: false,
      showEditNameConfirmation: false,
      collectionToEditName: null,
      numberCharacters: 0,
      configBarShownOption: 'default',
      showReorderConfirmation: false,
      showAddToCartSuccess: false,
      showAddToCartFailure: false,
      selectedPageId: '',
      showDeletePagesAfterConfiguration: false
    }
  }

  componentDidMount() {
    if (!this.props.location.state) {
      this.props.history.push('/projects')
    } else {
      this.loadCollectionData();
      this.props.loadAlbumConfigurationsAction();
      this.props.loadCartAction(false)
    }
  }

  reloadData = () => {
    this.loadCollectionData();
    this.props.loadAlbumConfigurationsAction();
  }

  selectPhotoHandler = (photo, source, pageId) => {
    const { configBarShownOption } = this.state;
    if (source === 'quotes' || configBarShownOption !== 'default') {
      return;
    } else {
      this.setState({ 
        showPhotoDetail: true,
        selectedPageId: pageId
      });
      this.props.showPhotoDetailAction(photo);
    }
  }

  selectPhotoForDeleteHandler = (photo) => {
    let { selectedPhotosForDelete } = this.state;
    const { collectionSelected } = this.props;

    if (selectedPhotosForDelete.includes(photo.id)) {
      selectedPhotosForDelete = selectedPhotosForDelete.filter(item => item !== photo.id);
    } else {
      let selectedPhotosForDeleteCount = selectedPhotosForDelete.length;
      let collectionPhotosCount = collectionSelected.photos.length;
      if (collectionPhotosCount > (selectedPhotosForDeleteCount + 1)) {
        selectedPhotosForDelete.push(photo.id);
      }
    }
    this.setState({ selectedPhotosForDelete });
    if (selectedPhotosForDelete.length > 0) {
      this.setState({configBarShownOption: 'deletePhotos'});
    } else {
      this.setState({configBarShownOption: 'default'});
    }
  }

  cancelCreateProjectConfirmation = () => {
    this.setState({
      ...this.state,
      showAlertSource: false,
    });
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex === newIndex) {
      return;
    }
    const { collectionSelected, updateCollectionSelectedAction, createSortableDataSourceAction } = this.props;

    this.setState({configBarShownOption: 'reorder'});
    const numPages = parseInt(collectionSelected.configurations.configurations[1].description)
    const reorderedPhotos = changeOnePhotoPosition(oldIndex, newIndex, collectionSelected);
    updateCollectionSelectedAction(reorderedPhotos, numPages);
    createSortableDataSourceAction(reorderedPhotos, numPages);
  }

  showDeleteConfirmationHandler = () => {
    this.setState({
      showDeleteConfirmation: true,
    });
  }

  okDeleteConfirmationHandler = async() => {
    this.setState({ showDeleteConfirmation: false });
    const { selectedPhotosForDelete } = this.state;
    const { deletePhotosAlbumAction, collectionSelected } = this.props;
    await deletePhotosAlbumAction(selectedPhotosForDelete, collectionSelected);
    await this.loadCollectionData();
    this.setState({ 
      selectedPhotosForDelete: [],
      configBarShownOption: 'default'
    });
  }

  cancelDeleteConfirmationHandler = () => {
    this.setState({ 
      showDeleteConfirmation: false, 
      selectedPhotosForDelete: [],
      configBarShownOption: 'default'
    });
  }

  okErrorHandler = () => {
    this.props.hideErrorAction();
  }

  addPhotosHandler = () => {
    const { toggleIsAddingPhotosAction, changeProjectTypeAction, collectionSelected } = this.props;
    const numPages = parseInt(collectionSelected.configurations.configurations[1].description)
    if (collectionSelected.pages.length >= numPages) {
      this.props.showErrorAction(`No se pueden agregar más de ${numPages} fotos al álbum.`);
    } else {
      changeProjectTypeAction('album');
      toggleIsAddingPhotosAction(true);
      this.setState({
        ...this.state,
        showAlertSource: true
      });
    }
  }

  changeCoverHandler = (index) => {
    const { collectionBooks, toggleShowChangeCoverSelectSourceAction, selectCoverForEditAction } = this.props;
    // toggleShowChangeCoverAction(true);
    const selectedBook = collectionBooks[index];
    selectCoverForEditAction(selectedBook.cover_url, index);
    toggleShowChangeCoverSelectSourceAction(true);
  }

  editCoverHandler = (index) => {
    const { collectionBooks, toggleShowChangeCoverAction, selectCoverForEditAction } = this.props;
    const selectedBook = collectionBooks[index];
    selectCoverForEditAction(selectedBook.cover_url, index);
    toggleShowChangeCoverAction(true);
  }

  handleMouseEnter = (item) => {
    this.setState({ mouseOverItem: item })
  }

  handleClosePhotoDetail = (update) => {
    if (update) {
      this.setState({ showPhotoDetail: false });
      this.loadCollectionData();
    } else {
      this.setState({ showPhotoDetail: false });
    }
  }

  loadCollectionData = async() => {
    await this.props.loadCollectionAlbumsAction(this.props.location.state.id)
    const numPages = parseInt(this.props.collectionSelected.configurations.configurations[1].description)
    this.props.createSortableDataSourceAction(this.props.collectionSelected, numPages)
  }


  handleCloseImageForEdit = (editedImage) => {
    if (editedImage) {
      this.props.toggleShowChangeCoverAction(false);
    }
  }

  handleOpenAddTitle = () => {
    this.setState({ showAddTitle: true });
  }

  handleCloseAddTitle = () => {
    this.setState({ showAddTitle: false });
  }

  handleShowConfigurations = (reloadData) => {
    const { showConfigurations } = this.state;
    if (showConfigurations && reloadData) {
      this.setState({showConfigurations: false});
      this.reloadData();
    } else if (showConfigurations && !reloadData) {
      this.setState({showConfigurations: false});
    } else {
      this.setState({showConfigurations: true});
    }
  }

  editNameCollectionHandler = (collection) => {
    this.setState({
      ...this.state,
      showEditNameConfirmation: true,
      collectionToEditName: collection,
      numberCharacters: collection.name.length
    });
  }

  updateCollectionName = (newName) => {
    const setName = new Promise(resolve => resolve(
      this.setState({
        showEditNameConfirmation: false,
        collectionToEditName: {
          ...this.state.collectionToEditName,
          name: newName
        }
      })
    ));
    setName.then(() => this.editNameCollectionApiCall(this.state.collectionToEditName));
  }

  editNameCollectionApiCall = (collection) => {
    const numPages = parseInt(collection.configurations.configurations[1].description)
    TokenHelper.getUserToken().then(idToken => {
      ApiCustomer.updateCollectionSettings({
        token: idToken,
        album_id: collection.id,
        print_dates: collection.print_dates,
        hard_cover: collection.hard_cover,
        title: collection.name
      }).then(response => {
        if (response.data.success) {
          this.props.updateCollectionSelectedAction(collection, numPages);
        } else {
          this.props.hideLoadingAction();
          this.props.showErrorAction('Ocurrió un error, por favor intenta más tarde.');
        }
      }).catch(error => {
        this.props.hideLoadingAction();
        this.props.showErrorAction('Ocurrió un error, por favor intenta más tarde.');
      });
    }).catch(error => {
      this.props.hideLoadingAction();
      this.props.showErrorAction('Ocurrió un error, por favor intenta más tarde.');
    });
  }

  cancelEditNameConfirmation = () => {
    this.setState({
      ...this.state,
      showEditNameConfirmation: false,
      collectionToEditName: null
    });
  }

  handleSelectConfigBarOption = (selectedOption) => {
    this.setState({configBarShownOption: selectedOption});
  }

  handleCloseReorder = () => {
    this.handleSelectConfigBarOption('default');
    this.handleRestoreOrder();
  }

  handleReorderFromOldest = () => {
    const { collectionSelected, updateCollectionSelectedAction, createSortableDataSourceAction } = this.props;
    const reorderedPhotos = fromOldestToNewest(collectionSelected);
    const numPages = parseInt(collectionSelected.configurations.configurations[1].description)
    updateCollectionSelectedAction(reorderedPhotos, numPages);
    createSortableDataSourceAction(reorderedPhotos, numPages);
  }

  handleReorderFromNewest = () => {
    const { collectionSelected, updateCollectionSelectedAction, createSortableDataSourceAction } = this.props;
    const reorderedPhotos = fromNewestToOldest(collectionSelected);
    const numPages = parseInt(collectionSelected.configurations.configurations[1].description)
    updateCollectionSelectedAction(reorderedPhotos, numPages);
    createSortableDataSourceAction(reorderedPhotos, numPages);
  }

  handleRestoreOrder = () => {
    const { collectionSelected, updateCollectionSelectedAction, createSortableDataSourceAction } = this.props;
    const originalPagesOrder = JSON.parse(localStorage.getItem('originalPagesOrder'));
    const originalCollectionSelected = {
      ...collectionSelected,
      pages: originalPagesOrder
    }
    const numPages = parseInt(collectionSelected.configurations.configurations[1].description)
    updateCollectionSelectedAction(originalCollectionSelected, numPages);
    createSortableDataSourceAction(originalCollectionSelected, numPages);
  }

  handleShowReorderConfirmation = () => {
    const { showReorderConfirmation } = this.state;
    if (showReorderConfirmation) {
      this.setState({showReorderConfirmation: false});
    } else {
      this.setState({showReorderConfirmation: true});
    }
  }

  handleSaveReorder = async() => {
    const { collectionSelected, startLoadingAction, hideLoadingAction } = this.props;
    this.setState({showReorderConfirmation: false});
    startLoadingAction();
    try {
      const pagesToReorder = collectionSelected.pages.map(pageObj => {
        return {
          "id": pageObj.id,
          "index": pageObj.index
        }
      });
      const idToken = await TokenHelper.getUserToken();
      const response = await ApiCustomer.updatePagesOrder({
        token: idToken,
        album_id: collectionSelected.id,
        pages: pagesToReorder
      });
      if (response.data.success) {
        localStorage.setItem("originalPhotosOrder", JSON.stringify(collectionSelected.pages));
        this.setState({configBarShownOption: 'default'});
        hideLoadingAction();
      }
    } catch(e) {
      console.log('err', e);
    }
  }

  handleCloseDeleteOption = () => {
    this.setState({
      configBarShownOption: 'default',
      selectedPhotosForDelete: []
    });
  }

  handleAddToCart = async(album) => {
    const { collectionSelected, cartProducts } = this.props;
    const numPages = parseInt(collectionSelected.configurations.configurations[1].description)
    if (collectionSelected.pages.length === numPages) {
      const reducer = (accumulator, currentValue) => accumulator + currentValue;
      const totalPrice = cartProducts.map(item => item.price).reduce(reducer, 0) + collectionSelected.price;
      if (totalPrice > 10000) {
        this.props.showErrorAction('No es posible agregar más de $10,000 al carrito.');
      } else {
        this.setState({ isLoading: true });
        let albumToAdd = {
          name: collectionSelected.name,
          cover_url: album.cover_url,
          print_dates: collectionSelected.print_dates,
          pages: collectionSelected.pages,
          predesign_cover_id: album.predesign_data ? album.predesign_data.id : '',
          configurations: parseAlbumConfiguration(collectionSelected.configurations)
        };
        const albumsArray = [albumToAdd]
        await this.props.addAlbumToCartAction(albumsArray);
        await this.props.loadCartAction(false)
        this.setState({ 
          isLoading: false,
          showAddToCartSuccess: true
        });
        
        //Analytics
        this.initializePixelAnalytics();
        this.loadAddToCartAnalytics(collectionSelected);
      }
    } else {
      this.setState({showAddToCartFailure: true});
    }
  }


  initializePixelAnalytics = () => {
    this.dataLayer = window.dataLayer;
  }

  loadAddToCartAnalytics = (collectionSelected) => {
    this.dataLayer.push({
      'event': 'addToCart',
      'ecommerce': {
        'currencyCode': 'MXN',
        'add': {
          'products': [{
            'name': collectionSelected.name,
            'id': collectionSelected.id,
            'price': collectionSelected.price,
            'num_photos': collectionSelected.photos.length,
            'quantity': collectionSelected.quantity
          }]
        }
      }
    });
  }

  handleCloseFailurePopup = () => {
    this.setState({showAddToCartFailure: false});
  }

  handleCloseSuccessPopup = () => {
    this.setState({showAddToCartSuccess: false});
  }


  handleDeletePage = async(id) => {
    const { collectionSelected, startLoadingAction, hideLoadingAction, showErrorAction } = this.props;
    startLoadingAction();
    try {
      const idToken = await TokenHelper.getUserToken();
      const params = {
        "token": idToken,
        "album_id": collectionSelected.id,
        "pages_ids": [id]
      }
      const response = await ApiCustomer.deleteAlbumPages(params);
      if (response.data.success) {
        this.reloadData();
      } else {
        hideLoadingAction();
        showErrorAction('Ocurrió un error, por favor intenta más tarde.')
      }
    } catch(error) {
      hideLoadingAction();
      showErrorAction('Ocurrió un error, por favor intenta más tarde.')
    }
  }

  showDeletePagesAfterConfigurationHandler = () => {
    const { showDeletePagesAfterConfiguration } = this.state;
    if (showDeletePagesAfterConfiguration) {
      this.setState({showDeletePagesAfterConfiguration: false})
    } else {
      this.setState({showDeletePagesAfterConfiguration: true})
    }
  }

  handleSaveConfigurationsAndDeletePhotos = async() => {
    try {
      const { paramsForUpdateAlbumConfiguration, pagesDiference, collectionSelected } = this.props;
      const idsForDelete = collectionSelected.pages
        .slice(collectionSelected.pages.length - pagesDiference, collectionSelected.pages.length)
        .map(page => page.id)
      const idToken = await TokenHelper.getUserToken();
      const params = {
        "token": idToken,
        "album_id": collectionSelected.id,
        "pages_ids": idsForDelete
      }
      const deleteResponse = await ApiCustomer.deleteAlbumPages(params);
      const updateConfigResponse = await ApiCustomer.updateCollectionSettings(paramsForUpdateAlbumConfiguration)
      if (deleteResponse.data.success && updateConfigResponse.data.success) {
        this.showDeletePagesAfterConfigurationHandler();
        this.reloadData();
      } else {
        showErrorAction('Ocurrió un error, por favor intenta más tarde.')
      }
    } catch(e) {
      showErrorAction('Ocurrió un error, por favor intenta más tarde.')
    }
  }


  render() {
    const { sortableDataSource, collectionSelected, showChangeCover, isLoading, showChangeCoverSelectSource, collectionBooks, selectedAlbumConfiguration } = this.props;
    const { 
      showDeleteConfirmation, 
      selectedPhotosForDelete, 
      selectedCover, 
      selectedCoverIndex, 
      showAlertSource, 
      mouseOverItem, 
      showPhotoDetail, 
      showAddTitle, 
      showConfigurations,
      showEditNameConfirmation,
      collectionToEditName,
      configBarShownOption,
      showReorderConfirmation,
      showAddToCartSuccess,
      showAddToCartFailure,
      showDeletePagesAfterConfiguration
    } = this.state;
    const numPages = collectionSelected && parseInt(collectionSelected.configurations.configurations[1].description)
    const updateSortableData = () => {
      if (collectionSelected && collectionSelected.pages.length < numPages) {
        return sortableDataSource.concat('addPhoto');
      } else {
        return sortableDataSource;
      }
    }
    const sourceIds = collectionSelected && collectionSelected.pages
      .map(page => {
        return {
          "source_id": page && (page.photos && page.photos[0].source_id),
          "source": page && (page.photos && page.photos[0].source)
        }
      })
      .filter(item => item.source_id !== "FILES" && item.souce_id !== "android" && item.source_id !== "");
    const pagesLength = collectionSelected && collectionSelected.pages.length;  
    const parseConfigurations = (configurations) => {
      const coverType = configurations.configurations.filter(item => item.type === 'cover')[0].description === 'dura' ? 'Pasta Dura ' : 'Pasta Suave ';
      const size = configurations.title === '16x16' ? '16 x 16 cm' : '21 x 21 cm';
      return coverType + size;
    }
    return (
      <React.Fragment>
        {
          showChangeCover &&
          <Modal>
            <EditPhoto
              productType={'album'}
              history={this.props.history}
              collectionSelected={collectionSelected}
              handleCloseImageForEdit={this.handleCloseImageForEdit}
              handleOpenAddTitle={this.handleOpenAddTitle}
            />
          </Modal>
        }
        {
          showAddTitle &&
          <Modal>
            <AddTitleToCover handleCloseAddTitle={this.handleCloseAddTitle} />
          </Modal>
        }
        {
          showChangeCoverSelectSource &&
          <FullScreenView>
            <ChangeCoverSelectSource
              currentCover={selectedCover}
              coverIndex={selectedCoverIndex}
              nameCollection={collectionSelected.name}
              reloadData={this.reloadData}
            />
          </FullScreenView>
        }
        {
          showAlertSource &&
          <Modal>
            <CreateProject cancelHandler={this.cancelCreateProjectConfirmation} />
          </Modal>
        }
        {
          this.props.showingError &&
          <Modal>
            <AlertView
              title={'¡Ups!'}
              message={this.props.errorMessage}
              okHandler={this.okErrorHandler}
              cancel={true} />
          </Modal>
        }
        {
          showDeleteConfirmation &&
          <Modal>
            <AlertView
              title={'Pixyalbum'}
              message={(selectedPhotosForDelete.length > 1) ? '¿Deseas eliminar ' + selectedPhotosForDelete.length + ' fotos seleccionadas?' : '¿Deseas eliminar la foto seleccionada?'}
              okHandler={this.okDeleteConfirmationHandler}
              cancelHandler={this.cancelDeleteConfirmationHandler}
              cancel={true}
            />
          </Modal>
        }
        {
          showPhotoDetail &&
          <Modal>
            <PhotoDetail 
              selectedPageId={this.state.selectedPageId}
              handleClosePhotoDetail={this.handleClosePhotoDetail} 
              showDeleteConfirmationHandler={this.showDeleteConfirmationHandler}
              selectPhotoForDeleteHandler={this.selectPhotoForDeleteHandler}
              loadCollectionData={this.loadCollectionData}
              handleDeletePage={this.handleDeletePage}
            />
          </Modal>
        }
        {
          showEditNameConfirmation &&
          <Modal>
            <EditProductName
              productType={'album'}
              name={collectionToEditName.name}
              updateAlbumName={this.updateCollectionName}
              cancelEditProductName={this.cancelEditNameConfirmation}
            />
          </Modal>
        }
        {
          (this.props.albumConfigurations && showConfigurations) &&
          <Modal>
            <AlbumConfigurations
              collectionSelected={collectionSelected}
              errorHandler={this.props.showErrorAction}
              handleShowConfigurations={this.handleShowConfigurations}
              showDeletePagesAfterConfigurationHandler={this.showDeletePagesAfterConfigurationHandler}
            />
          </Modal>
        }
        {
          showReorderConfirmation &&
          <Modal>
            <RegularPopup
              handleShowReorderConfirmation={this.handleShowReorderConfirmation}
              handleSaveReorder={this.handleSaveReorder}
            />
          </Modal>
        }
        {
          showAddToCartSuccess &&
          <Modal>
            <SuccessPopup
              handleCloseSuccessPopup={this.handleCloseSuccessPopup}
              textMessage={'Tu Pixyalbum se ha agregado al carrito, los cambios que hagas a partir de ahora no se verán reflejados en el carrito.'}
              history={this.props.history}
              fromAlbums
            />
          </Modal>
        }
        {
          showAddToCartFailure &&
          <Modal>
            <FailurePopup
              handleCloseFailurePopup={this.handleCloseFailurePopup}
              numPages={numPages}
              history={this.props.history}
            />
          </Modal>
        }
        {
          showDeletePagesAfterConfiguration &&
          <Modal>
            <AlertView
              // title={'¡Ups!'}
              message={`Se borrarán las últimas ${this.props.pagesDiference} páginas del album.`}
              okHandler={this.handleSaveConfigurationsAndDeletePhotos}
              cancelHandler={this.showDeletePagesAfterConfigurationHandler}
              cancel={true} 
            />
          </Modal>
        }
        {isLoading ? <Loading /> :
          <div>
            <div className="sortablelistbook_header d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                <img src={albums_icon} height="20" alt="" />
                <p className="gotham-bold-15 ml-3 mr-1">Fotolibros > {collectionSelected && collectionSelected.name}</p>
                {configBarShownOption === 'default' &&
                  <img className="mb-2 cursor-pointer" src={edit_img} height="15" alt=""  onClick={() => this.editNameCollectionHandler(collectionSelected)}/>
                }
              </div>
              {configBarShownOption === 'default' && (
                pagesLength < numPages ? 
                  <button className="generic-button-gray" onClick={() => this.handleAddToCart(collectionBooks[0])}>
                    Agregar al carrito
                  </button> :
                  <button className="generic-button-blue" onClick={() => this.handleAddToCart(collectionBooks[0])}>
                    Agregar al carrito
                  </button>
                )
              }
            </div>
            <div className="sortablelistbook_main-content">
              <ConfigurationsBar 
                addPhotosHandler={this.addPhotosHandler}
                handleMouseEnter={this.handleMouseEnter} 
                handleShowConfigurations={this.handleShowConfigurations}
                handleSelectConfigBarOption={this.handleSelectConfigBarOption}
                handleReorderFromOldest={this.handleReorderFromOldest}
                handleReorderFromNewest={this.handleReorderFromNewest}
                handleCloseDeleteOption={this.handleCloseDeleteOption}
                showDeleteConfirmationHandler={this.showDeleteConfirmationHandler}
                handleRestoreOrder={this.handleRestoreOrder}
                handleCloseReorder={this.handleCloseReorder}
                handleShowReorderConfirmation={this.handleShowReorderConfirmation}
                configBarShownOption={configBarShownOption}
                selectedPhotosForDelete={selectedPhotosForDelete}
              />        
              <div className="sortablelistbook_title-container d-flex justify-content-center align-items-center">
                <h1 className="gotham-bold-gray-18 my-3">{selectedAlbumConfiguration && parseConfigurations(selectedAlbumConfiguration)}</h1>
              </div>
              <SortableList
                items={updateSortableData()}
                onSortEnd={this.onSortEnd}
                axis="xy"
                distance={1}
                selectPhotoHandler={this.selectPhotoHandler}
                selectPhotoForDeleteCallback={this.selectPhotoForDeleteHandler}
                selectedPhotosForDelete={selectedPhotosForDelete}
                changeCoverCallback={this.changeCoverHandler}
                nameCollection={collectionSelected}
                addPhotosHandler={this.addPhotosHandler}
                showDeleteConfirmationHandler={this.showDeleteConfirmationHandler}
                editCoverHandler={this.editCoverHandler}
                handleDeletePage={this.handleDeletePage}
                sourceIds={sourceIds}
                configBarShownOption={configBarShownOption}
                selectedAlbumConfiguration={selectedAlbumConfiguration}
              />
              <Footer history={this.props.history}/>

            </div>
          </div>
        }
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  isLoading: state.globalReducer.isLoading,
  collectionSelected: state.collections.collectionSelected,
  sortableDataSource: state.collections.sortableDataSource,
  showingError: state.globalReducer.showingError,
  errorMessage: state.globalReducer.errorMessage,
  collectionBooks: state.collections.collectionBooks,
  showChangeCover: state.collections.showChangeCover,
  showChangeCoverSelectSource: state.collections.showChangeCoverSelectSource,
  selectedCoverForEdit: state.collections.selectedCoverForEdit,
  selectedCoverIndexForEdit: state.collections.selectedCoverIndexForEdit,
  albumConfigurations: state.globalReducer.albumConfigurations,
  cartProducts: state.collections.cartProducts,
  selectedAlbumConfiguration: state.collections.selectedAlbumConfiguration,
  paramsForUpdateAlbumConfiguration: state.collections.paramsForUpdateAlbumConfiguration,
  pagesDiference: state.collections.pagesDiference
})

const mapDispatchToProps = {
  showPhotoDetailAction: showPhotoDetailAction,
  deletePhotosAlbumAction: deletePhotosAlbumAction,
  createSortableDataSourceAction: createSortableDataSourceAction,
  hideErrorAction: hideErrorAction,
  toggleIsAddingPhotosAction: toggleIsAddingPhotosAction,
  toggleShowChangeCoverAction: toggleShowChangeCoverAction,
  toggleShowChangeCoverSelectSourceAction: toggleShowChangeCoverSelectSourceAction,
  selectCoverForEditAction: selectCoverForEditAction,
  loadCollectionAlbumsAction,
  changeProjectTypeAction: changeProjectTypeAction,
  loadAlbumConfigurationsAction: loadAlbumConfigurationsAction,
  updateCollectionSelectedAction: updateCollectionSelectedAction,
  showErrorAction: showErrorAction,
  startLoadingAction: startLoadingAction,
  hideLoadingAction: hideLoadingAction,
  addAlbumToCartAction: addAlbumToCartAction,
  loadCartAction: loadCartAction
}

export default compose(
  firebaseConnect(),
  connect(mapStateToProps, mapDispatchToProps)
)(SortableListBook);