import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { getCartProductsQuantityAction } from '../../actions/CustomerActions';
import AccessoriesCard from './AccessoriesCard';
import Footer from '../Footer';
import Modal from '../Modal';
import SuccessPopup from '../SuccessPopup';
import ApiCustomer from '../../utils/Api/ApiCustomer';
import TokenHelper from '../../utils/Firebase/TokenHelper';
import Loading from '../Loading';
import AlertView from '../AlertView';
import './Accessories.scss';



class Accessories extends Component {

  constructor(props) {
    super(props);
    this.state = {
      accessories: [],
      isLoading: true,
      showAddToCartSuccess: false,
      showError: false,
      errorMessage: ''
    }
  }

  componentDidMount() {
    this.getAllAccessories();
  }

  getAllAccessories = async() => {
    try {
      const idToken = await TokenHelper.getUserToken();
      const response = await ApiCustomer.getAllAccessories({token: idToken});
      if (response.data.success) {
        this.setState({
          accessories: response.data.data,
          isLoading: false
        });
      } else {
        this.setState({
          isLoading: false,
          showError: true,
          errorMessage: 'Ha ocurrido un error, por favor intenta mas tarde.'
        });
      }
    } catch(e) {
      this.setState({
        isLoading: false,
        showError: true,
        errorMessage: 'Ha ocurrido un error, por favor intenta mas tarde.'
      });
    }
  }

  handleSelectAccessory = (selectedItem) => {
    const { accessories } = this.state;
    if (selectedItem.available) {
      const selectedAccessory = accessories.filter(item => item.id === selectedItem.id)[0];
      this.props.history.push({
        pathname: '/accessory/detail',
        accessory: selectedAccessory
      })
    } else {
      return;
    }
  }

  handleAddToCart = async(selectedItem) => {
    if (selectedItem.available) {
      this.setState({isLoading: true});
      try {
        const idToken = await TokenHelper.getUserToken();
        const addToCartData = {
          token: idToken, 
          product_type: "item",
          data: [selectedItem.id]
        }
        const response = await ApiCustomer.addProductToCart(addToCartData);
        await this.props.getCartProductsQuantityAction();
        if (response.data.success) {
          this.setState({
            showAddToCartSuccess: true,
            isLoading: false
          });
        } else {
          this.setState({
            isLoading: false,
            showError: true,
            errorMessage: 'Ha ocurrido un error, por favor intenta mas tarde.'
          });
        }
      } catch(e) {
        this.setState({
          isLoading: false,
          showError: true,
          errorMessage: 'Ha ocurrido un error, por favor intenta mas tarde.'
        });
      }
    } else {
      return;
    }
  }

  handleCloseSuccessPopup = () => {
    this.setState({showAddToCartSuccess: false});
  }

  okErrorHandler = () => {
    this.setState({
      showError: false,
      errorMessage: ''
    });
  }

  render() {
    const { accessories, isLoading, showAddToCartSuccess, showError, errorMessage } = this.state;
    return (
      isLoading ? <Loading/> :
      <div className="accessories_main">
        {
          showError &&
            <Modal>
              <AlertView
                message={errorMessage}
                okHandler={this.okErrorHandler}
              />
            </Modal>
        }
        {
          showAddToCartSuccess &&
          <Modal>
            <SuccessPopup
              handleCloseSuccessPopup={this.handleCloseSuccessPopup}
              textMessage={'¡Se agregó al carrito!'}
              history={this.props.history}
            />
          </Modal>
        }
        <div className="pb-5 d-flex justify-content-center">
          <div className="accessories_inside d-flex flex-wrap">
            {accessories.map((item, i) => (
              <AccessoriesCard
                key={i}
                item={item}
                handleSelectAccessory={this.handleSelectAccessory}
                handleAddToCart={this.handleAddToCart}
              />
            ))}
          </div>
        </div>
        <Footer history={this.props.history} />
      </div>
    );
  }
}


const mapStateToProps = state => ({
});

const mapDispatchToProps = {
  getCartProductsQuantityAction: getCartProductsQuantityAction
};


export default compose(connect(mapStateToProps, mapDispatchToProps))(Accessories);

