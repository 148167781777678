import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { showLoadingAction, hideLoadingAction } from '../../actions/GlobalActions';
import { addCardTokenToUserAction } from '../../actions/CustomerActions';
import ConektaHelper from '../../utils/ConektaHelper';

import card_chip from '../../images/newDesignImages/card-chip-img.png';
import question_img from '../../images/newDesignImages/question-img.png';
import close_gray from '../../images/newDesignImages/close-gray.png';
import card_default from '../../images/newDesignImages/card-default.png';
import card_visa from '../../images/newDesignImages/card-visa.png';
import card_mastercard from '../../images/newDesignImages/card-mastercard.png';
import card_american from '../../images/newDesignImages/card-american.png';
import icon_visa from '../../images/newDesignImages/visa-card-icon.png';
import icon_mastercard from '../../images/newDesignImages/mastercard-card-icon.png';
import icon_american from '../../images/newDesignImages/american-card-icon.png';
import cvv_instructions from '../../images/newDesignImages/cvv-instructions.png';
import './CreateCardToken.scss';

class CreateCardToken extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      cardholder: '',
      card_number: '',
      card_expiration: '',
      card_cvc: '',
      errorMessages: [],
      showCvvInstructions: false
    }
  }

  handleChange = e => {
    this.setState({
      [e.target.name] : e.target.value
    });
  }

  handleDateInput = e => {
    const text = e.target.value.length === 3 && !e.target.value.includes("/")
      ? `${e.target.value.substring(0, 2)}/${e.target.value.substring(2)}`
      : e.target.value
    this.setState({[e.target.name]: text});
  }

  createCallBack = (token) => {
    this.props.addCardTokenToUserAction(token.id);
  }

  createCallBackError = (error) => {
    this.props.handleCardError(error)
    this.props.hideLoadingAction();
  }

  validateForm = () => {
    const { cardholder, card_number, card_expiration, card_cvc } = this.state;
    var validateErrorMessages = [];

    if (cardholder === '') {
      validateErrorMessages.push('Por favor ingrese nombre del tarjetahabiente');
    } 
    
    if (card_number === '') {
      validateErrorMessages.push('Por favor ingrese número de tarjeta');
    } else if (card_number.length > 16 || card_number.length < 15) {
      validateErrorMessages.push('Por favor ingrese número de tarjeta válido');
    }
    
    if (card_expiration === '') {
      validateErrorMessages.push('Por favor ingrese fecha de expiración');
    } else if (card_expiration < 5) {
      validateErrorMessages.push('El formato de la fecha de expiración no es válido.');
    }
    
    if (card_cvc === '') {
      validateErrorMessages.push('Por favor ingrese número de seguridad');
    }

    return validateErrorMessages;
  }

  handleSubmit = e => {
    e.preventDefault();

    //Validate form
    let errorsArray = this.validateForm();
    if (errorsArray.length > 0) { 
      this.setState({ errorMessages: errorsArray});
      return 
    }

    //Conekta Request
    this.props.showLoadingAction();
    ConektaHelper.initConekta();
    const { cardholder, card_number, card_expiration, card_cvc } = this.state;
    let expirationComponents = card_expiration.split("/");
    if (expirationComponents.length === 2) {
      ConektaHelper.tokenize(card_number, cardholder, expirationComponents[0], expirationComponents[1], card_cvc, this.createCallBack, this.createCallBackError);
    } else {
      this.props.hideLoadingAction();
      var conektaErrors = [];
      conektaErrors.push('El formato de la fecha de expiración no es válido.');
      this.setState({ errorMessages: conektaErrors});
    }
    this.props.handleShowCreateCard();
  }

  handleCancel = e => {
    e.preventDefault();
    this.props.dismissHandler();
  }

  handleShowCvvInstructions = () => {
    const { showCvvInstructions } = this.state;
    if (showCvvInstructions) {
      this.setState({ showCvvInstructions: false });
    } else {
      this.setState({ showCvvInstructions: true });
    }
  }

  render() {
    const { creatingCardTokenOnServer } = this.props;
    const { errorMessages, showCvvInstructions } = this.state;
    const cardNumber = (numberString) => {
      let first4 = numberString.slice(0,4);
      let second4 = numberString.slice(4,8);
      let third4 = numberString.slice(8,12);
      let fourth4 = numberString.slice(12,16);
      let width = () => {
        if (numberString.length <= 4) return 25;
        else if (5 <= numberString.length && numberString.length <= 8) return 50;
        else if (9 <= numberString.length && numberString.length <= 12) return 75;
        else return 100;
      }
      return (
        <div className={`w-${width()} mx-3 d-flex justify-content-between`}>
          <p className="create-card_card-num">{first4}</p>
          <p className="create-card_card-num">{second4}</p>
          <p className="create-card_card-num">{third4}</p>
          <p className="create-card_card-num">{fourth4}</p>
        </div>
      );
    }
    const getCardBrand = (cardNumber) => {
      const firstTwoNumbers = cardNumber.slice(0,2);
      if (cardNumber[0] === '4') {
        return {
          card: card_visa,
          icon: icon_visa
        }
      } else if (['51', '52', '53', '54', '55'].includes(firstTwoNumbers)) {
        return {
          card: card_mastercard,
          icon: icon_mastercard
        }
      } else if (['34', '37'].includes(firstTwoNumbers)) {
        return {
          card: card_american,
          icon: icon_american
        }
      } else {
        return {
          card: card_default,
          icon: null
        }
      }
    }
    const backgroundCard = {
      background: 'url(' + getCardBrand(this.state.card_number).card + ') center no-repeat' 
    }
    return (
      <div className="create-address_main d-flex justify-content-center align-items-center">
        <div className="create-card_inside d-flex flex-column justify-content-center align-items-center">
          <img className="create-address_close cursor-pointer" src={close_gray} height="20" alt="" onClick={this.props.handleShowCreateCard}/>
          <p className="gotham-md-gray-15 mb-4">Ingresa una nueva tarjeta</p>
          <div className="create-card_top d-flex justify-content-center align-items-center">
            <div className="create-card_card d-flex justify-content-start align-items-center" style={backgroundCard}>
              <img className="create-card_chip-img" src={card_chip} height="40" alt=""/>
              <img className="create-card_brand-img" src={getCardBrand(this.state.card_number).icon} height="35" alt=""/>
              {cardNumber(this.state.card_number)}
              <p className="create-card_card-cardholder">{this.state.cardholder}</p>
              <p className="create-card_card-expiration">{this.state.card_expiration}</p>
            </div>
          </div>
          <form className="create-address_form mt-3" onSubmit={this.handleSubmit}>
            <div className="crate-address_input-container w-100">
              <input 
                name="cardholder"
                required
                disabled={creatingCardTokenOnServer}
                type="text"
                value= {this.state.cardholder}
                placeholder="Nombre del titular de la tarjeta"
                onChange={this.handleChange}
              />
            </div>
            <hr className="cart_info-inline"></hr>
            <div className="crate-address_input-container w-100">
              <input 
                name="card_number"
                required
                disabled={creatingCardTokenOnServer}
                type="text"
                maxLength="16"
                pattern="[0-9]*"
                value={this.state.card_number}
                placeholder="Número de tarjeta"
                onChange={this.handleChange}
              />
            </div>
            <hr className="cart_info-inline"></hr>

            <div className="w-100 d-flex">
              <div className="crate-address_input-container w-50">
                <input 
                  name="card_expiration"
                  required
                  disabled={creatingCardTokenOnServer}
                  type="text"
                  maxLength="5"
                  value={this.state.card_expiration}
                  placeholder="MM/AA"
                  onChange={this.handleDateInput}
                  // onChange={this.handleChange}
                />
              </div>
              <div className="crate-card_cvc-container w-50 d-flex align-items-center">
                <input 
                  name="card_cvc"
                  required
                  disabled={creatingCardTokenOnServer}
                  type="password"
                  minLength="3"
                  maxLength="4"
                  pattern="[0-9]*"
                  value={this.state.card_cvc}
                  placeholder="CVV"
                  onChange={this.handleChange}
                />
                <img className="cursor-pointer" height="25" src={question_img} alt="" onClick={this.handleShowCvvInstructions} />
                {showCvvInstructions && <img className="create-card_cvv-instructions" height="300" src={cvv_instructions} alt="" onClick={this.handleShowCvvInstructions}/>}
              </div>
            </div>
            <hr className="cart_info-inline"></hr>
            
            <div className="w-100 d-flex justify-content-center align-items-center">
              <button id="add-new-card" type="submit" className="create-address_button my-3" disabled={creatingCardTokenOnServer}>Agregar</button>
            </div>
          </form>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  creatingCardTokenOnServer: state.collections.creatingCardTokenOnServer
})

const mapDispatchToProps = {
  showLoadingAction: showLoadingAction,
  hideLoadingAction: hideLoadingAction,
  addCardTokenToUserAction: addCardTokenToUserAction
}

export default compose( 
  connect(mapStateToProps, mapDispatchToProps)
)(CreateCardToken);