import React, { Component } from 'react';

//Redux
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firebaseConnect } from 'react-redux-firebase';

import edit_img from '../../../images/newDesignImages/edit-blue-img.png';
import './CardAlbum.scss';

class CardAlbum extends Component {

  state = {
    mouseOverEditbtn: false
  }

  handleMouseOverEditbtn = (boolValue) => {
    this.setState({mouseOverEditbtn: boolValue});
  }

  render() {
    const { book, selectAlbumCallBack, addToCartCallback, indexBook, predesignData } = this.props;
    const { mouseOverEditbtn } = this.state;
    const predesignCoverStyles = {
      backgroundImage: 'url(' + book.cover_url + ')',
      backgroundSize: 'cover'
    }
    return (
      (predesignData && predesignData.id) ? (
        <div className="album-card_container my-3 d-flex flex-column align-items-center justify-content-start">
          <div 
            className="album-card_edit-btn d-flex justify-content-center align-items-center cursor-pointer" 
            onClick={() => selectAlbumCallBack(indexBook)}
            onMouseEnter={() => this.handleMouseOverEditbtn(true)}
            onMouseLeave={() => this.handleMouseOverEditbtn(false)}
          >
            <img height="40%" src={edit_img} alt="edit"/>
            {mouseOverEditbtn &&
              <div className="album-card_edit d-flex justify-content-center align-items-center shadow">
                <p className="gotham-md-gray-15">Edita las fotos de tu álbum</p>
              </div>
            }
          </div>
          <div className="album-card_top-normal d-flex shadow" onClick={() => selectAlbumCallBack(indexBook)}>
            <div className="album-card_album d-flex" style={predesignCoverStyles}>
              <div className="album-card_left"></div>
              <div className="album-card_right d-flex justify-content-center align-items-center"></div>
            </div>
          </div>
          <div className="justify-content-center text-center mt-2">
            <h6>Vol. {indexBook + 1} </h6>
            <p className="f-w-350 m-0"><b>{book.photos.length + ' de 60 fotos'}</b></p>
          </div>
          <div >
            <div className="d-flex justify-content-center">
              {/* {(book.photos.length < 60) ?
                <div className="album-card_warning d-flex align-items-center">
                  <i className="d-flex color-warning fa fa-exclamation-triangle" />
                  <p className="d-flex ml-2">Álbum incompleto</p>
                </div>
                : */}
                <button id="add-to-cart" className="generic-button-blue cursor-pointer" onClick={() => addToCartCallback(book)}>
                  Añadir al carrito
                </button>
              {/* // } */}
            </div>
          </div>
        </div>
      ) : (
        <div className="album-card_container my-3 d-flex flex-column align-items-center justify-content-start">
          <div 
            className="album-card_edit-btn d-flex justify-content-center align-items-center cursor-pointer" 
            onClick={() => selectAlbumCallBack(indexBook)}
            onMouseEnter={() => this.handleMouseOverEditbtn(true)}
            onMouseLeave={() => this.handleMouseOverEditbtn(false)}
          >
            <img height="40%" src={edit_img} alt="edit"/>
            {mouseOverEditbtn &&
              <div className="album-card_edit d-flex justify-content-center align-items-center shadow">
                <p className="gotham-md-gray-15">Edita las fotos de tu álbum</p>
              </div>
            }
          </div>
          <div className="album-card_top-normal d-flex shadow" onClick={() => selectAlbumCallBack(indexBook)}>
            <div className="album-card_album d-flex">
              <div className="album-card_left"></div>
              <div className="album-card_right d-flex justify-content-center align-items-center">
                <img className="card_img" src={book.cover_url} height="90%" width="90%" alt=""/>
              </div>
            </div>
          </div>
          <div className="justify-content-center text-center mt-2">
            <h6>Vol. {indexBook + 1} </h6>
            <p className="f-w-350 m-0"><b>{book.photos.length + ' de 60 fotos'}</b></p>
          </div>
          <div >
            <div className="d-flex justify-content-center">
              {/* {(book.photos.length < 60) ?
                <div className="album-card_warning d-flex align-items-center">
                  <i className="d-flex color-warning fa fa-exclamation-triangle" />
                  <p className="d-flex ml-2">Álbum incompleto</p>
                </div>
                : */}
                <button id="add-to-cart" className="generic-button-blue cursor-pointer" onClick={() => addToCartCallback(book)}>
                  Añadir al carrito
                </button>
              {/* } */}
            </div>
          </div>
        </div>
        )
    );
  }
}

const mapStateToProps = state => ({
  auth: state.firebase.auth,
  photosBook: state.collections.photosBook,
  collectionBook: state.collections.collectionBook,
  collectionSelected: state.collections.collectionSelected,
});

export default compose(
  firebaseConnect(),
  connect(mapStateToProps, {})
)(CardAlbum);
