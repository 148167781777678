import React from 'react';

// Remove this and put the good ones, this must be just one reference outside any component
import img_checked from '../../images/img_checked.png';
import img_unchecked from '../../images/img_unchecked.png';


import img_oxxo from '../../images/oxxo.png';

import './CustomerCardItem.scss';

import visa_img from '../../images/newDesignImages/visa-img.png';
import visa_v2 from '../../images/newDesignImages/visa-img-v2.png';
import trash_img from '../../images/newDesignImages/trash-img.png';
import elem_unchecked from '../../images/newDesignImages/elem-unchecked.png';
import elem_checked from '../../images/newDesignImages/elem-checked.png';
import oxxo_img from '../../images/newDesignImages/oxxo-pay-img.jpg';
import master_img from '../../images/mastercard.png';


const CustomerCardItem = (props) => {
  const { card, isViewMyAccount, selected, handleSelectCard, defaultPayment } = props;
  return (
    isViewMyAccount ? (
      <div className="myaccount_address-item py-4 d-flex justify-content-between align-items-center">
        <div className="ml-4">
          <p>{!card.brand.includes('oxxo') ? '**** **** ****' : ''} {card.last4}</p>
        </div>
        <div className="d-flex justify-content-between align-items-center">
          {card.brand === 'visa' ? <img src={visa_img} height="20" alt="" /> : card.brand === 'mastercard' ? <img src={master_img} height="30" alt="" /> : null}
          <img className="ml-5 cursor-pointer" src={trash_img} alt="" onClick={() => props.handleDeleteCard(card.id)} />
        </div>
      </div>
    ) : (
      <div className="w-100 py-3 pr-4 d-flex justify-content-start align-items-center cursor-pointer" onClick={() => handleSelectCard(card)}>
        <div className="w-25 d-flex justify-content-center align-items-center">
          {card.brand === 'visa' ? <img src={visa_v2} height="25" alt="" /> : card.brand === 'oxxo' ? <img src={oxxo_img} alt="" height="80" /> : card.brand === 'mastercard' ? <img src={master_img} height="30" alt="" /> : null}
        </div>
        <div>
          <p className={card.id === defaultPayment.id ? "gotham-bold-blue-15" : "gotham-bold-darkgray-15"}>{!card.brand.includes('oxxo') ? '****' : ''} {card.last4}</p>
        </div>
      </div>
    )
  );
}

export default CustomerCardItem;