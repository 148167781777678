import React from 'react';
import empty_img from '../../images/newDesignImages/empty-cart-img.gif'
import './EmptyCart.scss';

function EmptyCart(props) {
  return (
    <div className="empty-cart-main d-flex flex-column justify-content-center align-items-center">
      <p className="gotham-bold-18">Tu carrito está vacío.</p>
      <p className="gotham-book-gray2-15">Agrega productos y</p>
      <p className="gotham-book-gray2-15">colecciona momentos.</p>
      <img className="empty-cart_img" src={empty_img} alt="" />
      <button className="empty-cart-btn" onClick={props.handleGoToBuyProducts}>
        <p className="gotham-bold-white-15">Continuar comprando</p>
      </button>
    </div>
  );
}

export default EmptyCart;