import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import cupon_img from '../../images/newDesignImages/add-cupon-img.png';
import voucher_img from '../../images/newDesignImages/voucher-img.png';

import './ApplyVoucher.scss'

class ApplyVoucher extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      currentVoucher: ''
    }
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  }

  applyOrDeleteVoucher = () => {
    const { currentVoucher } = this.state;
    const { applyVoucherCallbak } = this.props;
    if (currentVoucher !== '') {
      const applyVoucher = new Promise(resolve => resolve(applyVoucherCallbak(currentVoucher)));
      applyVoucher.then(() => this.setState({ currentVoucher: '' }));
    } else {
      applyVoucherCallbak('');
    }
  }

  removeVoucher = () => {
    this.setState({ currentVoucher: '' });
    const { applyVoucherCallbak } = this.props;
    applyVoucherCallbak('');
  }

  render() {
    const { orderSummary } = this.props;
    return (
      <div>
        <p className="gotham-md-gray-15 mb-3">Agrega un cupón</p>
        <div className="voucher_input-button-container d-flex">
          <div className="voucher_input-container pl-2 d-flex align-items-center">
            <img src={voucher_img} height="15" alt="" />
            <input className="voucher_input pl-2" type="text" name="currentVoucher" placeholder="Ingresa tu código" maxLength="20" value={this.state.currentVoucher} onChange={this.handleChange} />
          </div>
          <button id="apply-discount" className="voucher_apply-cancel-button" onClick={this.applyOrDeleteVoucher}>
            <p className="gotham-md-white-15">{orderSummary.voucher_value === '' ? 'Aplicar' : 'Cancelar'}</p>
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  orderSummary: state.checkoutReducer.orderSummary
})

const mapDispatchToProps = {

}

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(ApplyVoucher);