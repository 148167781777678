import React, { Component } from 'react';
import Loading from '../Loading';
import Footer from '../Footer';
import img_success from '../../images/gif-comprafin.gif';
import advertising_img from '../../images/newDesignImages/sigue-coleccionando.png';
import confeti_img from '../../images/newDesignImages/confeti.gif';
import claps_img from '../../images/newDesignImages/claps.gif';
import './OrderSuccess.scss';

class OrderSuccess extends Component {

  constructor(props) {
    super(props);
    this.state = {
      orderId: '',
      email: '',
      paymentReference: '',
      cashPaymentReference: '',
      isOxxoPay: null,
      isLoading: true
    }
  }

  componentDidMount() {
    if (!this.props.location.data) {
      this.props.history.push('/cart');
    } else {
      this.props.location.data.getCartProductsQuantityAction();
      this.setState({
        orderId: this.props.location.data.orderId,
        email: this.props.location.data.email,
        paymentReference: this.props.location.data.paymentReference,
        cashPaymentReference: this.props.location.data.cashPaymentReference,
        isOxxoPay: this.props.location.data.isOxxoPay,
        total: this.props.location.data.total,
        isLoading: false
      });
    }
  }

  render() {
    const { orderId, email, isLoading, isOxxoPay, cashPaymentReference, total } = this.state;
    const background = {
      backgroundImage: 'url(' + confeti_img  + ')',
      backgroundSize: 'cover'
    }
    return (
      isLoading ? <Loading/> :
      <div className="order-success_main">
        <div className="mb-3 d-flex flex-column justify-conten-center align-items-center">
          <img src={claps_img} height="200" alt="" />
          <div className="order-success_title d-flex flex-column justify-conten-center align-items-center">
            <p className="gotham-bold-pink-25">¡Lo lograste!</p>
            <p className="gotham-bold-pink-25">Pronto enviaremos tu orden.</p>
          </div>
          <div style={background} className="order-success_confeti">
            <img src={advertising_img} height="180" alt="" />
          </div>
          <p className="gotham-md-darkgray-20 mb-4">El resumen de tu pedido sera enviado a {email}</p>
          <p className="gotham-book-darkgray-18">{isOxxoPay ? 'Tu número de referencia es:' : 'Tu número de compra es:'}</p>
          <p className="gotham-book-darkgray-18">{isOxxoPay ? cashPaymentReference : orderId}</p>
        </div>
        {isOxxoPay &&
          <div className="order-success_oxxo-instructions mt-1 mb-5 mx-5 pl-2 pt-3">
            <p className="gotham-book-gray-15">1. Acude a la tienda OXXO más cercana.</p>
            <p className="gotham-book-gray-15">2. Indica al cajero el número de referencia y la cantidad a pagar ($ {total}).</p>
            <p className="gotham-book-gray-15">3. Conserva tu comprobante de pago.</p>
          </div>
        }
        <Footer/>
      </div>
    );
  }
}  


export default OrderSuccess;