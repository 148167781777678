import ApiCustomer from '../utils/Api/ApiCustomer';
import ApiQuotes from '../utils/Api/ApiQuotes';
import TokenHelper from '../utils/Firebase/TokenHelper';
import {
    SELECT_SOURCE,
    LOAD_SOCIAL_PHOTOS,
    ADD_PHOTO_TO_CREATE,
    REMOVE_PHOTO_FROM_CREATE,
    RESET_CREATE_PROJECT_STATE,
    START_LOADING_INFO,
    FINISH_LOADING_INFO,
    SHOW_ERROR,
    SET_SOCIAL_TOKEN,
    LOAD_SOCIAL_ALBUMS,
    SELECT_SOCIAL_ALBUM,
    LOAD_SOCIAL_ALBUM_DETAIL,
    RESET_PHOTOS_SELECTION,
    SET_GOOGLE_LOGGED_IN,
    REMOVE_DEFAULT_ALBUM_FOR_QUOTES,
    LOAD_QUOTES_ALBUMS,
    LOAD_QUOTES_ALBUM_DETAIL,
    SELECT_COLLECTION,
    TOGGLE_IS_ADDING_PHOTOS,
    BACK_TO_ALBUM_DETAIL_AFTER_ADD_PHOTOS,
    SELECT_MAGNET_PROJECT,
    SELECT_CANVAS_PROJECT,
    SELECT_ORNAMENT_PROJECT,
    UPDATE_ALBUM_CONFIGURATION_IDS,
    UPDATE_ALBUM_SELECTED_PAGES,
    UPDATE_ALBUM_PRINT_DATES,
    SET_DEFAULT_ALBUM
} from '../actions/ActionTypes';

// Clean the state
export const resetProjectReducerAction = () => {
    return {
        type: RESET_CREATE_PROJECT_STATE,
        payload: null
    }
}

// Set the source on the state
export const selectSourceAction = source => async (dispatch) => {
    dispatch({
        type: SELECT_SOURCE,
        payload: source
    })
}

// Get photos from the server
export const loadPhotosFromSourceAction = (source, socialToken = '', nextPage = null, productType) => async (dispatch) => {
    dispatch({
        type: START_LOADING_INFO,
        payload: true
    });
    const params = await TokenHelper.getUserToken().then(idToken => {
        let params = {
            token: idToken,
            source: source,
            social_token: socialToken,
            product_type: productType
        };
        if (nextPage) {
            params.next_page = nextPage
        }
        return params;
    });
    const response = await ApiCustomer.getCustomerSocialPhotos(params).then(response => { return response.data; }).catch(error => { return error.data; });
    if (response.success) {
        dispatch({
            type: LOAD_SOCIAL_PHOTOS,
            payload: response.data
        })
    } else {
        dispatch({
            type: SHOW_ERROR,
            payload: response.message
        })
    }
    dispatch({
        type: FINISH_LOADING_INFO,
        payload: false
    });
}

// Add photo to the state
export const addPhotoToCreateAlbumAction = (photo) => (dispatch) => {
    dispatch({
        type: ADD_PHOTO_TO_CREATE,
        payload: photo
    })
}

// Remove photo from the state
export const removePhotoFromCreateAlbumAction = (photo) => {
    return {
        type: REMOVE_PHOTO_FROM_CREATE,
        payload: photo
    }
}

// Send data to the server in order to create new collection
export const createAlbumAction = (photos) => async (dispatch) => {
    dispatch({
        type: START_LOADING_INFO,
        payload: true
    });
    const params = await TokenHelper.getUserToken().then(idToken => {
        let params = {
            token: idToken,
            data: photos
        };
        return params;
    });
    const response = await ApiCustomer.createAlbum(params).then(response => { return response.data; }).catch(error => { return error.data; });
    if (response.success) {
        // We select the album automatically in order to show it in detail
        dispatch({
            type: SELECT_COLLECTION,
            payload: response.data
        })
    } else {
        dispatch({
            type: SHOW_ERROR,
            payload: response.message
        })
    }
    dispatch({
        type: FINISH_LOADING_INFO,
        payload: false
    });
}


export const createProductAction = (photos, productType, configurations) => async (dispatch) => {
    dispatch({
        type: START_LOADING_INFO,
        payload: true
    });
    const params = await TokenHelper.getUserToken().then(idToken => {
        if (productType === 'picture') {
            let params = {
                token: idToken,
                data: photos,
                product_type: productType,
                configurations: [configurations]
            };
            return params;
        } else if (productType === 'album') {
            let params = {
                token: idToken,
                data: photos,
                product_type: productType,
                configurations: configurations.albumConfigurationIds,
                print_dates: configurations.albumPrintDates
            };
            return params;
        } else {
            let params = {
                token: idToken,
                data: photos,
                product_type: productType
            };
            return params;
        }
    });
    const response = await ApiCustomer.createProduct(params).then(response => { return response.data; }).catch(error => { return error.data; });
    if (response.success) {
        // We select the album automatically in order to show it in detail
        if (productType === 'album') {
            dispatch({
                type: SELECT_COLLECTION,
                payload: response.data
            })
        } else if (productType === 'magnet') {
            dispatch({
                type: SELECT_MAGNET_PROJECT,
                payload: response.data
            })
        } else if (productType === 'picture') {
            dispatch({
                type: SELECT_CANVAS_PROJECT,
                payload: response.data
            })
        } else if (productType === 'ornament'){
            dispatch({
                type: SELECT_ORNAMENT_PROJECT,
                payload: response.data
            })
        }
    } else {
        dispatch({
            type: SHOW_ERROR,
            payload: response.message
        })
    }
    dispatch({
        type: FINISH_LOADING_INFO,
        payload: false
    });
}



// Set the token of the social login
export const setSocialTokenAction = (token) => {
    return {
        type: SET_SOCIAL_TOKEN,
        payload: token
    }
}

// Load social albums for the given source
export const loadSocialAlbumsAction = (source, socialToken = '', nextPage = '') => async (dispatch) => {
    dispatch({
        type: START_LOADING_INFO,
        payload: true
    });
    const params = await TokenHelper.getUserToken().then(idToken => {
        let params = {
            token: idToken,
            source: source
        };
        if (socialToken !== '') {
            params.social_token = socialToken
        }
        if (nextPage !== '') {
            params.next_page = nextPage
        }
        return params;
    });
    const response = await ApiCustomer.getCustomerSocialAlbums(params).then(response => { return response.data; }).catch(error => { return error.data; });
    if (response.success) {
        dispatch({
            type: LOAD_SOCIAL_ALBUMS,
            payload: response.data
        })
    } else {
        dispatch({
            type: SHOW_ERROR,
            payload: response.message
        })
    }
    dispatch({
        type: FINISH_LOADING_INFO,
        payload: false
    });
}

// Select social album in order to show the photos
export const selectSocialAlbumAction = (album) => {
    return {
        type: SELECT_SOCIAL_ALBUM,
        payload: album
    }
}

// Get photos from the selected album
export const loadPhotosFromAlbumAction = (source, album, socialToken = '', nextPage = null, productType) => async (dispatch) => {
    dispatch({
        type: START_LOADING_INFO,
        payload: true
    });
    const params = await TokenHelper.getUserToken().then(idToken => {
        let params = {
            token: idToken,
            source: source,
            album_id: album.id,
            social_token: socialToken,
            product_type: productType
        };
        if (nextPage) {
            params.next_page = nextPage
        }
        return params;
    });
    const response = await ApiCustomer.getCustomerSocialAlbumDetail(params).then(response => { return response.data; }).catch(error => { return error.data; });
    if (response.success) {
        dispatch({
            type: LOAD_SOCIAL_ALBUM_DETAIL,
            payload: response.data
        })
    } else {
        dispatch({
            type: SHOW_ERROR,
            payload: response.message
        })
    }
    dispatch({
        type: FINISH_LOADING_INFO,
        payload: false
    });
}

export const resetPhotosSelectionAction = () => {
    return {
        type: RESET_PHOTOS_SELECTION,
        payload: null
    }
}

export const loginSocialNetworkAction = (socialToken, source) => async (dispatch) => {
    dispatch({
        type: START_LOADING_INFO,
        payload: true
    });
    const params = await TokenHelper.getUserToken().then(idToken => {
        let params = {
            token: idToken,
            source: source,
            auth_code: socialToken,
            from_web: true
        };
        return params;
    });
    const response = await ApiCustomer.loginSocialNetwork(params).then(response => { return response.data; }).catch(error => { return error.data; });
    if (response.success) {
        if (source === 'instagram') {
            let loginInstagramData = {
                username: response.data.username,
                isLogged: true
            }
            localStorage.setItem("loginInstagram", JSON.stringify(loginInstagramData));
        }
        if (source === 'gphotos') {
            let loginGphotos = {
                username: response.data.username,
                isLogged: true
            }
            localStorage.setItem("loginGphotos", JSON.stringify(loginGphotos))
            dispatch({
                type: SET_GOOGLE_LOGGED_IN,
                payload: true
            });

        }
    } else {
        dispatch({
            type: SHOW_ERROR,
            payload: response.message
        })
    }
    dispatch({
        type: FINISH_LOADING_INFO,
        payload: false
    });
}

export const loadQuotesAlbumsAction = () => async (dispatch) => {
    dispatch({
        type: START_LOADING_INFO,
        payload: true
    });
    const params = await TokenHelper.getUserToken().then(idToken => {
        let params = {
            token: idToken
        };
        return params;
    });
    const response = await ApiQuotes.getQuotesAlbums(params).then(response => { return response.data; }).catch(error => { return error.data; });
    if (response.success) {
        let finalAlbums = []
        // Remove albums without photos
        for (let album of response.data) {
            if (album.count > 0) {
                finalAlbums.push(album);
            }
        }
        dispatch({
            type: LOAD_QUOTES_ALBUMS,
            payload: finalAlbums
        })
    } else {
        dispatch({
            type: SHOW_ERROR,
            payload: response.message
        })
    }
    dispatch({
        type: FINISH_LOADING_INFO,
        payload: false
    });
}

export const loadPhotosFromQuotesAlbumAction = (album) => async (dispatch) => {
    dispatch({
        type: START_LOADING_INFO,
        payload: true
    });
    const params = await TokenHelper.getUserToken().then(idToken => {
        let params = {
            token: idToken,
            album_id: album.id
        };
        return params;
    });
    const response = await ApiQuotes.getQuotesAlbumDetail(params).then(response => { return response.data; }).catch(error => { return error.data; });
    if (response.success) {
        dispatch({
            type: LOAD_QUOTES_ALBUM_DETAIL,
            payload: response.data
        })
    } else {
        dispatch({
            type: SHOW_ERROR,
            payload: response.message
        })
    }
    dispatch({
        type: FINISH_LOADING_INFO,
        payload: false
    });
}

export const removeDefaultAlbumForQuotesAction = () => {
    return {
        type: REMOVE_DEFAULT_ALBUM_FOR_QUOTES,
        payload: null
    };
}

export const setDefaultAlbumsAction = () => {
    return {
        type: SET_DEFAULT_ALBUM,
        payload: null
    }
} 

export const toggleIsAddingPhotosAction = (isAdding) => {
    localStorage.setItem('ADDING_PHOTOS', JSON.stringify(isAdding));
    return {
        type: TOGGLE_IS_ADDING_PHOTOS,
        payload: isAdding
    }
}

export const addPhotosToAlbumAction = (collection, photos) => async (dispatch) => {
    dispatch({
        type: START_LOADING_INFO,
        payload: true
    });
    const params = await TokenHelper.getUserToken().then(idToken => {
        let params = {
            token: idToken,
            album_id: collection.id,
            photos: photos
        };
        return params;
    });
    const response = await ApiCustomer.addPagesToAlbum(params).then(response => { return response.data; }).catch(error => { return error.data; });
    if (response.success) {
        for (let photoToAdd of response.data) {
            collection.pages.push(photoToAdd);
        }
        localStorage.setItem('SELECTED_COLLECTION', JSON.stringify(collection));
        dispatch({
            type: SELECT_COLLECTION,
            payload: collection
        });
        dispatch({
            type: BACK_TO_ALBUM_DETAIL_AFTER_ADD_PHOTOS,
            payload: true
        });
        dispatch({
            type: TOGGLE_IS_ADDING_PHOTOS,
            payload: false
        });
    } else {
        dispatch({
            type: SHOW_ERROR,
            payload: response.message
        });
    }
    dispatch({
        type: FINISH_LOADING_INFO,
        payload: false
    });
}


export const updateAlbumConfigurationDataAction = (idsArray, selectedPagesQuantity, albumPrintDates) => (dispatch) => {
    dispatch({
        type: UPDATE_ALBUM_CONFIGURATION_IDS,
        payload: idsArray
    });
    dispatch({
        type: UPDATE_ALBUM_SELECTED_PAGES,
        payload: selectedPagesQuantity
    });
    dispatch({
        type: UPDATE_ALBUM_PRINT_DATES,
        payload: albumPrintDates
    });
}