import { 
    ROUTE_CHANGE, 
    CHANGE_PROJECT_TYPE, 
    SELECT_MAGNET_PROJECT, 
    SELECT_CANVAS_PROJECT,
    SELECT_ORNAMENT_PROJECT,
    CHANGE_DATA_FOR_ADDING_TITLE,
    UPDATE_IS_NEW_CUSTOMER
} from './ActionTypes';

export const changeRouteAction = (newRoute) => (dispatch) => {
    dispatch({
        type: ROUTE_CHANGE,
        payload: newRoute
    });
}

export const changeProjectTypeAction = (projectType) => (dispatch) => {
    localStorage.setItem('PROJECT_TYPE', JSON.stringify(projectType));
    dispatch({
        type: CHANGE_PROJECT_TYPE,
        payload: projectType
    });
}

export const selectMagnetProject = (magnet) => (dispatch) => {
    localStorage.setItem('SELECTED_MAGNET', JSON.stringify(magnet));
    dispatch({
        type: SELECT_MAGNET_PROJECT,
        payload: magnet
    });
}

export const selectCanvasProject = (canvas) => (dispatch) => {
    localStorage.setItem('SELECTED_CANVAS', JSON.stringify(canvas));
    dispatch({
        type: SELECT_CANVAS_PROJECT,
        payload: canvas
    });
}

export const selectOrnamentProject = (ornament) => (dispatch) => {
    localStorage.setItem('SELECTED_ORNAMENT', JSON.stringify(ornament));
    dispatch({
        type: SELECT_ORNAMENT_PROJECT,
        payload: ornament
    });
}

export const changeDataForAddTitlteAction = (data) => (dispatch) => {
    dispatch({
        type: CHANGE_DATA_FOR_ADDING_TITLE,
        payload: data
    })
}

export const updateIsNewCustomer = (boolean) => (dispatch) => {
    dispatch({
        type: UPDATE_IS_NEW_CUSTOMER,
        payload: boolean
    })
}