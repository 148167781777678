import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { changeProjectTypeAction, selectMagnetProject } from '../../actions/NavigationActions';
import { toggleIsAddingPhotosAction } from '../../actions/CreateProjectActions';
import ApiCustomer from '../../utils/Api/ApiCustomer';
import TokenHelper from '../../utils/Firebase/TokenHelper';

import Modal from '../Modal';
import AlertView from '../AlertView';
import MagnetDetailItem from './MagnetDetailItem';
import CreateProject from '../CreateProject';
import Footer from '../Footer';
import EditProductName from '../EditProductName';
import EditPhoto from '../EditPhoto';
import Loading from '../Loading';


import magnets_icon from '../../images/newDesignImages/magnets-icon-img.png';
import duplicate_active from '../../images/newDesignImages/duplicate-n2.png';
import duplicate_inactive from '../../images/newDesignImages/duplicate-inactive.png';
import trash_active from '../../images/newDesignImages/trash-img-n2.png';
import trash_inactive from '../../images/newDesignImages/trash-inactive.png';
import edit_img from '../../images/newDesignImages/edit-blue-img.png';

import './MagnetDetail.scss';


class MagnetDetail extends Component {

  constructor(props) {
    super(props);
    this.state = {
      magnet: null,
      showError: false,
      errorMessage: '',
      selectedImages: [],
      showAddImagesSource: false,
      showEditMagnetName: false,
      showEditImage: false,
      selectedImageForEdit: null,
      isLoading: true,
      mouseOverImage: null
    }
  }

  componentDidMount() {
    if (!this.props.location.state) {
      this.props.history.push('/projects/');
    } else {
      const { id, productType } = this.props.location.state;
      this.getMagnetDetail(id, productType);
    }
  }

  getMagnetDetail = (id, productType) => {
    TokenHelper.getUserToken().then(idToken => {
      ApiCustomer.getCustomerProductDetail({
        token: idToken,
        product_id: id,
        product_type: productType
      }).then(response => {
        if (response.data.success) {
          this.setState({
            magnet: response.data.data,
            isLoading: false
          });
        } else {
          this.setState({
            showError: true,
            isLoading: false,
            errorMessage: 'Ocurrió un error, intenta más tarde.'
          });
        }
      }).catch(error => {
        this.setState({
          showError: true,
          isLoading: false,
          errorMessage: 'Ocurrió un error, intenta más tarde.'
        });
      })
    }).catch(error => {
      this.setState({
        showError: true,
        isLoading: false,
        errorMessage: 'Ocurrió un error, intenta más tarde.'
      });
    })
  }

  okErrorHandler = () => {
    this.setState({
      showError: false,
      errorMessage: ''
    })
  }

  handleSelectImage = (image) => {
    const { selectedImages } = this.state;
    if (selectedImages.includes(image)) {
      this.setState({ selectedImages: selectedImages.filter(item => !(item.id === image.id)) });
    } else {
      this.setState({ selectedImages: selectedImages.concat(image) });
    }
  }

  handleDuplicateImages = (selectedImages) => {
    const { magnet } = this.state;
    if (selectedImages.length + magnet.photos.length > 15) {
      this.setState({
        showError: true,
        errorMessage: 'No es posible agregar más de 15 fotos a tu proyecto.'
      })
    } else {
      let photosDataForAPI = [];
      let sortedPhotosForCreate = selectedImages.sort((a, b) => b.index - a.index);
      let index = magnet.photos.length;
      for (let photoElement of sortedPhotosForCreate) {
        photoElement.image_url = photoElement.image_url.slice(0, photoElement.image_url.indexOf('?'));
        photoElement.print_url = photoElement.print_url.slice(0, photoElement.print_url.indexOf('?'));
        delete photoElement.id;
        photoElement.index = index;
        photosDataForAPI.push(photoElement);
        index += 1;
      }
      this.addImagesToMagnet(magnet.id, true, photosDataForAPI);
    }
  }

  addImagesToMagnet = (id, isClone, photos) => {
    this.setState({ isLoading: true })
    TokenHelper.getUserToken().then(idToken => {
      ApiCustomer.addMagnetImage({
        token: idToken,
        magnet_project_id: id,
        is_clone: isClone,
        photos: photos
      }).then(response => {
        if (response.data.success) {
          this.setState({ selectedImages: [], isLoading: false });
          this.getMagnetDetail(id, 'magnet');
        } else {
          this.setState({
            showError: true,
            errorMessage: 'Ocurrió un error, intenta más tarde.',
            isLoading: false
          });
        }
      }).catch(error => {
        this.setState({
          showError: true,
          errorMessage: 'Ocurrió un error, intenta más tarde.',
          isLoading: false
        });
      })
    }).catch(error => {
      this.setState({
        showError: true,
        errorMessage: 'Ocurrió un error, intenta más tarde.',
        isLoading: false
      });
    })
  }


  handleAddImageClick = () => {
    this.props.toggleIsAddingPhotosAction(true);
    this.props.selectMagnetProject(this.state.magnet);
    this.props.changeProjectTypeAction('magnet');
    this.setState({ showAddImagesSource: true });
  }

  cancelCreateProjectConfirmation = () => {
    this.setState({ showAddImagesSource: false });
  }

  handleDeleteImages = (selectedImages) => {
    this.setState({ isLoading: true })
    const { magnet } = this.state;
    const magnetProjectId = magnet.id;
    const selectedImagesIds = selectedImages.map(item => item.id);
    TokenHelper.getUserToken().then(idToken => {
      ApiCustomer.deleteMagnetImage({
        token: idToken,
        magnet_project_id: magnetProjectId,
        photos_ids: selectedImagesIds
      }).then(response => {
        if (response.data.success) {
          this.setState({
            selectedImages: [],
            magnet: {
              ...magnet,
              photos: magnet.photos.filter(item => !selectedImages.includes(item))
            },
            isLoading: false
          })
        } else {
          this.setState({
            showError: true,
            errorMessage: 'Ocurrió un error, intenta más tarde.',
            isLoading: false
          });
        }
      }).catch(error => {
        this.setState({
          showError: true,
          errorMessage: 'Ocurrió un error, intenta más tarde.',
          isLoading: false
        });
      })
    }).catch(error => {
      this.setState({
        showError: true,
        errorMessage: 'Ocurrió un error, intenta más tarde.',
        isLoading: false
      });
    })
  }

  handleShowEditMagnetName = () => {
    this.setState({ showEditMagnetName: true });
  }

  cancelEditMagnetName = () => {
    this.setState({ showEditMagnetName: false });
  }

  handleEditMagnetName = () => {
    this.setState({ showEditMagnetName: false });
    this.getMagnetDetail(this.state.magnet.id, 'magnet');
  }

  selectImageForEdit = (image) => {
    this.setState({
      showEditImage: true,
      selectedImageForEdit: image
    })
  }

  handleCloseImageForEdit = (editedImage) => {
    if (editedImage) {
      this.setState({
        showEditImage: false,
        isLoading: true
      });
    }
    this.setState({ showEditImage: false });
  }

  handleCropImageSuccess = (editedPhoto) => {
    const { magnet, selectedImageForEdit } = this.state;
    const updatedPhotos = magnet.photos.map(photo => {
      if (photo === selectedImageForEdit) {
        return editedPhoto;
      }
      return photo;
    })
    this.setState({
      isLoading: false,
      magnet: {
        ...magnet,
        photos: updatedPhotos
      }
    })
  }

  handleError = (message) => {
    this.setState({
      isLoading: false,
      showError: true,
      errorMessage: message,
      showEditMagnetName: false,
      showEditImage: false
    });
  }

  handleMouseEnter = (image) => {
    this.setState({ mouseOverImage: image })
  }


  handleAddToCart = () => {
    const reducer = (accumulator, currentValue) => accumulator + currentValue;
    const totalPrice = this.props.cartProducts.map(item => item.price).reduce(reducer, 0) + this.state.magnet.price;
    if (totalPrice > 10000) {
      this.setState({
        showError: true,
        errorMessage: 'No es posible agregar más de $10,000 al carrito.'
      });
    } else if (this.state.magnet.photos.length < 15) {
      this.setState({
        showError: true,
        errorMessage: 'Necesitas al menos 15 fotos para agregar el proyecto al carrito.'
      });
    } else {
      this.addMagnetToCart();
    }
  }

  addMagnetToCart = () => {
    this.setState({ isLoading: true });
    TokenHelper.getUserToken().then(idToken => {
      ApiCustomer.addProductToCart({
        token: idToken,
        data: [this.state.magnet],
        product_type: 'magnet'
      }).then(response => {
        if (response.data.success) {
          this.props.history.push('/cart');
        } else {
          this.setState({
            isLoading: false,
            showError: true,
            errorMessage: 'Ocurrió un error, intenta más tarde.'
          });
        }
      }).catch(error => {
        this.setState({
          isLoading: false,
          showError: true,
          errorMessage: 'Ocurrió un error, intenta más tarde.'
        });
      })
    }).catch(error => {
      this.setState({
        isLoading: false,
        showError: true,
        errorMessage: 'Ocurrió un error, intenta más tarde.'
      });
    })
  }


  render() {
    const {
      isLoading,
      showError,
      errorMessage,
      magnet,
      selectedImages,
      showAddImagesSource,
      showEditMagnetName,
      showEditImage,
      selectedImageForEdit,
      mouseOverImage
    } = this.state;
    const widthPercentage = magnet && magnet.photos.length * (100 / 15);
    const photos = magnet && (magnet.photos.length === 15 ? magnet.photos : magnet.photos.concat('addPhoto'));
    return (
      isLoading ? <Loading /> :
        <div>
          {
            showError &&
            <Modal>
              <AlertView
                message={errorMessage}
                okHandler={this.okErrorHandler}
              />
            </Modal>
          }
          {
            showAddImagesSource &&
            <Modal>
              <CreateProject cancelHandler={this.cancelCreateProjectConfirmation} />
            </Modal>
          }
          {
            showEditMagnetName &&
            <Modal>
              <EditProductName
                productType={'magnet'}
                name={magnet.name}
                id={magnet.id}
                handleEditMagnetName={this.handleEditMagnetName}
                cancelEditProductName={this.cancelEditMagnetName}
                handleError={this.handleError}
              />
            </Modal>
          }
          {
            showEditImage &&
            <Modal>
              <EditPhoto
                productType={'magnet'}
                selectedImageForEdit={selectedImageForEdit}
                magnetId={magnet.id}
                handleCloseImageForEdit={this.handleCloseImageForEdit}
                handleCropImageSuccess={this.handleCropImageSuccess}
                handleCropImageError={this.handleError}
              />
            </Modal>
          }

          <div className="magnet_header d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center">
              <img src={magnets_icon} height="20" alt="" />
              <p className="gotham-bold-15 ml-3">Imanes {magnet.name}</p>
            </div>
            <div className="magnet_header-right d-flex justify-content-end">
              {
                selectedImages.length > 0 ?
                  <div className="magnet_header-item mr-4 d-flex align-items-center cursor-pointer" onClick={() => this.handleDuplicateImages(selectedImages)}>
                    <img src={duplicate_active} height="20" alt="" />
                    <p className="ml-2">Duplicar</p>
                  </div> :
                  <div className="magnet_header-item mr-4 d-flex align-items-center cursor-pointer">
                    <img src={duplicate_active} height="20" alt="" />
                    <p className="ml-2">Duplicar</p>
                  </div>
              }
              {/* {
                selectedImages.length > 0 ?
                  <div className="d-flex align-items-center cursor-pointer" onClick={() => this.handleDuplicateImages(selectedImages)}>
                    <img src={duplicate_active} height="20" alt="" />
                    <p className="futura-md-15 ml-3">Duplicar</p>
                  </div> :
                  <div className="d-flex align-items-center">
                    <img src={duplicate_inactive} height="20" alt="" />
                    <p className="futura-md-lightgray-15 ml-3">Duplicar</p>
                  </div>
              } */}

              {
                selectedImages.length > 0 && (magnet.photos.length - selectedImages.length > 0) ?
                  <div className="magnet_header-item mr-4 d-flex align-items-center cursor-pointer" onClick={() => this.handleDeleteImages(selectedImages)}>
                    <img src={trash_active} height="20" alt="" />
                    <p className="ml-2">Eliminar</p>
                  </div> :
                  <div className="magnet_header-item mr-4 d-flex align-items-center cursor-pointer">
                    <img src={trash_active} height="20" alt="" />
                    <p className="ml-2">Eliminar </p>
                  </div>

              }
              {/* {
                selectedImages.length > 0 && (magnet.photos.length - selectedImages.length > 0) ?
                  <div className="d-flex align-items-center cursor-pointer" onClick={() => this.handleDeleteImages(selectedImages)}>
                    <img src={trash_active} height="20" alt="" />
                    <p className="futura-md-15 ml-3">Eliminar</p>
                  </div> :
                  <div className="d-flex align-items-center">
                    <img src={trash_inactive} height="20" alt="" />
                    <p className="futura-md-lightgray-15 ml-3">Eliminar </p>
                  </div>
              } */}

              <button className="generic-button-blue d-flex align-items-center justify-content-center cursor-pointer" onClick={this.handleAddToCart}>
                <p>Añadir al Carrito</p>
              </button>
            </div>
          </div>
          <div className="magnet_main-content">
            <div className="my-5 d-flex justify-content-center">
              <div className="magnet_content">
                <div className="magnet_content-header">
                  <div className="magnet_header-top px-2 d-flex justify-content-between align-items-center">
                    <p className="futura-md-13">Título:</p>
                    <div className="d-flex align-items-center cursor-pointer" onClick={() => this.handleShowEditMagnetName()}>
                      <img src={edit_img} height="12" alt="" />
                      <p className="futura-md-blue-13 ml-1">Editar</p>
                    </div>
                  </div>
                  <div className="magnet_header-bottom pl-2 d-flex align-items-center" >
                    <p className="gotham-bold-20">{magnet.name}</p>
                  </div>
                </div>
                <div className="magnet_warning-container p-2">
                  <div className="d-flex my-2 align-items-center">
                    <i className="fa fa-exclamation-triangle fa-lg"></i>
                    <p className="gotham-bold-15 ml-2">IMPORTANTE</p>
                  </div>
                  <p>Recuerda editar y centrar la foto para que no salga cortada.</p>
                </div>
                <div className="mt-3 d-flex flex-column align-items-center">
                  {magnet.photos.length < 15 ?
                    <div className="magnet_counter d-flex justify-content-center">
                      <p className="futura-md-blue-15">{magnet.photos.length}</p>
                      <p className="futura-md-15">/15 imanes</p>
                    </div>
                    :
                    <div className="magnet_counter d-flex justify-content-between">
                      <p className="futura-md-blue-15">{magnet.photos.length}/15 imanes</p>
                      <p className="futura-md-15">Total: ${magnet.price}</p>
                    </div>
                  }
                  <div className="magnet_bar mt-1">
                    <div className="magnet_bar-inside" style={{ width: `${widthPercentage}%` }}></div>
                  </div>
                </div>
                <div className="d-flex flex-wrap">
                  {photos.map((item, i) => (
                    <MagnetDetailItem
                      key={i}
                      image={item.image_url}
                      item={item}
                      handleSelectImage={this.handleSelectImage}
                      isImageSelected={selectedImages.includes(item)}
                      handleAddImageClick={this.handleAddImageClick}
                      selectImageForEdit={this.selectImageForEdit}
                      handleMouseEnter={this.handleMouseEnter}
                      mouseOverImage={mouseOverImage}
                    />
                  ))}
                </div>
              </div>
            </div>
            <Footer history={this.props.history}/>
          </div>
        </div>
    );
  }
}


const mapStateToProps = state => ({
  projectType: state.navigationReducer.projectType,
  selectedMagnetProject: state.navigationReducer.selectedMagnetProject,
  cartProducts: state.collections.cartProducts
});

const mapDispatchToProps = {
  changeProjectTypeAction: changeProjectTypeAction,
  toggleIsAddingPhotosAction: toggleIsAddingPhotosAction,
  selectMagnetProject: selectMagnetProject
};


export default compose(connect(mapStateToProps, mapDispatchToProps))(MagnetDetail);