import React from 'react';
import './CoverCard.scss';

const CoverCard = (props) => {
  return (
    <div className="cover-card_main d-flex justify-content-center align-items-center">
      <div 
        className="cover-card cursor-pointer" 
        onClick={() => props.handleSelectCover('', props.collectionSelected, props.selectedCoverIndexForEdit, props.item.id)}
      >
        <img className="cover-card_img" src={props.thumbnail} alt=""/>
      </div>
    </div>

  );
}

export default CoverCard;