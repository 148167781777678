import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import ApiCustomer from '../../utils/Api/ApiCustomer';
import TokenHelper from '../../utils/Firebase/TokenHelper';
import { changeProjectTypeAction, selectCanvasProject } from '../../actions/NavigationActions';
import { toggleIsAddingPhotosAction } from '../../actions/CreateProjectActions';

import CanvasDetailItem from './CanvasDetailItem';
import Loading from '../Loading';
import Footer from '../Footer';
import EditProductName from '../EditProductName';
import Modal from '../Modal';
import EditPhoto from '../EditPhoto';
import CreateProject from '../CreateProject';
import AlertView from '../AlertView';
import PhotoDetailCanvas from '../PhotoDetailCanvas';

import canvas_icon from '../../images/newDesignImages/canvas-icon.png';
import check_icon from '../../images/newDesignImages/check.png';
import duplicate_active from '../../images/newDesignImages/duplicate-n2.png';
import trash_active from '../../images/newDesignImages/trash-img-n2.png';
import edit_img from '../../images/newDesignImages/edit-blue-img.png';
import './CanvasDetail.scss';

class CanvasDetail extends Component {

  constructor(props) {
    super(props);
    this.state = {
      canvas: null,
      isLoading: true,
      selectedImages: [],
      showError: false,
      errorMessage: '',
      showEditCanvasName: false,
      showEditImage: false,
      selectedImageForEdit: null,
      showAddImagesSource: false,
      mouseOverImage: null,
      canvasConfigurations: [],
      // selectedFrameUrl: null,
      showPhotoDetail: false,
      selectedPhotoForEdit: null,
      showDeleteConfirmation: false,
      selectedPhotoForDelete: null
    }
  }

  componentDidMount() {
    if (!this.props.location.state) {
      this.props.history.push('/projects/');
    } else {
      const { id, productType } = this.props.location.state;
      this.getCanvasAndConfigData(id, productType);
    }
  }

  getCanvasAndConfigData = async(id, productType) => {
    await this.getCanvasDetail(id, productType);
    this.getCanvasConfigurations();
  }

  getCanvasDetail = async(id, productType) => {
    const idToken = await TokenHelper.getUserToken();
    const data = {
      token: idToken,
      product_id: id,
      product_type: productType
    };
    const response = await ApiCustomer.getCustomerProductDetail(data);
    if (response.data.success) {
      this.setState({
        canvas: response.data.data
      });
    } else {
      this.setState({
        showError: true,
        isLoading: false,
        errorMessage: 'Ocurrió un error, intenta más tarde.'
      });
    }

  }

  handleSelectImage = (image) => {
    const { selectedImages } = this.state;
    if (selectedImages.includes(image)) {
      this.setState({ selectedImages: selectedImages.filter(item => !(item.id === image.id)) });
    } else {
      this.setState({ selectedImages: selectedImages.concat(image) });
    }
  }

  handleShowEditCanvasName = () => {
    this.setState({ showEditCanvasName: true });
  }

  handleEditCanvasName = () => {
    this.setState({ showEditCanvasName: false });
    this.getCanvasDetail(this.state.canvas.id, 'picture');
  }

  handleError = (message) => {
    this.setState({
      isLoading: false,
      showError: true,
      errorMessage: message,
      showPhotoDetail: false,
      showEditCanvasName: false,
      showEditImage: false
    });
  }

  cancelEditCanvasName = () => {
    this.setState({ showEditCanvasName: false });
  }

  selectImageForEdit = (image) => {
    this.setState({
      showEditImage: true,
      selectedImageForEdit: image
    })
  }

  handleCloseImageForEdit = (editedImage) => {
    if (editedImage) {
      this.setState({
        showEditImage: false,
        isLoading: true
      });
    }
    this.setState({ showEditImage: false });
  }

  handleCropImageSuccess = () => {
    const { id, productType } = this.props.location.state;
    this.getCanvasAndConfigData(id, productType);
  }

  // handleCropImageSuccess = (editedPhoto) => {
  //   const { canvas, selectedImageForEdit } = this.state;
  //   const updatedPhotos = canvas.photos.map(photo => {
  //     if (photo === selectedImageForEdit) {
  //       return editedPhoto;
  //     }
  //     return photo;
  //   })
  //   this.setState({
  //     isLoading: false,
  //     canvas: {
  //       ...canvas,
  //       photos: updatedPhotos
  //     }
  //   })
  // }

  handleAddImageClick = () => {
    this.props.toggleIsAddingPhotosAction(true);
    this.props.selectCanvasProject(this.state.canvas);
    this.props.changeProjectTypeAction('picture');
    this.setState({ showAddImagesSource: true });
  }

  cancelCreateProjectConfirmation = () => {
    this.setState({ showAddImagesSource: false });
  }


  handleDeleteImages = (selectedImages) => {
    this.setState({ isLoading: true })
    const { canvas } = this.state;
    const canvasProjectId = canvas.id;
    const selectedImagesIds = selectedImages.map(item => item.id);
    TokenHelper.getUserToken().then(idToken => {
      ApiCustomer.deleteCanvasImage({
        token: idToken,
        picture_project_id: canvasProjectId,
        photos_ids: selectedImagesIds
      }).then(response => {
        if (response.data.success) {
          this.updateCanvasConfigurations(canvasProjectId, 'delete', selectedImages.length);
        } else {
          this.setState({
            showError: true,
            errorMessage: 'Ocurrió un error, intenta más tarde.',
            isLoading: false
          });
        }
      }).catch(error => {
        this.setState({
          showError: true,
          errorMessage: 'Ocurrió un error, intenta más tarde.',
          isLoading: false
        });
      })
    }).catch(error => {
      this.setState({
        showError: true,
        errorMessage: 'Ocurrió un error, intenta más tarde.',
        isLoading: false
      });
    })
  }

  updateCanvasConfigurations = async(canvasProjectId, deleteOrDuplicate, photosQuantity) => {
    const sizeConfigurations = this.canvasConfigValidaton(deleteOrDuplicate, photosQuantity);
    let configurations = [sizeConfigurations];
    if (this.state.canvas.configurations.length > 1) {
      configurations.push(this.state.canvas.configurations[1]);
    }
    const idToken = await TokenHelper.getUserToken();
    const data = {
      token: idToken,
      picture_project_id: canvasProjectId,
      configurations: configurations
    }
    const response = await ApiCustomer.updateCanvasSettings(data);
    if (response.data.success) {
      let frames = this.state.canvasConfigurations.filter(item => item.type === 'frame')
      frames.push(sizeConfigurations);
      this.setState({
        canvas: response.data.data.result,
        frames: frames,
        isLoading: false
      });
    } else {
      this.setState({
        showError: true,
        errorMessage: 'Ocurrió un error, intenta más tarde.',
        isLoading: false
      });
    }
  }

  addImagesToCanvas = async(id, isClone, photos) => {
    this.setState({ 
      isLoading: true,
      showPhotoDetail: false
    });
    const idToken = await TokenHelper.getUserToken();
    const data = {
      token: idToken,
      picture_project_id: id,
      is_clone: isClone,
      photos: photos
    };
    const response = await ApiCustomer.addCanvasImage(data);
    if (response.data.success) {
      this.updateCanvasConfigurations(this.state.canvas.id, 'duplicate', photos.length);
    } else {
      this.setState({
        showError: true,
        errorMessage: 'Ocurrió un error, intenta más tarde.',
        isLoading: false
      });
    }
  }

  handleAddToCart = () => {
    const reducer = (accumulator, currentValue) => accumulator + currentValue;
    const totalPrice = this.props.cartProducts.map(item => item.price).reduce(reducer, 0) + this.state.canvas.price;
    if (totalPrice > 10000) {
      this.setState({
        showError: true,
        errorMessage: 'No es posible agregar más de $10,000 al carrito.'
      });
    } else {
      this.addCanvasToCart();
    }
  }


  addCanvasToCart = () => {
    this.setState({isLoading: true});
    TokenHelper.getUserToken().then(idToken => {
      ApiCustomer.addProductToCart({
        token: idToken,
        data: [this.state.canvas],
        product_type: 'picture'
      }).then(response => {
        if (response.data.success) {
          this.props.history.push('/cart');
        } else {
          this.setState({
            isLoading: false,
            showError: true,
            errorMessage: 'Ocurrió un error, intenta más tarde.'
          });
        }
      }).catch(error => {
        this.setState({
          isLoading: false,
          showError: true,
          errorMessage: 'Ocurrió un error, intenta más tarde.'
        });
      })
    }).catch(error => {
      this.setState({
        isLoading: false,
        showError: true,
        errorMessage: 'Ocurrió un error, intenta más tarde.'
      });
    })
  }

  handleMouseEnter = (image) => {
    this.setState({ mouseOverImage: image })
  }

  okErrorHandler = () => {
    this.setState({
      showError: false,
      errorMessage: ''
    })
  }

  getCanvasConfigurations = async() => {
    const idToken = await TokenHelper.getUserToken();
    const response = await ApiCustomer.getProductConfigurations({token: idToken, product_type: 'picture'});
    if (response.data.success) {      
      this.setState({ 
        canvasConfigurations: response.data.data
      });
    } else {
      this.setState({
        isLoading: false,
        showError: true,
        errorMessage: 'Hubo un error, por favor intenta más tarde.'
      });
    }
    let frames = response.data.data.filter(item => item.type === 'frame')
    frames.push(this.validateSizeConfigurations());
    this.setState({
      frames: frames,
      isLoading: false
    });
  }


  canvasConfigValidaton = (deleteOrDuplicate, photosQuantity) => {
    const { selectedImages, canvas, canvasConfigurations } = this.state;
    if (deleteOrDuplicate === 'delete') {
      if (canvas.photos.length - photosQuantity === 1) {
        return canvasConfigurations.find(element => (element.items_for_standard_cost === 1 && element.type === 'size'))
      } else if (canvas.photos.length - photosQuantity === 2) {
        return canvasConfigurations.find(element => (element.items_for_standard_cost === 2 && element.type === 'size'))
      } else if (canvas.photos.length - photosQuantity >= 3) {
        return canvasConfigurations.find(element => (element.items_for_standard_cost === 3 && element.type === 'size'))
      }
    } else {
      if (canvas.photos.length + photosQuantity === 2) {
        return canvasConfigurations.find(element => (element.items_for_standard_cost === 2 && element.type === 'size'))
      } else if (canvas.photos.length + photosQuantity >= 3) {
        return canvasConfigurations.find(element => (element.items_for_standard_cost === 3 && element.type === 'size'))
      }
    }
  }

  /////////////////////

  handleSelectFrame = (selectedFrameOption) => {
    this.setState({
      isLoading: true
    });
    this.updateFrame(selectedFrameOption);
  }

  selectPhotoHandler = (photo, source) => {
    if (source === 'quotes') {
      return;
    } else {
      this.setState({ 
        showPhotoDetail: true,
        selectedPhotoForEdit: photo
      });
    }
  }

  handleClosePhotoDetail = (crop) => {
    if (crop) {
      this.setState({
        showPhotoDetail: false,
        isLoading: true
      });
    } else {
      this.setState({showPhotoDetail: false});
    }
  }

  updateFrame = async(selectedFrameOption) => {
    const { canvas, frames } = this.state;
    const idToken = await TokenHelper.getUserToken();
    const sizeConfigurations = frames[2];
    const data = selectedFrameOption === frames[2] ? {
      token: idToken,
      picture_project_id: canvas.id,
      configurations: [selectedFrameOption]
    } : {
      token: idToken,
      picture_project_id: canvas.id,
      configurations: [sizeConfigurations, selectedFrameOption]
    }
    const response = await ApiCustomer.updateCanvasSettings(data);
    if (response.data.success) {
      this.setState({
        canvas: response.data.data.result,
        isLoading: false
      })
    } else {
      this.setState({
        isLoading: false,
        showError: true,
        errorMessage: 'Hubo un error, por favor intenta más tarde.'
      });
    }

  }

  validateSizeConfigurations = () => {
    const { canvasConfigurations, canvas } = this.state;
    if (canvas.photos.length === 1) {
      return canvasConfigurations.find(element => (element.items_for_standard_cost === 1 && element.type === 'size'))
    } else if (canvas.photos.length === 2) {
      return canvasConfigurations.find(element => (element.items_for_standard_cost === 2 && element.type === 'size'))
    } else if (canvas.photos.length >= 3) {
      return canvasConfigurations.find(element => (element.items_for_standard_cost === 3 && element.type === 'size'))
    }
  }

  showDeleteConfirmationHandler = (selectedPhotoForEdit) => {
    this.setState({
      showDeleteConfirmation: true,
      selectedPhotoForDelete: selectedPhotoForEdit
    });
  }

  cancelDeleteConfirmationHandler = () => {
    this.setState({showDeleteConfirmation: false});
  }

  okDeleteConfirmationHandler = () => {
    this.handleDeleteImages([this.state.selectedPhotoForDelete]);
    this.setState({showDeleteConfirmation: false});
  }


  render() {
    const { 
      canvas, 
      isLoading, 
      selectedImages, 
      showEditCanvasName, 
      showEditImage, 
      selectedImageForEdit, 
      showAddImagesSource, 
      mouseOverImage, 
      showError, 
      errorMessage, 
      showPhotoDetail,
      selectedPhotoForEdit,
      canvasConfigurations,
      frames,
      showDeleteConfirmation
    } = this.state;
    const photos = canvas && canvas.photos.concat('addPhoto');
    return (
      isLoading ? <Loading /> :
        <div>
          {
            showError &&
            <Modal>
              <AlertView
                message={errorMessage}
                okHandler={this.okErrorHandler}
              />
            </Modal>
          }
          {
            showEditCanvasName &&
            <Modal>
              <EditProductName
                productType={'picture'}
                name={canvas.name}
                id={canvas.id}
                handleEditCanvasName={this.handleEditCanvasName}
                cancelEditProductName={this.cancelEditCanvasName}
                handleError={this.handleError}
              />
            </Modal>
          }
          {
            showAddImagesSource &&
            <Modal>
              <CreateProject cancelHandler={this.cancelCreateProjectConfirmation} />
            </Modal>
          }
          {
          showPhotoDetail &&
            <Modal>
              <PhotoDetailCanvas
                photos={photos}
                selectedPhotoForEdit={selectedPhotoForEdit}
                handleClosePhotoDetail={this.handleClosePhotoDetail}
                canvasId={canvas.id}
                canvasSelectedFrame={canvas.frame_url}
                addImagesToCanvas={this.addImagesToCanvas}
                handleDeleteImages={this.handleDeleteImages}
                handleCropImageError={this.handleError}
                handleCropImageSuccess={this.handleCropImageSuccess}
                showDeleteConfirmationHandler={this.showDeleteConfirmationHandler}
                userId={this.props.firebase.auth.uid}
              />
            </Modal>
          }
          {
            showDeleteConfirmation &&
            <Modal>
              <AlertView
                title={'Pixyalbum'}
                message={'¿Deseas eliminar la foto seleccionada?'}
                okHandler={this.okDeleteConfirmationHandler}
                cancelHandler={this.cancelDeleteConfirmationHandler}
                cancel={true}
              />
            </Modal>
          }

          <div className="canvas_header d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center">
              <img src={canvas_icon} height="20" alt="" />
              <p className="gotham-bold-15 ml-3">Pixycuadros</p>
            </div>
            <div className="canvas_header-right d-flex justify-content-end">
              <button className="generic-button-blue d-flex align-items-center justify-content-center cursor-pointer" onClick={this.handleAddToCart}>
                <p className="gotham-md-white-15">Agregar al Carrito</p>
              </button>
            </div>
          </div>

          <div className="canvas_main-content">
            <div className="canvas_frame-select d-flex justify-content-center align-items-center">
              <div className="canvas_frame-select-item d-flex flex-column justify-content-center align-items-center cursor-pointer" onClick={() => this.handleSelectFrame(frames[2])}>
                {canvas.frame_url === frames[2].frame_url && <img className="canvas_check-img-noframe" src={check_icon} height="15%" alt=""/>}
                <img src={frames[2].frame_url} height="auto" width="100%"  alt=""/>
                <p className={canvas.frame_url === frames[2].frame_url ? "canvas_frame-description-selected" : "canvas_frame-description"}>{frames[2].description}</p>
              </div>
              <div className="canvas_frame-select-item d-flex flex-column justify-content-center align-items-center cursor-pointer" onClick={() => this.handleSelectFrame(frames[0])}>
                {canvas.frame_url === frames[0].frame_url && <img className="canvas_check-img" src={check_icon} height="15%" alt=""/>}
                <img src={frames[0].frame_url} height="auto" width="100%" alt=""/>
                <p className={canvas.frame_url === frames[0].frame_url ? "canvas_frame-description-selected" : "canvas_frame-description"}>{frames[0].description}</p>
              </div>
              <div className="canvas_frame-select-item mx-3 d-flex flex-column justify-content-center align-items-center cursor-pointer" onClick={() => this.handleSelectFrame(frames[1])}>
                {canvas.frame_url === frames[1].frame_url && <img className="canvas_check-img" src={check_icon} height="15%" alt=""/>}
                <img src={frames[1].frame_url} height="auto" width="100%"  alt=""/>
                <p className={canvas.frame_url === frames[1].frame_url ? "canvas_frame-description-selected" : "canvas_frame-description"}>{frames[1].description}</p>
              </div>
            </div>

            <div className="canvas_photos-container">
              <div className="pt-1 pr-5 d-flex justify-content-end">
                <p className="gotham-md-gray-18">Total: MXN ${canvas.price}</p>
              </div>
              <div className="canvas_photos d-flex justify-content-center">
                <div className="canvas_photos-inside d-flex flex-wrap">
                  {photos.map((item, i) => (
                    <CanvasDetailItem
                      key={i}
                      photoData={item}
                      handleSelectImage={this.handleSelectImage}
                      isImageSelected={selectedImages.includes(item)}
                      selectImageForEdit={this.selectImageForEdit}
                      handleAddImageClick={this.handleAddImageClick}
                      handleMouseEnter={this.handleMouseEnter}
                      mouseOverImage={mouseOverImage}

                      selectPhotoHandler={this.selectPhotoHandler}
                      canvas={canvas}
                      frames={frames}
                    />
                  ))}
                </div>
              </div>
            </div>
            <Footer  history={this.props.history}/>
          </div>
        </div>
    );
  }
}

const mapStateToProps = state => ({
  cartProducts: state.collections.cartProducts,
  firebase: state.firebase
});

const mapDispatchToProps = {
  changeProjectTypeAction: changeProjectTypeAction,
  toggleIsAddingPhotosAction: toggleIsAddingPhotosAction,
  selectCanvasProject: selectCanvasProject
};


export default compose(connect(mapStateToProps, mapDispatchToProps))(CanvasDetail);