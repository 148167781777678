import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { updateUserDataAction } from '../../actions/CustomerActions';
import { showErrorAction } from '../../actions/GlobalActions';
import * as firebase from 'firebase';
import logo_gray from '../../images/logo-gray.png';
import './UpdateEmail.scss';


class UpdateEmail extends Component {

  constructor(props) {
    super(props);
    this.state = {
      newEmail: '',
      currentPassword: ''
    }
  }

  handleInput = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  handleUpdateEmail = (newEmail) => {
    firebase.auth()
      .signInWithEmailAndPassword(this.props.customerEmail, this.state.currentPassword)
      .then(userCredentials => userCredentials.user.updateEmail(newEmail))
      .then(() => this.props.updateUserDataAction(null, newEmail, null, null))
      .then(() => this.props.closeUpdateEmail())
      .catch(() => {
        this.props.closeUpdateEmail();
        this.props.showErrorAction('Ocurrió un error, por favor intenta más tarde');
      });
  }

  render() {
    return (
      <div className="update-email_container d-flex justify-content-center align-items-center">
        <div className="update-email_container-inside d-flex flex-column justify-content-center align-items-center">
          <img src={logo_gray} alt="" height="70" />
          <p className="futura-md-15">Correo actual: {this.props.customerEmail}</p>
          <input type="email" name="newEmail" placeholder="Nuevo correo" value={this.state.newEmail} onChange={this.handleInput} />
          <input type="password" name="currentPassword" placeholder="Tu contraseña" value={this.state.currentPassword} onChange={this.handleInput} />
          <div className="update-email_buttons-container mt-3 d-flex justify-content-around">
            <button className="update-email_cancel-button" onClick={this.props.closeUpdateEmail}>Cancelar</button>
            <button className="update-email_ok-button" onClick={() => this.handleUpdateEmail(this.state.newEmail)}>Cambiar correo</button>
          </div>
        </div>
      </div>
    );
  }
}



const mapStateToProps = state => ({
  customerName: state.collections.customerName,
  customerEmail: state.collections.customerEmail,
  customerProfileImage: state.collections.customerProfileImage
});

const mapDispatchToProps = {
  updateUserDataAction: updateUserDataAction,
  showErrorAction: showErrorAction
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(UpdateEmail);