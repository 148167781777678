import React from 'react';
import './CoverCategories.scss';

const CoverCategoryCard = (props) => {
  return (
    <div className="from-album-item_main d-flex justify-content-center align-items-center">
      <div className="selectquotes-item_cathegory d-flex flex-column justify-content-center align-items-center cursor-pointer" onClick={() => props.handleSelectCategory(props.item.id)}>
        <img className="selectquotes-item_img" src={props.image} alt="" />
        <div className="selectquotes-item_cat-name d-flex justify-content-center align-items-center">
          <p className="gotham-md-lightgray-15">{props.name}</p>
        </div>
      </div>
    </div>
  );
}

export default CoverCategoryCard;