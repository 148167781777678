import React from 'react';
import added_card from '../../images/newDesignImages/added-card.png';
import card_img from '../../images/newDesignImages/exchange-card-img.png';

import './GiftCards.scss'

function ExchangedGiftcard(props) {
  return (
    <div className="giftcard-exchange_container d-flex flex-column justify-content-center">
      <div className="mx-5 d-flex">
        <div className="giftcard-exchanged_left d-flex align-items-center justify-content-center">
          <img src={card_img} alt="" height="200" />
        </div>
        <div className="giftcard-exchanged_right d-flex flex-column align-items-center justify-content-center">
          <img src={added_card} alr="" height="50" />
          <p className="futura-bold-blue-25 py-2">Tarjeta abonada</p>
          <p className="futura-md-18">Se abonaron $ {props.exchangedCodeQuantity} Pixypesos</p>
          <p className="futura-md-18">a tu cuenta que podrás usar en tu</p>
          <p className="futura-md-18">próxima compra</p>
        </div>
      </div>
      <div className="checkout_buy-button my-5 d-flex justify-content-center align-items-start">
        <button className="futura-bold-white-18" onClick={() => props.history.push('/wallet')}>Ver saldo en Pixypesos</button>
      </div>
    </div>
  );
}

export default ExchangedGiftcard;