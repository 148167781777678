import React from 'react';
import DragAndDropLoading from './DragAndDropLoading';

import img_icon from '../../images/newDesignImages/img-icon.png';
import close_img from '../../images/newDesignImages/close-gray.png';
import './DragAndDropItem.scss';

export default function PhotoItem(props) {
  const { item } = props;
  return (
    <div className="dragndrop-item_main py-3 px-1 d-flex align-items-center justify-content-between">
      <div className="d-flex align-items-center ">
        <img className="dragndrop-item_show-img" src={item.imageToShow} alt="" />
        <p className="gotham-md-gray-15 ml-2">{item.name}</p>
      </div>
      <div>
        {item.progress > 0 ?
          <DragAndDropLoading
            progress={item.progress}
            size={25}
            strokeWidth={3}
            circleOneStroke={'#BDBDBD'}
            circleTwoStroke={'#51B7F3'}
            fromLoadingItem
          /> :
          <div className="cursor-pointer" onClick={() => props.handleRemovePhotoForUpload(item.index)}>
            <img src={close_img} height="18" alt="" />
          </div>
        }
      </div>
      
    </div>
  );
}