import React from 'react';

import img_logo_gray from '../../images/logogris-28.png';
import img_logo_gray_text from '../../images/logogris-27.png';
import edit_circle from '../../images/newDesignImages/edit-circle.png';
import check_dark from '../../images/newDesignImages/check-dark.png';
import edit_album from '../../images/newDesignImages/edit-white.png';
import trash_img from '../../images/newDesignImages/trash-img.png';
import add_photos from '../../images/newDesignImages/add-photos.png';

import './CoverHeader.scss';


function CoverHeader(props) {
  const { cover, changeCoverCallback, collectionSelected, selectedPhotosForDelete, addPhotosHandler, showDeleteConfirmationHandler, editCoverHandler, selectedAlbumConfiguration } = props;
  const selectedAlbumNumPages = parseInt(selectedAlbumConfiguration.configurations[1].description);
  return (
    <React.Fragment>
      <div className="col-12 mb-5">
        <div className="container">
          <div className="row justify-content-center">

            <div className="col-12 col-md-12 col-lg-8 col-xl-8">

              {cover.image_url.length > 0 ? (
                <div className="row d-flex justify-content-center">
                  <div className="col-5 col-md-4 d-flex align-items-end justify-content-center p-0 shadow">
                    <img src={img_logo_gray_text} className="d-flex mt-2 img-logo" alt="img" />
                  </div>

                  <div className="border bg-book shadow-sm">
                    <div className="d-flex justify-content-center">
                      <img src={img_logo_gray} className=" mt-2 d-flex vertical-logo" width="12" height="10" alt="img" />
                    </div>
                    <div className="spine-portada">
                      <div className="vertical-spine-text">
                        <p>{collectionSelected.name}</p>
                      </div>
                    </div>
                  </div>

                  <div className="col-5 col-md-4 coverheader_page-right p-0 shadow">
                    { /*cover.image_url &&
                      <img className="coverheader_cover-img cursor-pointer" onClick={() => editCoverHandler(cover.index)} src={edit_circle} height="60" alt="" />
                    */}
                    <div className="row mx-0">
                      <div className="col p-0 m-0">
                        <div className="square-page-cover">
                          <div className="square-page-content-cover">
                            <img src={cover.image_url} className="fluid-contain-cover p-2" alt="img" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                  <div className="row d-flex justify-content-center">
                    <div className="border bg-book shadow-sm">
                      <img src={cover.predesign_cover.thumbnail_url} height="250" alt="cover" />
                    </div>
                  </div>
                )}

            </div>
            <div className="col-6 d-flex justify-content-end align-items-center">
              <img src={check_dark} height="18" alt="" />
              <p className="gotham-book-13 ml-2">{collectionSelected.pages.length}/{selectedAlbumNumPages} páginas</p>
            </div>
            <div className="col-12 d-flex justify-content-center">
              {cover.image_url &&
                <button className="generic-button-white mr-2" onClick={() => editCoverHandler(cover.index)}>
                  Editar Portada
                </button>
              }
              <button className="generic-button-white ml-2" onClick={() => changeCoverCallback(cover.index)}>
                Cambiar Portada
              </button>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );

}

export default CoverHeader;