import React from 'react';

const AlertViewBar = (props) => (
  <div className="mx-auto my-auto d-flex modal-dialog alert-card-bar">
    <div className="modal-content alert-card d-flex">
      <div className="modal-header p-2 mb-2 bg-bar text-dark d-flex align-items-center ">
        <div className="d-flex flex-fill justify-content-center align-items-center">
          <h5 className=" m-0">{props.title}</h5>
        </div>
        <div className="d-flex">
          <i className="fa fa-times text-dark cursor-pointer" onClick={() => props.cancelHandler()}></i>
        </div>
      </div>
      <div className="modal-body d-flex">
        {props.message}
      </div>
      {(props.isButtonContinue)? 
        <div className="modal-footer p-2 d-flex float-right">
          <div className="btn-pixyalbum-primary d-flex" onClick={() => props.okHandler()}>Continuar</div>
        </div>:null }
    </div>
  </div>
);

export default AlertViewBar;