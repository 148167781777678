import {
    SHOW_ERROR,
    HIDE_ERROR,
    START_LOADING_INFO,
    FINISH_LOADING_INFO,
    CREATING_USER,
    UPDATE_LOADING_INFO,
    SET_ALBUM_CONFIGURATIONS
} from '../actions/ActionTypes';

const initialState = {
    isLoading: false,
    showingError: false,
    errorMessage: '',
    creatingUser: false,
    loadingMessage: '',
    albumConfigurations: null
}

function globalReducer(state = initialState, action) {
    switch (action.type) {
        case START_LOADING_INFO:
            return {
                ...state,
                isLoading: action.payload
            };
        case FINISH_LOADING_INFO:
            return {
                ...state,
                isLoading: action.payload
            };
        case SHOW_ERROR:
            return {
                ...state,
                errorMessage: action.payload,
                showingError: true
            };
        case HIDE_ERROR:
            return {
                ...state,
                errorMessage: '',
                showingError: false
            };
        case CREATING_USER:
            return {
                ...state,
                creatingUser: action.payload
            };
        case UPDATE_LOADING_INFO:
            return {
                ...state,
                isLoading: true,
                loadingMessage: action.payload
            };
        case SET_ALBUM_CONFIGURATIONS:
            return {
                ...state,
                albumConfigurations: action.payload
            }
        default:
            return state;
    }
}

export default globalReducer;