import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { loadUserAddressesAction, reloadUserAddressesAction, deleteCustomerAddressAction } from '../../actions/CustomerActions';
import { selectShippingAddressAction } from '../../actions/CheckoutActions';

import CustomerAddressItem from '../../components/CustomerAddressItem';
import Loading from '../Loading';
import add_img from '../../images/newDesignImages/add-icon-gray.png';
import './SelectShippingAddress.scss';

class SelectShippingAddress extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedAddress: null
    }
    this.handleDeleteAddress = this.handleDeleteAddress.bind(this);
  }

  componentDidMount() {
    this.props.reloadUserAddressesAction(true);
    this.props.loadUserAddressesAction();
  }

  componentDidUpdate() {
    if (this.props.reloadUserAddresses) {
      this.setState({ selectedAddress: null });
      this.props.reloadUserAddressesAction(false);
      this.props.loadUserAddressesAction();
    }
  }

  selectAddress = address => {
    this.setState({ selectedAddress: address });
    this.props.selectShippingAddressAction(address);
  }

  handleDeleteAddress(addressId) {
    this.props.deleteCustomerAddressAction(addressId)
  }

  render() {
    const { loadingAddresses, userAddresses, isViewMyAccount, handleSelectAddress } = this.props;
    const { selectedAddress } = this.state;
    return (
      isViewMyAccount ? 
        <div>
          {
            loadingAddresses ?
              <div className="d-flex my-5 justify-content-center align-items-center">
                <Loading title=" " />
              </div>
              :
              userAddresses.map((address, index) => {
                let selected = false;
                if (selectedAddress && selectedAddress.id === address.id) {
                  selected = true;
                }
                return React.createElement(
                  CustomerAddressItem, {
                    key: index,
                    address: address,
                    selected: selected,
                    selectAddressCallback: this.selectAddress,
                    isViewMyAccount: isViewMyAccount,
                    handleDeleteAddress: this.handleDeleteAddress
                })
              })
          }
        </div> :
        <div className="d-flex flex-column align-items-center">
          {userAddresses.map((address, i) => (
            <CustomerAddressItem
              key={i}
              address={address}
              handleSelectAddress={handleSelectAddress}
              defaultAddress={this.props.defaultAddress}
              defaultAddressId={userAddresses[0].id}
            />
          ))}
          <button className="select-address_add mt-2 d-flex justify-content-center align-items-center" onClick={this.props.handleShowCreateAddress}>
            <img src={add_img} height="30" alt=""/>
            <p className="gotham-bold-gray-18 ml-3">Ingresa una nueva dirección</p>
          </button>
        </div>
    )
  }
}

const mapStateToProps = state => ({
  loadingAddresses: state.collections.loadingAddresses,
  userAddresses: state.collections.userAddresses,
  reloadUserAddresses: state.collections.reloadUserAddresses
})

const mapDispatchToProps = {
  loadUserAddressesAction: loadUserAddressesAction,
  selectShippingAddressAction: selectShippingAddressAction,
  reloadUserAddressesAction: reloadUserAddressesAction,
  deleteCustomerAddressAction: deleteCustomerAddressAction
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(SelectShippingAddress);