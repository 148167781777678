import React, { useState, useEffect, useRef } from 'react';
import './DragAndDropLoading.scss';



export default function DragAndDropLoading(props) {
  const { 
    size, 
    progress, 
    strokeWidth, 
    circleOneStroke, 
    circleTwoStroke,
    fromLoadingItem,
  } = props;
  const center = size / 2;
  const radius = size / 2 - strokeWidth / 2;
  const circumference = 2 * Math.PI * radius;
  const textPositionY = center + 6;
  const rotate = `rotate(-90 ${center} ${center})`;
  const [offset, setOffset] = useState(0);
  const circleRef = useRef(null);
  useEffect(() => {
    const progressOffset = ((100 - progress) / 100) * circumference;
    setOffset(progressOffset);
  }, [setOffset, circumference, progress, offset]);
  return (
    <svg className={fromLoadingItem ? null : "svg"} width={size} height={size}>
      <circle
        className="svg-circle-bg"
        stroke={circleOneStroke}
        cx={center}
        cy={center}
        r={radius}
        strokeWidth={strokeWidth}
      />
      <circle
        className="svg-circle"
        stroke={circleTwoStroke}
        cx={center}
        cy={center}
        r={radius}
        ref={circleRef}
        strokeWidth={strokeWidth}
        strokeDasharray={circumference}
        strokeDashoffset={offset}
        transform={rotate}
      />
      {!fromLoadingItem &&
        <text className="svg-circle-text" x={center}  y={textPositionY}>
          {progress}%
        </text>
      }
    </svg>
  );
}