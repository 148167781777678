import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { getCartProductsQuantityAction } from '../../actions/CustomerActions';
import ApiCustomer from '../../utils/Api/ApiCustomer';
import TokenHelper from '../../utils/Firebase/TokenHelper';
import Loading from '../Loading';
import Footer from '../Footer';
import Modal from '../Modal';
import AlertView from '../AlertView';
import SuccessPopup from '../SuccessPopup';
import './AccessoryDetail.scss';

class AccessoryDetail extends Component {

  constructor(props) {
    super(props);
    this.state = {
      accessory: null,
      isLoading: true,
      errorMessage: '',
      showAddToCartSuccess: false
    }
  }

  componentDidMount() {
    const { accessory } = this.props.location;
    if (accessory) {
      this.setState({
        accessory,
        isLoading: false
      });
    } else {
      this.props.history.replace('/accessories');
    }
  }

  handleAddToCart = async(id) => {
    this.setState({isLoading: true});
    try {
      const idToken = await TokenHelper.getUserToken();
      const addToCartData = {
        token: idToken, 
        product_type: "item",
        data: [id]
      }
      const response = await ApiCustomer.addProductToCart(addToCartData);
      await this.props.getCartProductsQuantityAction();
      if (response.data.success) {
        this.setState({
          isLoading: false,
          showAddToCartSuccess: true
        })
      } else {
        this.setState({
          isLoading: false,
          showError: true,
          errorMessage: 'Ha ocurrido un error, por favor intenta mas tarde.'
        });
      }
    } catch(e) {
      this.setState({
        isLoading: false,
        showError: true,
        errorMessage: 'Ha ocurrido un error, por favor intenta mas tarde.'
      });
    }
  }

  okErrorHandler = () => {
    this.setState({
      showError: false,
      errorMessage: ''
    });
  }

  handleCloseSuccessPopup = () => {
    this.setState({showAddToCartSuccess: false});
    this.props.history.push('/accessories');
  }

  render() {
    const { accessory, isLoading, showError, errorMessage, showAddToCartSuccess } = this.state;
    return (        
      isLoading ? <Loading/> :
        <div className="accessory-detail_main">
          {
            showError &&
              <Modal>
                <AlertView
                  message={errorMessage}
                  okHandler={this.okErrorHandler}
                />
              </Modal>
          }
          {
            showAddToCartSuccess &&
            <Modal>
              <SuccessPopup
                handleCloseSuccessPopup={this.handleCloseSuccessPopup}
                textMessage={'¡Se agregó al carrito!'}
                history={this.props.history}
              />
            </Modal>
          }
          <div className="row">
            <div className="col-8 accessory-detail_left py-5 d-flex flex-wrap">
              {accessory.images.map((imageObject, i) => (
                <div key={i} className="accessory-detail_img-container d-flex justify-content-center align-items-center">
                  <img className="accessory-detail_img" src={imageObject.url} alt="" />
                </div>
              ))}
            </div>
            <div className="col-4 product-info_right d-flex flex-column justify-content-center">
              <div className="d-flex flex-column">
                <div className="product-info_title mb-3">
                  <p className="gotham-md-gray-25">{accessory.name}</p>
                </div>
                <hr className="product-info_divition-line"></hr>
                <div className="my-5">
                  <p className="product-info_description">{accessory.description}</p>
                </div>
                <button className="generic-button-blue my-4 d-flex justify-content-center align-items-center cursor-pointer" onClick={() => this.handleAddToCart(accessory.id)}>
                  <p className="gotham-md-white-15">Agregar al carrito</p>
                </button>
              </div>
            </div>
          </div>
          <Footer history={this.props.history}/>
        </div>
    );
  }
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = {
  getCartProductsQuantityAction: getCartProductsQuantityAction
};


export default compose(connect(mapStateToProps, mapDispatchToProps))(AccessoryDetail);



// export default AccessoryDetail;