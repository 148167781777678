import React, { Component } from 'react';
import Loading from '../Loading';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { changeAlbumCoverAction, toggleShowChangeCoverAction } from '../../actions/CustomerActions';
import firebase from 'firebase/app';
import "firebase/app";
import "firebase/storage";

import back_arrow from '../../images/newDesignImages/slider-left.png';
import close_circular_img from '../../images/newDesignImages/close-white-img.png';
import colors_img from '../../images/newDesignImages/colors.png';
import circle_white from '../../images/newDesignImages/circle-white.png';
import circle_black from '../../images/newDesignImages/circle-black.png';
import circle_gray from '../../images/newDesignImages/circle-gray.png';
import align_img from '../../images/newDesignImages/aligned-img.png';
import top_active from '../../images/newDesignImages/top-active.png';
import top_inactive from '../../images/newDesignImages/top-inactive.png';
import bottom_active from '../../images/newDesignImages/bottom-active.png';
import bottom_inactive from '../../images/newDesignImages/bottom-inactive.png';
import fonts_img from '../../images/newDesignImages/fonts-img.png';
import dropdown_img from '../../images/newDesignImages/dropdown-arrow.png';
import './AddTitleToCover.scss';


class AddTitleToCover extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collectionSelected: [],
      selectedCoverForEdit: '',
      selectedCoverIndexForEdit: '',
      isLoading: false,
      imageText: '',
      positionFromTop: 80,
      hiddenImagePositionFromTop: 0,
      colorText: 'white',
      font: '50px AvantGarde',
      hiddenImageFont: '',
      showEditFont: false
    }
  }

  componentDidMount() {
    if (!this.props.addTitleToCoverData) {
      this.props.history.push('/collection/albums/detail')
    } else {
      const fontSize = this.props.addTitleToCoverData.croppedArea.height / 12;
      this.setState({
        collectionSelected: this.props.addTitleToCoverData.collectionSelected,
        selectedCoverForEdit: window.URL.createObjectURL(this.props.addTitleToCoverData.selectedCoverForEdit),
        selectedCoverIndexForEdit: this.props.addTitleToCoverData.selectedCoverIndexForEdit,
        hiddenImagePositionFromTop: this.props.addTitleToCoverData.croppedArea.height / 8,
        hiddenImageFont: `${fontSize}px AvantGarde`
      });
      const canvas = this.refs.canvas
      const ctx = canvas.getContext("2d")
      const img = this.refs.image
      img.onload = () => {
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height)
      }

      const canvasHidden = this.refs.canvasHidden;
      const ctxHidden = canvasHidden.getContext('2d');
      const imgHidden = this.refs.imageHidden;
      imgHidden.onload = () => {
        ctxHidden.drawImage(imgHidden, 0, 0, canvasHidden.width, canvasHidden.height)
      }
    }
  }

  componentDidUpdate() {
    if (this.refs.canvas) {
      const canvas = this.refs.canvas;
      const ctx = canvas.getContext("2d");
      const img = this.refs.image;
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
      ctx.font = this.state.font;
      ctx.fillStyle = this.state.colorText;
      ctx.textAlign = 'center';
      ctx.fillText(this.state.imageText, 300, this.state.positionFromTop);

      const canvasHidden = this.refs.canvasHidden;
      const ctxHidden = canvasHidden.getContext('2d');
      const imgHidden = this.refs.imageHidden;
      ctxHidden.drawImage(imgHidden, 0, 0, canvasHidden.width, canvasHidden.height);
      ctxHidden.font = this.state.hiddenImageFont;
      ctxHidden.fillStyle = this.state.colorText;
      ctxHidden.textAlign = 'center';
      ctxHidden.fillText(this.state.imageText, canvasHidden.width / 2, this.state.hiddenImagePositionFromTop);
    }
  }

  handleInput = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  handleChangeTextPosition = (position) => {
    if (position === 'top') {
      this.setState({
        positionFromTop: 80,
        hiddenImagePositionFromTop: this.props.addTitleToCoverData.croppedArea.height / 8
      });
    } else {
      this.setState({
        positionFromTop: 545,
        hiddenImagePositionFromTop: 10 * this.props.addTitleToCoverData.croppedArea.height / 11
      });
    }
  }

  handleChangeColor = (color) => {
    if (color === 'white') {
      this.setState({ colorText: 'white' });
    } else if (color === 'gray') {
      this.setState({ colorText: 'rgb(136, 136, 136)' });
    } else {
      this.setState({ colorText: 'rgb(0, 0, 0)' });
    }
  }

  handleChangeFont = (font) => {
    const fontSize = this.props.addTitleToCoverData.croppedArea.height / 12;
    this.handleShowFonts(false);
    if (font === 'Didot') {
      this.setState({
        font: '50px Didot',
        hiddenImageFont: `${fontSize}px Didot`
      });
    } else if (font === 'AvantGarde') {
      this.setState({
        font: '50px AvantGarde',
        hiddenImageFont: `${fontSize}px AvantGarde`
      });
    } else {
      this.setState({
        font: '50px LeahGaviota',
        hiddenImageFont: `${fontSize}px LeahGaviota`
      });
    }
  }

  handleSaveButton = () => {
    const canvas = this.refs.canvasHidden;
    canvas.toBlob(file => this.uploadCoverToGCS(file));
  }

  uploadCoverToGCS = (file) => {
    this.setState({ isLoading: true });

    const updateCover = this.updateCover;
    let r = (Math.random() + 1).toString(36).substring(5);
    const path = this.props.firebase.auth.uid + '/covers/cover_' + Date.now() + "_" + r + '.jpg'
    const storage = firebase.storage();
    const uploadRef = storage.ref().child(path);
    const uploadTask = uploadRef.put(file);
    
    uploadTask.on('state_changed', 
      (snapshot) => {
        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED:
            break;
          case firebase.storage.TaskState.RUNNING:
            break;
        }
      }, 
      (error) => {
        console.log(error)
      }, 
      () => {
        // Handle successful uploads on complete
        const baseUrl = window._env_.GCS_BUCKET_BASE_URL;
        const coverUrl = baseUrl + path;
        updateCover(coverUrl);
      }
    );
  }


  updateCover = (coverUrl) => {
    const { changeAlbumCoverAction } = this.props;
    const changeAlbumCover = new Promise(resolve => resolve(changeAlbumCoverAction(coverUrl, this.state.collectionSelected, this.state.selectedCoverIndexForEdit, '')));
    changeAlbumCover
      .then(() => this.props.toggleShowChangeCoverAction(false))
      .then(() => this.props.handleCloseAddTitle());
  }

  handleShowFonts = (showTrue) => {
    if (showTrue) {
      this.setState({ showEditFont: true });
    } else {
      this.setState({ showEditFont: false });
    }
  }

  render() {
    const { width, height } = this.props.addTitleToCoverData.croppedArea;
    const { colorText, positionFromTop, font, showEditFont, isLoading } = this.state;
    const selectedColorStyles = { border: '2px solid #00baf1', borderRadius: '30px' };
    const parsedFont = font.slice(5);
    return (
      <div className="add-title_container d-flex justify-content-center align-items-center">
        {isLoading ? <Loading alternativeLoading /> :
          <div className="add-title_inner-container">
            <div className="add-title_top-container w-100 px-3 py-2 d-flex justify-content-between align-items-center" onClick={this.props.handleCloseAddTitle}>
              <div className="d-flex align-items-center cursor-pointer">
                <img src={back_arrow} alt="" height="18" />
                <p className="futura-md-white-18 pl-2">Regresar</p>
              </div>
              <img className="cursor-pointer" src={close_circular_img} alt="" onClick={this.props.handleCloseAddTitle} />
            </div>
            <div className="add-title_inner-inside d-flex flex-column justify-content-center align-items-center py-3">
              <div className="add-title_customize d-flex justify-content-between align-items-center">
                <div>
                  <div className="d-flex align-items-center">
                    <img src={colors_img} height="15" alt="" />
                    <p className="fuura-md-13 ml-2">Colores</p>
                  </div>
                  <div className="d-flex">
                    <img className="cursor-pointer" src={circle_black} style={colorText === 'rgb(0, 0, 0)' ? selectedColorStyles : null} height="25" alt="" onClick={() => this.handleChangeColor('black')} />
                    <img className="mx-2 cursor-pointer" style={colorText === 'white' ? selectedColorStyles : null} src={circle_white} height="25" alt="" onClick={() => this.handleChangeColor('white')} />
                    <img className="cursor-pointer" src={circle_gray} style={colorText === 'rgb(136, 136, 136)' ? selectedColorStyles : null} height="25" alt="" onClick={() => this.handleChangeColor('gray')} />
                  </div>
                </div>

                <div>
                  <div className="d-flex align-items-center">
                    <img src={align_img} height="15" alt="" />
                    <p className="fuura-md-13 ml-2">Alineado</p>
                  </div>
                  <div>
                    <img className="mr-2 cursor-pointer" src={positionFromTop === 80 ? top_active : top_inactive} height="25" alt="" onClick={() => this.handleChangeTextPosition('top')} />
                    <img className="cursor-pointer" src={positionFromTop === 545 ? bottom_active : bottom_inactive} height="25" alt="" onClick={() => this.handleChangeTextPosition('bottom')} />
                  </div>
                </div>

                <div>
                  <div className="ml-1 d-flex align-items-center">
                    <img src={fonts_img} height="15" alt="" />
                    <p className="fuura-md-13 ml-2">Fuentes</p>
                  </div>
                  <div className="add-title_fonts pl-4 d-flex justify-content-between align-items-center cursor-pointer" onClick={() => showEditFont ? this.handleShowFonts(false) : this.handleShowFonts(true)}>
                    {parsedFont === 'AvantGarde' ?
                      <p style={{ fontFamily: 'AvantGarde' }}>ITC Avant Garde</p> :
                      parsedFont === 'Didot' ?
                        <p style={{ fontFamily: 'Didot' }}>Didot</p> :
                        <p style={{ fontFamily: 'LeahGaviota' }}>Leah Gaviota</p>
                    }
                    <img src={dropdown_img} height="29" alt="" />
                  </div>
                  {showEditFont &&
                    <div className="add-title_fonts-dropdown d-flex flex-column align-items-center shadow-sm">
                      <p className="cursor-pointer" style={{ fontFamily: 'AvantGarde' }} onClick={() => this.handleChangeFont('AvantGarde')}>ITC Avant Garde</p>
                      <p className="cursor-pointer" style={{ fontFamily: 'Didot' }} onClick={() => this.handleChangeFont('Didot')}>Didot</p>
                      <p className="cursor-pointer" style={{ fontFamily: 'LeahGaviota' }} onClick={() => this.handleChangeFont('LeahGaviota')}>Leah Gaviota</p>
                    </div>
                  }
                </div>
              </div>

              <div className="add-title-input d-flex flex-row justify-content-center">
                <input
                  type="text"
                  name="imageText"
                  placeholder=" Escribe el título de tu portada"
                  maxLength="18"
                  value={this.state.imageText}
                  onChange={this.handleInput}
                />
              </div>
              <div className="row d-flex justify-content-center align-items-center w-100 h-auto">
                <canvas className="myCanvas" ref="canvas" width={600} height={600} />
                <img ref="image" src={this.state.selectedCoverForEdit} style={{ display: 'none' }} />
              </div>



              <div style={{ visibility: 'hidden', position: 'absolute', top: '0' }}>
                <canvas ref="canvasHidden" width={width} height={height} />
                <img ref="imageHidden" src={this.state.selectedCoverForEdit} style={{ display: 'none' }} />
              </div>

            </div>
            <button className="add-title_save-btn w-100 mt-3" onClick={this.handleSaveButton}>
              <p className="gotham-bold-white-20">Listo</p>
            </button>
          </div>

        }
      </div>
    );
  }
}


const mapStateToProps = state => ({
  firebase: state.firebase,
  addTitleToCoverData: state.navigationReducer.addTitleToCoverData
});

const mapDispatchToProps = {
  changeAlbumCoverAction: changeAlbumCoverAction,
  toggleShowChangeCoverAction: toggleShowChangeCoverAction
};


export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(AddTitleToCover);