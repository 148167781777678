import React from 'react';
import './AlertViewCustomizable.scss';
import img_logo_gray_pixyalbum from '../../images/logo-gray.png';

const AlertViewCustomizable = (props) => (
  <div className="mx-auto my-auto d-flex">  
    <div className="card alert-card">
      <div className="card-body">
        <div className="d-flex align-items-center justify-content-center ">
          <img className="d-flex size-logo" src={img_logo_gray_pixyalbum} alt="Pixyalbum"/>
        </div>
        { props.message ?  <div className="card-text mt-2 p-2 text-center">{props.message}</div> : null}
        { props.content ?  <div className="card-text mt-2 p-2 text-center">{props.content}</div> : null}
        <div className="mt-4">
          { props.cancelHandler ?
            <div className="row p-2">
              <div className="btn-pixyalbum-border col mx-2" onClick={() => props.cancelHandler()}> {props.cancelTitle ? props.cancelTitle : 'Cancelar' } </div>
              {props.okHandler ? <div className="btn-pixyalbum-primary col mx-2" onClick={() => props.okHandler()}> {props.Title ? props.cancelTitle: 'Ok'} </div> : null}
            </div>
            : 
            <div className="btn-pixyalbum-primary btn-block" onClick={() => props.okHandler()}>Ok</div>
          }
        </div>
      </div>
    </div>
  </div>
);

export default AlertViewCustomizable;