import React, { Component } from 'react';
import { firebaseConnect } from 'react-redux-firebase';
import logo_pixyalbum from '../../images/newDesignImages/pixyalbum-logo.png';
import close_img from '../../images/newDesignImages/close-gray.png';
import './PasswordForgetComponent.scss';

class PasswordForgetComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      errorMessage: '',
      successMessage: ''
    }
  }

  readData = e => {
    this.setState({
      [e.target.name] : e.target.value
    })
  }

  recoverRassword = e => {
    e.preventDefault();
    const { email } = this.state;
    const { firebase } = this.props;
    firebase.auth().sendPasswordResetEmail(email)
      .then( () => {
        this.setState({ 
          successMessage: 'Revisa tu correo para continuar.',
          email: '',
          errorMessage: ''
        });
      })
      .catch( error => {
        this.setState({ 
          errorMessage: error.message,
          successMessage: '',
          email: ''
        });
      });
  }

  render() {
    const { successMessage, errorMessage } = this.state;
    return (
      <div className="passforget_main d-flex justify-content-center align-items-center">
        <form  onSubmit={this.recoverRassword} className="passforget_form d-flex flex-column align-items-center justify-content-center">
          <img className="passforget_close cursor-pointer" onClick={this.props.handleShowUpdatePassword} src={close_img} height="15" alt=""/>
          <img className="mb-4" src={logo_pixyalbum} height="50" alt="" />
          <p className="gotham-bold-gray-18">Recuperar contraseña</p>
          <input 
            type="email" 
            name="email" 
            id="email" 
            placeholder="Correo electrónico" 
            className="signup_input"
            value={this.state.email}
            onChange={this.readData}
          />
          <button className="signup_register-btn" id="send-email" type="submit">Enviar correo</button>
          {
            (errorMessage !== '') ? <div className="text-danger">{errorMessage}</div> : <br/>
          }
          {
            (successMessage !== '') ? <div className="text-primary">{successMessage}</div> : <br/>
          }
        </form>
      </div>
    );
  }
}

export default firebaseConnect()(PasswordForgetComponent);