import ApiService from './ApiService';

class ApiCheckout {

    constructor() {
        this.calculateOrderPath = 'v4/checkout/calculatecart/';
        this.placeOrderPath = 'v4/checkout/placeorder/';
    }

    calculateOrder = (payload) => {
        return ApiService.makeRequest(this.calculateOrderPath, payload);
    }

    placeOrder = (payload) => {
        return ApiService.makeRequest(this.placeOrderPath, payload);
    }
}

export default new ApiCheckout();