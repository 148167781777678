import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { loadCoversAction, changeAlbumCoverAction, toggleShowChangeCoverSelectSourceAction } from '../../actions/CustomerActions';

import Scrollable from '../../components/Scrollable';
import CoverCard from './CoverCard';
import Loading from '../../components/Loading';
import FullScreenView from '../../components/FullScreenView';


import close_img from '../../images/newDesignImages/close-white.png';
import back_img from '../../images/newDesignImages/back-arrow-blue.png';
import './Covers.scss';

class Covers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true
    }
  }

  componentDidMount() {
    if (!this.props.location.state.id) {
      this.props.history.push('/cover/categories/');
    }
    const loadCovers = new Promise((resolve, reject) => {
      resolve(this.props.loadCoversAction(this.props.location.state.id));
    })
    loadCovers.then(() => this.setState({isLoading: false}));
  }

  handleSelectCover = async(coverUrl, selectedCollection, index, predesignId) => {
    await this.props.changeAlbumCoverAction(coverUrl, selectedCollection, index, predesignId);
    this.props.toggleShowChangeCoverSelectSourceAction(false);
    this.props.history.push({
      pathname: '/collection/albums/detail',
      state: this.props.collectionSelected
    });
  }

  render() {
    return (
      <FullScreenView>
        {this.state.isLoading ? <Loading/> : (
          <Scrollable scrollableContent={
            <div className="changecover-selectsource_main">
              <div className="changecover-selectsource_header d-flex">
                <div className="changecover-selectsource_close d-flex justify-content-center align-items-center cursor-pointer" onClick={() => this.props.history.push('/collection/albums/detail')}>
                  <img src={close_img} height="23" alt="" />
                </div>
                <div className="changecover-selectsource_header-right d-flex justify-content-between align-items-center">
                  <p className="gotham-bold-18 ml-3">Portada > Portadas prediseñadas</p>
                  <button className="generic-button-blue mr-3" onClick={() => this.props.history.goBack()}>
                    Cancelar
                  </button>
                </div>
              </div>
              <div className="changecover-selectsource_content d-flex flex-column justify-content-center align-items-center">
                <div className="changecover-selectsource_back-container d-flex justify-content-start align-items-center">
                  <img className="cursor-pointer" src={back_img} height="20" alt="" onClick={() => this.props.history.push('/collection/albums/detail')} />
                </div>
                <div className="changecover-selectsource_photos pb-5 d-flex flex-wrap">
                  {this.props.covers.map((item, i) => (
                    <CoverCard
                      key={i}
                      item={item} 
                      handleSelectCover={this.handleSelectCover}
                      thumbnail={item.thumbnail_url}
                      collectionSelected={this.props.collectionSelected}
                      selectedCoverIndexForEdit={this.props.selectedCoverIndexForEdit}
                    />
                  ))}
                </div>
              </div>
            </div>
          }/>
        )}
        </FullScreenView>
    );
  }
}

const mapStateToProps = state => ({
  covers: state.collections.covers,
  collectionSelected: state.collections.collectionSelected,
  selectedCoverIndexForEdit: state.collections.selectedCoverIndexForEdit
});

const mapDispatchToProps = {
  loadCoversAction: loadCoversAction,
  changeAlbumCoverAction: changeAlbumCoverAction,
  toggleShowChangeCoverSelectSourceAction: toggleShowChangeCoverSelectSourceAction
};


export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(Covers);