import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { reactReduxFirebase, firebaseReducer } from 'react-redux-firebase';
import customerReducers from './reducers/CustomerReducers';
import validateReducers from './reducers/ValidateReducer';
import createProjectReducer from './reducers/CreateProjectReducers';
import globalReducer from './reducers/GlobalReducer';
import checkoutReducer from './reducers/CheckoutReducer';
import navigationReducer from './reducers/NavigationReducer';
import promise from 'redux-promise';
import thunk from 'redux-thunk';
import firebase from 'firebase/app';
import 'firebase/auth';

const firebaseConfig = {
    apiKey: window._env_.FIREBASE_API_KEY,
    authDomain: window._env_.FIREBASE_AUTH_DOMAIN,
    databaseURL: window._env_.FIREBASE_DATABASE_URL,
    projectId: window._env_.FIREBASE_PROJECT_ID,
    storageBucket: window._env_.FIREBASE_STORAGE_BUCKET,
    messagingSenderId: window._env_.FIREBASE_MESSAGING_SENDER_ID
}

firebase.initializeApp(firebaseConfig);

const middleware = [thunk, promise];

// configuration of react-redux
const rrfConfig = {
    userProfile: 'users'
}

// create of enhacer compose of redux
const createStoreWithFirebase = compose(
    reactReduxFirebase(firebase, rrfConfig),
)(createStore);

// Reducers 
const rootReducer = combineReducers({
    firebase: firebaseReducer,
    collections: customerReducers,
    validateForm: validateReducers,
    createProject: createProjectReducer,
    globalReducer: globalReducer,
    checkoutReducer: checkoutReducer,
    navigationReducer: navigationReducer
})

// state init 
const initialState = {}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStoreWithFirebase(rootReducer, initialState, composeEnhancers(
    applyMiddleware(...middleware),
    reactReduxFirebase(firebase),
));

export default store;