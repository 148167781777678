import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firebaseConnect } from 'react-redux-firebase';
import { Link } from 'react-router-dom';
import './Navigation.scss';
import Modal from '../../Modal';
import AlertView from '../../AlertView';
import ProductsPopover from '../../ProductsPopover';
import { resetCollectionStateActions, loadUserDetailAction, getCartProductsQuantityAction } from '../../../actions/CustomerActions';
import { changeRouteAction } from '../../../actions/NavigationActions';

import pixyalbum_logo from '../../../images/newDesignImages/pixyalbum-logo.png';
import home_inactive from '../../../images/newDesignImages/home-inactive.png';
import home_active from '../../../images/newDesignImages/home-active.png';
import myaccount from '../../../images/newDesignImages/myaccount.png';
import myaccount_active from '../../../images/newDesignImages/myaccount-active.png';
import wallet_inactive from '../../../images/newDesignImages/wallet-inactive.png';
import wallet_active from '../../../images/newDesignImages/wallet-active.png';
import mycart_inactive from '../../../images/newDesignImages/cart-inactive.png';
import mycart_active from '../../../images/newDesignImages/cart-active.png';
import logout_inactive from '../../../images/newDesignImages/logout-inactive.png';
import logout_active from '../../../images/newDesignImages/logout-active.png';
import create_active from '../../../images/newDesignImages/create-project-active.png';
import create_inactive from '../../../images/newDesignImages/create-project-inactive.png';
import info_active from '../../../images/newDesignImages/info-active.png';
import info_inactive from '../../../images/newDesignImages/info-inactive.png';
import orders_active from '../../../images/newDesignImages/orders-blue.png';
import orders_inactive from '../../../images/newDesignImages/orders-black.png';
import giftcard_active from '../../../images/newDesignImages/giftcard-img-active.png';
import giftcard_inactive from '../../../images/newDesignImages/giftcard-img-inactive.png';



class Navigation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAuthenticated: false,
      showLogoutConfirmation: false,
      isLoadDetailUser: false,
      showProductsPopover: false,
      routeName: '',
      showDropdown: false,
      mouseOverItem: null
    }
  }

  componentDidMount() {
    const { isAuthenticated } = this.state;
    if (isAuthenticated) {
      this.props.changeRouteAction(window.location.href.split('/').slice(-1)[0]);
      // La siguiente acción se usa para mostrar en el navbar el número de elementos en el carrito.
      this.props.getCartProductsQuantityAction();
    }
  }

  // Get the props automatically
  static getDerivedStateFromProps(props, state) {
    const { auth } = props;
    if (auth.uid) {
      if (!state.isAuthenticated && !state.isLoadDetailUser) {
        props.loadUserDetailAction();
      }
      return { isAuthenticated: true }
    } else {
      return { isAuthenticated: false }
    }
  }

  logOut = () => {
    this.setState({ showLogoutConfirmation: true });
  }

  cancelLogOut = () => {
    this.setState({ showLogoutConfirmation: false });
  }

  confirmLogOut = () => {
    const { firebase, resetCollectionStateActions } = this.props;
    firebase.logout();
    this.setState({ showLogoutConfirmation: false, isLoadDetailUser: false });
    resetCollectionStateActions();
    localStorage.removeItem("loginInstagram");
    localStorage.removeItem("loginGphotos");
  }

  toggleShowProducts = () => {
    const { showProductsPopover } = this.state;
    this.setState({ showProductsPopover: !showProductsPopover });
  }


  handleMouseOver = (isMouseOver) => {
    if (isMouseOver) {
      this.setState({ 
        showDropdown: true,
        mouseOverItem: 'myaccount'
      })
    } else {
      this.setState({ 
        showDropdown: false,
        mouseOverItem: ''
      })
    }
  }

  handleMouseOverItem = (item) => {
    this.setState({ mouseOverItem: item });
  }

  render() {
    const { showLogoutConfirmation, isAuthenticated, showProductsPopover, mouseOverItem } = this.state;
    const { customerName, customerEmail, changeRouteAction, routeName, cartProductsQuantity } = this.props;
    // Hace falta poner un id="see_products", que es necesario para el tag manager.
    return (
      <React.Fragment>
        {
          showLogoutConfirmation &&
          <Modal>
            <AlertView
              title={'Pixyalbum'}
              message={'¿Cerrar sesión?'}
              okHandler={this.confirmLogOut}
              cancelHandler={this.cancelLogOut}
              cancel={true} />
          </Modal>
        }
        {
          showProductsPopover &&
          <Modal
            dismissHandler={this.toggleShowProducts}>
            <ProductsPopover />
          </Modal>
        }
        {isAuthenticated ?
          <div className="navbar_container">
            <div className="navbar_top d-flex justify-content-between align-items-center">
              <Link to="/" className="navbar_logo-container ml-2 cursor-pointer">
                <img src={pixyalbum_logo} height="45" alt="logo" />
              </Link>
              <div className="icons_container d-flex justify-content-end align-items-center">
                <Link
                  to="/"
                  className="pr-3 d-flex align-items-center navbar_icon cursor-pointer"
                  style={{ color: routeName === 'projects' ? '#01baf2' : null }}
                  onClick={() => changeRouteAction('projects')}
                  onMouseEnter={() => this.handleMouseOverItem('projects')}
                  onMouseLeave={() => this.handleMouseOverItem(null)}
                >
                  <img height="27" className="mr-2" src={(routeName === 'projects' || mouseOverItem === 'projects') ? home_active : home_inactive} alt="home" />
                  {(routeName === 'projects' || mouseOverItem === 'projects') ? <p className="navbar_icon-subtext-selected">Inicio</p> : <p className="navbar_icon-subtext">Inicio</p>}
                </Link>
                {
                  (routeName === 'projects' || routeName === 'cart' || routeName === 'checkout' || routeName === 'account' || routeName === 'wallet') &&
                  <Link
                    to="/create"
                    className="px-3 d-flex align-items-center navbar_icon cursor-pointer"
                    style={{ color: routeName === 'create' ? '#01baf2' : null }}
                    onClick={() => changeRouteAction('create')}
                    onMouseEnter={() => this.handleMouseOverItem('create')}
                    onMouseLeave={() => this.handleMouseOverItem(null)}
                  >
                    <img className="mr-2" src={(routeName === 'create' || mouseOverItem === 'create') ? create_active : create_inactive} height="30" alt="create" />
                    {(routeName === 'create' || mouseOverItem === 'create') ? <p className="navbar_icon-subtext-selected">Crear Proyecto</p> : <p className="navbar_icon-subtext">Crear Proyecto</p>}
                  </Link>
                }
                <div
                  className="px-2 d-flex align-items-center navbar_dorpdown-icon cursor-pointer"
                  onMouseEnter={() => this.handleMouseOver(true)}
                  onMouseLeave={() => this.handleMouseOver(false)}
                >
                  <img className="mr-2" height="35" src={mouseOverItem === 'myaccount' ? myaccount_active : myaccount} />
                  {mouseOverItem === 'myaccount' ? <p className="navbar_icon-subtext-selected">Mi cuenta</p> : <p className="navbar_icon-subtext">Mi cuenta</p>}
                  {this.state.showDropdown &&
                    <div className="navbar_dropdown" onMouseEnter={() => this.handleMouseOver(true)} onMouseLeave={() => this.handleMouseOver(false)}>
                      <div className="px-2 d-flex align-items-center navbar_dorpdown-icon cursor-pointer">
                        <img className="mr-2" height="35" src={mouseOverItem === 'myaccount' ? myaccount_active : myaccount} />
                        {mouseOverItem === 'myaccount' ? <p className="navbar_icon-subtext-selected">Mi cuenta</p> : <p className="navbar_icon-subtext">Mi cuenta</p>}
                      </div>
                      <div className="navbar_dropdown-box">
                        <Link
                          to="/account"
                          className="pl-3 pr-1 my-3 d-flex align-items-center navbar_dorpdown-icon cursor-pointer"
                          style={{ color: routeName === 'account' ? '#01baf2' : null }}
                          onClick={() => changeRouteAction('account')}
                          onMouseEnter={() => this.handleMouseOverItem('account')}
                          onMouseLeave={() => this.handleMouseOverItem(null)}
                        >
                          <img className="mr-2" height="18" src={(routeName === 'account' || mouseOverItem === 'account') ? info_active : info_inactive} alt="myaccount" />
                          {(routeName === 'account' || mouseOverItem === 'account') ? <p className="navbar_icon-subtext-selected">Mis datos</p> : <p className="navbar_icon-subtext">Mis datos</p>}
                        </Link>
                        <Link
                          to="/orders"
                          className="pl-3 pr-1 my-3 d-flex align-items-center navbar_dorpdown-icon cursor-pointer"
                          style={{ color: routeName === 'orders' ? '#01baf2' : null }}
                          onClick={() =>  changeRouteAction('orders')}
                          onMouseEnter={() => this.handleMouseOverItem('orders')}
                          onMouseLeave={() => this.handleMouseOverItem(null)}
                        >
                          <img className="mr-2" height="15" src={(routeName === 'orders' || mouseOverItem === 'orders') ? orders_active : orders_inactive} alt="" />
                          {(routeName === 'orders' || mouseOverItem === 'orders') ? <p className="navbar_icon-subtext-selected">Mis ordenes</p> : <p className="navbar_icon-subtext">Mis ordenes</p>}
                        </Link>
                        <Link
                          to="/wallet"
                          className="pl-3 pr-1 my-3 d-flex align-items-center navbar_dorpdown-icon cursor-pointer"
                          style={{ color: routeName === 'wallet' ? '#01baf2' : null }}
                          onClick={() => changeRouteAction('wallet')}
                          onMouseEnter={() => this.handleMouseOverItem('wallet')}
                          onMouseLeave={() => this.handleMouseOverItem(null)}
                        >
                          <img className="mr-2" height="18" src={(routeName === 'wallet' || mouseOverItem === 'wallet') ? wallet_active : wallet_inactive} alt="" />
                          {(routeName === 'wallet' || mouseOverItem === 'wallet') ? <p className="navbar_icon-subtext-selected">Pixypesos</p> : <p className="navbar_icon-subtext">Pixypesos</p>}
                        </Link>
                        <Link
                          to="/giftcard"
                          className="pl-3 pr-1 my-3 d-flex align-items-center navbar_dorpdown-icon cursor-pointer"
                          style={{ color: routeName === 'giftcard' ? '#01baf2' : null }}
                          onClick={() =>  changeRouteAction('giftcard')}
                          onMouseEnter={() => this.handleMouseOverItem('giftcard')}
                          onMouseLeave={() => this.handleMouseOverItem(null)}
                        >
                          <img className="mr-2" height="18" src={(routeName === 'giftcard' || mouseOverItem === 'giftcard') ? giftcard_active : giftcard_inactive} alt="" />
                          {(routeName === 'giftcard' || mouseOverItem === 'giftcard') ? <p className="navbar_icon-subtext-selected">Tarjeta de regalo</p> : <p className="navbar_icon-subtext">Tarjeta de regalo</p>}
                        </Link>
                        <div id="logout" className="pl-3 pr-1 my-2 d-flex align-items-center navbar_dorpdown-icon cursor-pointer" onClick={this.logOut} onMouseEnter={() => this.handleMouseOverItem('logout')} onMouseLeave={() => this.handleMouseOverItem(null)}>
                          <img className="mr-2" src={mouseOverItem === 'logout' ? logout_active : logout_inactive} height="18" alt="logout" />
                          {mouseOverItem === 'logout' ? <p className="navbar_icon-subtext-selected">Cerrar</p> : <p className="navbar_icon-subtext">Cerrar</p>}
                        </div>
                      </div>
                    </div>
                  }
                </div>

                <Link
                  id="go-to-cart"
                  to="/cart"
                  className="d-flex align-items-center navbar_icon cursor-pointer"
                  style={{ color: routeName === 'cart' ? '#01baf2' : null, position: 'relative', width: '110px' }}
                  onClick={() => changeRouteAction('cart')}
                  onMouseEnter={() => this.handleMouseOverItem('cart')}
                  onMouseLeave={() => this.handleMouseOverItem(null)}
                >
                  <img className="mr-2" src={(routeName === 'cart' || mouseOverItem === 'cart') ? mycart_active : mycart_inactive} height="30" alt="mycart" />
                  {cartProductsQuantity > 0 && 
                    <div className="navbar_cart-quantity d-flex justify-content-center align-items-center">
                      <p className="gotham-book-white-11">{cartProductsQuantity}</p>
                    </div>
                  }
                  {(routeName === 'cart' || mouseOverItem === 'cart') ? <p className="navbar_icon-subtext-selected">Mi carrito</p> : <p className="navbar_icon-subtext">Mi carrito</p>}
                </Link>
              </div>
            </div>
            <hr></hr>
            <div className="navbar_bottom d-flex align-items-center">
              <div className="ml-2 mr-3">
                Hola <strong>{customerName} <span style={{color: '#E23389'}}>¡Utiliza la aplicación móvil para más funcionalidades y tener una mejor experiencia!</span></strong>
              </div>
            </div>
          </div>
          : null}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.firebase.auth,
  customerName: state.collections.customerName,
  customerEmail: state.collections.customerEmail,
  routeName: state.navigationReducer.routeName,
  cartProductsQuantity: state.collections.cartProductsQuantity,
});

const mapDispatchToProps = {
  resetCollectionStateActions: resetCollectionStateActions,
  loadUserDetailAction: loadUserDetailAction,
  changeRouteAction: changeRouteAction,
  getCartProductsQuantityAction: getCartProductsQuantityAction
}

export default compose(
  firebaseConnect(),
  connect(mapStateToProps, mapDispatchToProps)
)(Navigation);