import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { getCartProductsQuantityAction, deleteProductFromCartAction, updateCartProductsAction } from '../../actions/CustomerActions';
import { hideErrorAction, showErrorAction } from '../../actions/GlobalActions';
import { addProductsToCheckoutAction, calculateOrderAction, placeOrderAction, finishLoadingAction, updateCustomerInfoAction } from '../../actions/CheckoutActions';
import { changeRouteAction } from '../../actions/NavigationActions';
import { parseAlbumConfiguration, parseOrnamentConfiguration } from '../../utils/Parsers';

import TokenHelper from '../../utils/Firebase/TokenHelper';
import ApiCustomer from '../../utils/Api/ApiCustomer';

import CartAlbumItem from './CartAlbumItem';
import Loading from '../../components/Loading';
import Modal from '../../components/Modal';
import AlertView from '../../components/AlertView';
import Scrollable from '../../components/Scrollable';
import CreateProject from '../../components/CreateProject';
import AlertViewBar from '../../components/AlertViewBar';
import Footer from '../../components/Footer';
import SelectShippingAddress from '../../components/SelectShippingAddress';
import SelectPaymentMethod from '../../components/SelectPaymentMethod';
import ApplyVoucher from '../../components/ApplyVoucher';
import OrderSummary from '../../components/OrderSummary';
import CreateAddress from '../../components/CreateAddress';
import CreateCardToken from '../../components/CreateCardToken';
import AcceessoriesCart from '../../components/AccessoiesCart';
import EmptyCart from './EmptyCart';

import dates_img from '../../images/newDesignImages/dates-img.png';
import resume_img from '../../images/newDesignImages/resume-img.png';
import address_img from '../../images/newDesignImages/address-img.png';
import card_img from '../../images/newDesignImages/card-img.png';
import dropdown_img from '../../images/newDesignImages/dropdown-img.png';

import cart_img from '../../images/newDesignImages/cart-img.png';
import products_img from '../../images/newDesignImages/products-inactive.png';
import advertising from '../../images/newDesignImages/advertising-img.png';
import list_element from '../../images/newDesignImages/list-element.png';

import './Cart.scss';


class Cart extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showCreateProjectConfirmation: false,
      giftcardCategories: null,
      cartProducts: null,
      cost: null,
      // customerInfo: null,
      deliveryDate: '',
      isLoading: true,
      showSelectAddress: false,
      showSelectPaymentMethod: false,
      showApplyVoucher: false,
      showCreateAddress: false,
      showCreateCard: false
    }
    this.okCreateProjectConfirmation = this.okCreateProjectConfirmation.bind(this);
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = async() => {
    await this.loadCart();
    await this.getGiftcardCategories();
    this.props.changeRouteAction('cart');
    this.props.finishLoadingAction();
  }

  loadCart = async() => {
    try {
      const idToken = await TokenHelper.getUserToken();
      const response = await ApiCustomer.getCart({token: idToken});
      if (response.data.success) {
        for (let album of response.data.data.cart.albums) {
          album['product_type'] = 'album';
        }
        for (let giftcard of response.data.data.cart.giftcards) {
          giftcard['product_type'] = 'giftcard';
        }
        for (let item of response.data.data.cart.items) {
          item['product_type'] = 'item';
        }
        for (let magnet of response.data.data.cart.magnets) {
          magnet['product_type'] = 'magnet';
        }
        for (let picture of response.data.data.cart.pictures) {
          picture['product_type'] = 'picture';
        }
        for (let ornament of response.data.data.cart.ornaments) {
          ornament['product_type'] = 'ornament';
        }
        let cartProducts = response.data.data.cart.albums
          .concat(response.data.data.cart.giftcards)
          .concat(response.data.data.cart.items)
          .concat(response.data.data.cart.magnets)
          .concat(response.data.data.cart.pictures)
          .concat(response.data.data.cart.ornaments);
        this.props.updateCartProductsAction(cartProducts);
        this.setState({ 
          cartProducts,
          cost: response.data.data.cost,
          // customerInfo: response.data.data.customer_info,
          deliveryDate: response.data.data.estimated_delivery_date
        });
        if (!this.props.customerInfo) {
          this.props.updateCustomerInfoAction(response.data.data.customer_info);
        }
      } else {
        this.props.showErrorAction('Hubo un error, por favor intenta más tarde.');
      }
    } catch(error) {
      this.props.showErrorAction('Hubo un error, por favor intenta más tarde.');
    }
  }

  okErrorHandler = () => {
    this.props.hideErrorAction();
  }

  getGiftcardCategories = async() => {
    try {
      const idToken = await TokenHelper.getUserToken();
      const response = await ApiCustomer.getAllGiftcards({ token: idToken });
      if (response.data.success) {
        this.setState({ 
          giftcardCategories: response.data.data,
          isLoading: false
        });
      } else {
        this.props.showErrorAction('Hubo un error, por favor intenta más tarde.');
      }
    } catch(error) {
      this.props.showErrorAction('Hubo un error, por favor intenta más tarde.');
    }
  }

  buyButtonClicked = () => {
    const { cartProducts } = this.props;
    if (cartProducts.length > 0) {
      this.props.addProductsToCheckoutAction(cartProducts);
      this.loadInitiateCheckoutPixel();

      this.initializePixelAnalytics();
      this.loadCheckOutAnalytics(cartProducts);

      this.props.history.push('/checkout');
    }
  }

  deleteProductHandler = async(product) => {
    const { cost } = this.state;
    await this.props.deleteProductFromCartAction(product);
    this.setState({ cartProducts: this.props.cartProducts });
    await this.calculateOrderFromServer(cost.voucher_code);
    this.setState({ cost: this.props.orderSummary });

    //Analytics
    if (product.product_type === 'album') {
      const initPixelAnalytics = new Promise(resolve => resolve(this.initializePixelAnalytics()));
      initPixelAnalytics.then(() => this.loadRemoveToCartAnalytics(product));
    }
  }

  initializePixelAnalytics = () => {
    this.dataLayer = window.dataLayer;
  }

  loadRemoveToCartAnalytics = (album) => {
    this.dataLayer.push({
      'event': 'removeFromCart',
      'ecommerce': {
        'remove': {
          'products': [{
            'name': album.name,
            'id': album.id,
            'price': album.price,
            'hard_cover': album.hard_cover,
            'quantity': album.quantity,
            'num_photos': album.pages.length
          }]
        }
      }
    });
  }

  loadCheckOutAnalytics = (cartProducts) => {

    this.albums = [];
    this.albums = cartProducts.filter(item => item.product_type === 'album').map((album) => ({
      'name': album.name,
      'id': album.id,
      'price': album.price,
      'hard_cover': album.hard_cover,
      'quantity': album.quantity,
      'num_photos': album.pages.length
    }));
    this.dataLayer.push({
      'event': 'checkout',
      'ecommerce': {
        'checkout': {
          'actionField': { 'step': 1 },
          'products': this.albums
        }
      }
    });
  }


  createProjectCallBack = () => {
    this.okCreateProjectConfirmation();
  }

  okCreateProjectConfirmation = () => {
    this.setState({
      ...this.state,
      showCreateProjectConfirmation: true
    });
  }

  cancelCreateProjectConfirmation = () => {
    this.setState({
      ...this.state,
      showCreateProjectConfirmation: false,
    })
  }

  loadInitiateCheckoutPixel = () => {
    const { auth } = this.props;
    window.fbq('track', 'InitiateCheckout', {
      user_id: auth.uid,
      origin: 'web'
    });
  }

  ////////////////////

  changeQuantityHandler = (product, plusMinusOrError) => {
    this.setProductQuantity(product, plusMinusOrError)
    if (this.calculatePrice() < 10000) {
      const { cost } = this.state;
      this.calculateOrderFromServer(cost.voucher_code)
    } else {
      this.props.showErrorAction('Por el momento no es posible comprar más de $10,000 por orden.');
    }
  }

  setProductQuantity = (product, plusMinusOrError) => {
    const { cartProducts } = this.state;
    for (let i = 0; i < cartProducts.length; i++) {
      if (cartProducts[i] === product) {
        if (plusMinusOrError === 'plus') {
          cartProducts[i].quantity = cartProducts[i].quantity + 1;
        } else if (plusMinusOrError === 'minus') {
          cartProducts[i].quantity = cartProducts[i].quantity > 1 ? cartProducts[i].quantity - 1 : 1;
        } else if (plusMinusOrError === 'quantityExceded') {
          cartProducts[i].quantity = cartProducts[i].quantity - 1;
        }
      }
    }
    this.setState({ cartProducts });
  }

  calculatePrice = () => {
    const { cost, cartProducts } = this.state;
    const reducer = (accumulator, currentValue) => accumulator + currentValue;
    return cartProducts.map(item => item.price * item.quantity).reduce(reducer, cost.shipping);
  }

  calculateOrderFromServer = async(voucherString) => {
    const { cartProducts } = this.state;
    let albums = cartProducts.filter(item => item.product_type === 'album');
    let giftcardsData = cartProducts.filter(item => item.product_type === 'giftcard').map(item => item.configurations[0]);
    let magnets = cartProducts.filter(item => item.product_type === 'magnet');
    let canvas = cartProducts.filter(item => item.product_type === 'picture');
    let ornaments = cartProducts.filter(item => item.product_type === 'ornament');
    let items = cartProducts.filter(elem => elem.product_type === 'item');
    let albumsData = [];
    for (let album of albums) {
      let albumData = {
        quantity: album.quantity,
        configurations: parseAlbumConfiguration(album.configurations)
      };
      albumsData.push(albumData);
    }
    let magnetsData = [];
    for (let magnet of magnets) {
      let magnetData = {
        quantity: magnet.quantity,
        configurations: magnet.configurations
      }
      magnetsData.push(magnetData);
    }
    let canvasData = [];
    for (let canvasItem of canvas) {
      canvasItem.configurations[0].description = "Canvas";
      let canvasItemData = {
        quantity: canvasItem.quantity,
        total_pictures: canvasItem.photos.length,
        configurations: canvasItem.configurations
      }
      canvasData.push(canvasItemData);
    }
    let ornamentsData = [];
    for (let ornament of ornaments) {
      let ornamentData = {
        quantity: ornament.quantity,
        configurations: parseOrnamentConfiguration(ornament.configurations)
      }
      ornamentsData.push(ornamentData);
    }
    let itemsData = items.map(elem => ({item_id: elem.item_id, quantity: elem.quantity}));
    let orderData = {
      voucher: voucherString,
      items: {
        albums: albumsData,
        giftcards: [{ configurations: giftcardsData }],
        magnets: magnetsData,
        pictures: canvasData,
        ornaments: ornamentsData,
        items: itemsData
      }
    }
    await this.props.calculateOrderAction(orderData);
    this.setState({ cost: this.props.orderSummary })
  }

  placeOrder = async() => {
    const { paymentCard, shippingAddress, customerInfo } = this.props;
    const { cartProducts, cost } = this.state;
    if (customerInfo.default_payment_method.id === '') {
      this.props.showErrorAction('Agrega un método de pago para continuar.');
    } else if (customerInfo.default_address.id === '') {
      this.props.showErrorAction('Agrega una dirección de envío para continuar.');
    } else {
      this.setState({isLoading: true});
      
      let albums = cartProducts
        .filter(item => item.product_type === 'album')
        .map(album => {
          return {
            ...album,
            configurations: parseAlbumConfiguration(album.configurations),
            cover_photos: album.cover.photos,
            mask_cover_id: album.cover.mask_data.id,
            predesign_cover_id: album.cover.predesign_data.id,
          };
        });
      let giftcards = cartProducts
        .filter(item => item.product_type === 'giftcard')
        .map(item => ({ id: item.id, configurations: item.configurations }));
      let magnets = cartProducts
        .filter(item => item.product_type === 'magnet')
        .map(magnetsProject => {
          let newPhotos = [];
          for (let photo of magnetsProject.photos) {
            let newPhoto = {
              ...photo,
              image_url: photo.image_url.slice(0, photo.image_url.indexOf('?')),
              print_url: photo.print_url.slice(0, photo.print_url.indexOf('?'))
            }
            newPhotos.push(newPhoto);
          }
          let unisignedImgsMagnetsProject = {
            ...magnetsProject,
            photos: newPhotos
          }
          return unisignedImgsMagnetsProject;
        });
      let canvas = cartProducts
        .filter(item => item.product_type === 'picture')
        .map(canvasProject => {
          let newPhotos = [];
          for (let photo of canvasProject.photos) {
            let newPhoto = {
              ...photo,
              image_url: photo.image_url.slice(0, photo.image_url.indexOf('?')),
              print_url: photo.print_url.slice(0, photo.print_url.indexOf('?'))
            }
            newPhotos.push(newPhoto);
          }
          let unisignedImgsCanvasProject = {
            ...canvasProject,
            photos: newPhotos
          }
          return unisignedImgsCanvasProject;
        });
      let ornaments = cartProducts
      .filter(item => item.product_type === 'ornament')
      .map(ornamentsProject => {
        let newPhotos = [];
        for (let photo of ornamentsProject.photos) {
          let newPhoto = {
            ...photo,
            image_url: photo.image_url.slice(0, photo.image_url.indexOf('?')),
            print_url: photo.print_url.slice(0, photo.print_url.indexOf('?'))
          }
          newPhotos.push(newPhoto);
        }
        let unisignedImgsOrnamentsProject = {
          ...ornamentsProject,
          photos: newPhotos,
          configurations: parseOrnamentConfiguration(ornamentsProject.configurations)
        }
        return unisignedImgsOrnamentsProject;
      });
      let items = cartProducts
        .filter(elem => elem.product_type === 'item')
        .map(elem => ({item_id: elem.item_id, quantity: elem.quantity}));
      if (customerInfo.default_payment_method === null && cost.total > 0) {
        this.setState({isLoading: false})
        this.props.showErrorAction('Debes seleccionar un método de pago');
        return;
      }
      if (customerInfo.default_address === null) {
        this.setState({isLoading: false})
        this.props.showErrorAction('Debes seleccionar una dirección de envío');
        return;
      }
      let shippingAddressToSend = { ...customerInfo.default_address };
      delete shippingAddressToSend.id;
      let voucher = cost.voucher_code;
      // paymentMethod is the object to send to the server
      // customerInfo.default_payment_method is from user selected on checkout
      let paymentMethod = {}
      if (customerInfo.default_payment_method != null) {
        if (customerInfo.default_payment_method.id === 'oxxo') {
          paymentMethod['type'] = 'oxxo_cash';
        } else {
          paymentMethod['type'] = 'card';
          paymentMethod['token'] = customerInfo.default_payment_method.id;
        }
      }
      
      let orderData = {
        shipping_address: shippingAddressToSend,
        device: 'web',
        voucher: voucher,
        payment_method: paymentMethod,
        albums: albums,
        giftcards: giftcards,
        magnets: magnets,
        pictures: canvas,
        ornaments: ornaments,
        items: items,
        from_cart: true
      }

      const analyticsData = JSON.parse(localStorage.getItem('google_adds'));
      let orderDataAndAnalytics = analyticsData ? {...orderData, ...analyticsData} : orderData;
      await this.props.placeOrderAction(orderDataAndAnalytics, cost);
      localStorage.removeItem('google_adds');

      const isOxxoPay = customerInfo.default_payment_method.brand === 'oxxo' ? true : false;
      if (this.props.orderId !== '') {
        this.props.history.push({
          pathname: '/order-success',
          data: {
            orderId: this.props.orderId,
            email: this.props.auth.email,
            getCartProductsQuantityAction: this.props.getCartProductsQuantityAction,
            paymentReference: this.props.paymentReference,
            cashPaymentReference: this.props.cashPaymentReference,
            total: this.state.cost.total,
            isOxxoPay: isOxxoPay
          } 
        });
      } else {
        this.setState({isLoading: false});
        this.props.showErrorAction('Ha ocurrido un error, por favor intenta mas tarde.')
      }
    }
  }

  handleAddressDropdown = () => {
    const { showSelectAddress } = this.state;
    if (showSelectAddress) {
      this.setState({ showSelectAddress: false });
    } else {
      this.setState({ showSelectAddress: true });
    }
  }

  handlePaymentMethodDropdown = () => {
    const { showSelectPaymentMethod } = this.state;
    if (showSelectPaymentMethod) {
      this.setState({ showSelectPaymentMethod: false });
    } else {
      this.setState({ showSelectPaymentMethod: true });
    }
  }

  handleShowApplyVoucher = () => {
    const { showApplyVoucher } = this.state;
    if (showApplyVoucher) {
      this.setState({ showApplyVoucher: false });
    } else {
      this.setState({ showApplyVoucher: true });
    }
  }

  applyVoucherCallbak = (voucher) => {
    this.calculateOrderFromServer(voucher);
  }

  handleShowCreateAddress = () => {
    const { showCreateAddress } = this.state;
    if (showCreateAddress) {
      this.setState({ showCreateAddress: false });
    } else {
      this.setState({ showCreateAddress: true });
    }
  }

  handleShowCreateCard = () => {
    const { showCreateCard } = this.state;
    if (showCreateCard) {
      this.setState({ showCreateCard: false });
    } else {
      this.setState({ showCreateCard: true });
    }
  }

  handleSelectAddress = (address) => {
    const customerInfo = {
      ...this.props.customerInfo,
      default_address: address
    }
    this.props.updateCustomerInfoAction(customerInfo);
    this.setState({ showSelectAddress: false });
    // this.setState({ 
    //   customerInfo: {
    //     ...this.state.customerInfo,
    //     default_address: address
    //   },
    //   showSelectAddress: false
    // });
  }

  handleSelectCard = (card) => {
    const customerInfo = {
      ...this.props.customerInfo,
      default_payment_method: card
    }
    this.props.updateCustomerInfoAction(customerInfo);
    this.setState({ showSelectPaymentMethod: false });
    // this.setState({
    //   customerInfo: {
    //     ...this.state.customerInfo,
    //     default_payment_method: card
    //   },
    //   showSelectPaymentMethod: false
    // });
  }

  handleGoToBuyProducts = () => {
    this.props.history.push('/create');
  }

  handleAddAccessoryToCart = async(selectedItem) => {
    const { cartProducts } = this.state;
    if (selectedItem.available) {
      this.setState({isLoading: true});
      let accessoriesInCart = cartProducts.filter(elem => elem.product_type === 'item')
      const accessoriesInCartIds = cartProducts.filter(elem => elem.product_type === 'item').map(elem => parseInt(elem.item_id));
      if (accessoriesInCartIds.includes(selectedItem.id)) {
        const selectedAccessory = accessoriesInCart.filter(elem => elem.item_id == selectedItem.id)[0];
        this.changeQuantityHandler(selectedAccessory, 'plus');
        this.setState({isLoading: false});
      } else {
        try {
          const idToken = await TokenHelper.getUserToken();
          const addToCartData = {
            token: idToken, 
            product_type: "item",
            data: [selectedItem.id]
          }
          const response = await ApiCustomer.addProductToCart(addToCartData);
          if (response.data.success) {
            this.loadData();
          } else {
            this.setState({isLoading: false});
            this.props.showErrorAction('Ha ocurrido un error, por favor intenta mas tarde.');
          }
        } catch(e) {
          this.setState({isLoading: false});
          this.props.showErrorAction('Ha ocurrido un error, por favor intenta mas tarde.');
        }
      }
    } else {
      return;
    }
  }

  handleCardError = (error) => {
    this.props.showErrorAction(error.message_to_purchaser)
  }

  render() {
    const { 
      showCreateProjectConfirmation, 
      giftcardCategories, 
      errorMessage, 
      isLoading, 
      cost, 
      cartProducts, 
      deliveryDate, 
      showSelectAddress, 
      showSelectPaymentMethod,
      // customerInfo,
      showApplyVoucher,
      showCreateAddress,
      showCreateCard
    } = this.state;
    const { customerInfo } = this.props;
    return (
      <React.Fragment>
        {
          this.props.showingError &&
          <Modal>
            <AlertView
              title={'¡Ups!'}
              message={this.props.errorMessage}
              okHandler={this.okErrorHandler}
              cancel={true} />
          </Modal>
        }
        {
          showCreateProjectConfirmation &&
          <Modal>
            <AlertViewBar
              title={'Crear álbum desde'}
              message={
                <React.Fragment>
                  <CreateProject />
                </React.Fragment>
              }
              isButtonContinue={false}
              okHandler={this.okCreateProjectConfirmation}
              cancelHandler={this.cancelCreateProjectConfirmation} />
          </Modal>
        }
        {
          showCreateAddress &&
            <Modal>
              <CreateAddress handleShowCreateAddress={this.handleShowCreateAddress}/>
            </Modal>
        }
        {
          showCreateCard &&
            <Modal>
              <CreateCardToken 
                handleShowCreateCard={this.handleShowCreateCard} 
                handleCardError={this.handleCardError}
              />
            </Modal>
        }

        {(isLoading || this.props.isLoading) ? <Loading/> :
        <Scrollable
          scrollableContent={
            <div className="cart_main-container">
              {(cartProducts && cartProducts.length === 0) ? <EmptyCart handleGoToBuyProducts={this.handleGoToBuyProducts}/> :(
              <div >
                <div className="d-flex">
                  <div className="cart_container p-5">
                    <p className="gotham-bold-20">Mi carrito</p>
                    {cartProducts && cartProducts.map((product, index) =>
                      <CartAlbumItem
                        key={index}
                        product={product}
                        deleteCallback={this.deleteProductHandler}
                        giftcardCategories={giftcardCategories}
                        changeQuantityHandler={this.changeQuantityHandler}
                      />
                    )}
                    <div className="cart_delivery-date py-3 pl-5 d-flex flex-column">
                      <p className="gotham-md-gray-15">Fecha estimada de entrega</p>
                      <div className="ml-3 d-flex align-items-center">
                        <img src={dates_img} height="18" alt=""/>
                        <p className="gotham-md-lightgray-14 ml-2">{deliveryDate}</p>
                      </div>
                    </div>
                  </div>
                  <div className="cart_container d-flex flex-column align-items-start">
  
                    <div className="cart_customer-info">
  
                      <div className="cart_info-item py-3 px-4 d-flex justify-content-between align-items-center">
                        <div>
                          <p className="gotham-md-gray-15">Dirección de envío</p>
                          <div className="pl-3 d-flex align-items-center">
                            <img src={address_img} height="15" alt="" />
                            {customerInfo.default_address.id !== '' ? 
                              <p className="gotham-md-lightgray-15 ml-2">{customerInfo.default_address.street} {customerInfo.default_address.number}</p> :
                              <p className="gotham-md-lightgray-15 ml-2">Seleciona una dirección de envío</p>
                            }
                          </div>
                        </div>
                        <img className="cursor-pointer" height="10" src={dropdown_img} alt="" onClick={this.handleAddressDropdown}/>
                      </div>
                      <hr className="cart_info-inline"></hr> 
                      {showSelectAddress && 
                        <SelectShippingAddress 
                          isViewAccount={false} 
                          handleShowCreateAddress={this.handleShowCreateAddress}
                          handleSelectAddress={this.handleSelectAddress}
                          defaultAddress={customerInfo.default_address}
                        />
                      }
  
                      <div className="cart_info-item py-3 px-4 d-flex justify-content-between align-items-center">
                        <div>
                          <p className="gotham-md-gray-15">Método de pago</p>
                          <div className="pl-3 d-flex align-items-center">
                            <img src={card_img} height="15" alt="" />
                            {customerInfo.default_payment_method.id !== '' ? 
                              <p className="gotham-md-lightgray-15 ml-2">{customerInfo.default_payment_method.brand.toUpperCase()} **** {customerInfo.default_payment_method.last4}</p> :
                              <p className="gotham-md-lightgray-15 ml-2">Selecciona un método de pago</p>
                            }
                          </div>
                        </div>
                        <img className="cursor-pointer" height="10" src={dropdown_img} alt="" onClick={this.handlePaymentMethodDropdown}/>
                      </div>
                      <hr className="cart_info-inline"></hr>
                      {showSelectPaymentMethod && 
                        <SelectPaymentMethod 
                          isViewAccount={false} 
                          handleShowCreateCard={this.handleShowCreateCard}
                          handleSelectCard={this.handleSelectCard}
                          defaultPayment={customerInfo.default_payment_method}
                        />
                      }
                      
                      <div id="apply-voucher-container" className="py-3 px-4">
                        <ApplyVoucher
                          applyVoucherCallbak={this.applyVoucherCallbak}
                          orderSummary={cost}
                        />
                      </div>
                    </div>
                    <div className="cart_summary my-4">
                      <OrderSummary
                        orderSummary={cost}
                        placeOrder={this.placeOrder}
                      />
                    </div>
                  </div>
                </div>
                <AcceessoriesCart 
                  history={this.props.history}
                  handleAddAccessoryToCart={this.handleAddAccessoryToCart} 
                />
              </div>
              )}
                <Footer history={this.props.history}/>
            </div>
          } />
        }
      </React.Fragment>
    )
  }
}



const mapStateToProps = state => ({
  isLoading: state.globalReducer.isLoading,
  cartProducts: state.collections.cartProducts,
  showingError: state.globalReducer.showingError,
  errorMessage: state.globalReducer.errorMessage,
  orderSummary: state.checkoutReducer.orderSummary,
  orderId: state.checkoutReducer.orderId,
  paymentReference: state.checkoutReducer.paymentReference,
  cashPaymentReference: state.checkoutReducer.cashPaymentReference,
  customerInfo: state.checkoutReducer.customerInfo,
  auth: state.firebase.auth,
})

const mapDispatchToProps = {
  getCartProductsQuantityAction: getCartProductsQuantityAction,
  hideErrorAction: hideErrorAction,
  addProductsToCheckoutAction: addProductsToCheckoutAction,
  deleteProductFromCartAction: deleteProductFromCartAction,
  changeRouteAction: changeRouteAction,
  showErrorAction: showErrorAction,
  calculateOrderAction: calculateOrderAction,
  updateCartProductsAction: updateCartProductsAction,
  placeOrderAction: placeOrderAction,
  finishLoadingAction: finishLoadingAction,
  updateCustomerInfoAction: updateCustomerInfoAction
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(Cart);