import React from 'react';

import './AccessoriesCartItem.scss';

export default function AccessoriesCartItem(props) {
  const { item } = props;
  const backgroundStyles = {
    backgroundImage: 'url(' + item.images[0].url + ')',
    backgroundSize: 'cover',
    opacity: item.available ? 1 : 0.7,
    // opacity: 0.7
  }
  return (
    <div className="accessories-cart-item p-1 d-flex flex-column justify-content-start">
      <div className="accessories-cart-item_img-container d-flex justify-content-center align-items-center cursor-pointer" style={backgroundStyles} onClick={() => props.handleSelectAccessory(item)}>
        {!item.available && <p className="accessories-cart_text">PRÓXIMAMENTE</p>}
      </div>
      {/* <img className="accessories-cart-item_img cursor-pointer" src={item.images[0].url} height="70%" alt="" onClick={() => props.handleSelectAccessory(item)}/> */}
      <div className="accessories-cart-item_data d-flex flex-column justify-content-center">
        <p className="gotham-bold-18">{item.name}</p>
        <p className="gotham-book-13">{item.short_description}</p>
        <p className="gotham-md-18">${item.price} MXN</p>
        <button className={item.available ? "mt-2 generic-button-blue" : "mt-2 generic-button-gray"} onClick={() => props.handleAddAccessoryToCart(item)}>
          Agregar al carrito
        </button>
      </div>
    </div>
  );
}