import React from 'react';
import close_img from '../../images/newDesignImages/close-gray.png';
import question_face from '../../images/newDesignImages/question-face.png'
import './RegularPopup.scss';

export default function RegularPopup(props) {
  return (
    <div className="success-popup_main d-flex justify-content-center align-items-center">
      <div className="success-popup_inside d-flex flex-column justify-content-center align-items-center">
        <img className="success-popup_close cursor-pointer" src={close_img} height="20" alt="" onClick={props.handleShowReorderConfirmation} />
        <img className="mb-5" src={question_face} />
        <p className="gotham-bold-25 mb-2">¡Importante!</p>
        <p className="gotham-book-18" style={{textAlign: 'center'}}>
          ¿Estás seguro que deseas guardar los cambios? <br></br> Una vez guardados los cambios, no se pueden restaurar.
        </p>
        <div className="success-popup_buttons mt-5 d-flex align-items-center justify-content-center">
          <button className="regular-popup_cancel-btn mr-2" onClick={props.handleShowReorderConfirmation}>
            Cancelar
          </button>
          <button className="failure-popup_continue-btn ml-2" onClick={props.handleSaveReorder}>
            Continuar
          </button>
        </div>
      </div>
    </div>
  );
}