import React, {useState} from 'react';


function SelectOptions(props) {
  const [mouseOverItem, changeMouseOverItem] = useState('');
  const horizontalMargin = props.fromCanvas ? 'mx-5 d-flex flex-column align-items-center cursor-pointer' : 'mx-4 d-flex flex-column align-items-center cursor-pointer';
  return (
    <div className="d-flex justify-content-center align-items-center">
      {props.selectOptionsArray.map((item, i) => (
        <div key={i} className={horizontalMargin} onClick={() => props.selectOptionToUpdate(item.name)} onMouseEnter={() => changeMouseOverItem(item.name)} onMouseLeave={() => changeMouseOverItem('')}>
          <img src={(mouseOverItem === item.name || props.optionToUpdate === item.name) ? item.img_active : item.img_inactive} height="40" alt=""/>
          {(mouseOverItem === item.name || props.optionToUpdate === item.name) ? <p className="gotham-md-blue-12 mt-1">{item.title}</p> : <p className="gotham-md-gray-12 mt-1">{item.title}</p>}
        </div>
      ))}
    </div>
  );
}

export default SelectOptions;