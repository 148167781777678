import React from 'react';
import { Link } from 'react-router-dom';
import img_album from '../../images/newDesignImages/from-photos.png';
import source_device from '../../images/newDesignImages/from-pc.png';
import source_quotes from '../../images/newDesignImages/from-phrases.png';
import predesign from '../../images/newDesignImages/from-covers.png';
import './ChangeCoverSelectSource.scss';


const SelectCoverFromSourceButtons = (props) => {
  const { selectFromAlbumCallback, selectFromFileCallback, selectPhrasesCallback, selectCoversHandler } = props;
  return (
    <React.Fragment>
      <div className="w-100 my-4 d-flex justify-content-center">
        <div className="source-buttons_main shadow">
          <div className="source-buttons_top d-flex justify-content-center align-items-center">
            <p className="gotham-md-white-18">Selecciona tu foto: </p>
          </div>
          <div className="source-buttons_bottom px-2 pt-3 d-flex justify-content-center align-items-center">
            <div className="py-4 px-5 d-flex flex-column align-items-center justify-content-center cursor-pointer" onClick={selectFromAlbumCallback}>
              <img src={img_album} width="70" height="55" alt="img" />
              <p className="gotham-md-15 pt-3 text-center">Imágenes <br/>desde el álbum</p>
            </div>
            <div className="py-4 px-5 d-flex flex-column align-items-center justify-content-center cursor-pointer" onClick={selectFromFileCallback}>
              <img src={source_device} width="80" height="65" alt="img" />
              <p className="gotham-md-15 pt-3 card-title text-center">Computadora</p>
            </div>
            <div className="py-4 px-5 d-flex flex-column align-items-center justify-content-center cursor-pointer" onClick={selectPhrasesCallback}>
              <img src={source_quotes} width="70" height="70" alt="" />
              <p className="gotham-md-15 pt-3 card-title text-center">Frases</p>
            </div>
            <div className="pt-4 pb-2 px-5 d-flex flex-column align-items-center justify-content-center cursor-pointer" onClick={selectCoversHandler}>
              {/* <Link className="d-flex flex-column align-items-center justify-content-center" to={{ pathname: '/cover/categories' }}> */}
                <img src={predesign} width="70" height="70" alt="img" />
                <p className="gotham-md-15 pt-2 card-title text-center my-orders-link">Portadas <br/> prediseñadas</p>
              {/* </Link> */}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default SelectCoverFromSourceButtons;