import React, { Component } from 'react';
import TokenHelper from '../../utils/Firebase/TokenHelper';
import ApiCustomer from '../../utils/Api/ApiCustomer';
import Loading from '../Loading';
import Scrollable from '../Scrollable';
import OrderDetailProductItem from '../OrderDetailProductItem';
import Footer from '../Footer';
import AlertView from '../AlertView';
import Modal from '../Modal';

import box from '../../images/newDesignImages/box-black-img.png';
import back_arrow from '../../images/newDesignImages/back-arrow-blue.png';
import forth_arrow from '../../images/newDesignImages/forth-arrow-blue.png';
import my_address from '../../images/newDesignImages/my-addresses-clear.png';
import date_img from '../../images/newDesignImages/date-img.png';
import clock_img from '../../images/newDesignImages/clock-img.png';
import payment_method from '../../images/newDesignImages/payment-method.png';
import packing_img from '../../images/newDesignImages/packing-img.png';
import tracking_img from '../../images/newDesignImages/tracking-img.png'
import oxxo_pay from '../../images/newDesignImages/oxxo-pay-img.jpg';
import wallet from '../../images/newDesignImages/my-wallet-img.png';
import pixypesos from '../../images/newDesignImages/pixypesos-img.png';
import shipping_img from '../../images/newDesignImages/shipping-img.png';
import products_img from '../../images/newDesignImages/products-inactive.png';
import visa_img from '../../images/newDesignImages/visa-img.png';
import master_img from '../../images/mastercard.png';
import payd_img from '../../images/newDesignImages/status-payd-img.png';
import cancelled_img from '../../images/newDesignImages/status-cancelled-img.png';
import delivered_img from '../../images/newDesignImages/status-delivered-img.png';
import printed_img from '../../images/newDesignImages/status-printed-img.png';
import shipped_img from '../../images/newDesignImages/status-shipped-img.png';
import cupon_img from '../../images/newDesignImages/cupon-img.png';
import './OrderDetail.scss';


class OrderDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderDetail: null,
      orderProducts: null,
      giftcardCategories: null,
      isLoading: true,
      showError: false,
      errorMessage: '',
      orders: null
    }
  }

  componentDidMount() {
    const { id, orders } = this.props.location;
    if (!id) {
      this.props.history.push('/orders/');
    } else {
      this.getOrderDetails(id);
      this.getOrderProducts(id);
      this.getGiftcardCategories();
      this.setState({ orders })
    }
  }

  getOrderDetails = (id) => {
    TokenHelper.getUserToken().then(idToken => {
      ApiCustomer.customerOrderDetail({
        token: idToken,
        order_id: id
      }).then(response => {
        if (response.data.success) {
          this.setState({
            orderDetail: response.data.data,
            isLoading: false
          })
        } else {
          this.setState({
            showError: true,
            errorMessage: 'Ocurrió un error, intenta más tarde',
            isLoading: false
          });
        }
      }).catch(error => {
        this.setState({
          showError: true,
          errorMessage: 'Ocurrió un error, intenta más tarde',
          isLoading: false
        });
      })
    }).catch(error => {
      this.setState({
        showError: true,
        errorMessage: 'Ocurrió un error, intenta más tarde',
        isLoading: false
      });
    })
  }

  getOrderProducts = (id) => {
    TokenHelper.getUserToken().then(idToken => {
      ApiCustomer.customerOrderProducts({
        token: idToken,
        order_id: id
      }).then(response => {
        if (response.data.success) {
          const { albums, giftcards, magnets, pictures, ornaments, items } = response.data.data[0].products;
          // Next is for adding product_type key to each product
          albums.map(item => item['product_type'] = 'album');
          giftcards.map(item => item['product_type'] = 'giftcard');
          magnets.map(item => item['product_type'] = 'magnet');
          pictures.map(item => item['product_type'] = 'picture');
          ornaments.map(item => item['product_type'] = 'ornament');
          items.map(item => item['product_type'] = 'item')
          let products = albums.concat(giftcards).concat(magnets).concat(pictures).concat(ornaments).concat(items);
          this.setState({ orderProducts: products });
        } else {
          this.setState({
            showError: true,
            errorMessage: 'Ocurrió un error, intenta más tarde.',
            isLoading: false
          });
        }
      }).catch(error => {
        this.setState({
          showError: true,
          errorMessage: 'Ocurrió un error, intenta más tarde.',
          isLoading: false
        });
      })
    }).catch(error => {
      this.setState({
        showError: true,
        errorMessage: 'Ocurrió un error, intenta más tarde.',
        isLoading: false
      });
    })
  }


  getGiftcardCategories = () => {
    TokenHelper.getUserToken().then(idToken => {
      ApiCustomer.getAllGiftcards({ token: idToken }).then(response => {
        if (response.data.success) {
          this.setState({ giftcardCategories: response.data.data });
        } else {
          this.setState({
            showError: true,
            errorMessage: 'Ocurrió un error, intenta más tarde.',
            isLoading: false
          });
        }
      }).catch(error => {
        this.setState({
          showError: true,
          errorMessage: 'Ocurrió un error, intenta más tarde.',
          isLoading: false
        });
      })
    }).catch(error => {
      this.setState({
        showError: true,
        errorMessage: 'Ocurrió un error, intenta más tarde.',
        isLoading: false
      });
    })
  }

  okErrorHandler = () => {
    this.setState({
      showError: false,
      errorMessage: ''
    });
  }


  handleChangeOrder = (prevOrNext) => {
    let idsArray = this.state.orders.map(item => item.id);
    let index = idsArray.indexOf(this.state.orderDetail.id)
    if (prevOrNext === 'previous') {
      this.setState({ isLoading: true });
      this.getOrderDetails(idsArray[index - 1]);
      this.getOrderProducts(idsArray[index - 1]);
    } else {
      this.setState({ isLoading: true });
      this.getOrderDetails(idsArray[index + 1]);
      this.getOrderProducts(idsArray[index + 1]);
    }
  }

  handleTrackShipping = (shippingCompany) => {
    const { tracking_number } = this.state.orderDetail.shipping_info;
    const lowerCaseShipping = shippingCompany.toLowerCase();
    if (lowerCaseShipping === 'fedex') {
      window.open(`https://www.fedex.com/apps/fedextrack/?action=track&trackingnumber=${tracking_number}&cntry_code=mx`);
    } else if (lowerCaseShipping === 'estafeta') {
      window.open(`https://rastreositecorecms.azurewebsites.net/Tracking/searchByGet/?wayBillType=1&wayBill=${tracking_number}`)
    } else if (lowerCaseShipping === 'jtexpress') {
      window.open(`https://www.jtexpress.mx/trajectoryQuery?waybillNo=${tracking_number}`)
    } else if (lowerCaseShipping === 'metropolitan') {
      window.open(`http://ape.mcssolution.com.mx/ecommerce/consulta/guia`)
    } else {
      return;
    }
  }

  render() {
    const { orderDetail, orderProducts, giftcardCategories, isLoading, showError, errorMessage, orders } = this.state;
    const lastFour = (string) => string.slice(string.length - 4)
    const paymentMethodNumber = () => {
      if (orderDetail) {
        const { payment_method, cash_payment_reference } = orderDetail.payment_info;
        if (payment_method.includes('Oxxo')) {
          return cash_payment_reference;
        } else {
          return `**** **** **** ${lastFour(payment_method)}`;
        }
      } else {
        return null;
      }
    }
    const paymentMethodImage = () => {
      if (orderDetail) {
        const { payment_method } = orderDetail.payment_info;
        if (payment_method.includes('Oxxo')) {
          return <img src={oxxo_pay} alt="" height="60" />;
        } else if (payment_method.includes('visa')) {
          return <img src={visa_img} alt="" height="20" />;
        } else if (payment_method.includes('mastercard')) {
          return <img src={master_img} alt="" height="30" />
        } else {
          return null;
        }
      } else {
        return null;
      }
    }

    function statusOrder(statusOrder) {
      let status = ""
      switch (statusOrder) {
        case "created": status = "Creada"; break;
        case "paid": status = "Pagada"; break;
        case "cancelled": status = "Cancelada"; break;
        case "shipped": status = "Enviada"; break;
        case "delivered": status = "Entregada"; break;
        case "declined": status = "Declinada"; break;
        case "expired": status = "Cancelada"; break;
        case "pending_payment": status = "Pendiente de pago"; break;
        case "printed": status = "En imprenta"; break;
        default: status = "-";
      }
      return status;
    }

    function statusColorImg(statusOrder) {
      let status = ""
      switch (statusOrder) {
        case "created": status = <div></div>; break;
        case "paid": status = <img src={payd_img} alt="" />; break;
        case "cancelled": status = <img src={cancelled_img} alt="" />; break;
        case "shipped": status = <img src={shipped_img} />; break;
        case "delivered": status = <img src={delivered_img} alt="" />; break;
        case "declined": status = <div></div>; break;
        case "expired": status = <div></div>; break;
        case "pending_payment": status = <div></div>; break;
        case "printed": status = <img src={printed_img} alt="" />; break;
        default: status = "-";
      }
      return status;
    }
    let idsArray = orders && orders.map(item => item.id);
    let index = orderDetail && idsArray.indexOf(orderDetail.id)
    return (
      isLoading ? <Loading /> :
        <React.Fragment>
          {
            showError &&
            <Modal>
              <AlertView
                title={'¡Ups!'}
                message={errorMessage}
                okHandler={this.okErrorHandler}
                cancel={true} />
            </Modal>
          }
          <Scrollable scrollableContent={
            <div className="order-detail_main">

              <div className="d-flex justify-content-between align-items-center mx-5 py-3">
                <div className="d-flex align-items-center">
                  <img src={box} alt="" height="25" />
                  <p className="ml-4 futura-bold-25">Mis ordenes</p>
                </div>
                <div className="d-flex">
                  {
                    index !== 0 &&
                    <div className="d-flex align-items-center mr-5 cursor-pointer" onClick={() => this.handleChangeOrder('previous')}>
                      <img src={back_arrow} height="20" alt="" />
                      <p className="futura-md-gray-18 ml-3">Anterior</p>
                    </div>
                  }
                  {
                    index !== idsArray.length - 1 &&
                    <div className="d-flex align-items-center cursor-pointer" onClick={() => this.handleChangeOrder('next')}>
                      <p className="futura-md-gray-18 mr-3">Siguiente</p>
                      <img src={forth_arrow} alt="" />
                    </div>
                  }
                </div>
              </div>

              <div className="order-detail_order-num d-flex justify-content-center py-4">
                <p className="futura-bkbt-white-20">Número de orden: <strong>{orderDetail.id}</strong></p>
              </div>

              <div className="container">
                <div className="row">
                  <div className="col-6 order-detail_container">
                    <div className="order-detail_container-inside d-flex flex-column my-5">

                      <div className="d-flex mx-4 pt-2 pb-4">
                        <div className="order-detail_img pt-3 d-flex align-items-start justify-content-center">
                          <img src={my_address} alt="" height="25" />
                        </div>
                        <div className="d-flex flex-column align-items-start">
                          <p className="futura-md-lightgray-15 py-3 pl-3">Dirección de entrega:</p>
                          <p className="futura-md-15 pl-3">{orderDetail.shipping_info.address.name}</p>
                          <p className="futura-bkbt-15 pl-3">{orderDetail.shipping_info.address.street} {orderDetail.shipping_info.address.number}, {orderDetail.shipping_info.address.neighborhood}, {orderDetail.shipping_info.address.state}, México. {orderDetail.shipping_info.address.zip_code}</p>
                          <p className="futura-bkbt-gray-15 pl-3">{orderDetail.shipping_info.address.references}</p>
                        </div>
                      </div>

                      <div className="d-flex order-detail_item mx-4 py-3">
                        <div className="order-detail_img d-flex align-items-center justify-content-center">
                          <img src={date_img} alt="" height="25" />
                        </div>
                        <div className="order-detail_item-content d-flex justify-content-between">
                          <div className="pl-3">
                            <p className="futura-md-lightgray-15">Fecha pedido:</p>
                            <p className="futura-bkbt-15">{orderDetail.created_at_date}</p>
                          </div>
                          <div className="d-flex align-items-center">
                            <img className="pr-2" src={clock_img} alt="" height="25" />
                            <div>
                              <p className="futura-md-lightgray-15">Hora de pedido:</p>
                              <p className="futura-bkbt-15">{orderDetail.created_at_time} hrs.</p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="d-flex order-detail_item mx-4 py-3">
                        <div className="order-detail_img d-flex justify-content-center align-items-center" >
                          <img src={payment_method} alt="" height="25" />
                        </div>
                        <div className="order-detail_item-content pl-3 d-flex justify-content-between align-items-center">
                          <p className="futura-md-lightgray-15">Método de pago</p>
                          <div className="d-flex align-items-center">
                            <p className="pr-2 futura-bkbt-15">{paymentMethodNumber()}</p>
                            {paymentMethodImage()}
                          </div>
                        </div>
                      </div>

                      <div className="d-flex order-detail_item-gray px-4 py-3">
                        <div className="order-detail_img d-flex justify-content-center align-items-center" >
                          <img src={wallet} alt="" height="20" />
                        </div>
                        <div className="d-flex justify-content-start">
                          <p className="pl-3 futura-md-lightgray-15">Detalles de pago:</p>
                        </div>
                      </div>

                      <div className="d-flex order-detail_item-gray px-4 py-2">
                        <div className="order-detail_img d-flex justify-content-center align-items-center">
                          <img src={pixypesos} alt="" height="18" />
                        </div>
                        <div className="order-detail_item-content pl-4 d-flex justify-content-between">
                          <p className="futura-bkbt-15">Descuento Pixypesos</p>
                          <p className="futura-bkbt-15">$ {orderDetail.payment_info.wallet_discount}</p>
                        </div>
                      </div>

                      <div className="d-flex order-detail_item-gray px-4 py-2">
                        <div className="order-detail_img d-flex justify-content-center align-items-center">
                          <img src={cupon_img} alt="" height="15" />
                        </div>
                        <div className="order-detail_item-content pl-4 d-flex justify-content-between">
                          <p className="futura-bkbt-15">Cupón de descuento</p>
                          <p className="futura-bkbt-15">{orderDetail.payment_info.voucher_code}</p>
                        </div>
                      </div>


                      <div className="d-flex order-detail_item-gray px-4 py-2">
                        <div className="order-detail_img d-flex justify-content-center align-items-center">
                          <img src={shipping_img} alt="" height="18" />
                        </div>
                        <div className="order-detail_item-content pl-4 d-flex justify-content-between">
                          <p className="futura-bkbt-15">Gastos de envío</p>
                          <p className="futura-bkbt-15">$ {orderDetail.payment_info.total_shipping}</p>
                        </div>
                      </div>

                      <div className="d-flex order-detail_item-gray px-4 pt-2 pb-3">
                        <div className="order-detail_img"></div>
                        <div className="order-detail_item-content pl-4 d-flex justify-content-between">
                          <p className="futura-bold-15">Total:</p>
                          <p className="gotham-bold-blue-25">$ {orderDetail.payment_info.total_paid}</p>
                        </div>

                      </div>


                      <div className="d-flex mx-4 py-4">
                        <div className="order-detail_img d-flex justify-content-center align-items-center">
                          <img src={packing_img} alt="" height="18" />
                        </div>
                        <div className="order-detail_item-content d-flex justify-content-between">
                          <div className="pl-3">
                            <p className="futura-md-lightgray-15">Paquetería:</p>
                            <p className="futura-bkbt-15">{orderDetail.shipping_info.tracking_number ? orderDetail.shipping_info.tracking_number : 'Pendiente'}</p>
                          </div>
                          <div>
                            <div className="d-flex justify-content-center align-items-center">
                              <img className="pr-2" src={tracking_img} alt="" height="25" />
                              <div>
                                <p className="futura-md-lightgray-15">Guía de rastreo:</p>
                                {orderDetail.shipping_info.tracking_number ? <p className="futura-bkbt-15 cursor-pointer" onClick={() => this.handleTrackShipping(orderDetail.shipping_info.shipping_company)}><u>{orderDetail.shipping_info.tracking_number}</u></p> : null}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="d-flex order-detail_item mx-4 py-3">
                        <div className="order-detail_img"></div>
                        <div className="order-detail_item-content pl-3 d-flex justify-content-between">
                          <p className="futura-md-lightgray-15">Estatus:</p>
                          <div className="d-flex justify-content-end align-items-center">
                            {statusColorImg(orderDetail.status)}
                            <p className="futura-bkbt-15 pl-3">{statusOrder(orderDetail.status)}</p>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>


                  <div className="col-6 order-detail_container">
                    <div className="order-detail_container-inside my-5">
                      <div className="d-flex align-items-center pl-5 pt-3 pb-2">
                        <img src={products_img} alt="" />
                        <p className="futura-md-gray-18 ml-3">Productos Pixyalbum</p>
                      </div>
                      <div>
                        {orderProducts && orderProducts.map((product, index) =>
                          <OrderDetailProductItem
                            key={index}
                            product={product}
                            giftcardCategories={giftcardCategories}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row order-detail_return-btn py-5 d-flex justify-content-center">
                  <button className="futura-bold-white-18" onClick={() => this.props.history.push('/account')}>Regresar a Mi Cuenta</button>
                </div>

              </div>
              <Footer history={this.props.history}/>



            </div>} />

        </React.Fragment>
    );
  }
}

export default OrderDetail;