import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { UserIsAuthenticated, UserIsNotAuthenticated } from '../../utils/Firebase/Auth';
import store from '../../store';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/browser';
import { isMobileOnly } from 'react-device-detect';

// Components 
import SignIn from '../../routes/SignIn';
import SignUp from '../../routes/SignUp';
import Navigation from './Navigation';
import Collections from '../../routes/Collections';
import Albums from '../../routes/Albums';
import CreateProject from '../../components/CreateProject';
import ImagePickerWithSource from '../../components/ImagePickerWithSource';
import InstagramHandler from '../../components/InstagramHandler';
import AlbumPickerWithSource from '../../components/AlbumPickerWithSource';
import ImagePickerWithAlbum from '../../components/ImagePickerWithAlbum';
import ImagesDragAndDrop from '../../components/ImagesDragAndDrop';
import PhotoDetail from '../../routes/PhotoDetail';
import Cart from '../../routes/Cart';
import Checkout from '../../routes/Checkout';
import SortableListBook from '../../components/SortableListBooks';
import Wallet from '../../routes/Wallet';
import WalletTransactions from '../../routes/WalletTransactions';
import Orders from '../../routes/Orders';
import MyAccount from '../../routes/MyAccount';
import GoToAppsScreen from '../../components/GoToAppsScreen';
import CoverCategories from '../../routes/CoverCategories';
import Covers from '../../routes/Covers';
import AddTitleToCover from '../../components/AddTitleToCover';
import GiftCards from '../../components/GiftCards';
import OrderDetail from '../../components/OrderDetail';
import StartProject from '../../components/StartProject';
import MagnetDetail from '../../components/MagnetDetail';
import ProductInfo from '../../components/ProductInfo';
import OrderSuccess from '../OrderSuccess';
import CanvasDetail from '../CanvasDetail';
import Accessories from '../Accessories';
import AccessoriesDetail from '../AccessoryDetail';
import CreateAlbumConfigurations from '../../components/CreateAlbumConfigurations';
import OrnamentDetail from '../../components/OrnamentDetail';


// console.log(window._env_.SENTY_DSN);
// console.log(window._env_.SENTRY_ENV);

Sentry.init({
  dsn: window._env_.SENTY_DSN,
  environment: window._env_.SENTRY_ENV
});

function App() {
  return (
    isMobileOnly ? <GoToAppsScreen /> :
      <div className="main-app d-flex flex-column">
        <Provider store={store}>
          <Router>
            <Navigation />
            <main className="container-main d-flex">
              <div className="workspace-main-wrapper d-flex flex-column">
                <Switch>
                  <Route exact path="/" component={UserIsNotAuthenticated(SignUp)} />
                  <Route exact path="/login" component={UserIsNotAuthenticated(SignIn)} />
                  {/* Redirection handled within SignUp component in order to call WS before go to collections, we cound't find a way to do it with the HOC */}
                  <Route exact path="/signup" component={UserIsNotAuthenticated(SignUp)} />
                  <Route exact path="/projects" component={UserIsAuthenticated(Collections)} />
                  <Route exact path="/photo/detail" component={UserIsAuthenticated(PhotoDetail)} />
                  <Route exact path="/source" component={UserIsAuthenticated(CreateProject)} />
                  <Route exact path="/source/select/photos" component={UserIsAuthenticated(ImagePickerWithSource)} />
                  <Route exact path="/source/select/album/photos" component={UserIsAuthenticated(ImagePickerWithAlbum)} />
                  <Route exact path="/source/photos/upload" component={UserIsAuthenticated(ImagesDragAndDrop)} />
                  <Route exact path="/source/select/album" component={UserIsAuthenticated(AlbumPickerWithSource)} />
                  <Route exact path="/_/insta/handletoken" component={UserIsAuthenticated(InstagramHandler)} />
                  <Route exact path="/cart" component={UserIsAuthenticated(Cart)} />
                  <Route exact path="/checkout" component={UserIsAuthenticated(Checkout)} />
                  <Route exact path="/collection/albums/detail" component={UserIsAuthenticated(SortableListBook)} />
                  <Route exact path="/wallet" component={UserIsAuthenticated(Wallet)} />
                  <Route exact path="/wallet/transactions" component={UserIsAuthenticated(WalletTransactions)} />
                  <Route exact path="/orders" component={UserIsAuthenticated(Orders)} />
                  <Route exact path="/account" component={UserIsAuthenticated(MyAccount)} />
                  <Route exact path="/cover/categories" component={UserIsAuthenticated(CoverCategories)} />
                  <Route exact path="/covers" component={UserIsAuthenticated(Covers)} />
                  <Route exact path="/cover/title" component={UserIsAuthenticated(AddTitleToCover)} />
                  <Route exact path="/giftcard" component={UserIsAuthenticated(GiftCards)} />
                  <Route exact path="/order/detail" component={UserIsAuthenticated(OrderDetail)} />
                  <Route exact path="/create" component={UserIsAuthenticated(StartProject)} />
                  <Route exact path="/magnet/detail" component={UserIsAuthenticated(MagnetDetail)} />
                  <Route exact path="/product/info" component={UserIsAuthenticated(ProductInfo)} />
                  <Route exact path="/canvas/detail" component={UserIsAuthenticated(CanvasDetail)} />
                  <Route exact path="/order-success" component={UserIsAuthenticated(OrderSuccess)} />
                  <Route exact path="/accessories" component={UserIsAuthenticated(Accessories)} />
                  <Route exact path="/accessory/detail" component={UserIsAuthenticated(AccessoriesDetail)} />
                  <Route exact path="/album-config" component={UserIsAuthenticated(CreateAlbumConfigurations)} />
                  <Route exact path="/ornament/detail" component={UserIsAuthenticated(OrnamentDetail)} />
                </Switch>
              </div>
            </main>
          </Router>
        </Provider>
      </div>
  );
}

export default App;
