import React from 'react';
import './SelectQuotesImage.scss';


function SelectQuotesImageItem(props) {
  const imgStyle = {
    background: 'url(' + props.item.image_url + ') center no-repeat',
    backgroundSize: 'cover'
  }
  return (
    props.showQuotesCathegory ?
      <div className="from-album-item_main d-flex justify-content-center align-items-center">
        <img className="from-album-item_img cursor-pointer" src={props.item.image_url} alt="" onClick={() => props.selectPhotoForEditHandler(props.item)}/>
      </div>
      :
      <div className="from-album-item_main d-flex justify-content-center align-items-center">
        <div className="selectquotes-item_cathegory d-flex flex-column justify-content-center align-items-center cursor-pointer" onClick={() => props.handleSelectAlbum(props.item.id)}>
          <img className="selectquotes-item_img" src={props.item.image_url} alt="" />
          <div className="selectquotes-item_cat-name d-flex justify-content-center align-items-center">
            <p className="gotham-md-lightgray-15">{props.item.name}</p>
          </div>
        </div>
      </div>
  );
}

export default SelectQuotesImageItem;