import React from 'react';
import pixypesos from '../../images/newDesignImages/pixypesos-img-v2.png';
import shipping from '../../images/newDesignImages/shipping-img.png';
import voucher_img from '../../images/newDesignImages/voucher-img.png';
import './OrderSummary.scss';

const OrderSummary = (props) => {
  const { orderSummary, totalOfProducts, cost } = props;
  return (
    <div className="py-4">
      <div className="px-3 pb-2 d-flex justify-content-between align-items-center">
        <p className="gotham-book-15">Subtotal:</p>
        <p className="gotham-book-15">MXN {orderSummary.sub_total % 1 === 0 ? ('$ ' + orderSummary.sub_total + '.00') : ('$ ' + orderSummary.sub_total)}</p>
      </div>

      {orderSummary.voucher_value && 
        <div className="px-3 py-3 d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <img src={voucher_img} height="15" alt=""/>
            <p className="gotham-md-gray-15 ml-2">{orderSummary.voucher_code}</p>
          </div>
          <p className="gotham-book-15">MXN {orderSummary.voucher_value % 1 === 0 ? (orderSummary.voucher_value + '.00') : (orderSummary.voucher_value)}</p>
        </div>
      }
      {orderSummary.voucher_value && <hr className="cart_info-inline"></hr>}

      <div className="px-3 py-3 d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-center">
          <img height="20" src={pixypesos} alt=""/>
          <p className="gotham-bold-purple-15 ml-2">Descuento Pixypesos</p>
        </div>
        <p className="gotham-bold-purple-15">MXN {orderSummary.wallet_discount % 1 === 0 ? ('$ -' + orderSummary.wallet_discount + '.00') : ('$ -' + orderSummary.wallet_discount)}</p>
      </div>
      <hr className="cart_info-inline"></hr>
      <div className="px-3 py-3 d-flex justify-content-between align-items-center">
        <p className="gotham-book-gray2-15">Gastos de envío</p>
        <p className="gotham-book-gray2-15">MXN {orderSummary.shipping % 1 === 0 ? ('$ ' + orderSummary.shipping + '.00') : ('$ ' + orderSummary.shipping)}</p>
      </div>
      <hr className="cart_info-inline-v2"></hr>
      <div className="px-3 py-3 d-flex justify-content-between align-items-center">
        <p className="gotham-bold-15">TOTAL:</p>
        <p className="gotham-bold-15">MXN {orderSummary.total % 1 === 0 ? ('$ ' + orderSummary.total + '.00') : ('$ ' + orderSummary.total)}</p>
      </div>
      <div className="d-flex justify-content-center align-items-center">
        <button className="summary_buy-btn my-3" onClick={props.placeOrder}>
          <p className="gotham-bold-white-18">Completar compra</p>
        </button>
      </div>
    </div>
  );
}

export default OrderSummary;