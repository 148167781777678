import React, { Component } from 'react';
import TokenHelper from '../../utils/Firebase/TokenHelper';
import ApiCustomer from '../../utils/Api/ApiCustomer';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { changeRouteAction, changeProjectTypeAction } from '../../actions/NavigationActions';
import CreateProductCard from '../CreateProductCard';
import Scrollable from '../Scrollable';
import Footer from '../Footer';
import AlertView from '../AlertView';
import Modal from '../Modal';
import Loading from '../Loading';


class StartProject extends Component {
  constructor(props) {
    super(props);
    this.state = {
      availableProducts: null,
      isLoading: true,
      showError: false,
      errorMessage: ''
    }
  }

  componentDidMount() {
    this.props.changeRouteAction('create');
    this.getProducts();
  }

  getProducts = () => {
    TokenHelper.getUserToken().then(idToken => {
      ApiCustomer.getProducts({
        token: idToken,
        version: "2"
      }).then(response => {
        if (response.data.success) {
          this.setState({
            availableProducts: response.data.data,
            isLoading: false
          });
        } else {
          this.setState({
            showError: true,
            errorMessage: 'Ha ocurrido un error, intenta más tarde.',
            isLoading: false
          });
        }
      }).catch(error => {
        this.setState({
          showError: true,
          errorMessage: 'Ha ocurrido un error, intenta más tarde.',
          isLoading: false
        });
      })
    }).catch(error => {
      this.setState({
        showError: true,
        errorMessage: 'Ha ocurrido un error, intenta más tarde.',
        isLoading: false
      });
    });
  }

  handleCreateClick = (productType) => {
    if (productType === "item") {
      this.props.history.push('/accessories')
    } else {
      this.props.history.push({
        pathname: '/product/info',
        state: this.state.availableProducts.filter(item => item.product_type === productType)
      });
    }
  }

  okErrorHandler = () => {
    this.setState({ showError: false });
    this.props.history.push('/');
  }


  render() {
    const { availableProducts, isLoading, showError, errorMessage } = this.state;
    return (
      <React.Fragment>
        {
          showError &&
          <Modal>
            <AlertView
              title={'¡Ups!'}
              message={errorMessage}
              okHandler={this.okErrorHandler}
              cancel={true} />
          </Modal>
        }
        {isLoading ? <Loading /> :
          <Scrollable scrollableContent={
            <div>
              <div className="py-5 d-flex justify-content-center">
                <div className="d-flex flex-wrap">
                  {availableProducts && availableProducts.map((item, i) =>
                    <div key={i}>
                      <CreateProductCard
                        item={item}
                        image={item.image}
                        title={item.title}
                        subtitle={item.subtitle}
                        handleCreateClick={this.handleCreateClick}
                      />
                    </div>
                  )}
                </div>
              </div>
              <Footer history={this.props.history}/>
            </div>
          } />
        }
      </React.Fragment>
    );
  }
}


const mapStateToProps = state => ({
  projectType: state.navigationReducer.projectType
});

const mapDispatchToProps = {
  changeRouteAction: changeRouteAction,
  changeProjectTypeAction: changeProjectTypeAction
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(StartProject);
