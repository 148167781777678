import React from 'react';
import check_img from '../../images/newDesignImages/check.png';
import uncheck_img from '../../images/newDesignImages/elem-unchecked.png';
import './ImagePickerSelectableImage.scss';

const ImagePickerSelectableImage = ({ photo, callback, isPhotoAlreadyAdded }) => {
  const imgStyle = isPhotoAlreadyAdded(photo) ?
    { backgroundImage: 'url(' + photo.image_url + ')', border: '#51B7F3 4px solid', borderRadius: '3px' } :
    { backgroundImage: 'url(' + photo.image_url + ')' };
  return (
    <div className="col-sm-6 col-md-4 col-lg-3 col-xl-2 my-2 position-relative cursor-pointer" onClick={() => callback(photo)}>
      <div className="d-flex justify-content-center mb-2">
        <img src={isPhotoAlreadyAdded(photo) ? check_img : uncheck_img} alt="" height="20" />
      </div>
      <div className="square-image" style={imgStyle}></div>
    </div>
  );
}

export default ImagePickerSelectableImage;