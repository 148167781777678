import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { changeProjectTypeAction, selectOrnamentProject } from '../../actions/NavigationActions';
import { toggleIsAddingPhotosAction } from '../../actions/CreateProjectActions';
import { parseOrnamentConfiguration } from '../../utils/Parsers';
import ApiCustomer from '../../utils/Api/ApiCustomer';
import TokenHelper from '../../utils/Firebase/TokenHelper';

import Modal from '../Modal';
import AlertView from '../AlertView';
import OrnamentDetailItem from './OrnamentDetailItem';
import CreateProject from '../CreateProject';
import Footer from '../Footer';
import EditProductName from '../EditProductName';
import EditPhoto from '../EditPhoto';
import Loading from '../Loading';


import ornaments_icon from '../../images/newDesignImages/ornaments-icon.png';
import duplicate_active from '../../images/newDesignImages/duplicate-n2.png';
import duplicate_inactive from '../../images/newDesignImages/duplicate-inactive.png';
import trash_active from '../../images/newDesignImages/trash-img-n2.png';
import trash_inactive from '../../images/newDesignImages/trash-inactive.png';
import edit_img from '../../images/newDesignImages/edit-blue-img.png';

import './OrnamentDetail.scss';


class OrnamentDetail extends Component {

  constructor(props) {
    super(props);
    this.state = {
      ornament: null,
      photoLimit: 5,
      showError: false,
      errorMessage: '',
      selectedImages: [],
      showAddImagesSource: false,
      showEditOrnamentName: false,
      showEditImage: false,
      selectedImageForEdit: null,
      isLoading: true,
      mouseOverImage: null
    }
  }

  componentDidMount() {
    if (!this.props.location.state) {
      this.props.history.push('/projects/');
    } else {
      const { id, productType } = this.props.location.state;
      this.getOrnamentDetail(id, productType);
    }
  }

  getOrnamentDetail = (id, productType) => {
    TokenHelper.getUserToken().then(idToken => {
      ApiCustomer.getCustomerProductDetail({
        token: idToken,
        product_id: id,
        product_type: productType
      }).then(response => {
        if (response.data.success) {
          var photoLimit = null;
          if (response.data.data != null) {
            response.data.data .configurations.configurations.forEach(config => {
              if (config.type === "size") {
                photoLimit = parseInt(config.description);
              }
            });
          }
          this.setState({
            ornament: response.data.data,
            photoLimit: photoLimit,
            isLoading: false
          });
        } else {
          this.setState({
            showError: true,
            isLoading: false,
            errorMessage: 'Ocurrió un error, intenta más tarde.'
          });
        }
      }).catch(error => {
        this.setState({
          showError: true,
          isLoading: false,
          errorMessage: 'Ocurrió un error, intenta más tarde.'
        });
      })
    }).catch(error => {
      this.setState({
        showError: true,
        isLoading: false,
        errorMessage: 'Ocurrió un error, intenta más tarde.'
      });
    })
  }

  okErrorHandler = () => {
    this.setState({
      showError: false,
      errorMessage: ''
    })
  }

  handleSelectImage = (image) => {
    const { selectedImages } = this.state;
    if (selectedImages.includes(image)) {
      this.setState({ selectedImages: selectedImages.filter(item => !(item.id === image.id)) });
    } else {
      this.setState({ selectedImages: selectedImages.concat(image) });
    }
  }

  handleDuplicateImages = (selectedImages) => {
    const { ornament } = this.state;
    if (selectedImages.length + ornament.photos.length > this.state.photoLimit) {
      this.setState({
        showError: true,
        errorMessage: 'No es posible agregar más de ' + this.state.photoLimit + ' fotos a tu proyecto.'
      })
    } else {
      let photosDataForAPI = [];
      let sortedPhotosForCreate = selectedImages.sort((a, b) => b.index - a.index);
      let index = ornament.photos.length;
      for (let photoElement of sortedPhotosForCreate) {
        photoElement.image_url = photoElement.image_url.slice(0, photoElement.image_url.indexOf('?'));
        photoElement.print_url = photoElement.print_url.slice(0, photoElement.print_url.indexOf('?'));
        delete photoElement.id;
        photoElement.index = index;
        photosDataForAPI.push(photoElement);
        index += 1;
      }
      this.addImagesToOrnament(ornament.id, true, photosDataForAPI);
    }
  }

  addImagesToOrnament = (id, isClone, photos) => {
    this.setState({ isLoading: true })
    TokenHelper.getUserToken().then(idToken => {
      ApiCustomer.addPhotosToCustomerOrnamentProject({
        token: idToken,
        ornament_project_id: id,
        is_clone: isClone,
        photos: photos
      }).then(response => {
        if (response.data.success) {
          this.setState({ selectedImages: [], isLoading: false });
          this.getOrnamentDetail(id, 'ornament');
        } else {
          this.setState({
            showError: true,
            errorMessage: 'Ocurrió un error, intenta más tarde.',
            isLoading: false
          });
        }
      }).catch(error => {
        this.setState({
          showError: true,
          errorMessage: 'Ocurrió un error, intenta más tarde.',
          isLoading: false
        });
      })
    }).catch(error => {
      this.setState({
        showError: true,
        errorMessage: 'Ocurrió un error, intenta más tarde.',
        isLoading: false
      });
    })
  }


  handleAddImageClick = () => {
    this.props.toggleIsAddingPhotosAction(true);
    this.props.selectOrnamentProject(this.state.ornament);
    this.props.changeProjectTypeAction('ornament');
    this.setState({ showAddImagesSource: true });
  }

  cancelCreateProjectConfirmation = () => {
    this.setState({ showAddImagesSource: false });
  }

  handleDeleteImages = (selectedImages) => {
    this.setState({ isLoading: true })
    const { ornament } = this.state;
    const ornamentProjectId = ornament.id;
    const selectedImagesIds = selectedImages.map(item => item.id);
    TokenHelper.getUserToken().then(idToken => {
      ApiCustomer.deletePhotosFromCustomerOrnamentProject({
        token: idToken,
        ornament_project_id: ornamentProjectId,
        photos_ids: selectedImagesIds
      }).then(response => {
        if (response.data.success) {
          this.setState({
            selectedImages: [],
            ornament: {
              ...ornament,
              photos: ornament.photos.filter(item => !selectedImages.includes(item))
            },
            isLoading: false
          })
        } else {
          this.setState({
            showError: true,
            errorMessage: 'Ocurrió un error, intenta más tarde.',
            isLoading: false
          });
        }
      }).catch(error => {
        this.setState({
          showError: true,
          errorMessage: 'Ocurrió un error, intenta más tarde.',
          isLoading: false
        });
      })
    }).catch(error => {
      this.setState({
        showError: true,
        errorMessage: 'Ocurrió un error, intenta más tarde.',
        isLoading: false
      });
    })
  }

  handleShowEditOrnamentName = () => {
    this.setState({ showEditOrnamentName: true });
  }

  cancelEditOrnamentName = () => {
    this.setState({ showEditOrnamentName: false });
  }

  handleEditOrnamentName = () => {
    this.setState({ showEditOrnamentName: false });
    this.getOrnamentDetail(this.state.ornament.id, 'ornament');
  }

  selectImageForEdit = (image) => {
    this.setState({
      showEditImage: true,
      selectedImageForEdit: image
    })
  }

  handleCloseImageForEdit = (editedImage) => {
    if (editedImage) {
      this.setState({
        showEditImage: false,
        isLoading: true
      });
    }
    this.setState({ showEditImage: false });
  }

  handleCropImageSuccess = (editedPhoto) => {
    const { ornament, selectedImageForEdit } = this.state;
    const updatedPhotos = ornament.photos.map(photo => {
      if (photo === selectedImageForEdit) {
        return editedPhoto;
      }
      return photo;
    })
    this.setState({
      isLoading: false,
      ornament: {
        ...ornament,
        photos: updatedPhotos
      }
    })
  }

  handleError = (message) => {
    this.setState({
      isLoading: false,
      showError: true,
      errorMessage: message,
      showEditOrnamentName: false,
      showEditImage: false
    });
  }

  handleMouseEnter = (image) => {
    this.setState({ mouseOverImage: image })
  }


  handleAddToCart = () => {
    const reducer = (accumulator, currentValue) => accumulator + currentValue;
    const totalPrice = this.props.cartProducts.map(item => item.price).reduce(reducer, 0) + this.state.ornament.price;
    if (totalPrice > 10000) {
      this.setState({
        showError: true,
        errorMessage: 'No es posible agregar más de $10,000 al carrito.'
      });
    } else if (this.state.ornament.photos.length < this.state.photoLimit) {
      this.setState({
        showError: true,
        errorMessage: 'Necesitas al menos ' + this.state.photoLimit + ' fotos para agregar el proyecto al carrito.'
      });
    } else {
      this.addOrnamentToCart();
    }
  }

  addOrnamentToCart = () => {
    this.setState({ isLoading: true });
    let ornamentData = {
        id: this.state.ornament.id,
        name: this.state.ornament.name,
        photos: this.state.ornament.photos,
        price: this.state.ornament.price,
        configurations: parseOrnamentConfiguration(this.state.ornament.configurations)
      };
    TokenHelper.getUserToken().then(idToken => {
      ApiCustomer.addProductToCart({
        token: idToken,
        data: [ornamentData],
        product_type: 'ornament'
      }).then(response => {
        if (response.data.success) {
          this.props.history.push('/cart');
        } else {
          this.setState({
            isLoading: false,
            showError: true,
            errorMessage: 'Ocurrió un error, intenta más tarde.'
          });
        }
      }).catch(error => {
        this.setState({
          isLoading: false,
          showError: true,
          errorMessage: 'Ocurrió un error, intenta más tarde.'
        });
      })
    }).catch(error => {
      this.setState({
        isLoading: false,
        showError: true,
        errorMessage: 'Ocurrió un error, intenta más tarde.'
      });
    })
  }


  render() {
    const {
      isLoading,
      showError,
      errorMessage,
      ornament,
      photoLimit,
      selectedImages,
      showAddImagesSource,
      showEditOrnamentName,
      showEditImage,
      selectedImageForEdit,
      mouseOverImage
    } = this.state;
    const widthPercentage = ornament && ornament.photos.length * (100 / photoLimit);
    const photos = ornament && (ornament.photos.length === photoLimit ? ornament.photos : ornament.photos.concat('addPhoto'));
    return (
      isLoading ? <Loading /> :
        <div>
          {
            showError &&
            <Modal>
              <AlertView
                message={errorMessage}
                okHandler={this.okErrorHandler}
              />
            </Modal>
          }
          {
            showAddImagesSource &&
            <Modal>
              <CreateProject cancelHandler={this.cancelCreateProjectConfirmation} />
            </Modal>
          }
          {
            showEditOrnamentName &&
            <Modal>
              <EditProductName
                productType={'ornament'}
                name={ornament.name}
                id={ornament.id}
                handleEditOrnamentName={this.handleEditOrnamentName}
                cancelEditProductName={this.cancelEditOrnamentName}
                handleError={this.handleError}
              />
            </Modal>
          }
          {
            showEditImage &&
            <Modal>
              <EditPhoto
                productType={'ornament'}
                selectedImageForEdit={selectedImageForEdit}
                ornamentId={ornament.id}
                handleCloseImageForEdit={this.handleCloseImageForEdit}
                handleCropImageSuccess={this.handleCropImageSuccess}
                handleCropImageError={this.handleError}
              />
            </Modal>
          }

          <div className="ornament_header d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center">
              <img src={ornaments_icon} height="20" alt="" />
              <p className="gotham-bold-15 ml-3">Esferas {ornament.name}</p>
            </div>
            <div className="ornament_header-right d-flex justify-content-end">
              {
                selectedImages.length > 0 ?
                  <div className="ornament_header-item mr-4 d-flex align-items-center cursor-pointer" onClick={() => this.handleDuplicateImages(selectedImages)}>
                    <img src={duplicate_active} height="20" alt="" />
                    <p className="ml-2">Duplicar</p>
                  </div> :
                  <div className="ornament_header-item mr-4 d-flex align-items-center cursor-pointer">
                    <img src={duplicate_active} height="20" alt="" />
                    <p className="ml-2">Duplicar</p>
                  </div>
              }
              {/* {
                selectedImages.length > 0 ?
                  <div className="d-flex align-items-center cursor-pointer" onClick={() => this.handleDuplicateImages(selectedImages)}>
                    <img src={duplicate_active} height="20" alt="" />
                    <p className="futura-md-15 ml-3">Duplicar</p>
                  </div> :
                  <div className="d-flex align-items-center">
                    <img src={duplicate_inactive} height="20" alt="" />
                    <p className="futura-md-lightgray-15 ml-3">Duplicar</p>
                  </div>
              } */}

              {
                selectedImages.length > 0 && (ornament.photos.length - selectedImages.length > 0) ?
                  <div className="ornament_header-item mr-4 d-flex align-items-center cursor-pointer" onClick={() => this.handleDeleteImages(selectedImages)}>
                    <img src={trash_active} height="20" alt="" />
                    <p className="ml-2">Eliminar</p>
                  </div> :
                  <div className="ornament_header-item mr-4 d-flex align-items-center cursor-pointer">
                    <img src={trash_active} height="20" alt="" />
                    <p className="ml-2">Eliminar </p>
                  </div>

              }
              {/* {
                selectedImages.length > 0 && (ornament.photos.length - selectedImages.length > 0) ?
                  <div className="d-flex align-items-center cursor-pointer" onClick={() => this.handleDeleteImages(selectedImages)}>
                    <img src={trash_active} height="20" alt="" />
                    <p className="futura-md-15 ml-3">Eliminar</p>
                  </div> :
                  <div className="d-flex align-items-center">
                    <img src={trash_inactive} height="20" alt="" />
                    <p className="futura-md-lightgray-15 ml-3">Eliminar </p>
                  </div>
              } */}

              <button className="generic-button-blue d-flex align-items-center justify-content-center cursor-pointer" onClick={this.handleAddToCart}>
                <p>Añadir al Carrito</p>
              </button>
            </div>
          </div>
          <div className="ornament_main-content">
            <div className="my-5 d-flex justify-content-center">
              <div className="ornament_content">
                <div className="ornament_content-header">
                  <div className="ornament_header-top px-2 d-flex justify-content-between align-items-center">
                    <p className="futura-md-13">Título:</p>
                    <div className="d-flex align-items-center cursor-pointer" onClick={() => this.handleShowEditOrnamentName()}>
                      <img src={edit_img} height="12" alt="" />
                      <p className="futura-md-blue-13 ml-1">Editar</p>
                    </div>
                  </div>
                  <div className="ornament_header-bottom pl-2 d-flex align-items-center" >
                    <p className="gotham-bold-20">{ornament.name}</p>
                  </div>
                </div>
                <div className="ornament_warning-container p-2">
                  <div className="d-flex my-2 align-items-center">
                    <i className="fa fa-exclamation-triangle fa-lg"></i>
                    <p className="gotham-bold-15 ml-2">IMPORTANTE</p>
                  </div>
                  <p>Recuerda editar y centrar la foto para que no salga cortada.</p>
                </div>
                <div className="mt-3 d-flex flex-column align-items-center">
                  {ornament.photos.length < photoLimit ?
                    <div className="ornament_counter d-flex justify-content-center">
                      <p className="futura-md-blue-15">{ornament.photos.length}</p>
                      <p className="futura-md-15">/{photoLimit} esferas</p>
                    </div>
                    :
                    <div className="ornament_counter d-flex justify-content-between">
                      <p className="futura-md-blue-15">{ornament.photos.length}/{photoLimit} esferas</p>
                      <p className="futura-md-15">Total: ${ornament.price}</p>
                    </div>
                  }
                  <div className="ornament_bar mt-1">
                    <div className="ornament_bar-inside" style={{ width: `${widthPercentage}%` }}></div>
                  </div>
                </div>
                <div className="d-flex flex-wrap">
                  {photos.map((item, i) => (
                    <OrnamentDetailItem
                      key={i}
                      image={item.image_url}
                      item={item}
                      handleSelectImage={this.handleSelectImage}
                      isImageSelected={selectedImages.includes(item)}
                      handleAddImageClick={this.handleAddImageClick}
                      selectImageForEdit={this.selectImageForEdit}
                      handleMouseEnter={this.handleMouseEnter}
                      mouseOverImage={mouseOverImage}
                    />
                  ))}
                </div>
              </div>
            </div>
            <Footer history={this.props.history}/>
          </div>
        </div>
    );
  }
}


const mapStateToProps = state => ({
  projectType: state.navigationReducer.projectType,
  selectedOrnamentProject: state.navigationReducer.selectedOrnamentProject,
  cartProducts: state.collections.cartProducts
});

const mapDispatchToProps = {
  changeProjectTypeAction: changeProjectTypeAction,
  toggleIsAddingPhotosAction: toggleIsAddingPhotosAction,
  selectOrnamentProject: selectOrnamentProject
};


export default compose(connect(mapStateToProps, mapDispatchToProps))(OrnamentDetail);