import React from 'react';
import success_img from '../../images/newDesignImages/success-icon.png'
import close_img from '../../images/newDesignImages/close-gray.png';
import './SuccessPopup.scss';


export default function SuccessPopup(props) {
  const { fromAlbums, textMessage } = props;
  return (
    <div className="success-popup_main d-flex justify-content-center align-items-center">
      <div className="success-popup_inside d-flex flex-column justify-content-center align-items-center">
        {fromAlbums && <img className="success-popup_close cursor-pointer" src={close_img} height="20" alt="" onClick={props.handleCloseSuccessPopup} />}
        <img className="success-popup_img mb-5" src={success_img} />
        <p className="gotham-bold-25 mb-2">{fromAlbums ? '¡Lo lograste!' : '¡Yei!'}</p>
        <p className="gotham-book-18" style={{textAlign: 'center', width: '70%'}}>{textMessage}</p>
        {fromAlbums ? 
          <div className="success-popup_buttons mt-4 d-flex align-items-center justify-content-between">
            <button className="success-popup_buymore" onClick={() => props.history.push('/')}>
              Seguir comprando
            </button>
            <button className="success-popup_gotocart" onClick={() => props.history.push('/cart')}>
              Ir al carrito
            </button>
          </div> :
          <div className="success-popup_buttons mt-4 d-flex align-items-center justify-content-center">
            <button className="success-popup_continue" onClick={props.handleCloseSuccessPopup}>
              Continuar
            </button>
          </div>
        }
      </div>
    </div>
  );
}