import ApiService from './ApiService';

class ApiCustomer {

    constructor() {
        this.updatePath = 'customer/';
        this.deleteAlbumPath = 'customer/album/delete/';
        this.deleteProductPath = 'v3/customer/product/delete/';
        this.deletePhotoAlbumPath = 'customer/album/photos/delete/'
        this.updatePhotoDataPath = 'v2/customer/album/photo/update/';
        this.updatePhotosOrderPath = 'customer/album/photos/reorder/';
        this.updatePageDataPath = 'customer/album/page/update/';
        this.updatePagesOrderPath = 'customer/album/pages/reorder/';
        this.updateCollectionSettingsPath = 'customer/album/settings/';
        this.socialPhotosPath = 'customer/social/photos/';
        this.createAlbumPath = 'customer/album/create/';
        this.createProductPath = 'v3/customer/product/create/';
        this.socialAlbumsPath = 'customer/social/albums/';
        this.socialAlbumDetailPath = 'customer/social/album/';
        this.loginSocialNetworkPath = 'customer/social/auth/';
        this.userCardsPath = 'customer/cards/';
        this.userAddressesPath = 'customer/addresses/';
        this.cartPath = 'v3/customer/cart/';
        this.createAddressPath = 'customer/addresses/create/';
        this.createCardTokenPath = 'addtoken/';
        this.deleteProductFromCartPath = 'v3/customer/cart/delete/';
        this.addPhotosToAlbumPath = 'customer/album/photos/add/';
        this.addPagesToAlbumPath = '/customer/album/pages/add/'
        this.changeAlbumCoverPath = 'v2/customer/album/cover/';
        this.userDetailPath = 'me/';
        this.walleDetailtPath = 'customer/wallet/';
        this.walleTransactionsPath = 'customer/wallet/transactions/';
        this.custumerOrdersPath = 'v3/customer/orders/';
        this.customerOrderDetailPath = 'v3/customer/order/detail/';
        this.customerOrderProductsPath = 'v3/customer/order/detail/products/';
        this.deleteCard = 'customer/token/delete/';
        this.deleteAddress = 'customer/addresses/delete/';
        this.coverCategoriesPath = 'v2/predesign/categories/';
        this.coversPath = 'v2/predesign/category/covers/';
        this.getBannersPath = 'configuration/banners/'
        this.getAllGiftcardsPath = 'v2/giftcards/cards/';
        this.addProductToCartPath = 'v3/customer/cart/add/';
        this.getGiftcardConfigurationsPath = 'configuration/giftcards/';
        this.giftcardRedeemPath = 'v2/giftcards/redeem/';
        this.getProductsPath = 'configuration/products/';
        this.getCustomerProductsPath = 'v3/customer/products/';
        this.getCustomerProductDetailPath = 'v3/customer/product/';
        this.deleteMagnetImagePath = 'v3/customer/magnet/photos/delete/';
        this.deleteCanvasImagePath = 'v3/customer/picture/photos/delete/';
        this.addMagnetImagePath = 'v3/customer/magnet/photos/add/';
        this.addCanvasImagePath = 'v3/customer/picture/photos/add/';
        this.updateMagnetSettingsPath = 'v3/customer/magnet/settings/';
        this.updateCanvasSettingsPath = 'v3/customer/picture/settings/';
        this.updatePhotoMagnetPath = 'v3/customer/magnet/photos/update/';
        this.updatePhotoCanvasPath = 'v3/customer/picture/photos/update/';
        this.getProductConfigurationsPath = 'v3/customer/product/configurations/';
        this.getCartPath = 'v5/customer/cart/';
        this.getCartItemsNumberPath = 'v4/customer/cart/count/';
        this.getAllAccessoriesPath = '/v3/items/';
        this.getAlbumConfigurationsPath = '/configuration/albums/';
        this.deleteAlbumPagesPath = '/customer/album/pages/delete/';
        //Ornaments
        this.updateCustomerOrnamentProjectPath = 'v3/customer/ornament/settings/';
        this.addPhotosToCustomerOrnamentProjectPath = 'v3/customer/ornament/photos/add/';
        this.updatePhotoFromCustomerOrnamentProjectPath = 'v3/customer/ornament/photos/update/';
        this.deletePhotosFromCustomerOrnamentProjectPath = 'v3/customer/ornament/photos/delete/';
        this.getCustomerOrnamentConfigurationsPath = 'v3/customer/product/configurations/';
    }

    updateCustomer = (payload) => {
        return ApiService.makeRequest(this.updatePath, payload);
    }

    updateCollectionSettings = (payload) => {
        return ApiService.makeRequest(this.updateCollectionSettingsPath, payload);
    }

    getCustomerCollections = (payload) => {
        return ApiService.makeRequest(this.updatePath + 'albums/', payload);
    }

    deleteCustomerCollection = (payload) => {
        return ApiService.makeRequest(this.deleteAlbumPath, payload);
    }

    deleteCustomerProduct = (payload) => {
        return ApiService.makeRequest(this.deleteProductPath, payload);
    }

    deleteCustomerPhotoAlbum = (payload) => {
        return ApiService.makeRequest(this.deletePhotoAlbumPath, payload);
    }

    updatePhotosOrder = payload => {
        return ApiService.makeRequest(this.updatePhotosOrderPath, payload);
    }
    
    updatePagesOrder = payload => {
        return ApiService.makeRequest(this.updatePagesOrderPath, payload);
    }

    getCustomerSocialPhotos = (payload) => {
        return ApiService.makeRequest(this.socialPhotosPath, payload);
    }

    updatePhotoData = (payload) => {
        return ApiService.makeRequest(this.updatePhotoDataPath, payload);
    }

    updatePageData = (payload) => {
        return ApiService.makeRequest(this.updatePageDataPath, payload);
    }

    createAlbum = (payload) => {
        return ApiService.makeRequest(this.createAlbumPath, payload);
    }

    createProduct = (payload) => {
        return ApiService.makeRequest(this.createProductPath, payload)
    }

    getCustomerSocialAlbums = (payload) => {
        return ApiService.makeRequest(this.socialAlbumsPath, payload);
    }

    getCustomerSocialAlbumDetail = (payload) => {
        return ApiService.makeRequest(this.socialAlbumDetailPath, payload);
    }

    loginSocialNetwork = (payload) => {
        return ApiService.makeRequest(this.loginSocialNetworkPath, payload);
    }

    loadUserCards = (payload) => {
        return ApiService.makeRequest(this.userCardsPath, payload);
    }

    loadUserAddresses = (payload) => {
        return ApiService.makeRequest(this.userAddressesPath, payload);
    }

    getCart = (payload) => {
        return ApiService.makeRequest(this.getCartPath, payload);
    }

    getCartItemsNumber = (payload) => {
        return ApiService.makeRequest(this.getCartItemsNumberPath, payload);
    }

    updateData = (payload) => {
        return ApiService.makeRequest(this.updatePath, payload);
    }

    createAddress = (payload) => {
        return ApiService.makeRequest(this.createAddressPath, payload);
    }

    createCardToken = (payload) => {
        return ApiService.makeRequest(this.createCardTokenPath, payload);
    }

    deleteProductFromCart = (payload) => {
        return ApiService.makeRequest(this.deleteProductFromCartPath, payload);
    }

    addPhotosToAlbum = (payload) => {
        return ApiService.makeRequest(this.addPhotosToAlbumPath, payload);
    }

    addPagesToAlbum = (payload) => {
        return ApiService.makeRequest(this.addPagesToAlbumPath, payload);
    }

    changeAlbumCover = (payload) => {
        return ApiService.makeRequest(this.changeAlbumCoverPath, payload);
    }

    userDetail = (payload) => {
        return ApiService.makeRequest(this.userDetailPath, payload);
    }

    walletDetail = (payload) => {
        return ApiService.makeRequest(this.walleDetailtPath, payload);
    }

    walletTransactions = (payload) => {
        return ApiService.makeRequest(this.walleTransactionsPath, payload);
    }

    custumerOrders = (payload) => {
        return ApiService.makeRequest(this.custumerOrdersPath, payload);
    }

    customerOrderDetail = (payload) => {
        return ApiService.makeRequest(this.customerOrderDetailPath, payload);
    }

    customerOrderProducts = (payload) => {
        return ApiService.makeRequest(this.customerOrderProductsPath, payload);
    }

    deleteCustomerCard = (payload) => {
        return ApiService.makeRequest(this.deleteCard, payload);
    }

    deleteCustomerAddress = (payload) => {
        return ApiService.makeRequest(this.deleteAddress, payload);
    }

    loadCoverCategories = (payload) => {
        return ApiService.makeRequest(this.coverCategoriesPath, payload);
    }

    loadCovers = (payload) => {
        return ApiService.makeRequest(this.coversPath, payload);
    }
    getAllGiftcards = (payload) => {
        return ApiService.makeRequest(this.getAllGiftcardsPath, payload);
    }

    addProductToCart = (payload) => {
        return ApiService.makeRequest(this.addProductToCartPath, payload);
    }

    getGiftcardConfigurations = (payload) => {
        return ApiService.makeRequest(this.getGiftcardConfigurationsPath, payload);
    }

    giftcardRedeem = (payload) => {
        return ApiService.makeRequest(this.giftcardRedeemPath, payload);
    }

    getProducts = (payload) => {
        return ApiService.makeRequest(this.getProductsPath, payload);
    }

    getCustomerProducts = (payload) => {
        return ApiService.makeRequest(this.getCustomerProductsPath, payload);
    }

    getCustomerProductDetail = (payload) => {
        return ApiService.makeRequest(this.getCustomerProductDetailPath, payload);
    }

    deleteMagnetImage = (payload) => {
        return ApiService.makeRequest(this.deleteMagnetImagePath, payload);
    }

    deleteCanvasImage = (payload) => {
        return ApiService.makeRequest(this.deleteCanvasImagePath, payload);
    }

    addMagnetImage = (payload) => {
        return ApiService.makeRequest(this.addMagnetImagePath, payload);
    }

    addCanvasImage = (payload) => {
        return ApiService.makeRequest(this.addCanvasImagePath, payload);
    }

    updateMagnetSettings = (payload) => {
        return ApiService.makeRequest(this.updateMagnetSettingsPath, payload);
    }

    updateCanvasSettings = (payload) => {
        return ApiService.makeRequest(this.updateCanvasSettingsPath, payload);
    }

    updatePhotoMagnet = (payload) => {
        return ApiService.makeRequest(this.updatePhotoMagnetPath, payload);
    }

    updatePhotoCanvas = (payload) => {
        return ApiService.makeRequest(this.updatePhotoCanvasPath, payload);
    }

    getProductConfigurations = (payload) => {
        return ApiService.makeRequest(this.getProductConfigurationsPath, payload);
    }

    getAllAccessories = (payload) => {
        return ApiService.makeRequest(this.getAllAccessoriesPath, payload);
    }

    getAlbumConfigurations = (payload) => {
        return ApiService.makeRequest(this.getAlbumConfigurationsPath, payload)
    }

    deleteAlbumPages = (payload) => {
        return ApiService.makeRequest(this.deleteAlbumPagesPath, payload)
    }

    //Ornaments

    updateCustomerOrnamentProject = (payload) => {
        return ApiService.makeRequest(this.updateCustomerOrnamentProjectPath, payload);
    }

    addPhotosToCustomerOrnamentProject = (payload) => {
        return ApiService.makeRequest(this.addPhotosToCustomerOrnamentProjectPath, payload);
    }

    updatePhotoFromCustomerOrnamentProject = (payload) => {
        return ApiService.makeRequest(this.updatePhotoFromCustomerOrnamentProjectPath, payload);
    }

    deletePhotosFromCustomerOrnamentProject = (payload) => {
        return ApiService.makeRequest(this.deletePhotosFromCustomerOrnamentProjectPath, payload)
    }

    getCustomerOrnamentConfigurations = (payload) => {
        return ApiService.makeRequest(this.getCustomerOrnamentConfigurationsPath, payload)
    }

}

export default new ApiCustomer();