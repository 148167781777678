import React, { Component, useState, useEffect } from 'react';
import ApiCustomer from '../../utils/Api/ApiCustomer';
import TokenHelper from '../../utils/Firebase/TokenHelper';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { changeProjectTypeAction } from '../../actions/NavigationActions';
import { toggleIsAddingPhotosAction, updateAlbumConfigurationDataAction } from '../../actions/CreateProjectActions';
import Loading from '../Loading';
import Footer from '../Footer';
import Scrollable from '../Scrollable';
import Modal from '../Modal';
import AlertView from '../AlertView';
import CreateProject from '../CreateProject';
import circle_white from '../../images/newDesignImages/circle-white.png';
import check from '../../images/newDesignImages/check.png';
import './CreateAlbumConfigurations.scss';


function CreateAlbumConfigurations(props) {

  const [isLoading, setLoading] = useState(true);
  const [configurations, setConfigurations] = useState([]);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [sizeConfiguration, setSizeConfiguration] = useState({});
  const [coverConfiguration, setCoverConfiguration] = useState({});
  const [pageConfiguration, setPageConfiguration] = useState({});
  const [printDates, setPrintDates] = useState(true);
  const [showCreateProject, setShowCreateProject] = useState(false);

  useEffect(() => {
    getAlbumConfigurations();
  }, [])

  const getAlbumConfigurations = async() => {
    try {
      const idToken = await TokenHelper.getUserToken();
      const response = await ApiCustomer.getAlbumConfigurations({token: idToken});
      setConfigurations(response.data.data)
      setSizeConfiguration(response.data.data[0])
      setCoverConfiguration(response.data.data[0].configurations[0])
      setPageConfiguration(response.data.data[0].configurations[2])
      setLoading(false)
    } catch(e) {
      setShowError(true);
      setErrorMessage('Ha ocurrido un error, intenta más tarde');
    }
  }

  const okErrorHandler = () => {
    setShowError(false);
    setErrorMessage('');
  }

  const handleSelectSize = (selectedSize) => {
    setSizeConfiguration(selectedSize)
    setCoverConfiguration(selectedSize.configurations[0])
    setPageConfiguration(selectedSize.configurations[2])
    setPrintDates(true)
  }

  const handleSelectCover = (selectedCover) => {
    setCoverConfiguration(selectedCover)
  }

  const handleSelectPages = (selectedPages) => {
    setPageConfiguration(selectedPages)
  }

  const handleShowCreateProject = () => {
    setShowCreateProject(true);
    props.changeProjectTypeAction('album');
    props.toggleIsAddingPhotosAction(false);
    const configurationsArray = [sizeConfiguration.id, coverConfiguration.id, pageConfiguration.id];
    const selectedPagesQuantity = parseInt(pageConfiguration.description);
    localStorage.setItem("album_config_ids", JSON.stringify(configurationsArray));
    localStorage.setItem("album_config_pages", JSON.stringify(selectedPagesQuantity));
    localStorage.setItem("album_config_dates", JSON.stringify(printDates));
    props.updateAlbumConfigurationDataAction(configurationsArray, selectedPagesQuantity, printDates);
  }


  const cancelCreateProject = () => {
    setShowCreateProject(false)
  }
  
  return (
    isLoading ? <Loading/> : 
    <React.Fragment>
      {
        showError &&
        <Modal>
          <AlertView
            title={'¡Ups!'}
            message={errorMessage}
            okHandler={okErrorHandler}
            cancel={true} />
        </Modal>
      }
      {
        showCreateProject &&
        <Modal>
          <CreateProject cancelHandler={cancelCreateProject} />
        </Modal>
      }
      <Scrollable scrollableContent={
      <div>
        <div className="createalbumconfig_main d-flex flex-column justify-content-between align-items-center">
          <div className="createalbumconfig_inside py-5 d-flex flex-column align-items-center">
            <h1 className="gotham-bold-darkgray-20">Personaliza tu fotolibro</h1>
            <div className="createalbumconfig_row d-flex justify-content-end align-items-center">
              <p className="gotham-book-gray-22">Total: </p>
              <p className="gotham-bold-black-23 ml-2">$ {sizeConfiguration.price + coverConfiguration.price + pageConfiguration.price}</p>
            </div>

            <div className="createalbumconfig_row mb-3">
              <p className="gotham-bold-18 ml-1">Tamaño de fotolibro</p>
              <div className="w-100 d-flex justify-content-between">
                <button 
                  className="createalbumconfig_standard-btn d-flex justify-content-center align-items-center" 
                  style={{
                    color: sizeConfiguration === configurations[0] ? '#51B7F3' : '#606060',
                    borderColor: sizeConfiguration === configurations[0] ? '#51B7F3' : '#606060'
                  }} 
                  onClick={() => handleSelectSize(configurations[0])}
                >
                  <img className="mr-2 mb-1" src={sizeConfiguration === configurations[0] ? check : circle_white} height="18" alt="" />
                  <p>{configurations[0].description}</p>
                </button>
                <button 
                  className="createalbumconfig_standard-btn d-flex justify-content-center align-items-center" 
                  style={{
                    color: sizeConfiguration === configurations[1] ? '#51B7F3' : '#606060',
                    borderColor: sizeConfiguration === configurations[1] ? '#51B7F3' : '#606060'
                  }} 
                  onClick={() => handleSelectSize(configurations[1])}
                >
                  <img className="mr-2 mb-1" src={sizeConfiguration === configurations[1] ? check : circle_white} height="18" alt="" />
                  <p>{configurations[1].description}</p>
                </button>
              </div>
            </div>

            <div className="createalbumconfig_row mb-3">
              <p className="gotham-bold-18 ml-1">Tipo de pasta</p>
              <div className="w-100 d-flex justify-content-between">
                <button 
                  className="createalbumconfig_standard-btn d-flex justify-content-center align-items-center" 
                  style={{
                    color: coverConfiguration === sizeConfiguration.configurations[0] ? '#51B7F3' : '#606060',
                    borderColor: coverConfiguration === sizeConfiguration.configurations[0] ? '#51B7F3' : '#606060'
                  }} 
                  onClick={() => handleSelectCover(sizeConfiguration.configurations[0])}
                >
                  <img className="mr-2 mb-1" src={coverConfiguration === sizeConfiguration.configurations[0] ? check : circle_white} height="18" alt="" />
                  <p>Pasta suave</p>
                </button>
                <button 
                  className="createalbumconfig_standard-btn d-flex justify-content-center align-items-center" 
                  style={{
                    color: coverConfiguration === sizeConfiguration.configurations[1] ? '#51B7F3' : '#606060',
                    borderColor: coverConfiguration === sizeConfiguration.configurations[1] ? '#51B7F3' : '#606060'
                  }}
                  onClick={() => handleSelectCover(sizeConfiguration.configurations[1])}
                >
                  <img className="mr-2 mb-1" src={coverConfiguration === sizeConfiguration.configurations[1] ? check : circle_white} height="18" alt="" />
                  <p>Pasta dura</p>
                </button>
              </div>
            </div>

            <div className="createalbumconfig_row mb-3">
              <p className="gotham-bold-18 ml-1">Número de páginas</p>
              <div className="w-100 d-flex justify-content-between">
                <button 
                  className="createalbumconfig_pages-btn d-flex justify-content-center align-items-center" 
                  style={{
                    color: pageConfiguration === sizeConfiguration.configurations[2] ? '#51B7F3' : '#606060',
                    borderColor: pageConfiguration === sizeConfiguration.configurations[2] ? '#51B7F3' : '#606060'
                  }}
                  onClick={() => handleSelectPages(sizeConfiguration.configurations[2])}
                >
                  <img className="mr-2 mb-1" src={pageConfiguration === sizeConfiguration.configurations[2] ? check : circle_white} height="18" alt="" />
                  60
                </button>
                <button 
                  className="createalbumconfig_pages-btn d-flex justify-content-center align-items-center" 
                  style={{
                    color: pageConfiguration === sizeConfiguration.configurations[3] ? '#51B7F3' : '#606060',
                    borderColor: pageConfiguration === sizeConfiguration.configurations[3] ? '#51B7F3' : '#606060'
                  }}
                  onClick={() => handleSelectPages(sizeConfiguration.configurations[3])}
                >
                  <img className="mr-2 mb-1" src={pageConfiguration === sizeConfiguration.configurations[3] ? check : circle_white} height="18" alt="" />
                  80
                </button>
                <button 
                  className="createalbumconfig_pages-btn d-flex justify-content-center align-items-center" 
                  style={{
                    color: pageConfiguration === sizeConfiguration.configurations[4] ? '#51B7F3' : '#606060',
                    borderColor: pageConfiguration === sizeConfiguration.configurations[4] ? '#51B7F3' : '#606060'
                  }}
                  onClick={() => handleSelectPages(sizeConfiguration.configurations[4])}
                >
                  <img className="mr-2 mb-1" src={pageConfiguration === sizeConfiguration.configurations[4] ? check : circle_white} height="18" alt="" />
                  100
                </button>
              </div>
            </div>

            <div className="createalbumconfig_row mb-3">
              <p className="gotham-bold-18 ml-1">Imprimir fechas</p>
              <div className="w-100 d-flex justify-content-between">
                <button 
                  className="createalbumconfig_standard-btn d-flex justify-content-center align-items-center" 
                  style={{
                    color: printDates ? '#51B7F3' : '#606060',
                    borderColor: printDates ? '#51B7F3' : '#606060'
                  }}
                  onClick={() => setPrintDates(true)}
                >
                  <img className="mr-2 mb-1" src={printDates ? check : circle_white} height="18" alt="" />
                  <p>Sí</p>
                </button>
                <button 
                  className="createalbumconfig_standard-btn d-flex justify-content-center align-items-center" 
                  style={{
                    color: !printDates ? '#51B7F3' : '#606060',
                    borderColor: !printDates ? '#51B7F3' : '#606060'
                  }}
                  onClick={() => setPrintDates(false)}
                >
                  <img className="mr-2 mb-1" src={!printDates ? check : circle_white} height="18" alt="" />
                  <p>No</p>
                </button>
              </div>
            </div>

            <div className="createalbumconfig_row my-3 d-flex justify-content-end">
              <button className="createalbumconfig_next-btn" onClick={handleShowCreateProject}>
                Siguiente
              </button>
            </div>

          </div>
          <Footer history={props.history}/>
        </div>
      </div>
      }/>
    </React.Fragment>
  );
}

const mapStateToProps = state => ({
  projectType: state.navigationReducer.projectType,
  isAddingPhotos: state.createProject.isAddingPhotos,
  albumConfigurationIds: state.createProject.albumConfigurationIds
});

const mapDispatchToProps = {
  toggleIsAddingPhotosAction: toggleIsAddingPhotosAction,
  changeProjectTypeAction: changeProjectTypeAction,
  updateAlbumConfigurationDataAction: updateAlbumConfigurationDataAction
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(CreateAlbumConfigurations);
