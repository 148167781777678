import React from 'react';

import giftcard_img from '../../images/newDesignImages/exchange-card-img.png';
import plus_album from '../../images/newDesignImages/plus-album.jpg';
import minus_album from '../../images/newDesignImages/minus-album.jpg';


const CheckoutProductItem = (props) => {
  const { product, changeQuantityCallback, giftcardCategories } = props;
  const categoryName = product.product_type === 'giftcard' ? (giftcardCategories ? giftcardCategories.filter(item => item.id === product.gift_card_design_id)[0].category_name : null) : null;
  switch (product.product_type) {
    case 'giftcard':
      return (
        <div className="row cart_item d-flex d-flex align-items-center justify-content-between mx-5">
          <div className="col-4 d-flex align-items-center">
            <img src={giftcard_img} height="60" alt="cover" />
          </div>
          <div className="col-6">
            <p className="futura-bkbt-15"><strong>Para:</strong> {product.recipient_email}</p>
            <p className="futura-bold-15">{categoryName}</p>
            <p className="futura-md-blue-15">$ {product.price} MXN</p>
          </div>
          <div className="col-2 d-flex align-items-center justify-content-end">
          </div>
        </div>
      );
    case 'magnet':
      return (
        <div className="row cart_item d-flex align-items-center justify-content-center mx-5">
          <div className="col-4">
            <div className="cart_magnet-background d-flex align-items-end justify-content-start">
              <img src={product.photos[0].image_url} height="87" width="87" alt="cover" />
            </div>
          </div>
          <div className="col-4">
            <p className="futura-bold-15">{product.name}</p>
            <p className="futura-md-15">{product.configurations[0].title}</p>
            <p className="futura-md-blue-15">$ {product.price} MXN</p>
          </div>
          <div className="col-4 d-flex align-items-center justify-content-end">
            <div className="d-flex flex-column" >
              <img className="cursor-pointer" src={plus_album} width="30" alt="" onClick={() => changeQuantityCallback(product, 'plus')} />
              <div className="checkout_quantity d-flex justify-content-center">
                {product.quantity}
              </div>
              <img className="cursor-pointer" src={minus_album} width="30" alt="" onClick={() => changeQuantityCallback(product, 'minus')} />
            </div>
          </div>
        </div>
      );
    case 'picture':
      const frameBackground = product.configurations.length > 1 && {
        backgroundImage: 'url(' + product.configurations[1].frame_url + ')',
        backgroundSize: 'cover',
        height: '100px',
        width: '100px',
      }
      return (
        <div className="row cart_item d-flex align-items-center justify-content-center mx-5">
          <div className="col-4">
            {product.configurations.length > 1 ? 
              <div className="d-flex align-items-center justify-content-center" style={frameBackground}>
                <img className="cart_canvas-fame-img" src={product.photos[0].image_url} height="57" width="57" alt=""/>
              </div> :
              <div className="cart_canvas-background d-flex align-items-start justify-content-start">
                <img src={product.photos[0].image_url} height="95" width="95" alt="cover" />
              </div>
            }
          </div>
          <div className="col-4">
            <p className="futura-bold-15">{product.name}</p>
            {/* <p className="futura-md-15">Pixycuadros</p> */}
            <p className="futura-md-15">{product.configurations.length > 1 ? product.configurations[1].title : product.configurations[0].title}</p>
            <p className="futura-md-15">{product.photos.length === 1 ? `${product.photos.length} foto` : `${product.photos.length} fotos`} </p>
            <p className="futura-md-blue-15">$ {product.price} MXN</p>
          </div>
          <div className="col-4 d-flex align-items-center justify-content-end">
            <div className="d-flex flex-column" >
              <img className="cursor-pointer" src={plus_album} width="30" alt="" onClick={() => changeQuantityCallback(product, 'plus')} />
              <div className="checkout_quantity d-flex justify-content-center">
                {product.quantity}
              </div>
              <img className="cursor-pointer" src={minus_album} width="30" alt="" onClick={() => changeQuantityCallback(product, 'minus')} />
            </div>
          </div>
        </div>
      );
    case 'ornament':
      return (
        <div className="row cart_item d-flex align-items-center justify-content-center mx-5">
          <div className="col-4">
            <div className="cart_ornament-background d-flex align-items-end justify-content-start">
              <img src={product.photos[0].image_url} height="87" width="87" alt="cover" />
            </div>
          </div>
          <div className="col-4">
            <p className="futura-bold-15">{product.name}</p>
            <p className="futura-md-blue-15">$ {product.price} MXN</p>
          </div>
          <div className="col-4 d-flex align-items-center justify-content-end">
            <div className="d-flex flex-column" >
              <img className="cursor-pointer" src={plus_album} width="30" alt="" onClick={() => changeQuantityCallback(product, 'plus')} />
              <div className="checkout_quantity d-flex justify-content-center">
                {product.quantity}
              </div>
              <img className="cursor-pointer" src={minus_album} width="30" alt="" onClick={() => changeQuantityCallback(product, 'minus')} />
            </div>
          </div>
        </div>
      );
    default:
      return (
        <div className="row cart_item d-flex align-items-center justify-content-center mx-5">
          <div className="col-4">
            {product.predesign_cover_id ?
              <div className="cart_album-background d-flex align-items-center justify-content-end">
                <img src={product.cover_url} height="100" width="107" alt="cover" />
              </div> :
              <div className="cart_album-background d-flex align-items-center justify-content-end pr-1">
                <img src={product.cover_url} height="93" width="93" alt="cover" />
              </div>
            }
          </div>
          <div className="col-4">
            <p className="futura-bold-15">{product.name}</p>
            <p className="futura-md-15">{product.hard_cover ? 'Pasta rígida' : 'Pasta suave'} - {product.configurations[0].title}</p>
            <p className="futura-md-blue-15">$ {product.price} MXN</p>
          </div>
          <div className="col-4 d-flex align-items-center justify-content-end">
            <div className="d-flex flex-column" >
              <img className="cursor-pointer" src={plus_album} width="30" alt="" onClick={() => changeQuantityCallback(product, 'plus')} />
              <div className="checkout_quantity d-flex justify-content-center">
                {product.quantity}
              </div>
              <img className="cursor-pointer" src={minus_album} width="30" alt="" onClick={() => changeQuantityCallback(product, 'minus')} />
            </div>
          </div>
        </div>
      );
  }
}


export default CheckoutProductItem;