import React from 'react';
import bag_black from '../../images/newDesignImages/bag-black.png';
import bag_blue from '../../images/newDesignImages/bag-blue.png';
import './AccessoriesCard.scss';

export default function AccessoriesCard(props) {
  const { item } = props;
  const backgroundStyles = {
    backgroundImage: 'url(' + item.images[0].url + ')',
    // backgroundColor: 'blue',
    backgroundSize: 'cover',
    opacity: item.available ? 1 : 0.7,
  }
  return (
    <div className="accessories-card_main d-flex justify-content-center align-items-center">
      <div className="accessories-card_content d-flex flex-column">
        <div 
          className="accessories-card_img-container d-flex justify-content-center align-items-center cursor-pointer" 
          style={backgroundStyles} 
          onClick={() => props.handleSelectAccessory(item)}
        >
          {!item.available && <p className="accessories-card_text">PRÓXIMAMENTE</p>}
        </div>
        <div className="accessories-card_data d-flex flex-column justify-content-center align-items-start">
          <p className="gotham-bold-18 mt-2">{item.name}</p>
          <p className="gotham-book-15 my-2" style={{textAlign: 'center'}}>{item.short_description}</p>
          <p className="gotham-md-18">$ {item.price} MXN</p>
          <button className={item.available ? "mt-2 generic-button-blue" : "mt-2 generic-button-gray"} onClick={() => props.handleAddToCart(item)}>
            Agregar al carrito
          </button>
        </div>
      </div>
    </div>
  );
}