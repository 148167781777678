import React from 'react';
import { createAddressAction } from '../../actions/CustomerActions';
import { compose } from 'redux';
import { connect } from 'react-redux';

import Scrollable from '../Scrollable';
import warning from '../../images/nuevas-14.png';
import img_logo_gray_pixyalbum from '../../images/logo-gray.png'
import close_gray from '../../images/newDesignImages/close-gray.png';

import './CreateAddress.scss';

class CreateAddress extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      receiver_name: '',
      receiver_phone: '',
      name: '',
      number: '',
      number_int: '',
      street: '',
      neighborhood: '',
      city: '',
      state: '',
      zip_code: '',
      references: ''
    }
    this.handlePlaceSelect = this.handlePlaceSelect.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.autocomplete = null;
  }

  componentDidMount() {
    this.autocomplete = new window.google.maps.places.Autocomplete(document.getElementById('autocomplete'), {types: ['geocode']});
    this.autocomplete.addListener('place_changed', this.handlePlaceSelect);
  }

  handlePlaceSelect = () => {
    let addressObject = this.autocomplete.getPlace();
    let name = '';
    let number = '';
    let street = '';
    let neighborhood = '';
    let city = '';
    let state = '';
    let zip_code = '';
    if (addressObject) {
      this.setState({ name: addressObject.name });
      let address_components = addressObject.address_components;
      for (let component of address_components) {
        if (component.types.includes("street_number")) {
          number = component.long_name;
        }
        if (component.types.includes("route")) {
          street = component.long_name;
        }
        if (component.types.includes("neighborhood")) {
          neighborhood = component.long_name;
        }
        if (component.types.includes("sublocality_level_1") && neighborhood === '') {
          neighborhood = component.long_name;
        }
        if (component.types.includes("locality")) {
          city = component.long_name;
        }
        if (component.types.includes("administrative_area_level_1")) {
          state = component.long_name;
        }
        if (component.types.includes("postal_code")) {
          zip_code = component.long_name;
        }
      }
    }
    this.setState({
      name: name,
      street: street,
      number: number,
      neighborhood: neighborhood,
      city: city,
      state: state,
      zip_code: zip_code
    });
  }

  handleChange = e => {
    this.setState({
      [e.target.name] : e.target.value
    });
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.createAddressAction(this.state);

    this.setState ({
      ...this.state,
      receiver_name: '',
      receiver_phone: '',
      name: '',
      number: '',
      number_int: '',
      street: '',
      neighborhood: '',
      city: '',
      state: '',
      zip_code: '',
      references: ''
    })
    this.props.handleShowCreateAddress();
  }

  handleCancel = e => {
    e.preventDefault();
    this.props.dismissHandler();
  }

  render() {
    const { creatingAddressOnServer } = this.props;
    return (
      <div className="create-address_main d-flex justify-content-center align-items-center">
        <div className="create-address_inside d-flex flex-column justify-content-center align-items-center">
          <img className="create-address_close cursor-pointer" src={close_gray} height="20" alt="" onClick={this.props.handleShowCreateAddress} />
          <p className="gotham-md-gray-15 mb-4">Ingresa una nueva dirección</p>
          <form className="create-address_form" onSubmit={this.handleSubmit}>
            <div className="crate-address_input-container w-100">
              <input 
                type="text" 
                required
                placeholder="Tipo de propiedad (Casa, Departamento o Negocio)" 
                name="name" 
                value={this.state.name} 
                onChange={this.handleChange} 
                disabled={creatingAddressOnServer}
              />
            </div>
            <hr className="cart_info-inline"></hr>
            <div className="crate-address_input-container w-100">
              <input 
                type="text" 
                required
                placeholder="Nombre de quien recibe (nombre y apellidos)" 
                name="receiver_name" 
                value={this.state.receiver_name} 
                onChange={this.handleChange} 
                disabled={creatingAddressOnServer}
              />
            </div>
            <hr className="cart_info-inline"></hr>
            <div className="crate-address_input-container w-100">
              <input 
                type="text" 
                placeholder="Teléfono (opcional, para facilitar la entrega)" 
                name="receiver_phone" 
                minLength="10" 
                maxLength="10" 
                value={this.state.receiver_phone} 
                onChange={this.handleChange} 
                disabled={creatingAddressOnServer}
              />
            </div>
            <hr className="cart_info-inline"></hr>
            <div className="crate-address_input-container w-100">
              <input 
                type="text" 
                required
                placeholder="Calle" 
                name="street" 
                value={this.state.street} 
                onChange={this.handleChange} 
                disabled={creatingAddressOnServer}
              />
            </div>
            <hr className="cart_info-inline"></hr>

            <div className="w-100 d-flex">
              <div className="crate-address_input-container w-50">
                <input 
                  type="text" 
                  required
                  placeholder="No. Ext." 
                  name="number" 
                  value={this.state.number} 
                  onChange={this.handleChange} 
                  disabled={creatingAddressOnServer}
                />
              </div>
              <div className="crate-address_input-container-right w-50">
                <input 
                  type="text" 
                  placeholder="No. Int." 
                  name="number_int" 
                  value={this.state.number_int} 
                  onChange={this.handleChange} 
                  disabled={creatingAddressOnServer}
                />
              </div>
            </div>
            <hr className="cart_info-inline"></hr>

            <div className="crate-address_input-container w-100">
              <input 
                type="text" 
                required
                placeholder="Colonia" 
                name="neighborhood" 
                value={this.state.neighborhood} 
                onChange={this.handleChange} 
                disabled={creatingAddressOnServer}
              />
            </div>
            <hr className="cart_info-inline"></hr>
            <div className="crate-address_input-container w-100">
              <input 
                type="text" 
                required
                placeholder="Ciudad/Delegación" 
                name="city" 
                value={this.state.city} 
                onChange={this.handleChange} 
                disabled={creatingAddressOnServer}
              />
            </div>
            <hr className="cart_info-inline"></hr>

            <div className="w-100 d-flex">
              <div className="crate-address_input-container w-50">
                <input 
                  type="text" 
                  required
                  placeholder="Estado" 
                  name="state" 
                  value={this.state.state} 
                  onChange={this.handleChange} 
                  disabled={creatingAddressOnServer}
                />
              </div>
              <div className="crate-address_input-container-right w-50">
                <input 
                  type="text" 
                  required
                  placeholder="Código Postal" 
                  name="zip_code" 
                  value={this.state.zip_code} 
                  onChange={this.handleChange} 
                  disabled={creatingAddressOnServer}
                />
              </div>
            </div>
            <hr className="cart_info-inline"></hr>

            <div className="crate-address_input-container w-100">
              <input 
                type="text" 
                required
                placeholder="Referencias" 
                name="references" 
                value={this.state.references} 
                onChange={this.handleChange} 
                disabled={creatingAddressOnServer}
              />
            </div>
            <hr className="cart_info-inline"></hr>
            <div className="w-100 d-flex justify-content-center align-items-center">
              <button id="create_address" type="submit" className="create-address_button my-3" disabled={creatingAddressOnServer}>Agregar</button>
            </div>
          </form>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  creatingAddressOnServer: state.collections.creatingAddressOnServer
})

const mapDispatchToProps = {
  createAddressAction: createAddressAction
}

export default compose( 
  connect(mapStateToProps, mapDispatchToProps)
)(CreateAddress);