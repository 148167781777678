import {
    VALIDATE_FORM,
    VALIDATE_FORM_SUCCESS,
    VALIDATE_FORM_ERROR,
    RESET_VALIDATE_FORM
} from '../actions/ActionTypes';

export function validateFormAction() {
    return dispatch => {
        dispatch(initValidate())
    }
}

export const initValidate = () => {
    return {
        type: VALIDATE_FORM
    }
}
export const validationSuccess = () => {
    return {
        type: VALIDATE_FORM_SUCCESS
    }
}

export const validationError = () => {
    return {
        type: VALIDATE_FORM_ERROR
    }
}

export const resetValidationFormAction = () => {
    return {
        type: RESET_VALIDATE_FORM
    }
}