import React, { Component } from 'react';
import { isIOS } from 'react-device-detect';
import img_logo_pixyalbum_complete from '../../images/Pixyalbum-logo.jpg'
import './GoToAppsScreen.scss';

class GoToAppsScreen extends Component {

  componentDidMount() {
    isIOS ? 
      window.location.href="https://apps.apple.com/us/app/pixyalbum-fotolibros/id1359242750" : 
      window.location.href="https://play.google.com/store/apps/details?id=com.mobilestudio.pixyalbum&hl=en"
  }

  render() {
    return (
      <div className="main-gotoapp">
        <img src={img_logo_pixyalbum_complete}/>
        <div className="m-2">
          <p className="text-gotoapp mb-3">¡Es más fácil en la aplicación!</p>
          {isIOS ? 
            <button onClick={() => window.location.href="https://apps.apple.com/us/app/pixyalbum-fotolibros/id1359242750"} className="button-gotoapp">DESCARGA LA APLICACION</button> : 
            <button onClick={() => window.location.href="https://play.google.com/store/apps/details?id=com.mobilestudio.pixyalbum&hl=en"} className="button-gotoapp">DESCARGA LA APLICACION</button>}
        </div>
        <div className="d-flex justify-content-center w-100">
          {/* <p>Seguir en el navegador</p> */}
        </div>
      </div>
    );
  }
}

export default GoToAppsScreen;