import React from 'react';
import './BannerInfoModal.scss';

function BannerInfoModal(props) {
  return (
    <div className="banner-info_container d-flex justify-content-center align-items-center">
      <div className="banner-info_container-inside">
        <img src={props.selectedBannerData.image_url} height="auto" width="100%" alt="" />
        <div >
          <div className="py-3 px-5 d-flex justify-content-start">
            <p className="futura-bold-25">{props.selectedBannerData.title}</p>
          </div>
          <div className="pb-3 px-5 d-flex justify-content-start">
            <p className="product-info_description">{props.selectedBannerData.description}</p>
          </div>
          <div className="pb-4 d-flex justify-content-center align-items-end">
            <button className="banner-info_button" onClick={props.handleCloseBannerDetails}>
              <p className="futura-md-white-15">Cerrar</p>
            </button>
          </div>
        </div>

      </div>
    </div >
  );
}

export default BannerInfoModal;