import React, { Component } from 'react';

import TokenHelper from '../../utils/Firebase/TokenHelper';
import ApiBanners from '../../utils/Api/ApiBanners';

import slider_left from '../../images/newDesignImages/slider-left.png';
import slider_right from '../../images/newDesignImages/slider-right.png';
import slider_inactive from '../../images/newDesignImages/slider-inactive.png';
import slider_active from '../../images/newDesignImages/slider-active.png';
import ver_mas from '../../images/newDesignImages/ver-mas.png';

import './Banner.scss';

class Banner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedImageIndex: 0,
      images: [],
      manualChange: false
    }
  }

  componentDidMount() {
    this.getBannersData()
  }


  getBannersData = () => {
    TokenHelper.getUserToken().then(idToken => {
      ApiBanners.getBanners({ token: idToken }).then(response => {
        if (response.data.success) {
          let images = response.data.data;
          for (let i = 0; i < images.length; i++) {
            images[i]['index'] = i;
          }
          this.setState({ images })
        }
      })
    })
  }

  handleNext = () => {
    const { selectedImageIndex, images } = this.state;
    const bannerImagesQuantity = images.length;
    this.setState({
      selectedImageIndex: (selectedImageIndex + 1) % bannerImagesQuantity,
      manualChange: true
    });
  }

  handlePrev = () => {
    const { selectedImageIndex, images } = this.state;
    const bannerImagesQuantity = images.length;
    this.setState({
      selectedImageIndex: (selectedImageIndex + (bannerImagesQuantity - 1)) % bannerImagesQuantity,
      manualChange: true
    });
  }

  render() {
    const { selectedImageIndex, images } = this.state;
    const imagesUrls = images.map(image => image.web_image_url);
    const imgStyle = {
      backgroundImage: 'url(' + imagesUrls[selectedImageIndex] + ')',
      backgroundSize: 'cover'
    };
    return (
      <div className="banner_main-container d-flex align-items-center justify-content-between pb-5" style={imgStyle}>
        <img className="cursor-pointer" onClick={() => this.handlePrev()} src={slider_left} alt="arrow-left" />
        <button className="d-flex justify-content-around align-items-center" onClick={() => this.props.handleShowBannerDetails(images[selectedImageIndex])}>
          <p className="gotham-md-17">VER MÁS</p>
          <img src={ver_mas} height="25" alt="" />
        </button>
        <div className="banner_central d-flex flex-column justify-content-end align-items-center">
          <div className="banner_selection-icons d-flex align-items-center justify-content-around">
            {images.map(item => <img key={item.index} src={selectedImageIndex === item.index ? slider_active : slider_inactive} alt="selector" />)}
          </div>
        </div>
        <img className="cursor-pointer" onClick={() => this.handleNext()} src={slider_right} alt="arrow-right" />
      </div>
    );
  }
}

export default Banner;