import React, { Component } from 'react';
import ApiCustomer from '../../utils/Api/ApiCustomer';
import TokenHelper from '../../utils/Firebase/TokenHelper';

import close_img from '../../images/newDesignImages/close-gray.png';
import './EditProductName.scss';

class EditProductName extends Component {

  constructor(props) {
    super(props);
    this.state = {
      productName: this.props.name
    }
  }

  handleInput = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  handleChangeName = (newName) => {
    switch (this.props.productType) {
      case 'magnet':
        this.updateMagnetName(newName);
        break;
      case 'picture':
        this.updateCanvasName(newName);
        break;
      case 'ornament':
        this.updateOrnamentName(newName);
        break;
      default:
        this.props.updateAlbumName(newName);
        break;
    }

  }

  updateMagnetName = (newName) => {
    TokenHelper.getUserToken().then(idToken => {
      ApiCustomer.updateMagnetSettings({
        token: idToken,
        magnet_project_id: this.props.id,
        title: newName
      }).then(response => {
        if (response.data.success) {
          this.props.handleEditMagnetName();
        } else {
          this.props.handleError('Ocurrió un error, intenta más tarde');
        }
      }).catch(error => this.props.handleError('Ocurrió un error, intenta más tarde'));
    }).catch(error => this.props.handleError('Ocurrió un error, intenta más tarde'));
  }


  updateCanvasName = (newName) => {
    TokenHelper.getUserToken().then(idToken => {
      ApiCustomer.updateCanvasSettings({
        token: idToken,
        picture_project_id: this.props.id,
        title: newName
      }).then(response => {
        if (response.data.success) {
          this.props.handleEditCanvasName();
        } else {
          this.props.handleError('Ocurrió un error, intenta más tarde');
        }
      }).catch(error => this.props.handleError('Ocurrió un error, intenta más tarde'));
    }).catch(error => this.props.handleError('Ocurrió un error, intenta más tarde'));
  }

  updateOrnamentName = (newName) => {
    TokenHelper.getUserToken().then(idToken => {
      ApiCustomer.updateCustomerOrnamentProject({
        token: idToken,
        ornament_project_id: this.props.id,
        title: newName
      }).then(response => {
        if (response.data.success) {
          this.props.handleEditOrnamentName();
        } else {
          this.props.handleError('Ocurrió un error, intenta más tarde');
        }
      }).catch(error => this.props.handleError('Ocurrió un error, intenta más tarde'));
    }).catch(error => this.props.handleError('Ocurrió un error, intenta más tarde'));
  }


  render() {
    const title = this.props.productType === 'album' ? 
    'Edita el lomo de tu Pixyalbum.' :
    'Cambia el nombre de tu proyecto.' 
    return (
      <div className="edit-name_container d-flex justify-content-center align-items-center">
        <div className="edit-name_container-inside py-5 d-flex flex-column align-items-center justify-content-center">
          <img className="edit-name_close cursor-pointer" src={close_img} height="20" alt=""  onClick={this.props.cancelEditProductName}/>
          <p className="gotham-md-20 mb-5">{title}</p>
          <div className="edit-name_input-container d-flex">
            <input name="productName" maxLength="30" value={this.state.productName} onChange={this.handleInput} />
          </div>
          <div className="edit-name_count-characters mb-5 pt-1 d-flex justify-content-end">
            <p className="gotham-md-lightgray-15">{this.state.productName.length} de 30 caracteres</p>
          </div>
          <div className="edit-name_buttons-container d-flex justify-content-between">
            <button className="edit-name_cancel-button" onClick={this.props.cancelEditProductName}>Cancelar</button>
            <button className="edit-name_ok-button" onClick={() => this.handleChangeName(this.state.productName)}>Cambiar nombre</button>
          </div>
        </div>
      </div>
    );
  }
}


export default EditProductName;