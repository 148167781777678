import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { loadSocialAlbumsAction, selectSocialAlbumAction, loadQuotesAlbumsAction } from '../../actions/CreateProjectActions';
import { changeProjectTypeAction } from '../../actions/NavigationActions';
import Loading from '../Loading';
import Scrollable from '../Scrollable';
import AlbumPickerSelectableAlbum from './AlbumPickerSelectableAlbum';
import './AlbumPickerWithSource.scss';

class AlbumPickerWithSource extends Component {

  componentDidMount() {
    if (this.props.source === '') {
      this.props.history.push('/projects/');
    } else {
      const projectType = JSON.parse(localStorage.getItem('PROJECT_TYPE'));
      this.props.changeProjectTypeAction(projectType);
      if (this.props.source === 'quotes') {
        if (this.props.albums.length === 0) {
          this.props.loadQuotesAlbumsAction();
        }
      }
      else if (this.props.albums.length === 1) {
        // We just load the albums the first time, maybe the user press back button and we have albums in the state
        this.props.loadSocialAlbumsAction(this.props.source, this.props.socialToken, this.props.albumsNextPage);
      }
    }
  }

  selecAlbum = (album) => {
    if (album.id === 'all_photos') {
      this.props.history.push('/source/select/photos/');
    } else {
      const selectSocailAlbum = new Promise((resolve, reject) => {
        resolve(this.props.selectSocialAlbumAction(album));
      });
      selectSocailAlbum.then(() => this.props.history.push('/source/select/album/photos'));
    }
  }

  handleScroll = (event) => {
    var node = event.target;
    // const bottom = node.scrollHeight - node.scrollTop === node.clientHeight;
    const bottom = Math.abs(node.scrollHeight - node.clientHeight - node.scrollTop) < 1;
    if (bottom) {
      if (!this.props.isLoading && this.props.albumsNextPage !== '') {
        this.props.loadSocialAlbumsAction(this.props.source, this.props.socialToken, this.props.albumsNextPage);
      }
    }
  }

  replaceIdName = (name) => {
    return name.split(' ').join('_');
  }

  render() {
    return (
      <React.Fragment>
        <div className="my-3 mx-5 d-flex justify-content-end">
          <button className="generic-button-blue" onClick={() => this.props.history.push('/')}>Regresar</button>
        </div>
        {this.props.isLoading && <Loading />}
        <Scrollable scrollableContent={
          <div className="d-flex flex-wrap mx-5 p-2">
            {
              this.props.albums.map((album) => React.createElement(AlbumPickerSelectableAlbum, { key: album.id, album: album, callback: this.selecAlbum, source: this.props.source, idName: this.replaceIdName(album.name) }))
            }
          </div>
        } onScroll={this.handleScroll.bind(this)}>
        </Scrollable>
      </React.Fragment>
    );
  }
}


const mapStateToProps = state => ({
  isLoading: state.globalReducer.isLoading,
  albums: state.createProject.albums,
  albumsNextPage: state.createProject.albumsNextPage,
  source: state.createProject.source,
  socialToken: state.createProject.socialToken
})

const mapDispatchToProps = {
  loadSocialAlbumsAction: loadSocialAlbumsAction,
  selectSocialAlbumAction: selectSocialAlbumAction,
  loadQuotesAlbumsAction: loadQuotesAlbumsAction,
  changeProjectTypeAction: changeProjectTypeAction
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(AlbumPickerWithSource);