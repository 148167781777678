import React from 'react';

import './AlertView.scss';

import img_logo_gray_pixyalbum from '../../images/logo-gray.png';

const AlertView = (props) => (
  <div className="alert_container d-flex justify-content-center align-items-center">
    <div className="alert_container-inside pb-2 pt-2 d-flex flex-column align-items-center justify-content-around">
      <img className="d-flex size-logo" src={img_logo_gray_pixyalbum} alt="img" />
      <p className="card-text" style={{textAlign: 'center'}}>{props.message}</p>
      {props.cancelHandler ?
        <div className="edit-name_buttons-container d-flex justify-content-around">
          <button className="generic-button-white mr-2" onClick={() => props.cancelHandler()}> {props.cancel ? 'Cancelar' : 'Regresar'} </button>
          <button className="generic-button-blue ml-2" onClick={() => props.okHandler()}>Ok</button>
        </div>
        :
        <button className="generic-button-blue" onClick={() => props.okHandler()}>Ok</button>
      }
    </div>
  </div>
);

export default AlertView;