import TokenHelper from '../utils/Firebase/TokenHelper';
import ApiConfigurations from '../utils/Api/ApiConfigurations';
import {
    HIDE_ERROR,
    SHOW_ERROR,
    START_LOADING_INFO,
    FINISH_LOADING_INFO,
    CREATING_USER,
    UPDATE_LOADING_INFO,
    SET_ALBUM_CONFIGURATIONS
} from './ActionTypes';

export const hideErrorAction = () => async (dispatch) => {
    dispatch({
        type: HIDE_ERROR,
        payload: null
    });
}

export const showErrorAction = (message) => {
    return {
        type: SHOW_ERROR,
        payload: message
    };
}

export const showLoadingAction = () => {
    return {
        type: START_LOADING_INFO,
        payload: true
    }
}

export const updateLoadingMessageAction = (message) => {
    return {
        type: UPDATE_LOADING_INFO,
        payload: message
    }
}

export const hideLoadingAction = () => {
    return {
        type: FINISH_LOADING_INFO,
        payload: false
    }
}

export const creatingUserAction = (creating) => {
    return {
        type: CREATING_USER,
        payload: creating
    }
}

export const loadAlbumConfigurationsAction = () => async (dispatch) => {
    const params = await TokenHelper.getUserToken().then(idToken => {
        let params = {
            token: idToken,
        };
        return params;
    });
    const response = await ApiConfigurations.getAlbumConfigurations(params).then(response => { return response.data; }).catch(error => { return error.data; });
    if (response.success) {
        localStorage.setItem('ALBUM_CONFIGURATIONS', JSON.stringify(response.data));
        dispatch({
            type: SET_ALBUM_CONFIGURATIONS,
            payload: response.data
        })
    }
}