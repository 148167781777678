import ApiCheckout from '../utils/Api/ApiCheckout';
import TokenHelper from '../utils/Firebase/TokenHelper';
import firebase from 'firebase/app';
import 'firebase/auth';

import {
  START_LOADING_INFO,
  FINISH_LOADING_INFO,
  SHOW_ERROR,
  ADD_PRODUCTS_TO_CHECKOUT,
  CALCULATE_ORDER,
  PLACE_ORDER,
  SELECT_PAYMENT_METHOD,
  RESET_CHECKOUT_STATE,
  SELECT_SHIPPING_ADDRESS,
  SHOW_GO_TO_CART_ALERT,
  CHANGE_ALBUM_QUANTITY,
  UPDATE_CUSTOMER_INFO
} from '../actions/ActionTypes';

// Add albums to checkout process
export const addProductsToCheckoutAction = (products) => {
  return {
    type: ADD_PRODUCTS_TO_CHECKOUT,
    payload: products
  }
}

export const updateCustomerInfoAction = (customerInfo) => (dispatch) => {
  dispatch({
    type: UPDATE_CUSTOMER_INFO,
    payload: customerInfo
  });
}


// Calculate order from server
export const calculateOrderAction = (orderData) => async (dispatch) => {
  dispatch({
    type: START_LOADING_INFO,
    payload: true
  });
  const params = await TokenHelper.getUserToken().then(idToken => {
    let params = {
      token: idToken,
      data: orderData
    };
    return params;
  });
  const response = await ApiCheckout.calculateOrder(params).then(response => { return response.data; }).catch(error => { return error.data; });
  if (response.success) {
    dispatch({
      type: CALCULATE_ORDER,
      payload: response.data
    })
    if (response.data.error_message !== '') {
      dispatch({
        type: SHOW_ERROR,
        payload: response.data.error_message
      })
    }
  } else {
    dispatch({
      type: SHOW_ERROR,
      payload: response.message
    })
  }
  dispatch({
    type: FINISH_LOADING_INFO,
    payload: false
  });
}

// Place order in server
export const placeOrderAction = (data, orderSummary) => async (dispatch) => {
  dispatch({
    type: START_LOADING_INFO,
    payload: true
  });
  const params = await TokenHelper.getUserToken().then(idToken => {
    let params = {
      token: idToken,
      data: data
    };
    return params;
  });
  const response = await ApiCheckout.placeOrder(params).then(response => { return response.data; }).catch(error => { return error.data; });
  if (response.success) {
    //Events log of Facebook
    var paramsEvent = {};
    paramsEvent['purchaseFirebaseId'] = response.data.order_id;
    paramsEvent['userId'] = firebase.auth().currentUser.uid;
    window.FB.AppEvents.logPurchase(params.data.total_paid, "MXN", paramsEvent);

    // Pixel facebook - Purchase
    window.fbq('track', 'Purchase', {
      content_ids: [response.data.order_id],
      content_type: 'product',
      value: orderSummary.total,
      currency: 'MXN',
      user_id: firebase.auth().currentUser.uid,
      voucher: data.voucher
    });


    var data_albums = params.data.albums;
    var albums = new Array();
    for (var i = 0; i < data_albums.length; i++) {
      albums.push({
        'name': data_albums[i].name,
        'id': data_albums[i].id,
        'price': data_albums[i].price,
        'hard_cover': data_albums[i].hard_cover,
        'quantity': data_albums[i].quantity,
        'num_photos': data_albums[i].pages.length
      });
    }

    // Pixel Google Analytics - purchase
    window.dataLayer.push({
      'event': 'purchase',
      'ecommerce': {
        'purchase': {
          'actionField': {
            'id': response.data.order_id,
            'step': 2,
            'revenue': orderSummary.total,
            'shipping': orderSummary.shipping,
            'coupon': data.voucher,
            'device': params.data.device,
            'payment_method': params.data.payment_method.type,
            'currency': 'MXN',
          },
          'products': albums
        }
      }

    });

    dispatch({
      type: PLACE_ORDER,
      payload: response.data
    })
  } else {
    dispatch({
      type: SHOW_ERROR,
      payload: response.message
    })
  }
  dispatch({
    type: FINISH_LOADING_INFO,
    payload: false
  });
}

export const finishLoadingAction = () => (dispatch) => {
  dispatch({
    type: FINISH_LOADING_INFO,
    payload: false
  });
}

export const selectPaymentMethodAction = (paymentMethodOption) => {
  return {
    type: SELECT_PAYMENT_METHOD,
    payload: paymentMethodOption
  }
}

export const selectShippingAddressAction = (address) => {
  return {
    type: SELECT_SHIPPING_ADDRESS,
    payload: address
  }
}

export const resetCheckoutStateAction = () => {
  return {
    type: RESET_CHECKOUT_STATE,
    payload: null
  }
}

export const toggleGoToCartAlertAction = (show) => {
  return {
    type: SHOW_GO_TO_CART_ALERT,
    payload: show
  }
}

export const changeAlbumQuantityAction = (album, quantity) => {
  return {
    type: CHANGE_ALBUM_QUANTITY,
    payload: { album, quantity }
  }
}