import React, { Component } from 'react';
import ApiCustomer from '../../utils/Api/ApiCustomer';
import TokenHelper from '../../utils/Firebase/TokenHelper';
import AccessoriesCartItem from './AccessoriesCartItem';
import './AccessoriesCart.scss';


class AccessoriesCart extends Component {

  constructor(props) {
    super(props);
    this.state = {
      accessories: []
    }
  }

  componentDidMount() {
    this.getAllAccessories();
  }

  getAllAccessories = async() => {
    try {
      const idToken = await TokenHelper.getUserToken();
      const response = await ApiCustomer.getAllAccessories({token: idToken});
      if (response.data.success) {
        this.setState({accessories: response.data.data});
      }
      
    } catch(e) {
     
    }
  }

  handleSelectAccessory = (selectedItem) => {
    const { accessories } = this.state;
    if (selectedItem.available) {
      const selectedAccessory = accessories.filter(item => item.id === selectedItem.id)[0];
      this.props.history.push({
        pathname: '/accessory/detail',
        accessory: selectedAccessory
      })
    } else {
      return;
    }
  }

  render() {
    const { accessories } = this.state;
    return (
      accessories && 
      <div className="accessories-cart_main d-flex justify-content-center">
        <div className="accessories-cart_inside pt-3 pb-5">
          <p className="pl-2 py-2 gotham-bold-20">¿Deseas agregar un accesorio al carrito?</p>
          <div className="accessories-cart_items ">
            {accessories.map((item, i) => (
              <AccessoriesCartItem
                key={i}
                item={item}
                handleSelectAccessory={this.handleSelectAccessory}
                handleAddAccessoryToCart={this.props.handleAddAccessoryToCart}
              />
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default AccessoriesCart;