import React, { Component } from 'react';
import { firebaseConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import { connect } from 'react-redux';
import './UpdatePassword.scss';


class UpdatePassword extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleforgetPasswordClick = this.handleforgetPasswordClick.bind(this);
    this.state = {
      password: '',
      newPassword: '',
      newPasswordRepeat: '',
      errorMessage: {
        inputOne: '',
        inputTwo: '',
        inputThree: ''
      }
    }
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({
        [name]: value
    });
  };

  handleSubmit(e) {
    e.preventDefault();
    const { password, newPassword, newPasswordRepeat } = this.state;
    const { firebase, hideAlertUpdatePassword } = this.props;
    if (newPassword !== newPasswordRepeat) {
      this.setState({
        errorMessage: {
          inputOne: '',
          inputTwo: '',
          inputThree: 'Las contraseñas no coinciden'
        }
      });
      return;
    }
    const user = firebase.auth().currentUser
    let credential = firebase.auth.EmailAuthProvider.credential(
      user.email,
      password
    );
    // Prompt the user to re-provide their sign-in credentials
    user.reauthenticateWithCredential(credential).then(() => {
      // Change user password
      user.updatePassword(newPassword).then(() => {
        hideAlertUpdatePassword();
      }).catch(error => {
        this.setState({
          errorMessage: {
            inputOne: '',
            inputTwo: error.message,
            inputThree: ''
          }
        });
      });
    }).catch(error => {
      this.setState({
        errorMessage: {
          inputOne: error.message,
          inputTwo: '',
          inputThree: ''
        }
      });
    });
  }

  handleforgetPasswordClick() {
    this.props.hideAlertUpdatePassword();
    this.props.openAlertPasswordForget();
  }

  render() {
    const { errorMessage } = this.state;
    return (
      <div>
        <form onSubmit={this.handleSubmit}>
          <div className="form-group">
            <input 
              type="password" 
              name="password" 
              id="password" 
              placeholder="Contraseña" 
              className="form-control"
              value={this.state.password}
              onChange={this.handleChange}
            />
            { errorMessage.inputOne !== '' && <div className="text-danger">{errorMessage.inputOne}</div> }
          </div>
          <div className="form-group">
            <input 
              type="password" 
              name="newPassword" 
              id="newPassword" 
              placeholder="Nueva contraseña" 
              className="form-control"
              value={this.state.newPassword}
              onChange={this.handleChange}
            />
            { errorMessage.inputTwo !== '' && <div className="text-danger">{errorMessage.inputTwo}</div> }
          </div>
          <div className="form-group">
            <input 
              type="password" 
              name="newPasswordRepeat" 
              id="newPasswordRepeat"
              placeholder="Repetir contraseña" 
              className="form-control"
              value={this.state.newPasswordRepeat}
              onChange={this.handleChange}
            />
            { errorMessage.inputThree !== '' && <div className="text-danger">{errorMessage.inputThree}</div> }
          </div>
          <div>
            <input 
              className="btn-pixyalbum-primary btn-block rounded-button"
              type ="submit" 
              value="Aceptar"
            />
          </div>
        </form>
        <div className="float-right my-1">
          <small className="forgot-password" onClick={() => this.handleforgetPasswordClick()}>¿Olvidaste tu contraseña?</small>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({

});

const mapDispatchToProps = {
  
};



export default compose( 
  firebaseConnect(),
  connect(mapStateToProps, mapDispatchToProps)
)(UpdatePassword);