import React from 'react';
import './InsideCover.scss';

const InsideCover = (props) => {
  return (
    <div className={props.begin ? "sortable-item_main d-flex flex-column justify-content-center align-items-end" : "sortable-item_main d-flex flex-column justify-content-center align-items-start"}>
      <div className="sortable-item_page d-flex flex-column justify-content-center align-items-center">
        <p className="inside-cover_title">{props.title}</p>
        <p className="inside-cover_subtitle">{props.title2}</p>
      </div>
    </div>
    

    // <div>
    //   <div className="row mx-0">
    //     <div className="col p-0 m-0">
    //       <div className={props.begin ? 'square-page float-right' : 'square-page'}>
    //         <div className="square-page-content d-flex flex-column justify-content-center align-items-center">
    //           <p className="text-center my-auto title-regular-10 text-muted d-flex">{props.title} <br />{props.title2} </p>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
}

export default InsideCover;