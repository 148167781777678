import React from 'react';
import './SelectFromAlbumItem.scss';

const SelectFromAlbumItem = (props) => {
  // TODO: Change classes square-page-cover square-page-content-cover and fluid-contain-cover
  // Because we don't know if this could change the behaviour later
  return (
    <div className="from-album-item_main d-flex justify-content-center align-items-center">
      <img className="from-album-item_img cursor-pointer" src={props.page.photos[0].image_url} alt="" onClick={() => props.selectPhotoForEditCallback(props.page.photos[0])}/>
    </div>
  )
}


export default SelectFromAlbumItem;