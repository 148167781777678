import React from 'react';
import './AlbumPickerWithSource.scss';

const AlbumPickerSelectableAlbum = ({ album, callback, source, idName }) => {
  const imgStyle = {
    background: 'url(' + album.image_url + ') center no-repeat',
    backgroundSize: 'cover'
  }
  const nameLength = album.name.length;
  return (
    album.image_url === '' ?
      <div className="album-picker_card-no-image m-3 d-flex align-items-end cursor-pointer" onClick={() => callback(album)}>
        <div className="album-picker_card-text d-flex align-items-center justify-content-center">
          <p>{album.name}</p>
        </div>
      </div> :
      <div id={idName} className="album-picker_card m-3 d-flex align-items-end cursor-pointer" style={imgStyle} onClick={() => callback(album)}>
        <div className="album-picker_card-text d-flex align-items-center justify-content-center">
          <p>{nameLength < 23 ? album.name : album.name.slice(0, 23) + '...'}</p>
        </div>
      </div>
  );
}


export default AlbumPickerSelectableAlbum;