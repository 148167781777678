import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { 
  selectSourceAction, 
  resetProjectReducerAction, 
  setSocialTokenAction, 
  loginSocialNetworkAction, 
  removeDefaultAlbumForQuotesAction, 
  setDefaultAlbumsAction 
} from '../../actions/CreateProjectActions';
import Scrollable from '../../components/Scrollable';
import { withRouter } from "react-router-dom";

import './CreateProject.scss';

// Images
import source_facebook from '../../images/source_facebook.png';
import source_instagram from '../../images/source_instagram.png';
import source_gphotos from '../../images/source_gphotos.png';
import source_quotes from '../../images/source_quotes.png';
import close_img from '../../images/newDesignImages/close-img-white.png';
import source_device from '../../images/newDesignImages/from-device.png';
import logo_img from '../../images/newDesignImages/pixyalbum-logo-white-complete.png';

class CreateProject extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showViewPhotosUpload: false,
      loginInstagramData: null,
      loginGphotosData: null
    }
  }

  componentDidMount() {
    // this.props.resetProjectReducerAction();
    this.initializeFacebookLogin();
    this.initializeGoogleLogin();
    this.getSocialNetworkCustomerData();
  }

  componentDidUpdate() {
    if (this.props.googleLoggedIn) {
      this.props.history.push('/source/select/album/');
    }
  }

  getSocialNetworkCustomerData = () => {
    const instagramData = JSON.parse(localStorage.getItem("loginInstagram"));
    const gphotosData = JSON.parse(localStorage.getItem("loginGphotos"));
    this.setState({
      loginInstagramData: instagramData,
      loginGphotosData: gphotosData
    });
  }

  logoutSocialNetworkHandler = (source) => {
    if (source === 'instagram') {
      localStorage.removeItem("loginInstagram");
      this.setState({ loginInstagramData: null });
    } else if (source === 'gphotos') {
      localStorage.removeItem("loginGphotos");
      this.setState({ loginGphotosData: null });
    }
  }

  initializeGoogleLogin = () => {
    this.auth2 = window.auth2;
  }

  initializeFacebookLogin = () => {
    this.FB = window.FB;
  }

  googleLogin = () => {
    this.auth2.grantOfflineAccess().then(this.googleLoginHandler);
  }

  googleLoginHandler = (authResult) => {
    this.props.loginSocialNetworkAction(authResult.code, this.props.source);
  }

  checkLoginStatus = () => {
    this.FB.getLoginStatus(this.facebookLoginHandler);
  }

  facebookLogin = () => {
    if (!this.FB) return;
    this.FB.getLoginStatus(response => {
      if (response.status === 'connected') {
        this.facebookLoginHandler(response);
      } else {
        this.FB.login(this.facebookLoginHandler, { scope: 'public_profile,user_photos' });
      }
    });
  }

  facebookLoginHandler = response => {
    if (response.status === 'connected') {
      this.props.setSocialTokenAction(response.authResponse.accessToken);
      this.props.history.push('/source/select/album/');
    } else {
      // TODO: Show error
    }
  }

  selectSource = (source) => {
    this.props.selectSourceAction(source);
    if (source === 'instagram') {
      if (this.state.loginInstagramData) {
        this.props.history.replace('/source/select/photos');
      } else {
        window.location.href = window._env_.INSTAGRAM_LOGIN_URL;
      }
    }
    else if (source === 'facebook') {
      this.props.setDefaultAlbumsAction()
      this.facebookLogin();
    }
    else if (source === 'gphotos') {
      if (this.state.loginGphotosData) {
        this.props.setDefaultAlbumsAction()
        this.props.history.push('/source/select/album/');
      } else {
        this.props.setDefaultAlbumsAction()
        this.googleLogin();
      }
    }
    else if (source === 'quotes') {
      this.props.removeDefaultAlbumForQuotesAction();
      this.props.history.push('/source/select/album/');
    }
    else if (source === 'device') {
      this.props.history.push('/source/photos/upload');
    }
    else {
      // console.log('Nothing to do here...');
    }
  }

  render() {
    const sourceOptions = [
      { id: 1, title: "Instagram", source: "instagram", image: source_instagram },
      { id: 2, title: "Facebook", source: "facebook", image: source_facebook },
      { id: 3, title: "Google Photos", source: "gphotos", image: source_gphotos },
      { id: 4, title: "Frases", source: "quotes", image: source_quotes },
      { id: 5, title: "Computadora", source: "device", image: source_device }
    ];

    return (
      <div className="create-project_container d-flex justify-content-center align-items-center">
        <div className="create-project_inner-container ">

          <div className="create-project_top-container d-flex justify-content-between align-items-center">
            <div></div>
            <p className="futura-bold-white-30">Selecciona tus fotos:</p>
            <div className="cursor-pointer pr-4" onClick={this.props.cancelHandler}>
              <img src={close_img} height="20" alt="" />
            </div>
          </div>
          <div className="create-project_sources-container d-flex justify-center align-items-center">
            <div className="w-100 d-flex justify-content-around align-items-start">
              {
                sourceOptions.map((option) =>
                  React.createElement(
                    CreateProjectOption, {
                    key: option.id,
                    sourceOption: option,
                    callback: this.selectSource,
                    loginInstagramData: this.state.loginInstagramData,
                    loginGphotosData: this.state.loginGphotosData,
                    logoutHandler: this.logoutSocialNetworkHandler
                  })
                )
              }
            </div>

          </div>
        </div>
      </div>
    )
  }
}

const CreateProjectOption = (props) => {
  const { sourceOption, callback, loginInstagramData, loginGphotosData, logoutHandler } = props;
  const username = ((loginInstagramData && sourceOption.source === 'instagram') ? ('@' + loginInstagramData.username) : (
    ((loginGphotosData && sourceOption.source === 'gphotos') && loginGphotosData.username)
  ));
  let idWithSource = "photos-from-" + sourceOption.title
  return (
    <div className="create-project_card d-flex flex-column align-items-center px-4 pt-1 pb-2 cursor-pointer">
      <img src={sourceOption.image} width="100" height="100" alt={sourceOption.title} id={idWithSource} className="cursor-pointer" onClick={() => callback(sourceOption.source)} />
      {((loginInstagramData && sourceOption.source === 'instagram') || (loginGphotosData && sourceOption.source === 'gphotos')) ? 
        <p className="create-project_card-subtitle m-0 pt-1">{sourceOption.title}</p> :
        <p className="create-project_card-subtitle m-0 pt-3">{sourceOption.title}</p>
      }
      <p className="social-media-username m-0">{username}</p>
      {
        ((loginInstagramData && sourceOption.source === 'instagram') || (loginGphotosData && sourceOption.source === 'gphotos')) &&
        <div className="logout-social-media d-flex justify-content-center align-items-center cursor-pointer" onClick={() => logoutHandler(sourceOption.source)}>
          Desvincular
        </div>
      }
    </div>
  );
}


const mapStateToProps = state => ({
  source: state.createProject.source,
  isAddingPhotos: state.createProject.isAddingPhotos,
  googleLoggedIn: state.createProject.googleLoggedIn,
  collectionSelected: state.collections.collectionSelected
})

const mapDispatchToProps = {
  selectSourceAction: selectSourceAction,
  resetProjectReducerAction: resetProjectReducerAction,
  setSocialTokenAction: setSocialTokenAction,
  loginSocialNetworkAction: loginSocialNetworkAction,
  removeDefaultAlbumForQuotesAction: removeDefaultAlbumForQuotesAction,
  setDefaultAlbumsAction: setDefaultAlbumsAction
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(CreateProject);