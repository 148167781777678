import {
    VALIDATE_FORM,
    VALIDATE_FORM_SUCCESS,
    VALIDATE_FORM_ERROR,
    RESET_VALIDATE_FORM
} from '../actions/ActionTypes';

const initialState = {
    form_error: null
}

export default function(state = initialState, action) {
    switch (action.type) {
        case VALIDATE_FORM:
            return {
                ...state,
                form_error: null
            }
        case VALIDATE_FORM_SUCCESS:
            return {
                ...state,
                form_error: false
            }
        case VALIDATE_FORM_ERROR:
            return {
                ...state,
                form_error: true
            }
        case RESET_VALIDATE_FORM:
            return {
                ...state,
                form_error: null
            }
        default:
            return state
    }
}