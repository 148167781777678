import React, { Component } from 'react';
import { firebaseConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { loadWalletTransactionsCustomerAction } from '../../actions/CustomerActions';
import './WalletTransactions.scss';


class Wallet extends Component {
  componentDidMount() {
    this.props.loadWalletTransactionsCustomerAction();
  }

  render() {
    const { walletTransactions } = this.props;
    return (
      <div className="container my-5">
        {walletTransactions.length > 0 &&
          <div>
            <div className="mx-4">
              <hr></hr>
            </div>
            <div className="t-color d-flex justify-content-center mt-3">
              <h5><strong>Tus Movimientos</strong></h5>
            </div>
            {walletTransactions.map((transaction, i) =>
              !transaction.from_giftcard ? (
                <React.Fragment key={i}>
                  <div className="row pl-3">
                    <div className="col-12 d-flex flex-column">
                      <p className={(transaction.value > 0) ? "d-flex title-semibold-24 color-pixypesos-plus" : "d-flex title-semibold-24 text-color-pink-pixy"}>
                        $ {transaction.value}
                      </p>
                      <p className="d-flex t-color ">
                        <strong>{(transaction.value > 0) ? transaction.referral_customer_name + " usó tu código" : "Usaste tu saldo"}</strong>
                      </p>
                      <p className="d-flex t-color">{transaction.created_at}</p>
                    </div>
                  </div>
                  <div className="mx-4">
                    <hr></hr>
                  </div>
                </React.Fragment>
              ) : (
                  <React.Fragment key={i}>
                    <div className="row pl-3">
                      <div className="col-12 d-flex flex-column">
                        <p className={(transaction.value > 0) ? "d-flex title-semibold-24 color-pixypesos-plus" : "d-flex title-semibold-24 text-color-pink-pixy"}>
                          $ {transaction.value}
                        </p>
                        <p className="d-flex t-color ">
                          <strong>Tarjeta de regalo canjeada</strong>
                        </p>
                        <p className="d-flex t-color">{transaction.created_at}</p>
                      </div>
                    </div>
                    <div className="mx-4">
                      <hr></hr>
                    </div>
                  </React.Fragment>
                )
            )}
          </div>
        }

      </div>
    );
  }
}

const mapStateToProps = state => ({
  walletTransactions: state.collections.walletTransactions
})

const mapDispatchToProps = {
  loadWalletTransactionsCustomerAction: loadWalletTransactionsCustomerAction
}

export default compose(
  firebaseConnect(),
  connect(mapStateToProps, mapDispatchToProps)
)(Wallet);