import React from 'react';
import close_img from '../../images/newDesignImages/close-gray.png';
import failure_img from '../../images/newDesignImages/failure-icon.png'
import './FailurePopup.scss';



export default function FailurePopup(props) {
  return (
    <div className="success-popup_main d-flex justify-content-center align-items-center">
      <div className="success-popup_inside d-flex flex-column justify-content-center align-items-center">
        <img className="success-popup_close cursor-pointer" src={close_img} height="20" alt="" onClick={props.handleCloseFailurePopup} />
        <img className="failure-popup_img mb-5" src={failure_img} />
        <p className="gotham-bold-25 mb-2">¡Hey espera!</p>
        <p className="gotham-book-18" style={{textAlign: 'center'}}>
          Necesitas {props.numPages} fotos para poder <br></br> agregar tu Pixyalbum al carrito.
        </p>
        <div className="success-popup_buttons mt-5 d-flex align-items-center justify-content-center">
          <button className="failure-popup_btn" onClick={props.handleCloseFailurePopup}>
            Entendido
          </button>
        </div>
      </div>
    </div>
  );
}