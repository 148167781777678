import React from 'react';

import './Loading.scss';

const Loading = (props) => {
  return (
    props.alternativeLoading ?
      <div className="loading_alternative d-flex flex-row justify-content-center align-items-center">
        <div className="spinner-border text-light" role="status" aria-hidden="true"></div>
        <span className="ml-3 text-light">
          {
            props.title ?
              props.title
              :
              'Cargando...'
          }
        </span>
      </div>
      :
      <div className="loading_regular d-flex flex-row justify-content-center align-items-center">
        <div className="spinner-border text-primary" role="status" aria-hidden="true"></div>
        <span className="ml-3 text-primary">
          {
            props.title ?
              props.title
              :
              'Cargando...'
          }
        </span>
      </div>
  );
}

export default Loading;