import React, { Component } from 'react';
import './Collections.scss';

// Redux
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firebaseConnect } from 'react-redux-firebase';
import { loadAvailableProductsAction, getCartProductsQuantityAction } from '../../actions/CustomerActions';
import { loadAlbumConfigurationsAction } from '../../actions/GlobalActions';
import { changeRouteAction, changeProjectTypeAction, updateIsNewCustomer } from '../../actions/NavigationActions';
import { updateLoadingMessageAction } from '../../actions/GlobalActions';
import { createProductAction } from '../../actions/CreateProjectActions'; 

import ApiCustomer from '../../utils/Api/ApiCustomer';
import TokenHelper from '../../utils/Firebase/TokenHelper';

//Components
import Scrollable from '../../components/Scrollable';
import Loading from '../../components/Loading';
import CollectionCard from '../../components/CollectionCard';
import Modal from '../../components/Modal';
import AlertView from '../../components/AlertView';
import Banner from '../../components/Banner';
import CreateProductCard from '../../components/CreateProductCard';
import Footer from '../../components/Footer';
import BannerInfoModal from '../../components/BannerInfoModal';
import MigrationLoader from '../../components/MigrationLoader';
import SuccessPopup from '../../components/SuccessPopup';
import promo_img from '../../images/newDesignImages/promo-new-customer.png';


class Collections extends Component {

  constructor(props) {
    super(props);
    this.state = {
      collections: [],
      showDeleteConfirmation: false,
      productToDelete: null,
      isLoading: true,
      showError: false,
      errorMessage: '',
      mouseOverCard: { id: '', productType: '' },
      availableProducts: null,
      customerProducts: [],
      showBannerDetails: false,
      selectedBannerData: null,
      showMigratingProject: false,
      migratingCollection: false,
      showSuccessMigrationPopup: false,
    }
  }

  componentDidMount() {
    this.props.loadAlbumConfigurationsAction();
    this.getProducts();
    this.props.changeRouteAction('projects');
    this.props.getCartProductsQuantityAction();
    this.getUserProducts();
  }

  reload = async() => {
    this.setState({isLoading: true});
    await this.props.loadAlbumConfigurationsAction();
    this.getProducts();
    this.props.changeRouteAction('projects');
    this.props.getCartProductsQuantityAction();
  }

  getProducts = () => {
    TokenHelper.getUserToken().then(idToken => {
      ApiCustomer.getProducts({
        token: idToken,
        version: "2"
      }).then(response => {
        if (response.data.success) {
          this.setState({ availableProducts: response.data.data });
          this.props.loadAvailableProductsAction(response.data.data);
        } else {
          this.setState({
            showError: true,
            errorMessage: 'Hubo un error, por favor intenta más tarde.',
            isLoading: false
          });
        }
      }).catch(error => {
        this.setState({
          showError: true,
          errorMessage: 'Hubo un error, por favor intenta más tarde.',
          isLoading: false
        });
      });
    }).catch(error => {
      this.setState({
        showError: true,
        errorMessage: 'Hubo un error, por favor intenta más tarde.',
        isLoading: false
      });
    });
  }

  getUserProducts = () => {
    TokenHelper.getUserToken().then(idToken => {
      ApiCustomer.getCustomerProducts({ token: idToken }).then(response => {
        if (response.data.success) {
          this.setState({
            customerProducts: response.data.data,
            isLoading: false
          })
        } else {
          this.setState({
            showError: true,
            errorMessage: 'Hubo un error, por favor intenta más tarde.',
            isLoading: false
          });
        }
      }).catch(error => {
        this.setState({
          showError: true,
          errorMessage: 'Hubo un error, por favor intenta más tarde.',
          isLoading: false
        });
      })
    }).catch(error => {
      this.setState({
        showError: true,
        errorMessage: 'Hubo un error, por favor intenta más tarde.',
        isLoading: false
      });
    });
  }

  deleteProduct = (product) => {
    this.setState({ isLoading: true })
    TokenHelper.getUserToken().then(idToken => {
      ApiCustomer.deleteCustomerProduct({
        token: idToken,
        product_id: product.id,
        product_type: product.product_type
      }).then(response => {
        if (response.data.success) {
          this.setState({
            customerProducts: this.state.customerProducts.filter(item => !(item.id === product.id && item.product_type === product.product_type)),
            isLoading: false
          });
        }
      }).catch(error => {
        this.setState({
          isLoading: false,
          showError: true,
          errorMessage: error.data.message
        });
      });
    }).catch(error => {
      this.setState({
        isLoading: false,
        showError: true,
        errorMessage: 'Hubo un error, por favor intenta más tarde.'
      });
    });
  }

  selectCollectionCallBack = (product, productType, numPages) => {
    if (product.migrating) {
      this.setState({
        showError: true,
        errorMessage: 'Espera un momento, estamos cargando tus fotos.'
      });
    } else {
      switch (productType) {
        case 'album':
          if (parseInt(product.images_count) > parseInt(numPages)) {
            this.migrateProjectToMaxPages(product, parseInt(numPages));
            break;
          } else {
            this.props.changeRouteAction('details');
            this.props.history.push({
              pathname: 'collection/albums/detail',
              state: product
            });
            break;
          }
        case 'magnet':
          this.props.changeRouteAction('details');
          this.props.history.push({
            pathname: '/magnet/detail',
            state: { id: product.id, productType: productType }
          });
          break;
        case 'picture':
          this.props.changeRouteAction('details');
          this.props.history.push({
            pathname: '/canvas/detail',
            state: { id: product.id, productType: productType }
          });
          break;
        case 'ornament':
          this.props.changeRouteAction('details');
          this.props.history.push({
            pathname: '/ornament/detail',
            state: { id: product.id, productType: productType }
          });
          break;
        default:
          break;
      }
    }
  }

  migrateProjectToMaxPages = async(product, maxPages) => {
    this.setState({
      showMigratingProject: true,
      migratingCollection: true
    });
    const idToken = await TokenHelper.getUserToken();
    const projectData = {
      token: idToken,
      product_id: product.id,
      product_type: 'album'
    }
    const response = await ApiCustomer.getCustomerProductDetail(projectData);
    if (response.data.success) {
      let photos = response.data.data.pages.map(item => item.photos[0]);
      let arrayOfSixtyPhotosArrays = [];
      for (let i=0; i<photos.length; i+=maxPages) {
        arrayOfSixtyPhotosArrays.push(photos.slice(i, i+maxPages))
      }
      for (let i=0; i<arrayOfSixtyPhotosArrays.length;  i++) {
        let photosDataForAPI = [];
        let index = 0;
        for (let photoElement of arrayOfSixtyPhotosArrays[i]) {
          delete photoElement.id;
          photoElement.image_url=photoElement.image_url.slice(0, photoElement.image_url.indexOf('?'))
          photoElement.print_url=photoElement.print_url.slice(0, photoElement.print_url.indexOf('?'))
          photoElement.index = index;
          photosDataForAPI.push(photoElement);
          index += 1;
        }
        const parsePagesQuantityId = (maxPages) => {
          switch(maxPages) {
            case 60: return '21';
            case 80: return '22';
            case 100: return '23';
            default: break;
          }
        }
        const params = {
          token: idToken,
          data: photosDataForAPI,
          product_type: 'album',
          product_title: product.name + ' ' + (i+1).toString(),
          configurations: ['1', '17', parsePagesQuantityId(maxPages)]
        }
        const createProductResponse = await ApiCustomer.createProduct(params);
      }

      const dataToDelete = {
        token: idToken,
        product_id: product.id,
        product_type: 'album'
      }
      const deleteResponse = await ApiCustomer.deleteCustomerProduct(dataToDelete)
      if (deleteResponse.data.success) {
        this.setState({
          showMigratingProject: false,
          migratingCollection: false
        });
        await this.reload();
        this.setState({showSuccessMigrationPopup: true});
      }
    }
  }


  deleteCallback = (product) => {
    if (product.migrating) {
      this.setState({
        showError: true,
        errorMessage: 'Espera un momento, estamos cargando tus fotos.'
      });
    } else {
      this.setState({
        showDeleteConfirmation: true,
        productToDelete: product
      });
    }
  }

  okErrorHandler = () => {
    this.setState({
      showError: false,
      errorMessage: ''
    });
  }

  okDeleteConfirmation = () => {
    this.setState({ showDeleteConfirmation: false });
    const { productToDelete } = this.state;
    if (productToDelete) {
      this.deleteProduct(productToDelete);
      this.setState({ productToDelete: null });
    }
  }

  cancelDeleteConfirmation = () => {
    this.setState({
      showDeleteConfirmation: false,
      productToDelete: null
    });
  }

  /////////////////////////////

  handleMouseOver = (id, productType) => {
    this.setState({
      mouseOverCard: { id, productType }
    });
  }

  handleCreateClick = (productType) => {
    if (productType === "item") {
      this.props.history.push('/accessories')
    } else {
      this.props.history.push({
        pathname: '/product/info',
        state: this.state.availableProducts.filter(item => item.product_type === productType)
      });
    }
  }

  handleShowBannerDetails = (selectedBannerData) => {
    this.setState({
      showBannerDetails: true,
      selectedBannerData
    });
  }

  handleCloseBannerDetails = () => {
    this.setState({ showBannerDetails: false });
  }

  handleCloseSuccessPopup = () => {
    this.setState({ showSuccessMigrationPopup: false });
  }

  handleClickOnPromo = () => {
    this.props.updateIsNewCustomer(false)
    this.props.history.push('/create');
  }


  render() {
    const {
      showDeleteConfirmation,
      productToDelete,
      showError,
      isLoading,
      availableProducts,
      customerProducts,
      showBannerDetails,
      selectedBannerData,
      showMigratingProject,
      migratingCollection,
      showSuccessMigrationPopup,
    } = this.state;
    return (
      <React.Fragment>
        {
          isLoading &&
          <Loading
            title={this.props.loadingMessage}
          />
        }
        {
          showError &&
          <Modal>
            <AlertView
              title={'¡Ups!'}
              message={this.state.errorMessage}
              okHandler={this.okErrorHandler}
              cancel={true} />
          </Modal>
        }
        {
          showDeleteConfirmation &&
          <Modal>
            <AlertView
              title={'Pixyalbum'}
              message={'¿Deseas eliminar ' + productToDelete.name + '?'}
              okHandler={this.okDeleteConfirmation}
              cancelHandler={this.cancelDeleteConfirmation}
              cancel={true} />
          </Modal>
        }
        {
          showBannerDetails &&
          <Modal>
            <BannerInfoModal
              selectedBannerData={selectedBannerData}
              handleCloseBannerDetails={this.handleCloseBannerDetails}
            />
          </Modal>
        }
        {
          showMigratingProject && 
          <Modal>
            <MigrationLoader migratingCollection={migratingCollection}/>
          </Modal>
        }
        {
          showSuccessMigrationPopup &&
          <Modal>
            <SuccessPopup
              handleCloseSuccessPopup={this.handleCloseSuccessPopup}
              textMessage={'¡Listo! Tu colección ya está dividida. Ahora todos los proyectos son de 1 fotolibro de 100 fotos.'}
              history={this.props.history}
            />
          </Modal>
        }
        {this.props.isNewCustomer &&
          <Modal>
            <div className="d-flex justify-content-center align-items-center" style={{width: '100%', height: '100%'}}>
              <img className="collections_promo-img" src={promo_img} alt="" onClick={this.handleClickOnPromo} />
            </div>
          </Modal>
        }
        <Scrollable scrollableContent={
          <div className="collections-main_container d-flex flex-column justify-content-center">
            <Banner handleShowBannerDetails={this.handleShowBannerDetails} />
            <div className="mt-5 mb-3 d-flex align-items-center justify-content-center" >
              <p className="gotham-bold-25">¿Qué te gustaría crear el día de hoy?</p>
            </div>
            <div className="mb-5 d-flex aling-items-center justify-content-center">
              <div className="collections_available-products-inside d-flex aling-items-center justify-content-center">
                {availableProducts && availableProducts.map((item, i) =>
                  <CreateProductCard
                    key={i}
                    item={item}
                    image={item.image}
                    title={item.title}
                    handleCreateClick={this.handleCreateClick}
                    fromHome
                  />
                )}
              </div>
            </div>

            {/*<div className="pb-5 d-flex justify-content-center">
              <div className="collections_accessories">
                <button className="collections_accessories-btn" onClick={() => this.props.history.push('/accessories')}>Ver todos</button>
              </div>  
                </div>*/}
            
            {/* <div className="py-5 d-flex justify-content-center">
              <div className="collections_accessories">
                <div className="collections_accessories-top pl-5 d-flex flex-column justify-content-center align-items-start">
                  <div className="collections_accessories-newtag d-flex justify-content-center align-items-center">
                    <p className="gotham-bold-white-30">NUEVO</p>
                  </div>
                  <p className="gotham-bold-green-30">Accesorios</p>
                  <p className="gotham-book-darkgray-20">Convierte tus fotos favoritas en un <br></br> fotolibro, en imanes o en un cuadro <br></br> para tu pared.</p>
                </div>
                <div className="collections_accessories-bottom pl-5 d-flex justify-content-start align-items-center">
                  <button className="collections_accessories-btn" onClick={() => this.props.history.push('/accessories')}>Ver todos</button>
                </div>
              </div>
            </div> */}

            {customerProducts.length > 0 ?
              <div className="collections_container">
                <div className="pl-5 py-3 d-flex justify-content-start align-items-center">
                  <p className="gotham-bold-25 ml-3 mr-4">Mis proyectos</p>
                </div>

                <div className="collections_customer-products d-flex justify-content-center">
                  <div className="collections_customer-products-inside d-flex flex-wrap">
                    {
                      customerProducts.map((product, i) => (
                        <CollectionCard
                          key={i}
                          product={product}
                          selectCollectionCallBack={this.selectCollectionCallBack}
                          handleMouseOver={this.handleMouseOver}
                          mouseOverCard={this.state.mouseOverCard}
                          deleteCallback={this.deleteCallback}
                        />
                      ))
                    }
                  </div>

                </div>
              </div> : null}
            <Footer history={this.props.history}/>

          </div>
        }></Scrollable>

      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.firebase.auth,
  loadingMessage: state.globalReducer.loadingMessage,
  customerName: state.collections.customerName,
  customerEmail: state.collections.customerEmail,
  projectType: state.navigationReducer.projectType,
  availableProductsGlobal: state.collections.availableProductsGlobal,
  cartProductsQuantity: state.collections.cartProductsQuantity,
  isNewCustomer: state.navigationReducer.isNewCustomer
});

const mapDispatchToProps = {
  updateLoadingMessageAction: updateLoadingMessageAction,
  loadAlbumConfigurationsAction: loadAlbumConfigurationsAction,
  changeRouteAction: changeRouteAction,
  changeProjectTypeAction: changeProjectTypeAction,
  loadAvailableProductsAction: loadAvailableProductsAction,
  getCartProductsQuantityAction: getCartProductsQuantityAction,
  createProductAction: createProductAction,
  updateIsNewCustomer: updateIsNewCustomer
};

export default compose(
  firebaseConnect(),
  connect(mapStateToProps, mapDispatchToProps)
)(Collections);