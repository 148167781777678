import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import ApiCustomer from '../../utils/Api/ApiCustomer';
import TokenHelper from '../../utils/Firebase/TokenHelper';
// import { createCollectionData } from '../../utils/CollectionsHelper/CreateCollectionData';
import { createCollectionData, saveParamsForUpdateAlbumConfigurationAction, updateCollectionSelectedAction } from '../../actions/CustomerActions'

import close_img from '../../images/newDesignImages/close-gray.png';
import check_active from '../../images/newDesignImages/check-active.png';
import check_inactive from '../../images/newDesignImages/check-inactive.png';
import calendar_img from '../../images/newDesignImages/calendar-img.png';

import './AlbumConfigurations.scss';

class AlbumConfigurations extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      collectionSelected: this.props.collectionSelected,
      isLoading: false,
      selectedSizeConfiguration: this.props.albumConfigurations.filter(item => item.id === this.props.collectionSelected.configurations.id)[0],
      selectedCoverConfiguration: this.props.collectionSelected.configurations.configurations.filter(item => item.type === 'cover')[0],
      selectedPrintDatesConfiguration: this.props.collectionSelected.print_dates,
      selectedPageConfiguration: this.props.collectionSelected.configurations.configurations.filter(item => item.type === 'page')[0],
    }
  }


  updateAlbumSettings = async() => {
    const { collectionSelected, selectedSizeConfiguration, selectedCoverConfiguration, selectedPrintDatesConfiguration, selectedPageConfiguration, isLoading } = this.state;
    this.setState({isLoading: true})
    try {
      const idToken = await TokenHelper.getUserToken();
      let params = {
        token: idToken,
        album_id: collectionSelected.id,
        print_dates: selectedPrintDatesConfiguration,
        configurations: [
          {
            type: "size",
            id: selectedSizeConfiguration.id
          },
          {
            type: "cover",
            id: selectedCoverConfiguration.id
          },
          {
            type: "page",
            id: selectedPageConfiguration.id
          }
        ]
      }
      if (this.validatePages(selectedPageConfiguration)) {
        const pagesDiference = collectionSelected.pages.length - parseInt(selectedPageConfiguration.description)
        this.props.saveParamsForUpdateAlbumConfigurationAction(params, pagesDiference)
        this.props.showDeletePagesAfterConfigurationHandler();
        this.props.handleShowConfigurations(false)
      } else {
        const response = await ApiCustomer.updateCollectionSettings(params)
        if (response.data.success) {
          const { collection } = createCollectionData(response.data.data.result, parseInt(selectedPageConfiguration.description));
          this.props.updateCollectionSelectedAction(collection, parseInt(selectedPageConfiguration.description));
          this.setState({isLoading: false});
          this.props.handleShowConfigurations(true)
        } else {
          
          this.setState({isLoading: false});
          this.props.handleShowConfigurations(false)
          this.props.errorHandler('Hubo un error, por favor intenta más tarde.');
        }
      }
    } catch(error) {
      this.props.handleShowConfigurations(false)
      this.props.errorHandler('Hubo un error, por favor intenta más tarde.');
    }
  }

  validatePages = (selectedPageConfiguration) => {
    const { collectionSelected } = this.props;
    if (parseInt(selectedPageConfiguration.description) < collectionSelected.pages.length) {
      return true;
    } else {
      return false;
    }
  }

  ///////////////
  
  handleUpdateSizeConfiguration = (selectedSize) => {
    const { selectedCoverConfiguration, selectedPageConfiguration } = this.state;
    const updatedCoverConfiguration = selectedSize.configurations.filter(item => item.description === selectedCoverConfiguration.description)[0]
    const updatedPageConfiguration = selectedSize.configurations.filter(item => item.description === selectedPageConfiguration.description)[0]
    this.setState({
      selectedSizeConfiguration: selectedSize,
      selectedCoverConfiguration: updatedCoverConfiguration,
      selectedPageConfiguration: updatedPageConfiguration
    })
  }

  handleUpdateCoverConfiguration = (hardOrSoft) => {
    const { selectedSizeConfiguration } = this.state;
    const selectedCoverConfiguration = selectedSizeConfiguration.configurations.filter(item => item.description === hardOrSoft)[0]
    this.setState({selectedCoverConfiguration})
  }

  handleUpdatePageConfiguration = (pagesNum) => {
    const { selectedSizeConfiguration } = this.state;
    const selectedPageConfiguration = selectedSizeConfiguration.configurations.filter(item => item.description === pagesNum)[0]
    this.setState({selectedPageConfiguration})
  }

  handleUpdatePrintDates = (printDates) => {
    this.setState({selectedPrintDatesConfiguration: printDates})
  }

  render() {
    const { collectionSelected, isLoading, selectedSizeConfiguration, selectedPrintDatesConfiguration, selectedCoverConfiguration, selectedPageConfiguration } = this.state;
    const price = selectedSizeConfiguration.price + selectedCoverConfiguration.price + selectedPageConfiguration.price;
    return (
      <div className="album-config_container d-flex justify-content-center align-items-center">
        <div className="album-config_inside py-5 d-flex flex-column justify-content-between">
          <img className="edit-name_close cursor-pointer" src={close_img} height="20" alt="" onClick={() => this.props.handleShowConfigurations(false)}/>
          <div className="d-flex justify-content-start align-items-center">
            <p className="gotham-bold-gray-18">Personaliza tu álbum</p>
          </div>
          <div className="d-flex justify-content-end align-items-center">
            <p className="gotham-md-gray-17">Total: ${price}.00 c/u</p>
          </div>
          <div>
            <p className="gotham-md-gray-17">Tamaño</p>
            <div className="mt-1 d-flex justify-content-between align-items-center">
              {this.props.albumConfigurations.map((item, i) => (
                <button 
                  key={i} 
                  className={selectedSizeConfiguration.id === this.props.albumConfigurations[i].id ? "album-config_selected-option" : "album-config_unselected-option"}
                  onClick={() => this.handleUpdateSizeConfiguration(this.props.albumConfigurations[i])}
                >
                  {item.description}
                </button>
              ))}
            </div>
          </div>
          <div>
            <p className="gotham-md-gray-17">Tipo de pasta</p>
            <div className="mt-1 d-flex justify-content-between align-items-center">
              <button 
                className={(selectedCoverConfiguration.id === '17' || selectedCoverConfiguration.id === '19') ? "album-config_selected-option" : "album-config_unselected-option"}
                onClick={() => this.handleUpdateCoverConfiguration('suave')}
              >
                Pasta suave
              </button>
              <button 
                className={(selectedCoverConfiguration.id === '18' || selectedCoverConfiguration.id === '20') ? "album-config_selected-option" : "album-config_unselected-option"}
                onClick={() => this.handleUpdateCoverConfiguration('dura')}
              >
                Pasta dura
              </button>
            </div>
          </div>
          
          <div>
            <p className="gotham-md-gray-17">Número de páginas</p>
            <div className="mt-1 d-flex justify-content-between align-items-center">
              <button 
                className={(selectedPageConfiguration.id === '21' || selectedPageConfiguration.id === '24') ? "album-config_selected-pages" : "album-config_unselected-pages"}
                onClick={() => this.handleUpdatePageConfiguration('60')}
              >
                60
              </button>
              <button 
                className={(selectedPageConfiguration.id === '22' || selectedPageConfiguration.id === '25') ? "album-config_selected-pages" : "album-config_unselected-pages"}
                onClick={() => this.handleUpdatePageConfiguration('80')}
              >
                80
              </button>
              <button 
                className={(selectedPageConfiguration.id === '23' || selectedPageConfiguration.id === '26') ? "album-config_selected-pages" : "album-config_unselected-pages"}
                onClick={() => this.handleUpdatePageConfiguration('100')}
              >
                100
              </button>
            </div>
          </div>

          <div>
            <p className="gotham-md-gray-17">Imprimir Fechas</p>
            <div className="mt-1 d-flex justify-content-between align-items-center">
              <button 
                className={selectedPrintDatesConfiguration ? "album-config_selected-option" : "album-config_unselected-option"}
                onClick={() => this.handleUpdatePrintDates(true)}
              >
                Si
              </button>
              <button
                className={!selectedPrintDatesConfiguration ? "album-config_selected-option" : "album-config_unselected-option"}
                onClick={() => this.handleUpdatePrintDates(false)}
              >
                No
              </button>
            </div>
          </div>

          {isLoading ? 
            <button className="album-config_save-btn mt-2 d-flex justify-content-center align-items-center" disabled>
              <div className="spinner-border spinner-border-sm"></div>
            </button> :
            <button className="album-config_save-btn mt-2" onClick={this.updateAlbumSettings}>Guardar</button>
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  albumConfigurations: state.globalReducer.albumConfigurations
});

const mapDispatchToProps = {
  saveParamsForUpdateAlbumConfigurationAction,
  updateCollectionSelectedAction
}


export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(AlbumConfigurations);
