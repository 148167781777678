import React, { Component } from 'react';
import { firebaseConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { loadWalletCustomerAction } from '../../actions/CustomerActions';
import { changeRouteAction } from '../../actions/NavigationActions';

import './Wallet.scss';

import imgShare from '../../images/share.png';
import imgMoney from '../../images/money.png';
import imgHeart from '../../images/heart.png';
import cover from '../../images/ilustracion-referidos.jpg';

//Components
import Scrollable from '../../components/Scrollable';
import WalletTransactions from '../WalletTransactions';


class Wallet extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  componentDidMount() {
    this.props.loadWalletCustomerAction();
    this.props.changeRouteAction('wallet');
  }

  render() {
    const { wallet } = this.props;
    return (
      <React.Fragment>
        <Scrollable scrollableContent={
          <div className="container">
            <div className="row mt-5">
              <div className="col-12 d-flex flex-column">
                <img className="cover-image" src={cover} alt="image" />
                <div className="d-flex justify-content-center border-pink flex-column p-3">
                  <p className="text-center">Saldo</p>
                  <p className="text-center title-semibold-24"> ${wallet.balance} </p>
                </div>
              </div>
            </div>
            <div className="row mt-5 mx-2">
              <div className="col-6 col-sm-4 d-flex flex-column justify-content-start">
                <img className="center-img" src={imgShare} alt="img" />
                <p className="text-center mt-3">Comparte tu código</p>
                <p className="text-center">con tus amigos</p>
              </div>
              <div className="col-6 col-sm-4 d-flex flex-column justify-content-start">
                <img className="center-img" src={imgHeart} alt="img" />
                <p className="text-center mt-3">Cuando hagan su primera compra</p>
                <p className="text-center">obtendrán un descuento de</p>
                <p className="text-center">${wallet.referral_discount} Pixypesos</p>
              </div>
              <div className="col-12 col-sm-3 d-flex flex-column justify-content-start">
                <img className="center-img" src={imgMoney} alt="img" />
                <p className="text-center mt-3">Tú recibirás ${wallet.referral_deposit} Pixypesos</p>
              </div>
            </div>
            <div className="row d-flex justify-content-center">
              <div className="col-4 d-flex flex-column mt-5 p-3 border-pixypesos bg-code">
                <p className="text-center">Tu código</p>
                <p className="text-center text-color-purple-pixy title-semibold-24">{wallet.referral_code}</p>
              </div>
            </div>
            <WalletTransactions />
          </div>
        }>
        </Scrollable>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  wallet: state.collections.wallet
})

const mapDispatchToProps = {
  loadWalletCustomerAction: loadWalletCustomerAction,
  changeRouteAction: changeRouteAction
}

export default compose(
  firebaseConnect(),
  connect(mapStateToProps, mapDispatchToProps)
)(Wallet);