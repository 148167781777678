import React from 'react';
import add_icon_filled from '../../images/newDesignImages/add-filled.png';
import reorder_icon from '../../images/newDesignImages/reorder-img.png';
import close_img from '../../images/newDesignImages/close-white.png';

import customize from '../../images/newDesignImages/customize.png';

import './ConfigurationsBar.scss';

export default function ConfigurationsBar(props) {
  return (
    props.configBarShownOption === 'default' ? (
      <div className="config-bar_default d-flex flex-column justify-content-around align-items-center">
        <div 
          className="config-bar_default-item d-flex flex-column align-items-center cursor-pointer"
          onClick={() => props.handleShowConfigurations(false)}
        >
          <img src={customize} height="20" alt="" />
          <p className="mt-1">Personalizar</p>
        </div>
        <div 
          className="config-bar_default-item d-flex flex-column align-items-center cursor-pointer" 
          onClick={props.addPhotosHandler} 
          onMouseEnter={() => props.handleMouseEnter('addPhotos')} 
          onMouseLeave={() => props.handleMouseEnter('')}
        >
          <img src={add_icon_filled} height="30" alt="" />
          <p className="mt-1">Agregar <br></br> fotos</p>
        </div>
        <div 
          className="config-bar_default-item d-flex flex-column align-items-center cursor-pointer"
          onClick={() => props.handleSelectConfigBarOption('reorder')}
        >
          <img src={reorder_icon} height="20" alt="" />
          <p className="mt-1">Ordenar <br></br> páginas</p>
        </div>
        
      </div> 
    ) : ( 
      props.configBarShownOption === 'deletePhotos' ? 
      <div className="config-bar_delete d-flex justify-content-between align-items-center">
        <div className="config-bar_delete-left d-flex align-items-center">
          <div className="config-bar_close-reorder d-flex justify-content-center align-items-center cursor-pointer" onClick={props.handleCloseDeleteOption}>
            <img src={close_img} height="30" alt="" />
          </div>
          <p className="gotham-md-white-18 ml-3">{props.selectedPhotosForDelete.length} {props.selectedPhotosForDelete.length > 1 ? 'fotos seleccionadas' : 'foto seleccionada'}</p>
        </div>
        <button className="config-bar_delete-btn" onClick={props.showDeleteConfirmationHandler}>
          Eliminar
        </button>
      </div>
      :
      <div className="config-bar_reorder d-flex justify-content-end align-items-center">
        <button className="mx-3 config-bar_option-btn-v2" onClick={props.handleReorderFromOldest}>
          De la más vieja a la más nueva
        </button>
        <button className="mx-3 config-bar_option-btn-v2" onClick={props.handleReorderFromNewest}>
          De la más nueva a la más vieja
        </button>
        <button className="mx-3 config-bar_option-btn" onClick={props.handleRestoreOrder}>
          Restaurar
        </button>
        <button className="mx-3 config-bar_option-btn" onClick={props.handleCloseReorder}>
          Cancelar
        </button>
        <button className="generic-button-blue" onClick={props.handleShowReorderConfirmation}>Guardar</button>
      </div>

    )
  );
}