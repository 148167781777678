import React from 'react';
import trash_img from '../../images/newDesignImages/trash-img.png';
import './CollectionCard.scss';

const CollectionCard = (props) => {
  const { cover_url, images_count, name, migrating, product_type } = props.product;
  const { selectCollectionCallBack, deleteCallback, collection, product, editNameCollectionCallBack, handleMouseOver, mouseOverCard } = props;
  switch (product_type) {
    case 'magnet':
      return (
        <div className="card_main-container d-flex flex-column align-items-center" onMouseOver={() => handleMouseOver(product.id, product_type)}>
          <div className="card_container cursor-pointer" onClick={() => selectCollectionCallBack(product, product_type)}>
            <div className="card_top-container d-flex flex-column align-items-center justify-content-center">
              <p className="gotham-md-18"><b>{name}</b></p>
              <p className="gotham-book-gray-15">{images_count}/15 imágenes</p>
            </div>
            <div className="card_img-container d-flex align-items-center justify-content-center">
              <div className="card_magnet mb-3 d-flex justiify-content-start align-items-end">
                <img className="card_img-magnet" src={cover_url} width="78%" height="86%" alt=""/>
              </div>
            </div>
          </div>
          {(mouseOverCard.id === product.id && mouseOverCard.productType === product.product_type) &&
            <div className="mt-2 w-100 d-flex justify-content-center align-items-center cursor-pointer">
              <div className="card_delete-container d-flex justify-content-center align-items-center" onClick={() => deleteCallback(product)}>
                <img src={trash_img} height="40%" alt="delete" />
              </div>
            </div>
          }
        </div>
      );
    case 'picture':
      return (
        <div className="card_main-container d-flex flex-column align-items-center" onMouseOver={() => handleMouseOver(product.id, product_type)}>
          <div className="card_container cursor-pointer" onClick={() => selectCollectionCallBack(product, product_type)}>
            <div className="card_top-container d-flex flex-column align-items-center justify-content-center">
              <p className="gotham-md-18"><b>{name}</b></p>
              <p className="gotham-bold-gray-15">{product.configurations}</p>
              <p className="gotham-book-gray-15">{images_count > 1 ? images_count + ' imágenes' : images_count + ' imagen'}</p>
            </div>
            <div className="card_img-container d-flex align-items-center justify-content-center" >
              <div className="card_canvas mb-3">
                <img className="card_img" src={cover_url} height="100%" width="100%" alt=""/>
              </div>
            </div>
          </div>
          {(mouseOverCard.id === product.id && mouseOverCard.productType === product.product_type) &&
            <div className="mt-2 w-100 d-flex justify-content-center align-items-center cursor-pointer">
              <div className="card_delete-container d-flex justify-content-center align-items-center" onClick={() => deleteCallback(product)}>
                <img src={trash_img} height="40%" alt="delete" />
              </div>
            </div>
          }
        </div>
      )
      case 'ornament':
        return (
          <div className="card_main-container d-flex flex-column align-items-center" onMouseOver={() => handleMouseOver(product.id, product_type)}>
            <div className="card_container cursor-pointer" onClick={() => selectCollectionCallBack(product, product_type)}>
              <div className="card_top-container d-flex flex-column align-items-center justify-content-center">
                <p className="gotham-md-18"><b>{name}</b></p>
                <p className="gotham-book-gray-15">{images_count}/10 imágenes</p>
              </div>
              <div className="card_img-container d-flex align-items-center justify-content-center">
                <div className="collection_card_ornament mb-3 d-flex justiify-content-start align-items-end">
                  <img className="collection_card_img-ornament" src={cover_url} width="90%" height="80%" alt=""/>
                </div>
              </div>
            </div>
            {(mouseOverCard.id === product.id && mouseOverCard.productType === product.product_type) &&
              <div className="mt-2 w-100 d-flex justify-content-center align-items-center cursor-pointer">
                <div className="card_delete-container d-flex justify-content-center align-items-center" onClick={() => deleteCallback(product)}>
                  <img src={trash_img} height="40%" alt="delete" />
                </div>
              </div>
            }
          </div>
        );
    case 'album':
      const predesignCoverStyles = {
        backgroundImage: 'url(' + cover_url + ')',
        backgroundSize: 'cover'
      }
      const parsePagesNum = () => {
        const firstDigit = product.configurations.split(' ')[2][6]
        if (firstDigit === '1') {
          return '100'
        } else if (firstDigit === '6') {
          return '60'
        } else if (firstDigit === '8') {
          return '80'
        }
      }
      return product.has_predesign_cover ? (
        <div className="card_main-container d-flex flex-column align-items-center" onMouseOver={() => handleMouseOver(product.id, product_type)}>
          <div className="card_container cursor-pointer" onClick={() => selectCollectionCallBack(product, product_type, parsePagesNum())}>
            <div className="card_top-container d-flex flex-column align-items-center justify-content-center">
              <p className="gotham-md-18"><b>{name}</b></p>
              <p className="gotham-bold-gray-15" style={{textAlign: 'center'}}>{product.configurations}</p>
              <p className="gotham-book-gray-15">{images_count}/{parsePagesNum()} páginas</p>
            </div>
            <div className="card_img-container d-flex align-items-center justify-content-center">
              <div className="card_album shadow d-flex" style={predesignCoverStyles}>
                <div className="card_album-left"></div>
                <div className="card_album-right d-flex align-items-center justify-content-center"></div>
              </div>
            </div>
          </div>
          {(mouseOverCard.id === product.id && mouseOverCard.productType === product.product_type) &&
            <div className="mt-2 w-100 d-flex justify-content-center align-items-center cursor-pointer">
              <div className="card_delete-container d-flex justify-content-center align-items-center" onClick={() => deleteCallback(product)}>
                <img src={trash_img} height="40%" alt="delete" />
              </div>
            </div>
          }
        </div>
      ) : (
        <div className="card_main-container d-flex flex-column align-items-center" onMouseOver={() => handleMouseOver(product.id, product_type)}>
          <div className="card_container cursor-pointer" onClick={() => selectCollectionCallBack(product, product_type, parsePagesNum())}>
            <div className="card_top-container d-flex flex-column align-items-center justify-content-center">
              <p className="gotham-md-18"><b>{name}</b></p>
              <p className="gotham-bold-gray-15" style={{textAlign: 'center'}}>{product.configurations}</p>
              <p className="gotham-book-gray-15">{images_count}/{parsePagesNum()} páginas</p>
            </div>
            <div className="card_img-container d-flex align-items-center justify-content-center" >
              <div className="card_album shadow d-flex">
                <div className="card_album-left"></div>
                <div className="card_album-right d-flex align-items-center justify-content-center">
                  <img className="card_img" src={cover_url} height="90%" width="90%" alt=""/>
                </div>
              </div>
            </div>
          </div>
          {(mouseOverCard.id === product.id && mouseOverCard.productType === product.product_type) &&
            <div className="mt-2 w-100 d-flex justify-content-center align-items-center cursor-pointer">
              <div className="card_delete-container d-flex justify-content-center align-items-center" onClick={() => deleteCallback(product)}>
                <img src={trash_img} height="40%" alt="delete" />
              </div>
            </div>
          }
        </div>
          )
    default:
      return ("");
  }

}

export default CollectionCard;