import React from 'react';

const Scrollable = ({ scrollableContent, onScroll = null }) => {
  return (
    <div className="container-main-workspace position-relative">
      <div className="scrollable-workspace position-absolute" onScroll={onScroll}>
        {scrollableContent}
      </div>
    </div>
  );
}

export default Scrollable;