import React, { useState } from 'react';
import { SortableElement } from 'react-sortable-hoc';
import { formattedDate } from '../../utils/Parsers';

// Remove this and put the good ones, this must be just one reference outside any component
import img_checked from '../../images/newDesignImages/check-active.png';
import img_unchecked from '../../images/img_unchecked.png';
import warning_icon from '../../images/newDesignImages/warning-regular.png';
import repeated_img from '../../images/newDesignImages/repeated-img.png';
import edit_img from '../../images/newDesignImages/edit-white-v2.png';
import delete_img from '../../images/newDesignImages/delete-img.png';
import check_blue from '../../images/newDesignImages/check-blue.png';
import check_gray from '../../images/newDesignImages/check-gray.png';
import './SortableItem.scss';


const SortableItem = SortableElement((props) => {
  const { position, photo, selectPhotoHandler, selectPhotoForDeleteCallback, selected, collectionSelected, realIndex, sourceIds, configBarShownOption, handleDeletePage } = props;
  const lowResolution = photo.width < 640 && photo.height < 640;
  const isDuplicated = sourceIds.filter(item => (item.source_id === photo.photos[0].source_id && item.source === photo.photos[0].source)).length > 1 ? true : false;
  const [mouseOverItem, changeMouseOverItem] = useState(false)
  delete photo.photos[0]['title'];
  photo.photos[0].title = photo.title;
  delete photo.photos[0]['date'];
  photo.photos[0].date = photo.date;
  return (
    <div className={position % 2 === 0 ? "sortable-item_main d-flex flex-column justify-content-center align-items-end" : "sortable-item_main d-flex flex-column justify-content-center align-items-start"}>
      <div className="sortable-item_vertical-space d-flex justify-content-center align-items-center">
      {isDuplicated &&
        <div className="d-flex align-items-center">
          <img src={repeated_img} height="12" alt=""/>
          <p className="gotham-md-gray-11 ml-1">Repetida</p>
        </div>
      }
      </div>
      <div className="sortable-item_page d-flex flex-column justify-content-center align-items-center">
        <div className={position % 2 === 0 ? "sortable-item_page-date d-flex align-items-end justify-content-start" : "sortable-item_page-date d-flex align-items-end justify-content-end"}>
          {collectionSelected.print_dates && photo.source !== 'quotes' &&
            <p className="sortable-item_comment-date-text">{formattedDate(photo.date)}</p>
          }
        </div>
        {configBarShownOption !== 'reorder' &&
          <img src={delete_img} onClick={() => handleDeletePage(photo.id)} className="sortable-item_delete cursor-pointer" height="15%" alt="" />
        }
        <div 
          className="sortable-item_page-img cursor-pointer"
          onClick={() => selectPhotoHandler(photo.photos[0], photo.photos[0].source, photo.id)}
          onMouseEnter={() => changeMouseOverItem(true)}
          onMouseLeave={() => changeMouseOverItem(false)}
        >
          <img className="sortable-item_img" src={photo.photos[0].image_url} alt="" />
          <div className="sortable-item_warning-container d-flex flex-column justify-content-end align-items-center">
            {photo.is_truncated_text &&
              <div className="sortable-item_warning-item d-flex justify-content-center align-items-center"> 
                <img src={warning_icon} height="12" alt=""/>
                <p className="sortable-item_warning-text ml-1">Texto cortado</p>
              </div>
            }
            {lowResolution &&
              <div className="sortable-item_warning-item d-flex justify-content-center align-items-center"> 
                <img src={warning_icon} height="12" alt=""/>
                <p className="sortable-item_warning-text ml-1">Baja resolución</p>
              </div>
            }
          </div>
          {(mouseOverItem && photo.source !== 'quotes' && configBarShownOption === 'default') && 
            <div className="sortable-item_page-img-hover d-flex justify-content-center align-items-center">
              <img src={edit_img} height="30%" alt="" />
            </div>
          }
        </div>
        <div className="sortable-item_page-comment">
          <p className="sortable-item_comment-date-text">{photo.title}</p>
        </div>
      </div>
      <div className="sortable-item_vertical-space d-flex justify-content-center align-items-center">
        <p className="gotham-md-lightgray-12">{position}</p>
      </div>
    </div>
  )
});

export default SortableItem;