import React from 'react';
import trash_img from '../../images/newDesignImages/trash-img.png';
import giftcard_img from '../../images/newDesignImages/exchange-card-img.png';
import plus_img from '../../images/newDesignImages/plus-active.png';
import minus_img from '../../images/newDesignImages/minus-active.png';
import delete_img from '../../images/newDesignImages/delete-inactive.png';
import './CartAlbumItem.scss';

const CartAlbumItem = (props) => {
  const { product, deleteCallback, giftcardCategories } = props;
  const categoryName = product.product_type === 'giftcard' ? (giftcardCategories ? giftcardCategories.filter(item => item.id === product.gift_card_design_id)[0].category_name : null) : null;
  const frameBackground = (product.product_type !== 'item' && product.configurations.length > 1) && {
    backgroundImage: 'url(' + product.configurations[1].frame_url + ')',
    backgroundSize: 'cover'
  }
  const displayProductImage = () => {
    switch (product.product_type) {
      case 'giftcard':
        return (
          <div className="cart-item_inside ml-3 d-flex align-items-center">
            <img className="cart-item_giftcard-img" src={giftcard_img} width="100%" alt="cover" />
          </div>
        );
      case 'magnet':
        return (
          <div className="pl-3">
            <div className="cart-item_magnet d-flex justiify-content-start align-items-end">
              <img src={product.photos[0].image_url} width="82%" height="87%" alt=""/>
            </div>
          </div>
        );
      case 'picture':
        return (
          <div className="pl-3">
            {product.configurations.length > 1 ? 
              <div className="cart-item_inside d-flex align-items-center justify-content-center" style={frameBackground}>
                <img className="cart-item_canvas-fame-img" src={product.photos[0].image_url} height="60%" width="60%" alt=""/>
              </div> :
              <div className="cart-item_canvas-background d-flex align-items-start justify-content-start">
                <img src={product.photos[0].image_url} height="95%" width="94%" alt="cover" />
              </div>
            }
          </div>
        );
      case 'ornament':
        return (
          <div className="pl-3">
            <div className="cart-item_ornament d-flex justiify-content-start align-items-end">
              <img src={product.photos[0].image_url} width="85%" height="80%" alt=""/>
            </div>
          </div>
        );
      case 'item':
        return (
          <div className="pl-3">
            <div className="cart-item_accessories d-flex align-items-center justify-content-center">
              <img src={product.image} height="90%" width="90%" alt="" />
            </div>
          </div>
        );
      default:
        const mask_template = product.cover.mask_data.template;
        const user_image_size = mask_template == 'border' ? {height:"70%", width:"70%"} : {height:"70%", width:"100%"};
        const predesignCoverStyles = {
          backgroundImage: 'url('+product.cover.predesign_data.thumbnail_square_url+')',
          backgroundSize: 'cover',
        };
        if(product.cover.mask_data.id){
          return(
            <div className="pl-3">
              <div className="cart-item_album shadow d-flex">
                <div className="cart-item_album-left"></div>
                <div className="cart-item_album-right">
                  <img className={"cart-item_album-mask cart-item_album-mask-"+mask_template} src={product.cover.photos[0].image_url} height={user_image_size.height} width={user_image_size.width} alt=""/>
                  <img className="cart-item_album-mask" src={product.cover.mask_data.thumbnail_square_url} height="100%" width="100%" alt=""/>
                </div>
              </div>
            </div>
          );
        }else if(product.cover.predesign_data.id){
          return(
            <div className="pl-3">
              <div className="cart-item_album shadow d-flex" style={predesignCoverStyles}>
                <div className="cart-item_album-left"></div>
                <div className="cart-item_album-right d-flex align-items-center justify-content-center"></div>
              </div>
            </div>
          );
        }else{
          return (
            <div className="pl-3">
              <div className="cart-item_album shadow d-flex">
                <div className="cart-item_album-left"></div>
                <div className="cart-item_album-right d-flex align-items-center justify-content-center">
                  <img src={product.cover.photos[0].image_url} height="90%" width="90%" alt=""/>
                </div>
              </div>
            </div>
          );
        }
    }
  }
  const displayProductInfo = () => {
    switch (product.product_type) {
      case 'giftcard':
        return (
          <div className="card-item_info-container pl-4 d-flex flex-column justify-content-between">
            <p className="gotham-bold-black-md"><strong>Para:</strong>{product.recipient_email}</p>
            <p className="gotham-md-lightgray-15">{categoryName}</p>
            <p className="gotham-bold-blue-14">$ {product.price} MXN</p>
          </div>
        );
      case 'magnet':
        return (
          <div className="card-item_info-container pl-4 d-flex flex-column justify-content-between">
            <p className="gotham-bold-black-md">{product.name}</p>
            <p className="gotham-md-lightgray-15">15 Imanes</p>
            <p className="gotham-bold-blue-14">$ {product.price} MXN</p>
          </div>
        );
      case 'picture':
        return (
          <div className="card-item_info-container pl-4 d-flex flex-column justify-content-between">
            <p className="gotham-bold-black-md">{product.name}</p>
            <div className="d-flex flex-column align-items-start">
              <p className="gotham-md-lightgray-15">{product.configurations.length > 1 ? product.configurations[1].title : product.configurations.title}</p>
              <p className="gotham-md-lightgray-15">{product.photos.length === 1 ? `${product.photos.length} pixycuadro` : `${product.photos.length} pixycuadros`} </p>
            </div>
            <p className="gotham-bold-blue-14">$ {product.price} MXN</p>
          </div>
        );
      case 'ornament':
        return (
          <div className="card-item_info-container pl-4 d-flex flex-column justify-content-between">
            <p className="gotham-bold-black-md">{product.name}</p>
            <p className="gotham-md-lightgray-15">10 Esferas</p>
            <p className="gotham-bold-blue-14">$ {product.price} MXN</p>
          </div>
        );
      case 'item':
        return (
          <div className="card-item_info-container pl-4 d-flex flex-column justify-content-between">
            <p className="gotham-bold-black-md">{product.name}</p>
            <p className="gotham-md-lightgray-15">{product.short_description}</p>
            <p className="gotham-bold-blue-14">$ {product.price} MXN</p>
          </div>
        );
      default:
        return (
          <div className="card-item_info-container pl-4 d-flex flex-column justify-content-between">
            <p className="gotham-bold-black-md">{product.name}</p>
            <div className="d-flex flex-column align-items-start">
              <p className="gotham-md-lightgray">Pasta {product.configurations.configurations[0].description}</p>
              <p className="gotham-md-lightgray">{product.print_dates ? 'Fechas: Sí' : 'Fechas: No'}</p>
              <p className="gotham-md-lightgray">{product.configurations.description}</p>
            </div>
            <p className="gotham-bold-blue-14">$ {product.price} MXN</p>
          </div>
        );
    }
  }
  return (
    <div className="cart-item d-flex align-items-center justify-content-between">
      <div className="d-flex align-items-center">
        {displayProductImage()}
        {displayProductInfo()}
      </div>
      <div className="d-flex align-items-center justify-content-end">
        <div className="d-flex align-items-center">
          {product.quantity === 1 ? 
            <img id="delete-from-cart" className="cursor-pointer" height="20" src={delete_img} alt="" onClick={() => deleteCallback(product)} /> :
            <img className="cursor-pointer" src={minus_img} height="4" alt="" onClick={() => props.changeQuantityHandler(product, 'minus')} />
          }
          <p className="gotham-book-gray-15 mx-3">{product.quantity}</p>
          <img className="cursor-pointer" src={plus_img} height="10" alt="" onClick={() => props.changeQuantityHandler(product, 'plus')} />
        </div>
      </div>
    </div>
  );
}

export default CartAlbumItem;