import ApiService from './ApiService';

class ApiConfigurations {

    constructor() {
        this.bannersPath = 'configuration/banners/'
        this.albumConfigurationsPath = 'configuration/albums/'
    }

    getBanners = (payload) => {
        return ApiService.makeRequest(this.bannersPath, payload)
    }

    getAlbumConfigurations = (payload) => {
        return ApiService.makeRequest(this.albumConfigurationsPath, payload)
    }
}

export default new ApiConfigurations();