import React, { Component } from 'react';
import { changeRouteAction, changeProjectTypeAction } from '../../actions/NavigationActions';
import { toggleIsAddingPhotosAction } from '../../actions/CreateProjectActions';
import { compose } from 'redux';
import { connect } from 'react-redux';
import ApiCustomer from '../../utils/Api/ApiCustomer';
import TokenHelper from '../../utils/Firebase/TokenHelper';
import Footer from '../Footer';
import Scrollable from '../Scrollable';
import Loading from '../Loading';
import Modal from '../Modal';
import CreateProject from '../CreateProject';
import AlbumInfoTable from './AlbumInfoTable';
import AlbumPrices from '../AlbumPrices';
import album_info from '../../images/newDesignImages/album-info.png';
import './ProductInfo.scss';

class ProductInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      productData: null,
      isLoading: true,
      showCreateProjectConfirmation: false,
      showAlbumPrices: false,
      albumConfigurations: [],
      pricesBtnDisabled: true
    }
  }

  componentDidMount() {
    if (!this.props.location.state) {
      this.props.history.push('/');
    } else {
      this.setState({
        productData: this.props.location.state[0],
        isLoading: false
      });
      this.props.changeRouteAction('create')
      if (this.props.location.state[0].product_type === 'album') {
        this.getAlbumConfigurations();
      }
    }
  }

  handleStartProject = (productType) => {
    switch (productType) {
      case "album":
        this.props.changeProjectTypeAction('album');
        this.props.toggleIsAddingPhotosAction(false);
        this.props.history.push('/album-config');
        break;
      case "magnet":
        this.setState({ showCreateProjectConfirmation: true });
        this.props.changeProjectTypeAction('magnet');
        this.props.toggleIsAddingPhotosAction(false);
        break;
      case "canvas":
        this.setState({ showCreateProjectConfirmation: true });
        this.props.changeProjectTypeAction('picture');
        this.props.toggleIsAddingPhotosAction(false);
        break;
      case "picture":
        this.setState({ showCreateProjectConfirmation: true });
        this.props.changeProjectTypeAction('picture');
        this.props.toggleIsAddingPhotosAction(false);
        break;
      case "ornament":
        this.setState({ showCreateProjectConfirmation: true });
        this.props.changeProjectTypeAction('ornament');
        this.props.toggleIsAddingPhotosAction(false);
        break;
      case "giftcard":
        this.props.history.push('/giftcard');
        break;
      default:
        break;
    }
  }

  cancelCreateProjectConfirmation = () => {
    this.setState({ showCreateProjectConfirmation: false });
  }

  handleShowPrices = () => {
    const { showAlbumPrices } = this.state;
    if (showAlbumPrices) {
      this.setState({ showAlbumPrices: false });
    } else {
      this.setState({ showAlbumPrices: true });
    }
  }

  getAlbumConfigurations = async() => {
    const idToken = await TokenHelper.getUserToken();
    const response = await ApiCustomer.getAlbumConfigurations({token: idToken});
    this.setState({
      albumConfigurations: response.data.data,
      pricesBtnDisabled: false
    });
  }

  render() {
    const { productData, isLoading, showCreateProjectConfirmation, showAlbumPrices, albumConfigurations, pricesBtnDisabled } = this.state;
    return (
      isLoading ? <Loading /> :
        <React.Fragment>
          {
            showCreateProjectConfirmation &&
            <Modal>
              <CreateProject cancelHandler={this.cancelCreateProjectConfirmation} />
            </Modal>
          }
          {
            showAlbumPrices &&
            <Modal>
              <AlbumPrices 
                handleShowPrices={this.handleShowPrices}
                albumConfigurations={albumConfigurations}
              />
            </Modal>
          }
          <Scrollable scrollableContent={
            <div>
              <div className="row product-info_main">
                <div className="col-8 product-info_left d-flex justify-content-center align-items-center">
                  {productData.product_type === 'album' ? 
                    <img className="product-info_album-img" src={album_info} alt="product-image" /> :
                    <img className="product-info_img" src={productData.image_header} alt="product-image" />
                  }
                </div>
                <div className="col-4 product-info_right d-flex flex-column justify-content-center" >
                  <div className="product-info_right-inside">
                    <div className="product-info_title mb-3">
                      <p className="gotham-md-gray-25">{productData.title}</p>
                      <p className="gotham-md-gray-15">{productData.subtitle}</p>
                    </div>
                    <hr className="product-info_divition-line"></hr>
                    <div className="mt-3">
                      {productData.product_type === 'album' ? 
                        <AlbumInfoTable 
                          productType={productData.product_type}
                          pricesBtnDisabled={pricesBtnDisabled}
                          handleStartProject={this.handleStartProject}
                          handleShowPrices={this.handleShowPrices}
                        /> :
                        <p className="product-info_description">{productData.description}</p>
                      }
                    </div>
                    {productData.product_type !== 'album' &&
                      <button className="generic-button-blue my-4 d-flex justify-content-center align-items-center cursor-pointer" onClick={() => this.handleStartProject(productData.product_type)}>
                        <p className="gotham-md-white-15">Comenzar</p>
                      </button>
                    }
                  </div>
                </div>
              </div>
              <Footer history={this.props.history}/>
            </div>
          } />
        </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  projectType: state.navigationReducer.projectType
});


const mapDispatchToProps = {
  changeRouteAction: changeRouteAction,
  toggleIsAddingPhotosAction: toggleIsAddingPhotosAction,
  changeProjectTypeAction: changeProjectTypeAction
};



export default compose(connect(mapStateToProps, mapDispatchToProps))(ProductInfo);