import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { showErrorAction, hideErrorAction } from '../../actions/GlobalActions';
import { calculateOrderAction, placeOrderAction, resetCheckoutStateAction } from '../../actions/CheckoutActions';
import { toggleCreateAddressAction, toggleCreateCardTokenAction, loadCartAction } from '../../actions/CustomerActions';

import TokenHelper from '../../utils/Firebase/TokenHelper';
import ApiCustomer from '../../utils/Api/ApiCustomer';

import CheckoutProductItem from './CheckoutProductItem';
import Scrollable from '../../components/Scrollable';
import SelectShippingAddress from '../../components/SelectShippingAddress';
import SelectPaymentMethod from '../../components/SelectPaymentMethod';
import OrderSummary from '../../components/OrderSummary';
import ApplyVoucher from '../../components/ApplyVoucher';
import Loading from '../../components/Loading';
import OrderSuccess from '../../components/OrderSuccess';
import Modal from '../../components/Modal';
import AlertView from '../../components/AlertView';
import CreateAddress from '../../components/CreateAddress';
import CreateCardToken from '../../components/CreateCardToken';
import Footer from '../../components/Footer';

import products_img from '../../images/newDesignImages/products-inactive.png';
import add_new from '../../images/newDesignImages/add-new.png';
import details_img from '../../images/newDesignImages/my-wallet-img.png';
import my_address from '../../images/newDesignImages/my-addresses.png';
import cart_black from '../../images/newDesignImages/cart-black-img.png';
import back_arrow from '../../images/newDesignImages/back-arrow-blue.png';
import './Checkout.scss';

class Checkout extends Component {

  constructor(props) {
    super(props);
    this.state = {
      openAdress: false,
      openCard: false,
      products: this.props.products,
      totalOfProducts: 0,
      errorSource: null,
      giftcardCategories: null
    };
  }

  componentDidMount() {
    const { products } = this.props;
    if (products.length === 0) {
      this.props.history.replace('/cart');
    } else {
      this.calculateOrderFromServer('');
      this.countProducts();
      this.getGiftcardCategories();
    }
  }


  componentWillUnmount() {
    this.props.resetCheckoutStateAction();
  }

  getGiftcardCategories = () => {
    TokenHelper.getUserToken().then(idToken => {
      ApiCustomer.getAllGiftcards({ token: idToken }).then(response => {
        if (response.data.success) {
          this.setState({ giftcardCategories: response.data.data });
        }
      }).catch(error => this.props.showErrorAction('Hubo un error, por favor intenta más tarde.'))
    }).catch(error => this.props.showErrorAction('Hubo un error, por favor intenta más tarde.'))
  }

  calculateOrderFromServer = (voucherString) => {
    const { products } = this.state;
    let albums = products.filter(item => item.product_type === 'album');
    let giftcardsData = products.filter(item => item.product_type === 'giftcard').map(item => item.configurations[0]);
    let magnets = products.filter(item => item.product_type === 'magnet');
    let canvas = products.filter(item => item.product_type === 'picture');
    let ornaments = products.filter(item => item.product_type === 'ornament');
    let albumsData = [];
    for (let album of albums) {
      let albumData = {
        quantity: album.quantity,
        configurations: album.configurations,
        mask_cover_id: album.cover.mask_data.id,
        predesign_cover_id: album.cover.predesign_data.id
      };
      albumsData.push(albumData);
    }
    let magnetsData = [];
    for (let magnet of magnets) {
      let magnetData = {
        quantity: magnet.quantity,
        configurations: magnet.configurations
      }
      magnetsData.push(magnetData);
    }
    let canvasData = [];
    for (let canvasItem of canvas) {
      canvasItem.configurations[0].description = "Canvas";
      let canvasItemData = {
        quantity: canvasItem.quantity,
        total_pictures: canvasItem.photos.length,
        configurations: canvasItem.configurations
      }
      canvasData.push(canvasItemData);
    }
    let ornamentsData = [];
    for (let ornament of ornaments) {
      let ornamentData = {
        quantity: ornament.quantity,
        configurations: ornament.configurations
      }
      ornamentsData.push(ornamentData);
    }
    let orderData = {
      voucher: voucherString,
      items: {
        albums: albumsData,
        giftcards: [{ configurations: giftcardsData }],
        magnets: magnetsData,
        pictures: canvasData,
        ornaments: ornamentsData
      }
    }
    this.props.calculateOrderAction(orderData);
  }

  toggle() {
    this.setState({
      ...this.state,
      openAdress: !this.state.openAdress
    });
  }

  toggleCard() {
    this.setState({
      ...this.state,
      openCard: !this.state.openCard
    });
  }

  placeOrder = () => {
    const { orderSummary, paymentCard, shippingAddress } = this.props;
    const { products } = this.state;
    let albums = products.filter(item => item.product_type === 'album');
    let giftcards = products
      .filter(item => item.product_type === 'giftcard')
      .map(item => ({ id: item.id, configurations: item.configurations }));
    let magnets = products.filter(item => item.product_type === 'magnet');
    let canvas = products.filter(item => item.product_type === 'picture');
    let ornaments = products.filter(item => item.product_type === 'ornament');
    if (paymentCard === null && orderSummary.total > 0) {
      this.props.showErrorAction('Debes seleccionar un método de pago');
      return;
    }
    if (shippingAddress === null) {
      this.props.showErrorAction('Debes seleccionar una dirección de envío');
      return;
    }
    let shippingAddressToSend = { ...shippingAddress };
    delete shippingAddressToSend.id;
    let voucher = orderSummary.voucher_code;
    // paymentMethod is the object to send to the server
    // paymentCard is from user selected on checkout
    let paymentMethod = {}
    if (paymentCard != null) {
      if (paymentCard.id === 'oxxo') {
        paymentMethod['type'] = 'oxxo_cash';
      } else {
        paymentMethod['type'] = 'card';
        paymentMethod['token'] = paymentCard.id;
      }
    }
    let orderData = {
      shipping_address: shippingAddressToSend,
      device: 'web',
      voucher: voucher,
      payment_method: paymentMethod,
      albums: albums,
      giftcards: giftcards,
      magnets: magnets,
      pictures: canvas,
      ornaments: ornaments,
      from_cart: true
    }
    this.props.placeOrderAction(orderData, orderSummary);
  }

  applyVoucherCallbak = (voucher) => {
    this.calculateOrderFromServer(voucher);
  }

  createAddress = () => {
    this.props.toggleCreateAddressAction(true);
  }

  createAddressDismissHandler = () => {
    this.props.toggleCreateAddressAction(false);
  }

  createCardToken = () => {
    this.props.toggleCreateCardTokenAction(true);
  }

  createCardDismissHandler = () => {
    this.props.toggleCreateCardTokenAction(false);
  }

  //////////////

  changeQuantityHandler = (product, plusMinusOrError) => {
    const setProductQuantity = new Promise(resolve => resolve(this.setProductQuantity(product, plusMinusOrError)));
    setProductQuantity.then(() => {
      if (this.calculatePrice() < 10000) {
        const { orderSummary } = this.props;
        this.calculateOrderFromServer(orderSummary.voucher_code);
      } else {
        this.setState({
          errorSource: {
            message: 'productsQuantityExceded',
            product: product
          }
        });
        this.props.showErrorAction('Por el momento no es posible comprar más de $10,000 por orden.');
      }
    })
  }

  calculatePrice = () => {
    const { products } = this.state;
    const { orderSummary } = this.props
    const reducer = (accumulator, currentValue) => accumulator + currentValue;
    return products.map(item => item.price * item.quantity).reduce(reducer, orderSummary.shipping);
  }

  setProductQuantity = (product, plusMinusOrError) => {
    const { products } = this.state;
    for (let i = 0; i < products.length; i++) {
      if (products[i] === product) {
        if (plusMinusOrError === 'plus') {
          products[i].quantity = products[i].quantity + 1;
        } else if (plusMinusOrError === 'minus') {
          products[i].quantity = products[i].quantity > 1 ? products[i].quantity - 1 : 1;
        } else if (plusMinusOrError === 'quantityExceded') {
          products[i].quantity = products[i].quantity - 1;
        }
      }
    }
    this.setState({ products });
  }

  countProducts = () => {
    const { products } = this.state;
    let countProducts = 0;
    for (let product of products) {
      if (product.product_type === 'giftcard') {
        countProducts += 1;
      } else {
        countProducts += product.quantity;
      }
    }
    this.setState({ totalOfProducts: countProducts })
  }

  okErrorHandler = () => {
    const { errorSource } = this.state;
    if (errorSource) {
      if (errorSource.message === 'productsQuantityExceded') {
        this.changeQuantityHandler(errorSource.product, 'quantityExceded');
        this.setState({ errorSource: null });
      }
    }
    this.props.hideErrorAction();
  }

  render() {
    const { orderSummary, orderId, creatingAddress, creatingCardToken, auth } = this.props;
    const { products, totalOfProducts, giftcardCategories } = this.state;
    return (
      <React.Fragment>
        {this.props.isLoading && <Loading />}
        {this.props.showingError &&
          <Modal>
            <AlertView
              title={'¡Ups!'}
              message={this.props.errorMessage}
              okHandler={this.okErrorHandler}
              cancel={true} />
          </Modal>
        }
        {
          creatingAddress &&
          <Modal>
            <CreateAddress dismissHandler={this.createAddressDismissHandler}></CreateAddress>
          </Modal>
        }
        {
          creatingCardToken &&
          <Modal>
            <CreateCardToken dismissHandler={this.createCardDismissHandler}></CreateCardToken>
          </Modal>
        }
        <Scrollable
          scrollableContent={
            orderId === '' ? (
              <div className="checkout_main-container">

                <div className="container pb-5 pt-3">
                  <div className="row d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                      <img src={cart_black} alt="" />
                      <p className="futura-bold-25 ml-3">Checkout</p>
                    </div>
                    <div className="d-flex align-items-center cursor-pointer" onClick={() => this.props.history.push('/cart')}>
                      <img src={back_arrow} height="20" alt="" />
                      <p className="futura-md-gray-18 ml-2">Regresar a Mi Carrito</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6 checkout_container d-flex flex-column align-items-start">
                      <div id="albums-container" className="checkout_container-inside my-3">
                        <div className="d-flex align-items-center pl-5 pt-3">
                          <img src={products_img} alt="" />
                          <p className="futura-md-gray-18 ml-3">Productos Pixyalbum</p>
                        </div>
                        <p className="pl-5 py-2 futura-md-18">¿Es correcto tu pedido?</p>
                        {products.map((product, index) =>
                          <CheckoutProductItem
                            key={index}
                            product={product}
                            changeQuantityCallback={this.changeQuantityHandler}
                            giftcardCategories={giftcardCategories}
                          />
                        )}
                      </div>
                      <div id="apply-voucher-container" className="checkout_container-inside my-3">
                        <ApplyVoucher
                          applyVoucherCallbak={this.applyVoucherCallbak}
                          orderSummary={orderSummary}
                        />
                      </div>
                      <div id="total-container" className="checkout_container-inside my-3 d-flex flex-column align-items-center">
                        <div className="pl-4 py-4 checkout_title-container d-flex align-item-center">
                          <img src={details_img} height="20" alt="" />
                          <p className="ml-2 futura-md-18">Detalles de pago:</p>
                        </div>
                        <OrderSummary
                          orderSummary={orderSummary}
                          totalOfProducts={totalOfProducts}
                        />
                      </div>
                    </div>

                    <div className="col-6 checkout_container d-flex flex-column align-items-end">
                      <div id="shipping-address-container " className="checkout_container-inside my-3">
                        <div className="d-flex align-items-center pl-5 pt-3">
                          <img src={my_address} height="20" alt="" />
                          <p className="futura-md-gray-18 ml-3">Dirección de entrega</p>
                        </div>
                        <p className="pl-5 py-2 futura-md-18">¿A dónde enviamos tu pedido?</p>
                        <hr className="space-line mx-4"></hr>
                        <SelectShippingAddress isViewAccount={false} />
                        <div id="add-new-address" className="gray-btn m-3 d-flex justify-content-center align-items-center cursor-pointer" onClick={this.toggle.bind(this)}>
                          <img src={add_new} alt="" height="18" />
                          <p className="futura-md-15 ml-4">Añadir nueva dirección</p>
                        </div>
                        <div id="collapse-address" className={"collapse" + (this.state.openAdress ? '-in' : '')}>
                          <CreateAddress hideAlertViewAddAddress={this.toggle.bind(this)} />
                        </div>
                      </div>
                      <div id="payment-method-container" className="checkout_container-inside my-3">
                        <div className="d-flex align-items-center pl-5 pt-3">
                          <img src={details_img} height="20" alt="" />
                          <p className="futura-md-gray-18 ml-3">Método de pago</p>
                        </div>
                        <p className="pl-5 py-2 futura-md-18">¿Cómo te gustaría pagar por esta orden?</p>
                        <hr className="space-line mx-4"></hr>
                        <SelectPaymentMethod isViewAccount={false} />
                        <div id="add-new-payment-method" className="gray-btn m-3 d-flex justify-content-center align-items-center cursor-pointer" onClick={this.toggleCard.bind(this)}>
                          <img src={add_new} alt="" height="18" />
                          <p className="futura-md-15 ml-4">Añadir nueva tarjeta</p>
                        </div>
                        <div id="collapse-card" className={"collapse-card" + (this.state.openCard ? '-in' : '')}>
                          <CreateCardToken hideAlertViewAddCard={this.toggleCard.bind(this)} />
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
                <div className="checkout_buy-button d-flex justify-content-center align-items-start">
                  <button id="finish-order" className="futura-bold-white-18" onClick={this.placeOrder}>Comprar ahora por ${orderSummary.total}MXN</button>
                </div>
                <Footer history={this.props.history}/>
              </div>
            ) : (
                <div>
                  <OrderSuccess
                    orderId={orderId}
                    email={auth.email}
                    loadCartAction={this.props.loadCartAction}
                  />
                </div>
              )
          } />
      </React.Fragment>
    )
  }
}


const mapStateToProps = state => ({
  auth: state.firebase.auth,
  isLoading: state.globalReducer.isLoading,
  albums: state.checkoutReducer.albums,
  products: state.checkoutReducer.products,
  orderSummary: state.checkoutReducer.orderSummary,
  firebase: state.firebase,
  orderId: state.checkoutReducer.orderId,
  paymentCard: state.checkoutReducer.paymentCard,
  shippingAddress: state.checkoutReducer.shippingAddress,
  showingError: state.globalReducer.showingError,
  errorMessage: state.globalReducer.errorMessage,
  creatingAddress: state.collections.creatingAddress,
  creatingCardToken: state.collections.creatingCardToken
})

const mapDispatchToProps = {
  calculateOrderAction: calculateOrderAction,
  placeOrderAction: placeOrderAction,
  resetCheckoutStateAction: resetCheckoutStateAction,
  hideErrorAction: hideErrorAction,
  showErrorAction: showErrorAction,
  toggleCreateAddressAction: toggleCreateAddressAction,
  toggleCreateCardTokenAction: toggleCreateCardTokenAction,
  loadCartAction: loadCartAction
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(Checkout);