import React from 'react';
import {
  addPhotoToCreateAlbumAction,
  resetProjectReducerAction,
  createAlbumAction,
  resetPhotosSelectionAction,
  addPhotosToAlbumAction,
  createProductAction
} from '../../actions/CreateProjectActions';
import TokenHelper from '../../utils/Firebase/TokenHelper';
import ApiCustomer from '../../utils/Api/ApiCustomer';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { showErrorAction, hideErrorAction } from '../../actions/GlobalActions';
import Loading from '../Loading';
import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import Moment from 'moment';
import Modal from '../../components/Modal';
import AlertView from '../AlertView';
import DragAndDropItem from './DragAndDropItem';
import firebase from 'firebase/app';
import "firebase/app";
import "firebase/storage";
import { browserName } from 'react-device-detect';

// FilePond plugins
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImageResize from 'filepond-plugin-image-resize';
import FilePondPluginImageTransform from 'filepond-plugin-image-transform';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImageSizeMetadata from 'filepond-plugin-image-size-metadata';

import back_img from '../../images/newDesignImages/back-arrow-blue.png';
import drop_imgs from '../../images/newDesignImages/drop-images.png';

import './ImagesDragAndDrop.scss'
import DragAndDropLoading from './DragAndDropLoading';
import { selectOrnamentProject } from '../../actions/NavigationActions';

let validateRegisterPlugin = () => {
  if (browserName === 'Safari' || browserName === 'Firefox') {
    registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginFileValidateType, FilePondPluginImageResize, FilePondPluginImageTransform, FilePondPluginImageSizeMetadata);
  } else {
    registerPlugin(FilePondPluginFileValidateType, FilePondPluginImageResize, FilePondPluginImageTransform, FilePondPluginImageSizeMetadata);
  }
}

validateRegisterPlugin()

class ImagesDragAndDrop extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      canvasConfigurations: [],
      filesForS3: [],
      filesWithError: [],
      errorForUploadFiles: [],
      showCreatingProject: false,
      createButtonActive: true,
      ornamentConfigurations: null
    }
  }

  componentDidMount() {
    if (this.props.source === '') {
      this.props.history.push('/projects/');
    } else {
      this.getCanvasConfigurations();
      this.getOrnamentConfigurations();
    }
  }

  componentDidUpdate() {
    const { isAddingPhotos, collectionSelected, projectType } = this.props;
    if (isAddingPhotos && collectionSelected === null && projectType === 'album') {
      const collectionSelected = JSON.parse(localStorage.getItem('SELECTED_COLLECTION'));
      this.props.selectCollectionAction(collectionSelected);
    }
  }

  componentWillUnmount() {
    this.props.resetPhotosSelectionAction();
  } 

  getCanvasConfigurations = () => {
    TokenHelper.getUserToken().then(idToken => {
      ApiCustomer.getProductConfigurations({
        token: idToken,
        product_type: 'picture'
      }).then(response => {
        if (response.data.success) {
          this.setState({ canvasConfigurations: response.data.data })
        }
      }).catch(error => this.props.showErrorAction('Hubo un error, por favor intenta más tarde.'))
    }).catch(error => this.props.showErrorAction('Hubo un error, por favor intenta más tarde.'))
  }

  getOrnamentConfigurations = () => {
    TokenHelper.getUserToken().then(idToken => {
      ApiCustomer.getCustomerOrnamentConfigurations({
        token: idToken,
        product_type: 'ornament'
      }).then(response => {
        if (response.data.success) {
          this.setState({ ornamentConfigurations: response.data.data })
        }
      }).catch(error => this.props.showErrorAction('Hubo un error, por favor intenta más tarde.'))
    }).catch(error => this.props.showErrorAction('Hubo un error, por favor intenta más tarde.'))
  }

  validateResizeImage = (metadataSize) => {
    const { projectType } = this.props;
    const { width, height } = metadataSize;
    switch (projectType) {
      case 'magnet':
        return this.resizeImage(width, height, 1200);
      case 'picture':
        return this.resizeImage(width, height, 3000);
      case 'ornament':
        return this.resizeImage(width, height, 1200);
      default:
        return this.resizeImage(width, height, 2500);
    }
  }

  resizeImage = (width, height, maximumSize) => {
    let finalSize = {
      resizedWidth: width,
      resizedHeight: height
    }
    if (width >= height && width > maximumSize) {
      const newWidth = maximumSize;
      const newHeight = Math.trunc((height/width) * maximumSize);
      finalSize.resizedWidth = newWidth;
      finalSize.resizedHeight = newHeight;
      return finalSize;
    } else if (height > width && height > maximumSize) {
      const newWidth = Math.trunc((width/height) * maximumSize);
      const newHeight = maximumSize;
      finalSize.resizedWidth = newWidth;
      finalSize.resizedHeight = newHeight;
      return finalSize;
    } else {
      return finalSize;
    }
  }


  createButtonClicked = async() => {
    // e.preventDefault();
    if (this.props.photosForCreate.length === 0) {
      this.props.showErrorAction('Por favor selecciona al menos una foto para continuar');
    } else {
      const { isAddingPhotos, addPhotosToAlbumAction, collectionSelected, photosForCreate, projectType, selectedMagnetProject, selectedCanvasProject, selectedOrnamentProject, albumConfigurationIds, albumPrintDates } = this.props;
      if (isAddingPhotos && projectType === 'album') {
        let photosDataForAPI = [];
        let index = collectionSelected.pages.length;
        let sortedPhotosForCreate = this.props.photosForCreate.sort((a, b) => a.index - b.index);
        for (let photoElement of sortedPhotosForCreate) {
          delete photoElement.id;
          photoElement.index = index;
          photosDataForAPI.push(photoElement);
          index += 1;
        }
        let addPhotosToAlbum = new Promise((resolve, reject) => resolve(addPhotosToAlbumAction(collectionSelected, photosDataForAPI)));
        addPhotosToAlbum.then(() => this.props.history.push({
          pathname: '/collection/albums/detail',
          state: collectionSelected
        }));
      } else if (isAddingPhotos && projectType === 'magnet') {
        let photosDataForAPI = [];
        let index = selectedMagnetProject.photos.length;
        let sortedPhotosForCreate = this.props.photosForCreate.sort((a, b) => a.index - b.index);
        for (let photoElement of sortedPhotosForCreate) {
          delete photoElement.id;
          photoElement.index = index;
          photosDataForAPI.push(photoElement);
          index += 1;
        }
        this.addImagesToMagnet(selectedMagnetProject.id, photosDataForAPI);
      } else if (isAddingPhotos && projectType === 'picture') {
        let photosDataForAPI = [];
        let index = selectedCanvasProject.photos.length;
        let sortedPhotosForCreate = this.props.photosForCreate.sort((a, b) => a.index - b.index);
        for (let photoElement of sortedPhotosForCreate) {
          delete photoElement.id;
          photoElement.index = index;
          photosDataForAPI.push(photoElement);
          index += 1;
        }
        this.addImagesToCanvas(selectedCanvasProject.id, photosDataForAPI);
      } else if (isAddingPhotos && projectType === 'ornament') {
        let photosDataForAPI = [];
        let index = selectedOrnamentProject.photos.length;
        let sortedPhotosForCreate = this.props.photosForCreate.sort((a, b) => a.index - b.index);
        for (let photoElement of sortedPhotosForCreate) {
          delete photoElement.id;
          photoElement.index = index;
          photosDataForAPI.push(photoElement);
          index += 1;
        }
        this.addImagesToOrnament(selectedOrnamentProject.id, photosDataForAPI);
      } else {
        let photosDataForAPI = [];
        let index = 0;
        let sortedPhotosForCreate = this.props.photosForCreate.sort((a, b) => a.index - b.index);
        for (let photoElement of sortedPhotosForCreate) {
          delete photoElement.id;
          photoElement.index = index;
          photosDataForAPI.push(photoElement);
          index += 1;
        }
        switch (projectType) {
          case 'magnet':
            await this.props.createProductAction(photosDataForAPI, this.props.projectType);
            this.loadCustomizeProductPixel(this.props.photosForCreate.length);
            this.props.history.push({
              pathname: '/magnet/detail',
              state: {
                id: this.props.selectedMagnetProject.id,
                productType: 'magnet'
              }
            });
            break;
          case 'picture':
            const canvasConfigurations = this.canvasConfigValidaton();
            await this.props.createProductAction(photosDataForAPI, this.props.projectType, canvasConfigurations);
            this.loadCustomizeProductPixel(this.props.photosForCreate.length)
            this.props.history.push({
              pathname: '/canvas/detail',
              state: {
                id: this.props.selectedCanvasProject.id,
                productType: 'picture'
              }
            });
            break;
          case 'ornament':
            await this.props.createProductAction(photosDataForAPI, this.props.projectType);
            this.loadCustomizeProductPixel(this.props.photosForCreate.length);
            this.props.history.push({
              pathname: '/ornament/detail',
              state: {
                id: this.props.selectedOrnamentProject.id,
                productType: 'ornament'
              }
            });
            break;
          default:
            const albumConfigurations = {
              "albumPrintDates": albumPrintDates,
              "albumConfigurationIds": albumConfigurationIds
            }
            await this.props.createProductAction(photosDataForAPI, this.props.projectType, albumConfigurations);
            this.loadCustomizeProductPixel(this.props.photosForCreate.length);
            this.props.history.push({
              pathname: '/collection/albums/detail',
              state: this.props.collectionSelected
            });
            break;
        }
      }
    }
  }

  canvasConfigValidaton = () => {
    const { isAddingPhotos, photosForCreate, selectedCanvasProject } = this.props;
    if (isAddingPhotos) {
      if (selectedCanvasProject.photos.length + photosForCreate.length === 2) {
        return this.state.canvasConfigurations.find(element => (element.items_for_standard_cost === 2 && element.type === 'size'))
      } else if (selectedCanvasProject.photos.length + photosForCreate.length >= 3) {
        return this.state.canvasConfigurations.find(element => (element.items_for_standard_cost === 3 && element.type === 'size'))
      }
    } else {
      if (photosForCreate.length === 1) {
        return this.state.canvasConfigurations.find(element => (element.items_for_standard_cost === 1 && element.type === 'size'))
      } else if (photosForCreate.length === 2) {
        return this.state.canvasConfigurations.find(element => (element.items_for_standard_cost === 2 && element.type === 'size'))
      } else if (photosForCreate.length >= 3) {
        return this.state.canvasConfigurations.find(element => (element.items_for_standard_cost === 3 && element.type === 'size'))
      }
    }
  }

  addImagesToMagnet = (id, photos) => {
    TokenHelper.getUserToken().then(idToken => {
      ApiCustomer.addMagnetImage({
        token: idToken,
        magnet_project_id: id,
        photos: photos
      }).then(response => {
        if (response.data.success) {
          this.props.history.push({
            pathname: '/magnet/detail',
            state: {
              id: id,
              productType: 'magnet'
            }
          });
        }
      }).catch(error => this.props.showErrorAction(error.data.message))
    }).catch(error => this.props.showErrorAction('Hubo un error, por favor intenta más tarde.'))
  }

  addImagesToCanvas = (id, photos) => {
    TokenHelper.getUserToken().then(idToken => {
      ApiCustomer.addCanvasImage({
        token: idToken,
        picture_project_id: id,
        photos: photos
      }).then(response => {
        if (response.data.success) {
          this.updateCanvasConfigurations(id);
        }
      }).catch(error => this.props.showErrorAction(error.data.message))
    }).catch(error => this.props.showErrorAction('Hubo un error, por favor intenta más tarde.'))
  }

  addImagesToOrnament = (id, photos) => {
    TokenHelper.getUserToken().then(idToken => {
      ApiCustomer.addPhotosToCustomerOrnamentProject({
        token: idToken,
        ornament_project_id: id,
        photos: photos
      }).then(response => {
        if (response.data.success) {
          this.props.history.push({
            pathname: '/ornament/detail',
            state: {
              id: id,
              productType: 'ornament'
            }
          });
        }
      }).catch(error => this.props.showErrorAction(error.data.message))
    }).catch(error => this.props.showErrorAction('Hubo un error, por favor intenta más tarde.'))
  }

  updateCanvasConfigurations = (id) => {
    const { selectedCanvasProject } = this.props;
    const sizeConfigurations = this.canvasConfigValidaton();
    let configurations = [sizeConfigurations];
    if (selectedCanvasProject.configurations.length > 1) {
      configurations.push(selectedCanvasProject.configurations[1]);
    }
    TokenHelper.getUserToken().then(idToken => {
      ApiCustomer.updateCanvasSettings({
        token: idToken,
        picture_project_id: id,
        configurations: configurations
      }).then(response => {
        if (response.data.success) {
          this.props.history.push({
            pathname: '/canvas/detail',
            state: {
              id: id,
              productType: 'picture'
            }
          });
        }
      }).catch(error => this.props.showErrorAction('Hubo un error, por favor intenta más tarde.'))
    }).catch(error => this.props.showErrorAction('Hubo un error, por favor intenta más tarde.'))
  }


  sortPhotosByDate = (photos) => {
    // in dateDetail 0 index element corresponds to year, 1 index to month, up to index 5 that corresponds to seconds
    return photos.sort((a, b) => {
      if (a.dateDetail[0] === b.dateDetail[0]) {
        if (a.dateDetail[1] === b.dateDetail[1]) {
          if (a.dateDetail[2] === b.dateDetail[2]) {
            if (a.dateDetail[3] === b.dateDetail[3]) {
              if (a.dateDetail[4] === b.dateDetail[4]) {
                return a.dateDetail[5] - b.dateDetail[5];
              } else {
                return a.dateDetail[4] - b.dateDetail[4];
              }
            } else {
              return a.dateDetail[3] - b.dateDetail[3];
            }
          } else {
            return a.dateDetail[2] - b.dateDetail[2];
          }
        } else {
          return a.dateDetail[1] - b.dateDetail[1];
        }
      } else {
        return a.dateDetail[0] - b.dateDetail[0];
      }
    });
  }

  loadCustomizeProductPixel(numberPhotos) {
    const { auth } = this.props.firebase;
    window.fbq('track', 'CustomizeProduct', {
      user_id: auth.uid,
      number_photos: numberPhotos,
      origin: 'web'
    });
  }

  closeFullScreenView = () => {
    this.setState({
      showViewUploadPhotos: false
    });
    this.props.history.push('/projects');
  }

  okErrorHandler = () => {
    this.props.hideErrorAction();
  }

  photosCounterHandler = (type) => {
    const { isAddingPhotos, collectionSelected, photosForCreate, selectedMagnetProject, selectedCanvasProject, selectedOrnamentProject, albumSelectedPagesQuantity } = this.props;
    const ornamentConfigurations = this.state.ornamentConfigurations;
    switch (type) {
      case "magnet":
        if (isAddingPhotos) {
          let totalPhotos = (selectedMagnetProject.photos.length % 15) + photosForCreate.length;
          return totalPhotos < 15 ?
            <div className="col align-self-center">
              {totalPhotos}/15 <i className="fa fa-times-circle incomplete-color"></i>
            </div> :
            <div className="col align-self-center">
              Proyecto completo <i className="fa fa-check-circle complete-color"></i>
            </div>
        } else {
          return photosForCreate.length < 15 ?
            <div className="col align-self-center">
              {this.props.photosForCreate.length}/15 <i className="fa fa-times-circle incomplete-color"></i>
            </div> :
            <div className="col align-self-center">
              Proyecto completo <i className="fa fa-check-circle complete-color"></i>
            </div>
        }
      case "picture":
        if (isAddingPhotos) {
          let totalPhotos = selectedCanvasProject.photos.length + photosForCreate.length;
          return (
            <div className="col align-self-center">
              {totalPhotos} imágenes
            </div>
          );
        } else {
          return (
            <div className="col align-self-center">
              {this.props.photosForCreate.length} imágenes
            </div>
          );
        }
      case "ornament":
        var selectedNumOrnaments = 10;
        if (selectedOrnamentProject != null) {
          selectedOrnamentProject.configurations.configurations.forEach(config => {
            if (config.type === "size") {
              selectedNumOrnaments = parseInt(config.description);
            }
          });
        } else {
          ornamentConfigurations[0].configurations.forEach(config => {
            if (config.type === "size") {
              selectedNumOrnaments = parseInt(config.description);
            }
          });
        }
        if (isAddingPhotos) {
          let totalPhotos = (selectedOrnamentProject.photos.length % selectedNumOrnaments) + photosForCreate.length;
          return totalPhotos < selectedNumOrnaments ?
            <div className="col align-self-center">
              {totalPhotos}/selectedNumOrnaments <i className="fa fa-times-circle incomplete-color"></i>
            </div> :
            <div className="col align-self-center">
              Proyecto completo <i className="fa fa-check-circle complete-color"></i>
            </div>
        } else {
          return photosForCreate.length < selectedNumOrnaments ?
            <div className="col align-self-center">
              {this.props.photosForCreate.length}/{selectedNumOrnaments} <i className="fa fa-times-circle incomplete-color"></i>
            </div> :
            <div className="col align-self-center">
              Proyecto completo <i className="fa fa-check-circle complete-color"></i>
            </div>
        }
      default:
        if (isAddingPhotos) {
          let totalPhotos = (collectionSelected.pages.length % albumSelectedPagesQuantity) + photosForCreate.length;
          return totalPhotos < albumSelectedPagesQuantity ?
            <div className="col align-self-center">
              {totalPhotos}/{albumSelectedPagesQuantity} <i className="fa fa-times-circle incomplete-color"></i>
            </div> :
            <div className="col align-self-center">
              Album completo <i className="fa fa-check-circle complete-color"></i>
            </div>
        } else {
          return photosForCreate.length < albumSelectedPagesQuantity ?
            <div className="col align-self-center">
              {this.props.photosForCreate.length}/{albumSelectedPagesQuantity} <i className="fa fa-times-circle incomplete-color"></i>
            </div> :
            <div className="col align-self-center">
              Album completo <i className="fa fa-check-circle complete-color"></i>
            </div>
        }
    }

  }

  ////////////////////////

  fileDaragndropInputHandler = () => {
    this.fileDaragndropInput.click();
  }

  onFilesAdded = async(e, addedMethod) => {
    e.preventDefault();
    this.setState({
      createButtonActive: false,
    });
    const { filesForS3 } = this.state;
    const { projectType } = this.props;
    const maxFiles = this.maxFiles(projectType);
    let fileArr = addedMethod === 'addFiles' ? this.fileListToArray(e.target.files) : [...e.dataTransfer.files];
    if (filesForS3.length + fileArr.length > maxFiles) {
      this.props.showErrorAction(`Sólo se pueden agregar ${maxFiles} fotos a este proyecto.`)
      this.setState({
        createButtonActive: true,
      });
    } else {
      let index = filesForS3.length;
      for (let file of fileArr) {
        const resizedFile = await this.handleResizeImage(file)
        file.fileForS3 = resizedFile;
        file.progress = 0;
        file.imageToShow = URL.createObjectURL(resizedFile)
        file.index = index;
        index += 1;
      }
      this.setState({
        filesForS3: filesForS3.concat(fileArr), 
        createButtonActive: true
      });
    }
  }

  fileListToArray = list => {
    const result = [];
    for (let i = 0; i < list.length; i++) {
      result.push(list.item(i));
    }
    return result;
  };


  handleResizeImage = (file) => {
    const validateResizeImage = this.validateResizeImage;
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        let img = new Image();
        img.src = e.target.result;
        img.onload = () => {
          let canvas = document.createElement('canvas');
          let width = img.width;
          let height = img.height;
          const resizedObject = validateResizeImage({width, height});
          canvas.width = resizedObject.resizedWidth;
          canvas.height = resizedObject.resizedHeight;
          let ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0, resizedObject.resizedWidth, resizedObject.resizedHeight);
          canvas.toBlob(resizedFile => {
            resolve(resizedFile)
          }, file.type, 1);
        }
      }

    })
  }


  handleDragEnter = (event) => {
    event.preventDefault();
  }

  handleDragOver = (event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }

  createProduct = async() => {
    const { filesForS3, createButtonActive } = this.state;
    if (createButtonActive) {
      this.setState({
        showCreatingProject: true,
        createButtonActive: false,
        filesWithError: []
      });
      let promises = [];
      for (let i=0; i<filesForS3.length; i++) {
        let file = filesForS3[i];
        promises.push(this.uploadToS3(file, i))
      }
      Promise.all(promises)
    } else {
      return;
    }
  }

  uploadToS3 = async(file, index) => {
    
    const uploadProgress = this.uploadProgress;
    const handleUploadImagesV2 = this.handleUploadImagesV2;

    const { filesWithError, filesForS3, errorForUploadFiles } = this.state;
    const photosForCreate = this.props.photosForCreate
    const {showErrorAction} = this.props;
    let r = (Math.random() + 1).toString(36).substring(5);

    const path = this.props.firebase.auth.uid + '/image_' + Date.now() +  "_" + r + '.jpg';
    const storage = firebase.storage();
    const uploadRef = storage.ref().child(path);
    const uploadTask = uploadRef.put(file.fileForS3);
    
    uploadTask.on('state_changed', 
      (snapshot) => {
        let progressPercentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        uploadProgress(file, progressPercentage);
        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED:
            break;
          case firebase.storage.TaskState.RUNNING:
            break;
        }
      }, 
      (error) => {
        filesWithError.push(file)
        errorForUploadFiles.push(error)
        if (((photosForCreate.length + filesWithError.length) === filesForS3.length) && filesWithError.length > 0) {
          let filesNames = "";
          filesWithError.forEach(item => {
            filesNames += item.name + ", ";
          });
          showErrorAction(`Error al a cargar ${filesNames} - ${errorForUploadFiles[0]}`);
        }
      }, 
      () => {
        // Handle successful uploads on complete
        handleUploadImagesV2(file, path, index)
      }
    );
  }

  uploadProgress = async(file, progressPercentage) => {
    let filesArray = this.state.filesForS3;
    for (let item of filesArray) {
      if (item.index === file.index) {
        item.progress = progressPercentage;
        if (progressPercentage === 100) {
          item.uploaded = true;
        }
      }
    }
    this.setState({filesForS3: filesArray});
  }

  handleUploadImagesV2 = (file, serverId, index) => {
    const validateResizeImage = this.validateResizeImage;
    const baseUrl = window._env_.GCS_BUCKET_BASE_URL;
    const fileUrl = baseUrl + serverId;
    const dateStr = Moment(file.lastModifiedDate).format('DD-MM-YYYY');
    const dateDetail = Moment.utc(file.lastModifiedDate).format('YYYY-M-D-H-m-s').split('-').map(item => parseInt(item));
    let reader = new FileReader();
    const { filesWithError, filesForS3, errorForUploadFiles } = this.state;
    const {showErrorAction} = this.props;
    reader.readAsDataURL(file);
    let photoObject = {
      image_url: fileUrl,
      print_url: fileUrl,
      title: "",
      date: dateStr,
      editable: true,
      source: "computer",
      source_id: "FILES",
      is_truncated_text: false,
      dateDetail: dateDetail,
      index: index
    }
    let sizeObject = {}
    reader.onload = function (e) {
      //Initiate the JavaScript Image object.
      let image = new Image();
      //Set the Base64 string return from FileReader as source.
      image.src = e.target.result;
      //Validate the File Height and Width.
      image.onload = function () {
        let height = this.height;
        let width = this.width;
        sizeObject.height = height;
        sizeObject.width = width;
        const { resizedWidth, resizedHeight } = validateResizeImage(sizeObject);
        photoObject.width = resizedWidth;
        photoObject.height = resizedHeight;
      };
    };
    this.props.addPhotoToCreateAlbumAction(photoObject);
    if ((this.props.photosForCreate.length + filesWithError.length) === filesForS3.length) {
      if (filesWithError.length > 0) {
        let filesNames = "";
        filesWithError.forEach(item => {
          filesNames += item.name + ", ";
        });
        showErrorAction(`Error al a cargar ${filesNames} - ${errorForUploadFiles[0]}`);
      }
      this.createButtonClicked();
    }
  }

  calculateTotal = () => {
    const { filesForS3 } = this.state;
    const total = filesForS3.length * 100;
    let totalProgress = 0;
    filesForS3.forEach(item => {
      totalProgress += item.progress
    });
    return  Math.round(totalProgress / total * 100);
  }
  
  maxFiles = (type) => {
    const { isAddingPhotos, collectionSelected, selectedMagnetProject, selectedCanvasProject, selectedOrnamentProject, albumSelectedPagesQuantity, selectedAlbumConfiguration } = this.props;
    const ornamentConfigurations = this.state.ornamentConfigurations;
    switch (type) {
      case 'album':
        if (isAddingPhotos) {
          const selectedAlbumNumPages = parseInt(selectedAlbumConfiguration.configurations[1].description);
          return selectedAlbumNumPages - collectionSelected.pages.length;
        } else {
          return albumSelectedPagesQuantity;
        }
      case 'picture':
        if (isAddingPhotos) {
          return 100 - selectedCanvasProject.photos.length;
        } else {
          return 100;
        }
      case 'magnet':
        if (isAddingPhotos) {
          return 15 - selectedMagnetProject.photos.length;
        } else {
          return 15;
        }
      case 'ornament':
        var selectedNumOrnaments = 10;
        if (isAddingPhotos) {
          if (selectedOrnamentProject != null) {
            selectedOrnamentProject.configurations.configurations.forEach(config => {
              if (config.type === "size") {
                selectedNumOrnaments = parseInt(config.description);
              }
            });
          } else {
            ornamentConfigurations[0].configurations.forEach(config => {
              if (config.type === "size") {
                selectedNumOrnaments = parseInt(config.description);
              }
            });
          }
          return selectedNumOrnaments - selectedOrnamentProject.photos.length;
        } else {
          return selectedNumOrnaments;
        }
      default:
        break;
    }
  }

  handleRemovePhotoForUpload = (selectedIndex) => {
    const { filesForS3 } = this.state;
    const newFilesForS3 = filesForS3.filter(item => item.index !== selectedIndex)
    let index = 0;
    for (let photo of newFilesForS3) {
      delete photo['index']
      photo.index = index;
      index += 1;
    }
    this.setState({filesForS3: newFilesForS3})
  }

  render() {
    const { isAddingPhotos, projectType, selectedMagnetProject, selectedCanvasProject, selectedOrnamentProject } = this.props;
    const { showCreatingProject, filesForS3, createButtonActive } = this.state;
    return (
      this.props.isLoading ? <Loading /> :
        <div>
          {this.props.showingError &&
            <Modal>
              <AlertView
                title={'¡Ups!'}
                message={this.props.errorMessage}
                okHandler={this.okErrorHandler}
                cancel={true} />
            </Modal>
          }
          <div className="dragndrop_header d-flex justify-content-between align-items-center">
            <img className="cursor-pointer ml-2" src={back_img} height="20" alt="" onClick={() => this.props.history.push('/projects')}/>
            {createButtonActive ? 
              <button className="generic-button-blue" onClick={this.createProduct}>
                Cargar fotos
              </button> : 
              <button className="generic-button-gray" disabled>
                Cargar fotos
              </button>
            }
          </div>

          <div className="dragndrop_main pb-5 d-flex flex-column align-items-center">
            <div className="dragndrop_top-container py-5 d-flex justify-content-center">
              {showCreatingProject ? 
                <div className="py-5 d-flex flex-column justify-content-center align-items-center" style={{height: '350px'}}>
                  <DragAndDropLoading 
                    progress={this.calculateTotal()}
                    size={100}
                    strokeWidth={5}
                    circleOneStroke={'#BDBDBD'}
                    circleTwoStroke={'#51B7F3'}
                  /> 
                  <p className="gotham-md-gray-15" style={{textAlign: 'center'}}>
                    Aún no están listas tus fotos, <br></br> espera un momento ya casi terminamos. <br></br> Favor de no abandonar esta pantalla.
                  </p>
                </div> :
                <div 
                  className="dragndrop_drop-container d-flex flex-column justify-content-center align-items-center"
                  onDrop={event => this.onFilesAdded(event, 'dropFiles')}
                  onDragOver={event => this.handleDragOver(event)}
                  onDragEnter={event => this.handleDragEnter(event)}
                >
                  <img src={drop_imgs} height="40" alt="" />
                  <p className="gotham-md-lightergray-20 my-4" style={{textAlign: 'center'}}>Arrastra y suelta las <br></br> imágenes aquí</p>
                  <input 
                    style={{display: 'none'}}
                    ref={(fileDaragndropInput) => this.fileDaragndropInput = fileDaragndropInput}
                    type="file"  
                    multiple
                    onChange={(event) => this.onFilesAdded(event, 'addFiles')} 
                    accept="image/png, image/jpeg, image/jpeg"
                  />
                  <button className="generic-button-white-2" onClick={this.fileDaragndropInputHandler}>
                    Selecciona los archivos
                  </button>
                </div>
              }
            </div>
            <div className="dragndrop_file-container py-4 d-flex flex-column align-items-center">
              {filesForS3.map((item, i) => (
                <DragAndDropItem
                  key={i}
                  item={item}
                  handleRemovePhotoForUpload={this.handleRemovePhotoForUpload}
                />
              ))}
            </div>
          </div>
        </div>
    );
  }
};

const mapStateToProps = state => ({
  isLoading: state.globalReducer.isLoading,
  source: state.createProject.source,
  photosForCreate: state.createProject.photosForCreate,
  collectionSelected: state.collections.collectionSelected,
  showingError: state.globalReducer.showingError,
  errorMessage: state.globalReducer.errorMessage,
  isAddingPhotos: state.createProject.isAddingPhotos,
  backToAlbumDetailAfterAddPhotos: state.createProject.backToAlbumDetailAfterAddPhotos,
  firebase: state.firebase,
  auth: state.firebase.auth,
  projectType: state.navigationReducer.projectType,
  selectedMagnetProject: state.navigationReducer.selectedMagnetProject,
  selectedCanvasProject: state.navigationReducer.selectedCanvasProject,
  selectedOrnamentProject: state.navigationReducer.selectedOrnamentProject,
  albumConfigurationIds: state.createProject.albumConfigurationIds,
  albumSelectedPagesQuantity: state.createProject.albumSelectedPagesQuantity,
  albumPrintDates: state.createProject.albumPrintDates,
  selectedAlbumConfiguration: state.collections.selectedAlbumConfiguration
});

const mapDispatchToProps = {
  addPhotoToCreateAlbumAction: addPhotoToCreateAlbumAction,
  resetPhotosSelectionAction: resetPhotosSelectionAction,
  resetProjectReducerAction: resetProjectReducerAction,
  createAlbumAction: createAlbumAction,
  hideErrorAction: hideErrorAction,
  showErrorAction: showErrorAction,
  addPhotosToAlbumAction: addPhotosToAlbumAction,
  createProductAction: createProductAction
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(ImagesDragAndDrop);