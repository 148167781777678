import React from 'react';

import img_checked from '../../images/img_checked.png';
import img_unchecked from '../../images/img_unchecked.png';

import '../../components/CustomerCardItem/CustomerCardItem.scss'
import './CustomerAddressItem.scss';
import trash_img from '../../images/newDesignImages/trash-img.png'
import elem_unchecked from '../../images/newDesignImages/elem-unchecked.png';
import elem_checked from '../../images/newDesignImages/elem-checked.png';
import heart_full from '../../images/newDesignImages/heart-full.png';
import heart_empty from '../../images/newDesignImages/heart-empty.png';


const CustomerAddressItem = (props) => {
  const { address, isViewMyAccount, selected, handleSelectAddress, defaultAddress, defaultAddressId } = props;
  return (
    isViewMyAccount ? (
      <div className="myaccount_address-item py-3 d-flex justify-content-between align-items-center">
        <div>
          <p className="futura-md-blue-15">{address.name}</p>
          <p className="futura-bkbt-15">{address.street} {address.number}, {address.neighborhood}, {address.state}, México. {address.zip_code}</p>
          <p className="futura-bkbt-gray-15">{address.references}</p>
        </div>
        <div className="d-flex align-items-center">
          <img className="cursor-pointer" src={trash_img} alt="" onClick={() => props.handleDeleteAddress(address.id)} />
        </div>
      </div>
    ) : (
      <div className="w-100 py-3 px-4 d-flex justify-content-between cursor-pointer" onClick={() => handleSelectAddress(address)}>
        <div>
          <p className={defaultAddress.id === address.id ? "gotham-bold-blue-15" : "gotham-bold-darkgray-15"}>{address.street} {address.number}</p>
          <p className={defaultAddress.id === address.id ? "gotham-md-blue-15" : "gotham-md-lightgray-15"}>{address.name}</p>
        </div>
        <img src={defaultAddressId === address.id ? heart_full : heart_empty} height="15" alt="" />
      </div>
    )
  );
}

export default CustomerAddressItem;