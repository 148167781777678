import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { loadUserCardsAction, reloadUserCardsAction, deleteCustomerCardAction } from '../../actions/CustomerActions';
import { selectPaymentMethodAction } from '../../actions/CheckoutActions';

import CustomerCardItem from '../../components/CustomerCardItem';
import Loading from '../Loading';
import add_img from '../../images/newDesignImages/add-icon-gray.png';


class SelectPaymentMethod extends React.Component {

  constructor(props) {
    super(props);
    const oxxoPayOption = {
      id: 'oxxo',
      brand: 'oxxo',
      name: 'Oxxo pay',
      last4: '*oxxo cobrará una comisión de $12.00 MXN'
    }
    this.state = {
      oxxoPayOption: oxxoPayOption,
      selectedPaymentOption: null
    }
    this.handleDeleteCard = this.handleDeleteCard.bind(this);
  }

  componentDidMount() {
    this.props.reloadUserCardsAction(false);
    this.props.loadUserCardsAction();
  }

  componentDidUpdate() {
    if (this.props.reloadUserCards) {
      this.setState({ selectedPaymentOption: null });
      this.props.reloadUserCardsAction(false);
      this.props.loadUserCardsAction();
    }
  }

  selectCard = card => {
    this.setState({ selectedPaymentOption: card });
    this.props.selectPaymentMethodAction(card);
  }

  handleDeleteCard(cardToken) {
    this.props.deleteCustomerCardAction(cardToken);
  }

  render() {
    const { loadingCards, userCards, isViewMyAccount, handleSelectCard } = this.props;
    const { oxxoPayOption, selectedPaymentOption } = this.state;
    const oxxoSelected = selectedPaymentOption && selectedPaymentOption.id === oxxoPayOption.id;
    return (
      isViewMyAccount ? 
        <div>
          {
            loadingCards ?
              <div className="d-flex my-5 justify-content-center align-items-center">
                <Loading title=" " />
              </div>
              :
              userCards.map((card) => {
                let selected = false;
                if (selectedPaymentOption && selectedPaymentOption.id === card.id) {
                  selected = true;
                }
                return React.createElement(
                  CustomerCardItem, {
                  key: card.id,
                  card: card,
                  selected: selected,
                  selectCardCallback: this.selectCard,
                  isViewMyAccount: isViewMyAccount,
                  handleDeleteCard: this.handleDeleteCard
                });
              }
              )
          }
          {
            isViewMyAccount ? null :
              React.createElement(CustomerCardItem, { key: 'oxxo', card: oxxoPayOption, selected: oxxoSelected, selectCardCallback: this.selectCard })
          }
        </div> :
        <div className="d-flex flex-column align-items-center">
          {
            userCards.map((card, i) => (
              <CustomerCardItem 
                key={i}
                card={card}
                handleSelectCard={handleSelectCard}
                defaultPayment={this.props.defaultPayment}
              />
            ))
          }
          {
            React.createElement(
              CustomerCardItem, { 
                key: 'oxxo', 
                card: oxxoPayOption, 
                selected: oxxoSelected, 
                selectCardCallback: this.selectCard, 
                handleSelectCard: handleSelectCard,
                defaultPayment: this.props.defaultPayment
              }
            )
          }
          <button className="select-address_add d-flex justify-content-center align-items-center" onClick={this.props.handleShowCreateCard}>
            <img src={add_img} height="30" alt=""/>
            <p className="gotham-bold-gray-18 ml-3">Ingresa una tarjeta</p>
          </button>
        </div>
    )
  }
}

const mapStateToProps = state => ({
  loadingCards: state.collections.loadingCards,
  userCards: state.collections.userCards,
  reloadUserCards: state.collections.reloadUserCards
})

const mapDispatchToProps = {
  loadUserCardsAction: loadUserCardsAction,
  selectPaymentMethodAction: selectPaymentMethodAction,
  reloadUserCardsAction: reloadUserCardsAction,
  deleteCustomerCardAction: deleteCustomerCardAction
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(SelectPaymentMethod);