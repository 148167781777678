import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { setSocialTokenAction, selectSourceAction } from '../../actions/CreateProjectActions';
import { toggleIsAddingPhotosAction, loginSocialNetworkAction } from '../../actions/CreateProjectActions';
import { changeProjectTypeAction, selectMagnetProject, selectCanvasProject, selectOrnamentProject } from '../../actions/NavigationActions';
import { selectCollectionAction } from '../../actions/CustomerActions';
import ApiCustomer from '../../utils/Api/ApiCustomer';
import TokenHelper from '../../utils/Firebase/TokenHelper';
import Loading from '../Loading';

class InstagramHandler extends React.Component {

  componentDidMount() {
    const cutUrlIndex = '?code='.length;
    const authCode = this.props.location.search.substring(cutUrlIndex);
    const isAddingPhotos = JSON.parse(localStorage.getItem('ADDING_PHOTOS'));
    const projectType = JSON.parse(localStorage.getItem('PROJECT_TYPE'))
    const selectSource = new Promise((resolve, reject) => {
      resolve(this.props.selectSourceAction('instagram'));
    });
    selectSource
      .then(() => this.props.toggleIsAddingPhotosAction(isAddingPhotos))
      .then(() => this.props.changeProjectTypeAction(projectType))
      .then(() => this.props.setSocialTokenAction(''))
      .then(() => this.props.loginSocialNetworkAction(authCode, 'instagram'))
      .then(() => {
        const collectionSelected = JSON.parse(localStorage.getItem('SELECTED_COLLECTION'));
        this.props.selectCollectionAction(collectionSelected);
        const selectedMagnetProject = JSON.parse(localStorage.getItem('SELECTED_MAGNET'));
        this.props.selectMagnetProject(selectedMagnetProject);
        const slectedCanvasProject = JSON.parse(localStorage.getItem('SELECTED_CANVAS'));
        this.props.selectCanvasProject(slectedCanvasProject);
        const selectedOrnamentProject = JSON.parse(localStorage.getItem('SELECTED_ORNAMENT'));
        this.props.selectOrnamentProject(selectedOrnamentProject);
      })
      .then(() => this.props.history.replace('/source/select/photos'))
  }

  loginInstagram = (socialToken, source) => {
    TokenHelper.getUserToken().then(idToken => {
      ApiCustomer.loginSocialNetwork({
        token: idToken,
        source: source,
        auth_code: socialToken
      }).then(response => {
        // console.log('res', response.data)
      }).catch(error => {
        // console.log('err', error.data)
      })
    }).catch(error => {
      // console.log('err', error)
    })
  }

  render() {
    return (
      <Loading></Loading>
    );
  }
};

const mapStateToProps = state => ({
  source: state.createProject.source,
  isLoggedToSocialNetwork: state.createProject.isLoggedToSocialNetwork,
  projectType: state.navigationReducer.projectType,
  isAddingPhotos: state.createProject.isAddingPhotos
})

const mapDispatchToProps = {
  setSocialTokenAction: setSocialTokenAction,
  selectSourceAction: selectSourceAction,
  toggleIsAddingPhotosAction: toggleIsAddingPhotosAction,
  loginSocialNetworkAction: loginSocialNetworkAction,
  selectCollectionAction: selectCollectionAction,
  changeProjectTypeAction: changeProjectTypeAction,
  selectMagnetProject: selectMagnetProject,
  selectCanvasProject: selectCanvasProject,
  selectOrnamentProject: selectOrnamentProject
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(InstagramHandler);