import React, { Component } from 'react';
import { firebaseConnect } from 'react-redux-firebase';
import { Link } from 'react-router-dom';
import ApiCustomer from '../../utils/Api/ApiCustomer';
import TokenHelper from '../../utils/Firebase/TokenHelper';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Modal from '../../components/Modal';
import AlertView from '../../components/AlertView';
import PasswordForgetComponent from '../../components/PasswordForgetComponent';

import { showLoadingAction, hideLoadingAction } from '../../actions/GlobalActions';
import fb_logo from '../../images/newDesignImages/fb-logo.png';
import logo_pixyalbum from '../../images/newDesignImages/pixyalbum-logo.png';
import collect_img from '../../images/newDesignImages/collect-img.png'


import './SignIn.scss';

import img_logo_pixyalbum from '../../images/logo_pixyalbum.png';

class SignIn extends Component {

  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      errorMessage: '',
      showError: false,
      showUpdatePassword: false
    }
  }

  componentDidMount() {
    this.initializeFacebookLogin();
  }

  initializeFacebookLogin = () => {
    this.FB = window.FB;
  }

  loginWithEmailAnsPassword = e => {
    e.preventDefault();
    this.setState({ errorMessage: '' });
    const { firebase, showLoadingAction, hideLoadingAction } = this.props;
    const { email, password } = this.state;
    showLoadingAction();
    firebase.login({
      email,
      password
    })
      .then(() => {
        // console.log('Mail Login success');
        hideLoadingAction();
      })
      .catch(error => {
        if (error.code === 'auth/wrong-password') {
          this.setState({ 
            showError: true,
            errorMessage: 'Contraseña incorrecta.'
          });
        } else if (error.code === 'auth/user-not-found') {
          this.setState({
            showError: true,
            errorMessage: 'Usuario no encontrado.'
          })
        } else {
          this.setState({ 
            showError: true,
            errorMessage: error.message 
          });
        }
        hideLoadingAction();
      });
  }

  loginWithFacebook = e => {
    e.preventDefault();
    this.FB.login(this.facebookLoginHandler, { scope: 'public_profile' })
  }

  facebookLoginHandler = response => {
    const { firebase } = this.props;
    if (response.status === 'connected') {
      var provider = firebase.auth.FacebookAuthProvider.credential(response.authResponse.accessToken);
      firebase.auth().signInWithCredential(provider)
        .then(result => {
          let user = result.user
          let email = ''
          let name = ''
          if (user.email) {
            email = user.email;
          }
          if (user.displayName) {
            name = user.displayName;
          }

          const userReference = this.props.firebase.ref('users/' + user.uid);
          userReference.once('value', (snapshot) => {
            if (!snapshot.exists()) {
              this.logRegisterEvent(user.uid, email, name);
            }
          })
          this.updateCustomer(email, name);
        })
        .catch(e => {
          // console.log('Facebook login error');
        });
    } else {
      // TODO: Show error
    }
  }

  updateCustomer = (email, name) => {
    // TODO: Change this method to actions
    TokenHelper.getUserToken()
      .then(userToken => {
        // Send update to server
        const params = {
          token: userToken,
          email: email,
          name: name
        }
        ApiCustomer.updateCustomer(params)
          .then(response => {
            if (response.data.success) {
              // console.log('Go to collections...');
            } else {
              // console.log('Show error');
            }
          });
      })
      .catch(e => {
        // console.log('Get token error');
      });
  }

  logRegisterEvent = (userId, email, name) => {
    var params = {};
    params['userId'] = userId;
    params['source'] = 'Facebook';
    params['email'] = email;
    params['name'] = name;
    this.FB.AppEvents.logEvent('Register', null, params);
  }

  readData = e => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  handleShowUpdatePassword = () => {
    const { showUpdatePassword } = this.state;
    if (showUpdatePassword) {
      this.setState({showUpdatePassword: false})
    } else {
      this.setState({showUpdatePassword: true})
    }
  }

  okErrorHandler = () => {
    this.setState({
      showError: false,
      errorMessage: ''
    })
  }

  render() {
    const { errorMessage, showUpdatePassword, showError } = this.state;
    return (
      <div className="signpu_main d-flex">
        {showError &&
          <Modal>
            <AlertView
              title={'¡Ups!'}
              message={errorMessage}
              okHandler={this.okErrorHandler} />
          </Modal>
        }
        {showUpdatePassword &&
          <Modal>
            <PasswordForgetComponent handleShowUpdatePassword={this.handleShowUpdatePassword} />
          </Modal>
        }
        <div className="login_left"></div>
        <div className="login_right d-flex flex-column align-items-center justify-content-center">
          <div className="signup_logo-container d-flex flex-column justify-content-center align-items-center">
            <img height="60" src={logo_pixyalbum} alt="" />
            <img className="mt-2 ml-4" height="50" src={collect_img} alt="" />
          </div>
          <form onSubmit={this.loginWithEmailAnsPassword} className="signup_form d-flex flex-column align-items-center justify-content-between">
            <div className="w-100 d-flex flex-column align-items-center">
              <p className="gotham-bold-green-16">Inicia sesión.</p>
              <input
                className="signup_input"
                type="email"
                name="email"
                placeholder="E-mail"
                autoComplete="email"
                required
                value={this.state.email}
                onChange={this.readData} 
              />
              <input
                className="signup_input"
                type="password"
                name="password"
                placeholder="Contraseña"
                required
                value={this.state.password}
                onChange={this.readData} 
              />

              <div className="d-flex">
                <p className="gotham-book-gray-15">¿Olvidaste tu contraseña?</p>
                <p className="signin_password-forget ml-2 cursor-pointer" onClick={this.handleShowUpdatePassword}>Entra aquí.</p>
              </div>
            </div>

        
            
            <div className="signup_alt-register d-flex flex-column align-items-center">
              <button className="signup_register-btn" type="submit">
                Comenzar
              </button>
              <div className="mt-2">
                <p className="gotham-book-gray-15">ó</p>
              </div>
              <button className="signup_fb-register mb-3 mt-3 d-flex justify-content-center align-items-center" onClick={this.loginWithFacebook}>
                <img src={fb_logo} height="25" alt="" />
                <p className="gotham-book-18 ml-2">Iniciar sesión con Facebook</p>
              </button>
            </div>
          </form>
        </div>
      </div>


      // <div className="login-main-wrapper">
      //   <div className="container d-flex min-vh-100">
      //     <div className="row align-items-center mx-auto">
      //       <div className="col-12">
      //         <div className="card login-card">
      //           <div className="card-body px-5 py-4">
      //             <div className="d-flex justify-content-center">
      //               <img src={img_logo_pixyalbum} alt="img" />
      //             </div><br />
      //             <h2 className="card-title text-center">INICIA SESION</h2>
      //             {
      //               (errorMessage !== '') && <div className="text-danger">{errorMessage}</div>
      //             }
      //             <form className="form" onSubmit={this.loginWithEmailAnsPassword}>
      //               <div className="form-group">
      //                 <input
      //                   type="email"
      //                   name="email"
      //                   required
      //                   placeholder="Correo"
      //                   value={this.state.email}
      //                   onChange={this.readData}
      //                   className="form-control rounded-input"
      //                 />
      //               </div>
      //               <div className="form-group mb-0">
      //                 <input
      //                   type="password"
      //                   name="password"
      //                   required
      //                   placeholder="Contraseña"
      //                   value={this.state.password}
      //                   onChange={this.readData}
      //                   className="form-control rounded-input"
      //                 />
      //               </div>
      //               <div className="form-group mt-0">
      //                 <div className="form-row m3">
      //                   <div className="col m4">
      //                     <div className="float-right">
      //                       <Link id="password-forget" to='/password/reset'><small>¿Olvidaste tu contraseña?</small></Link>
      //                     </div>
      //                   </div>
      //                 </div>
      //               </div>
      //               <div className="form-group">
      //                 <input
      //                   id="sign-in"
      //                   className="btn-pixyalbum-primary btn-block rounded-button"
      //                   type="submit"
      //                   value="Ingresar"
      //                 />
      //               </div>
      //             </form>
      //             <hr></hr>
      //             <div className="row mt-4 mb-0">
      //               <div className="col">
      //                 <button id="facebook-sign-in" className="btn-facebook rounded-button" onClick={this.loginWithFacebook}>
      //                   Iniciar sesión con facebook
      //                 </button>
      //               </div>
      //             </div>
      //             <div className="text-center">
      //               <small>¿Aún no estás registrado?&nbsp;<Link id="go-signup" to='/signup'>Regístrate</Link></small>
      //             </div>
      //           </div>
      //         </div>
      //       </div>
      //     </div>
      //   </div>
      // </div>
    );
  }
}

const mapStateToProps = state => ({

})

const mapDispatchToProps = {
  showLoadingAction: showLoadingAction,
  hideLoadingAction: hideLoadingAction
}

export default compose(
  firebaseConnect(),
  connect(mapStateToProps, mapDispatchToProps)
)(SignIn);