import React from 'react';

//Redux
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firebaseConnect } from 'react-redux-firebase';
import { addAlbumToCartAction } from '../../actions/CustomerActions';
import { toggleGoToCartAlertAction } from '../../actions/CheckoutActions';
import { loadAlbumConfigurationsAction } from '../../actions/GlobalActions';
import { changeRouteAction } from '../../actions/NavigationActions';

import ApiCustomer from '../../utils/Api/ApiCustomer';
import TokenHelper from '../../utils/Firebase/TokenHelper';
import { createCollectionData } from '../../utils/CollectionsHelper/CreateCollectionData';

// Components
import Scrollable from '../../components/Scrollable';
import CardAlbum from '../../components/App/CardAlbum';
import Loading from '../../components/Loading';
import Modal from '../../components/Modal';
import AlertView from '../../components/AlertView';
import AlbumConfigurations from '../../components/AlbumConfigurations';
import EditProductName from '../../components/EditProductName';
import Footer from '../../components/Footer';
import SuccessPopup from '../../components/SuccessPopup';
import FailurePopup from '../../components/FailurePopup';

import albums_icon from '../../images/newDesignImages/albums-icon.png';
import edit_img from '../../images/newDesignImages/edit-blue-img.png';
import down_arrow from '../../images/newDesignImages/down-white-arrow.png';
import up_arrow from '../../images/newDesignImages/up-white-arrow.png';


import './Albums.scss';

class Albums extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      collectionSelected: [],
      collectionBooks: [],
      showEditNameConfirmation: false,
      showEditSettings: false,
      collectionToEditName: null,
      numberCharacters: 0,
      isLoading: true,
      showError: false,
      errorMessage: '',
      showConfigurations: false,
      mouseOverEditbtn: false,
      showAddToCartSuccess: false,
      showAddToCartFailure: false
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    const { loadAlbumConfigurationsAction } = this.props;
    this.initializeFacebookLogin();
    this.initializePixelFacebook();
    if (!this.props.location.state) {
      this.props.history.push('/projects/');
    } else {
      this.loadCollectionAlbums(this.props.location.state.id);
      loadAlbumConfigurationsAction();
    }
  }

  loadCollectionAlbums = (id) => {
    TokenHelper.getUserToken().then(idToken => {
      ApiCustomer.getCustomerProductDetail({
        token: idToken,
        product_id: id,
        product_type: 'album'
      }).then(response => {
        if (response.data.success) {
          const { collection, collectionBooks } = createCollectionData(response.data.data);
          this.setState({
            collectionSelected: collection,
            collectionBooks: collectionBooks,
            isLoading: false
          });
        }
      }).catch(error => {
        this.setState({
          isLoading: false,
          showError: true,
          errorMessage: 'Hubo un error, por favor intenta más tarde.'
        });
      });
    }).catch(error => {
      this.setState({
        isLoading: false,
        showError: true,
        errorMessage: 'Hubo un error, por favor intenta más tarde.'
      });
    });
  }


  initializeFacebookLogin = () => {
    this.FB = window.FB;
  }

  initializePixelFacebook = () => {
    this.fbq = window.fbq;
  }

  selectAlbumCallBack = (indexBookSelected) => {
    this.props.history.push({
      pathname: '/collection/albums/detail',
      state: this.state.collectionSelected
    });
  }

  editNameCollectionHandler = (collection) => {
    this.setState({
      ...this.state,
      showEditNameConfirmation: true,
      collectionToEditName: collection,
      numberCharacters: collection.name.length
    });
  }

  okEditNameConfirmation = () => {
    this.setState({
      ...this.state,
      showEditNameConfirmation: false
    });
    const { collectionToEditName } = this.state;
    if (collectionToEditName) {
      this.editNameCollectionApiCall(collectionToEditName);
      this.setState({
        collectionToEditName: null
      });
    }
  }

  updateCollectionName = (newName) => {
    const setName = new Promise(resolve => resolve(
      this.setState({
        showEditNameConfirmation: false,
        collectionToEditName: {
          ...this.state.collectionToEditName,
          name: newName
        }
      })
    ));
    setName.then(() => this.editNameCollectionApiCall(this.state.collectionToEditName));
  }

  editNameCollectionApiCall = (collection) => {
    TokenHelper.getUserToken().then(idToken => {
      ApiCustomer.updateCollectionSettings({
        token: idToken,
        album_id: collection.id,
        print_dates: collection.print_dates,
        hard_cover: collection.hard_cover,
        title: collection.name
      }).then(response => {
        if (response.data.success) {
          this.setState({ collectionSelected: collection });
        }
      }).catch(error => {
        this.setState({
          isLoading: false,
          showError: true,
          errorMessage: error.data.message
        });
      });
    }).catch(error => {
      this.setState({
        isLoading: false,
        showError: true,
        errorMessage: 'Hubo un error, por favor intenta más tarde.'
      });
    });
  }

  cancelEditNameConfirmation = () => {
    this.setState({
      ...this.state,
      showEditNameConfirmation: false,
      collectionToEditName: null
    });
  }

  handleChange(e) {
    const value = e.target.value;
    this.setState({
      collectionToEditName: {
        ...this.state.collectionToEditName,
        name: value
      },
      numberCharacters: value.length
    })
  };

  addToCart = async(album) => {
    const albumPhotos = album.photos;
    if (albumPhotos.length === 60) {
      const reducer = (accumulator, currentValue) => accumulator + currentValue;
      const totalPrice = this.props.cartProducts.map(item => item.price).reduce(reducer, 0) + this.state.collectionSelected.price;
      if (totalPrice > 10000) {
        this.setState({
          showError: true,
          errorMessage: 'No es posible agregar más de $10,000 al carrito.'
        });
      } else {
        const { collectionSelected } = this.state;
        this.setState({ isLoading: true });
        let albumToAdd = {
          name: collectionSelected.name,
          hard_cover: collectionSelected.hard_cover,
          print_dates: collectionSelected.print_dates,
          photos: albumPhotos,
          cover_url: album.cover_url,
          predesign_cover_id: album.predesign_data ? album.predesign_data.id : '',
          configurations: collectionSelected.configurations
        };
        const albumsArray = [albumToAdd]
        await this.props.addAlbumToCartAction(albumsArray);
        this.setState({ 
          isLoading: false,
          showAddToCartSuccess: true
        });
        
        //Analytics
        this.initializePixelAnalytics();
        this.loadAddToCartAnalytics(collectionSelected);
        
      }
    } else {
      this.setState({showAddToCartFailure: true});
    }
  }

  initializePixelAnalytics = () => {
    this.dataLayer = window.dataLayer;
  }

  loadAddToCartAnalytics = (collectionSelected) => {
    this.dataLayer.push({
      'event': 'addToCart',
      'ecommerce': {
        'currencyCode': 'MXN',
        'add': {
          'products': [{
            'name': collectionSelected.name,
            'id': collectionSelected.id,
            'price': collectionSelected.price,
            'num_photos': collectionSelected.photos.length,
            'quantity': collectionSelected.quantity
          }]
        }
      }
    });
  }

  loadAddToCartPixel = (collectionsId, userId, email, price, hard_cover) => {
    this.fbq('track', 'AddToCart', {
      content_ids: [collectionsId],
      content_type: 'product',
      value: price,
      currency: 'MXN',
      user_id: userId,
      content_category: (hard_cover) ? 'hard_cover' : 'soft_cover'
    });
  }

  logAddToCartEvent = (cartId, collectionsId, userId, email, price) => {
    var params = {};
    params['cartId'] = cartId;
    params['collectionsId'] = collectionsId;
    params['userId'] = userId;
    params['email'] = email;
    this.FB.AppEvents.logEvent(this.FB.AppEvents.EventNames.ADDED_TO_CART, price, params);
  }

  okErrorHandler = () => {
    this.setState({
      showError: false,
      errorMessage: ''
    });
  }

  handleGoToCartOk = () => {
    this.props.toggleGoToCartAlertAction(false);
    this.props.changeRouteAction('cart');
    this.props.history.push('/cart');
  }

  handleGoToCartCancel = () => {
    this.props.toggleGoToCartAlertAction(false);
  }

  handleShowSettings = () => {
    this.setState({ showEditSettings: true });
  }

  handleCancelSettings = () => {
    this.setState({ showEditSettings: false });
    this.loadCollectionAlbums(this.props.location.state.id);
  }

  errorHandler = (message) => {
    this.setState({
      showEditSettings: false,
      isLoading: false,
      showError: true,
      errorMessage: message
    });
  }

  handleShowConfigurations = () => {
    const { showConfigurations } = this.state;
    if (showConfigurations) {
      this.setState({showConfigurations: false});
    } else {
      this.setState({showConfigurations: true});
    }
  }

  updateConfigurations = (collection) => {
    this.setState({collectionSelected: collection})
  }

  handleMouseOverEditname = (boolValue) => {
    this.setState({mouseOverEditbtn: boolValue})
  }

  handleCloseSuccessPopup = () => {
    this.setState({showAddToCartSuccess: false});
  }

  handleCloseFailurePopup = () => {
    this.setState({showAddToCartFailure: false});
  }

  render() {
    const {
      collectionBooks,
      collectionSelected,
      collectionToEditName,
      showEditNameConfirmation,
      showEditSettings,
      isLoading,
      showError,
      errorMessage,
      showConfigurations,
      mouseOverEditbtn,
      showAddToCartSuccess,
      showAddToCartFailure
    } = this.state;
    // TODO: Find better way to validate configurations
    const { configurations } = collectionSelected ? collectionSelected : {};
    const albumSizeConfiguration = configurations ? configurations[0] : null;
    return (
      <React.Fragment>
        {showError &&
          <Modal>
            <AlertView
              title={'¡Ups!'}
              message={errorMessage}
              okHandler={this.okErrorHandler}
              cancel={true} />
          </Modal>
        }
        {
          showEditNameConfirmation &&
          <Modal>
            <EditProductName
              productType={'album'}
              name={collectionToEditName.name}
              updateAlbumName={this.updateCollectionName}
              cancelEditProductName={this.cancelEditNameConfirmation}
            />
          </Modal>
        }
        {
          showAddToCartSuccess &&
          <Modal>
            <SuccessPopup
              handleCloseSuccessPopup={this.handleCloseSuccessPopup}
              history={this.props.history}
              fromAlbums
            />
          </Modal>
        }
        {
          showAddToCartFailure &&
          <Modal>
            <FailurePopup
              handleCloseFailurePopup={this.handleCloseFailurePopup}
              history={this.props.history}
            />
          </Modal>
        }
        {isLoading ? <Loading /> :
          <Scrollable scrollableContent={
            <div>
              <div className="albums_header d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                  <img src={albums_icon} height="20" alt="" />
                  <p className="gotham-bold-15 ml-3 mr-1">Fotolibros > {collectionSelected && collectionSelected.name}</p>
                  <img className="mb-2 cursor-pointer" src={edit_img} height="15" alt="" onClick={() => this.editNameCollectionHandler(collectionSelected)} />
                </div>
              </div>
              <div className="albums_config-bar d-flex justify-content-center align-items-center">
                <p className="gotham-bold-white-20 mr-3">Selecciona las especificaciones de la colección de tus Pixyalbums.</p>
                <img className="cursor-pointer" src={showConfigurations ? up_arrow : down_arrow} height="12" alt="" onClick={this.handleShowConfigurations} />
              </div>
              {(this.props.albumConfigurations && showConfigurations) &&
                <AlbumConfigurations
                  collectionSelected={collectionSelected}
                  updateConfigurations={this.updateConfigurations}
                  errorHandler={this.errorHandler}
                />
              }

              <div className="py-5 d-flex flex-column align-items-center justify-content-center">
                <div className="albums_name-container py-3 pl-4 d-flex flex-column justify-content-center align-items-start">
                  <p className="gotham-bold-lightgray-14">Título:</p>
                  <p className="gotham-md-gray-18">{collectionSelected.name}</p>
                  <div 
                    className="album_editname-btn d-flex justify-content-center align-items-center cursor-pointer" 
                    onClick={() => this.editNameCollectionHandler(collectionSelected)}
                    onMouseEnter={() => this.handleMouseOverEditname(true)}
                    onMouseLeave={() => this.handleMouseOverEditname(false)}
                  >
                    <img height="40%" src={edit_img} alt="edit"/>
                  </div>
                  {mouseOverEditbtn &&
                    <div className="album_hover-editname d-flex justify-content-center align-items-center shadow">
                      <p className="gotham-md-gray-15">Edita el lomo de tu álbum.</p>
                    </div>
                  }
                </div>
              </div>

              <div className="m-5 d-flex flex-wrap align-items-center justify-content-center">
                {collectionBooks.map((item, index) =>
                  <CardAlbum
                    key={index}
                    book={item}
                    selectAlbumCallBack={this.selectAlbumCallBack}
                    indexBook={index}
                    addToCartCallback={this.addToCart}
                    predesignData={item.predesign_data}
                  />
                )}
              </div>
              <Footer history={this.props.history}/>
            </div>
          } />
        }
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  showGoToCart: state.checkoutReducer.showGoToCart,
  cartProducts: state.collections.cartProducts,
  albumConfigurations: state.globalReducer.albumConfigurations
});

const mapDispatchToProps = {
  addAlbumToCartAction: addAlbumToCartAction,
  toggleGoToCartAlertAction: toggleGoToCartAlertAction,
  loadAlbumConfigurationsAction: loadAlbumConfigurationsAction,
  changeRouteAction: changeRouteAction
};

export default compose(
  firebaseConnect(),
  connect(mapStateToProps, mapDispatchToProps)
)(Albums);