import React from 'react';
import { SortableContainer } from 'react-sortable-hoc';

import CoverHeader from './CoverHeader';
import SortableItem from './SortableItem';
import InsideCover from './InsideCover';

import add_icon from '../../images/newDesignImages/add-icon-blue.png';
import './SortableList.scss';


const SortableList = SortableContainer((props) => {

  const {
    items,
    selectPhotoHandler,
    selectPhotoForDeleteCallback,
    selectedPhotosForDelete,
    changeCoverCallback,
    nameCollection,
    addPhotosHandler,
    showDeleteConfirmationHandler,
    editCoverHandler,
    sourceIds,
    configBarShownOption,
    selectedAlbumConfiguration,
    handleDeletePage
  } = props;
  // We start an -1 because we need to add +1 before pass the variable, so in this case we're gonna have 0 at the first element
  var realIndex = -1;
  return (
    <div className="sortable-list_main w-100 pb-5 d-flex flex-wrap">
      {
        items.map((value, index) => {
          if (value.type === 'cover') {
            return (
              <CoverHeader
                key={index}
                cover={value.data}
                changeCoverCallback={changeCoverCallback}
                collectionSelected={nameCollection}
                selectedPhotosForDelete={selectedPhotosForDelete}
                addPhotosHandler={addPhotosHandler}
                showDeleteConfirmationHandler={showDeleteConfirmationHandler}
                editCoverHandler={editCoverHandler}
                selectedAlbumConfiguration={selectedAlbumConfiguration}
              />
            )
          }
          else if (value.type === 'inside_cover_one') {
            return (
              <div key={index} className="col-2 mx-0 px-0 sortable-list_item">
                <InsideCover
                  title={'Contraportada'}
                  title2={'Hoja en Blanco'}
                  begin={true} />
              </div>
            )
          }
          else if (value.type === 'inside_cover_two') {
            return (
              <div key={index} className="col-2 mx-0 px-0 sortable-list_item">
                <InsideCover
                  title={'Contraportada'}
                  title2={'Hoja en blanco'}
                  begin={false} />
              </div>
            )
          }
          else if (value.type === 'photo') {
            realIndex += 1;
            const selected = selectedPhotosForDelete.includes(value.data.id);
            return (
              <div key={index} className="col-2 mx-0 px-0 sortable-list_item">
                <SortableItem
                  index={realIndex}
                  realIndex={realIndex}
                  photo={value.data}
                  position={realIndex + 1}
                  selectPhotoHandler={selectPhotoHandler}
                  selectPhotoForDeleteCallback={selectPhotoForDeleteCallback}
                  selected={selected}
                  collectionSelected={nameCollection}
                  sourceIds={sourceIds}
                  configBarShownOption={configBarShownOption}
                  handleDeletePage={handleDeletePage}
                />
              </div>
            )
          }
          else if (value === 'addPhoto') {
            return (
              <div 
                key={index} 
                className="col-2 mx-0 px-0 sortable-list_item d-flex flex-column align-items-center justify-content-center cursor-pointer"
                onClick={props.addPhotosHandler}
              >
                <img className="sortable-list_add_img" src={add_icon} alt="" />
                <p className="gotham-md-gray-15 pt-2" style={{textAlign: 'center'}}>Agregar fotos</p>
              </div>
            );
          }
        })
      }
    </div>
  )
});

export default SortableList;