import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { 
  loadCoverCategoriesAction, 
  toggleShowChangeCoverSelectSourceAction, 
  loadCoversAction, 
  changeAlbumCoverAction 
} from '../../actions/CustomerActions'

import Scrollable from '../../components/Scrollable';
import CoverCategoryCard from './CoverCategoryCard';
import Loading from '../../components/Loading';
import FullScreenView from '../../components/FullScreenView';
import CoverCard from '../Covers/CoverCard';

import close_img from '../../images/newDesignImages/close-white.png';
import back_img from '../../images/newDesignImages/back-arrow-blue.png';
import './CoverCategories.scss';


class CoverCategories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      showCoversCategories: true,

    }
  }

  async componentDidMount() {
    await this.props.loadCoverCategoriesAction();
    this.setState({isLoading: false});
  }

  handleSelectCategory = async(id) => {
    this.setState({isLoading: true});
    const categoryName = this.props.coverCategories.filter(item => item.id === id)[0].name;
    await this.props.loadCoversAction(id);
    this.setState({
      isLoading: false,
      showCoversCategories: false,
      selectedCategory: categoryName
    });
  }

  handleSelectCover = async(coverUrl, selectedCollection, index, predesignId) => {
    await this.props.changeAlbumCoverAction(coverUrl, selectedCollection, index, predesignId);
    this.props.reloadData();
    this.props.toggleShowChangeCoverSelectSourceAction(false);

    // this.props.history.push({
    //   pathname: '/collection/albums/detail',
    //   state: this.props.collectionSelected
    // });
  }

  render() {
    const { showCoversCategories, selectedCategory } = this.state;
    return (
      this.state.isLoading ? <Loading/> : (
        <FullScreenView>
          <Scrollable scrollableContent={
            <div className="changecover-selectsource_main">
              <div className="changecover-selectsource_header d-flex">
                <div className="changecover-selectsource_close d-flex justify-content-center align-items-center cursor-pointer" onClick={this.props.handleCloseCovers}>
                  <img src={close_img} height="23" alt="" />
                </div>
                <div className="changecover-selectsource_header-right d-flex justify-content-between align-items-center">
                  <p className="gotham-bold-18 ml-3">Portada > Portadas prediseñadas {!showCoversCategories && `> ${selectedCategory}`}</p>
                  <button className="generic-button-blue mr-3" onClick={this.props.handleCloseCovers}>
                    Cancelar
                  </button>
                </div>
              </div>
              <div className="changecover-selectsource_content d-flex flex-column justify-content-center align-items-center">
                <div className="changecover-selectsource_back-container d-flex justify-content-start align-items-center">
                  <img className="cursor-pointer" src={back_img} height="20" alt="" onClick={this.props.handleCloseCovers} />
                </div>
                {showCoversCategories ? 
                  <div className="changecover-selectsource_photos pb-5 d-flex flex-wrap">
                    {this.props.coverCategories.map((item, i) => (
                      <CoverCategoryCard 
                        key={i}
                        item={item}
                        name={item.name}
                        image={item.image_url}
                        handleSelectCategory={this.handleSelectCategory}
                      />
                    ))}
                  </div>
                  :
                  <div className="changecover-selectsource_photos pb-5 d-flex flex-wrap">
                    {this.props.covers.map((item, i) => (
                      <CoverCard
                        key={i}
                        item={item} 
                        handleSelectCover={this.handleSelectCover}
                        thumbnail={item.thumbnail_url}
                        collectionSelected={this.props.collectionSelected}
                        selectedCoverIndexForEdit={this.props.selectedCoverIndexForEdit}
                      />
                    ))}
                  </div>
                }
              </div>
            </div>
            }/>
        </FullScreenView>
      )
    );
  }
}


const mapStateToProps = state => ({
  coverCategories: state.collections.coverCategories,
  collectionSelected: state.collections.collectionSelected,
  selectedCoverIndexForEdit: state.collections.selectedCoverIndexForEdit,
  covers: state.collections.covers,
});

const mapDispatchToProps = {
  loadCoverCategoriesAction: loadCoverCategoriesAction,
  toggleShowChangeCoverSelectSourceAction: toggleShowChangeCoverSelectSourceAction,
  loadCoversAction: loadCoversAction,
  changeAlbumCoverAction: changeAlbumCoverAction
};


export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(CoverCategories);