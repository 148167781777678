import React from 'react';

import './ProductsPopover.scss';

import banner_products from '../../images/banner_products.jpg';

class ProductsPopover extends React.Component {

  render() {
    return (
      <div className="mx-auto my-auto d-flex">  
        <div className="card alert-card">
          <div className="card-body">
            <div className="row">
              <div className="col">
                <img width="824" height="540" src={banner_products} alt="Productos" />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ProductsPopover;