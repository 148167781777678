import React from 'react';
import './MigrationLoader.scss'

export default function MigrationLoader(props) {
  return (
    <div className="migration-loading_main d-flex justify-content-center align-items-center">
      <div className="migration-loading_inside d-flex flex-column justify-content-center align-items-center">
        <p className="gotham-md-gray-18" style={{textAlign: 'center', width: '70%'}}>Espera, estamos dividiendo tu proyecto en álbumes individuales, para que te sea más fácil editarlos.</p>
        {props.migratingCollection && 
          <div className="spinner-border text-primary mt-4" role="status" aria-hidden="true"></div>
        }
      </div>
    </div>
  );
}