import React from 'react';
import forth_arrow from '../../images/newDesignImages/forth-arrow-blue.png';
import payd_img from '../../images/newDesignImages/status-payd-img.png';
import cancelled_img from '../../images/newDesignImages/status-cancelled-img.png';
import delivered_img from '../../images/newDesignImages/status-delivered-img.png';
import printed_img from '../../images/newDesignImages/status-printed-img.png';
import shipped_img from '../../images/newDesignImages/status-shipped-img.png';
import './Orders.scss'

const CardOrderItem = (props) => {
  const { order, goToDetails } = props;

  function statusOrder(statusOrder) {
    let status = ""
    switch (statusOrder) {
      case "created": status = "Creada"; break;
      case "paid": status = "Pagada"; break;
      case "cancelled": status = "Cancelada"; break;
      case "shipped": status = "Enviada"; break;
      case "delivered": status = "Entregada"; break;
      case "declined": status = "Declinada"; break;
      case "expired": status = "Cancelada"; break;
      case "pending_payment": status = "Pendiente de pago"; break;
      case "printed": status = "En imprenta"; break;
      default: status = "-";
    }
    return status;
  }

  function statusColorImg(statusOrder) {
    let status = ""
    switch (statusOrder) {
      case "created": status = <div></div>; break;
      case "paid": status = <img src={payd_img} alt="" />; break;
      case "cancelled": status = <img src={cancelled_img} alt="" />; break;
      case "shipped": status = <img src={shipped_img} />; break;
      case "delivered": status = <img src={delivered_img} alt="" />; break;
      case "declined": status = <div></div>; break;
      case "expired": status = <div></div>; break;
      case "pending_payment": status = <div></div>; break;
      case "printed": status = <img src={printed_img} alt="" />; break;
      default: status = "-";
    }
    return status;
  }

  return (
    <div className="order_card cursor-pointer" onClick={() => goToDetails(order.id)}>
      <div className="order_card-date py-1 px-5">
        <p className="futura-bkbt-15">{order.created_at}</p>
      </div>
      <div className="px-5 py-3 d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-center">
          <div className="order_order-number d-flex justify-content-center align-items-center">
            <p className="futura-bkbt-18">Número de orden: <strong>{order.id}</strong></p>
          </div>
          <p className="ml-4 futura-md-blue-18">${order.grand_total} MXN</p>
        </div>
        <div className="order_status d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            {statusColorImg(order.status)}
            <p className="ml-3  ">{statusOrder(order.status)}</p>
          </div>
          <img src={forth_arrow} alt="" />
        </div>
      </div>
      <hr></hr>
    </div>
  );
}

export default CardOrderItem;