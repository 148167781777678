import {
    SHOW_COLLECTIONS,
    SELECT_COLLECTION,
    SHOW_ALBUMS,
    SHOW_ALBUM,
    DELETE_COLLECTION,
    SHOW_DETAIL_PHOTO,
    SELECT_ALBUM,
    SELECT_PHOTO,
    UPDATE_DETAIL_PHOTO,
    INDEX_PHOTO_BOOK_DETAIL,
    SELECTED_INDEX_BOOK_DETAIL_PHOTO,
    ADD_SELECTED_PHOTO_BOOK,
    REMOVE_SELECTED_PHOTO_BOOK,
    DELETE_PHOTOS_FROM_ALBUM,
    EDIT_NAME_COLLECTION,
    UPDATE_DETAIL_COLLECTION,
    RESET_COLLECTIONS_STATE,
    RESET_PHOTOS_SELECTED_BOOK,
    COVER_SELECTED_FOR_CHANGE,
    LOAD_USER_CARDS,
    START_LOADING_USER_CARDS,
    FINISH_LOADING_USER_CARDS,
    LOAD_USER_ADDRESSES,
    START_LOADING_USER_ADDRESSES,
    FINISH_LOADING_USER_ADDRESSES,
    LOAD_CART,
    CREATE_CUSTOMER_ADDRESS,
    CREATE_CUSTOMER_ADDRESS_ON_SERVER,
    SHOW_CREATE_ADDRESS,
    RELOAD_USER_ADDRESSES,
    SHOW_HIDE_CREATE_CARD_TOKEN,
    CREATE_CUSTOMER_CARD_TOKEN_ON_SERVER,
    RELOAD_USER_CARDS,
    ADD_LOCAL_ALBUM_TO_CART,
    REMOVE_LOCAL_PRODUCT_FROM_CART,
    UPDATE_PHOTOS_OF_SELECTED_COLLECTION,
    CREATE_SORTABLE_DATA_SOURCE,
    TOGGLE_UPLOADING_COVER_FLAG,
    TOGGLE_SHOW_CHANGE_COVER_MODAL,
    TOGGLE_SHOW_CHANGE_COVER_SELECT_SOURCE_MODAL,
    SELECT_COVER_FOR_EDIT,
    SET_CUSTOMER_DETAIL,
    SET_CUSTOMER_WALLET,
    LOAD_WALLET_TRANSACTIONS,
    LOAD_CUSTOMER_ORDERS,
    REMOVE_USER_CARD,
    REMOVE_USER_ADDRESS,
    START_LOADING_USER_ORDERS,
    FINISH_LOADING_USER_ORDERS,
    LOAD_COVER_CATEGORIES,
    LOAD_COVERS,
    LOAD_AVAILABLE_PRODUCTS,
    UPDATE_CART_QUANTITY,
    UPDATE_COLLECTION,
    UPDATE_ALBUM_CONFIGURATION,
    SAVE_PARAMS_FOR_ALBUM_CONFIGURATION,
    SAVE_PAGES_DIFERENCE
} from '../actions/ActionTypes';

const initialState = {
    collections: [],
    collectionSelected: null,
    collectionBooks: [],
    photosBook: [],
    photosSelected: [],
    photosShowBook: null,
    selectPhoto: null,
    photoDetail: null,
    userCards: [],
    loadingCards: false,
    userAddresses: [],
    loadingAddresses: false,
    reloadUserAddresses: false,
    selectedIndexBookDetailPhoto: null,
    selectedIndexPhotoDetail: null,
    cartProducts: [],
    cartProductsQuantity: 0,
    creatingAddress: false,
    creatingAddressOnServer: false,
    creatingCardToken: false,
    creatingCardTokenOnServer: false,
    reloadUserCards: false,
    sortableDataSource: [],
    isUploadingCover: false,
    showChangeCover: false,
    showChangeCoverSelectSource: false,
    selectedCoverForEdit: '',
    selectedCoverIndexForEdit: -1,
    customerName: '',
    customerEmail: '',
    wallet: [],
    walletTransactions: [],
    customerProfileImage: '',
    customerOrders: [],
    loadingOrders: false,
    coverCategories: [],
    covers: [],
    availableProductsGlobal: null,
    selectedAlbumConfiguration: null,
    paramsForUpdateAlbumConfiguration: null,
    pagesDiference: 0
}

export default function (state = initialState, action) {
    switch (action.type) {
        case SHOW_COLLECTIONS:
            return {
                ...state,
                collections: action.payload,
                collectionSelected: null,
                collectionBooks: []
            };
        case SELECT_COLLECTION:
            return {
                ...state,
                collectionSelected: action.payload,
                sortableDataSource: []
            }
        case UPDATE_COLLECTION:
            return {
                ...state,
                collectionSelected: action.payload
            }
        case DELETE_COLLECTION:
            return {
                ...state,
                collections: state.collections.filter(item => item.id !== action.payload.id)
            };
        case SELECT_ALBUM:
            return {
                ...state,
                selectAlbum: action.payload
            }
        case SHOW_ALBUMS:
            return {
                ...state,
                collectionBooks: action.payload
            };
        case SHOW_ALBUM:
            return {
                ...state,
                photosBook: action.payload
            };
        case SELECT_PHOTO:
            return {
                ...state,
                selectPhoto: action.payload
            }
        case SHOW_DETAIL_PHOTO:
            return {
                ...state,
                photoDetail: action.payload
            };
        case UPDATE_DETAIL_PHOTO:
            return {
                ...state,
                photoDetail: action.payload
            };
        case INDEX_PHOTO_BOOK_DETAIL:
            // REMOVE THIS CODE, WE NEED TO CHECK WHERE HAVE IMPORTS, COMPONENTS AND ACTIONS
            // var indexPhoto = null;
            // state.collectionBooks[state.selectedIndexBookDetailPhoto].map((photo, index) => {
            //     if (photo.index === action.payload.index) { indexPhoto = index }
            // });
            return {
                ...state
                // selectedIndexPhotoDetail: indexPhoto
            }
        case SELECTED_INDEX_BOOK_DETAIL_PHOTO:
            return {
                ...state,
                selectedIndexBookDetailPhoto: action.payload
            }
        case ADD_SELECTED_PHOTO_BOOK:
            {
                return {
                    ...state,
                    photosSelected: state.photosSelected.concat(action.payload)
                }
            }
        case REMOVE_SELECTED_PHOTO_BOOK:
            {
                return {
                    ...state,
                    photosSelected: state.photosSelected.filter(item => item.id !== action.payload.id)
                }
            }
        case DELETE_PHOTOS_FROM_ALBUM:
            {
                const collectionForUpdate = JSON.parse(JSON.stringify(state.collectionSelected));
                const photos = collectionForUpdate.photos;
                const remainPhotos = photos.filter(item => { return !action.payload.includes(item.id) });
                for (var x = 0; x < remainPhotos.length; x++) {
                    // Recalculate the correct index for the remain photos
                    remainPhotos[x].index = x;
                }
                collectionForUpdate.photos = remainPhotos;
                return {
                    ...state,
                    collectionSelected: collectionForUpdate
                };
            }
        case EDIT_NAME_COLLECTION:
            return {
                ...state,
                collections: state.collections.map((collection) => collection.id === action.payload.id ? collection = action.payload : collection),
                collectionSelected: action.payload
            };
        case UPDATE_DETAIL_COLLECTION:
            return {
                ...state,
                collections: state.collections.map((collection) => collection.id === action.payload.id ? collection = action.payload : collection),
                collectionSelected: action.payload
            }
        case COVER_SELECTED_FOR_CHANGE:
            return {
                ...state,
                photosShowBook: state.collectionBooks[action.payload]
            }
        case LOAD_USER_CARDS:
            return {
                ...state,
                userCards: action.payload
            };
        case START_LOADING_USER_CARDS:
            return {
                ...state,
                loadingCards: true
            };
        case FINISH_LOADING_USER_CARDS:
            return {
                ...state,
                loadingCards: false
            };
        case LOAD_USER_ADDRESSES:
            return {
                ...state,
                userAddresses: action.payload
            };
        case START_LOADING_USER_ADDRESSES:
            return {
                ...state,
                loadingAddresses: true
            };
        case FINISH_LOADING_USER_ADDRESSES:
            return {
                ...state,
                loadingAddresses: false
            };
        case RESET_COLLECTIONS_STATE:
            return initialState;
        case RESET_PHOTOS_SELECTED_BOOK:
            return {
                ...state,
                photosSelected: []
            }
        case LOAD_CART:
            return {
                ...state,
                cartProducts: action.payload
            };
        case UPDATE_CART_QUANTITY:
            return {
                ...state,
                cartProductsQuantity: action.payload
            }
        case CREATE_CUSTOMER_ADDRESS:
            return {
                ...state,
                userAddresses: state.userAddresses.concat(action.payload)
            };
        case CREATE_CUSTOMER_ADDRESS_ON_SERVER:
            return {
                ...state,
                creatingAddressOnServer: action.payload
            };
        case SHOW_CREATE_ADDRESS:
            return {
                ...state,
                creatingAddress: action.payload
            };
        case RELOAD_USER_ADDRESSES:
            if (action.payload) {
                return {
                    ...state,
                    reloadUserAddresses: action.payload,
                    userAddresses: []
                };
            } else {
                return {
                    ...state,
                    reloadUserAddresses: action.payload
                };
            }
        case SHOW_HIDE_CREATE_CARD_TOKEN:
            return {
                ...state,
                creatingCardToken: action.payload
            };
        case CREATE_CUSTOMER_CARD_TOKEN_ON_SERVER:
            return {
                ...state,
                creatingCardTokenOnServer: action.payload
            };
        case RELOAD_USER_CARDS:
            if (action.payload) {
                return {
                    ...state,
                    reloadUserCards: action.payload,
                    userCards: []
                };
            } else {
                return {
                    ...state,
                    reloadUserCards: action.payload
                };
            }
        case ADD_LOCAL_ALBUM_TO_CART:
            return {
                ...state,
                cartProducts: action.payload,
                cartProductsQuantity: state.cartProductsQuantity + 1
            };
        case REMOVE_LOCAL_PRODUCT_FROM_CART:
            return {
                ...state,
                cartProducts: state.cartProducts.filter(item => !(item.id === action.payload.id && item.product_type === action.payload.product_type)),
                cartProductsQuantity: state.cartProductsQuantity - 1
            }
        case UPDATE_PHOTOS_OF_SELECTED_COLLECTION:
            {
                let collectionSelectedForUpdate = { ...state.collectionSelected };
                collectionSelectedForUpdate.photos = action.payload;
                return {
                    ...state,
                    collectionSelected: collectionSelectedForUpdate
                }
            }
        case CREATE_SORTABLE_DATA_SOURCE:
            return {
                ...state,
                sortableDataSource: action.payload
            }
        case TOGGLE_UPLOADING_COVER_FLAG:
            return {
                ...state,
                isUploadingCover: action.payload
            }
        case TOGGLE_SHOW_CHANGE_COVER_MODAL:
            return {
                ...state,
                showChangeCover: action.payload
            }
        case TOGGLE_SHOW_CHANGE_COVER_SELECT_SOURCE_MODAL:
            return {
                ...state,
                showChangeCoverSelectSource: action.payload
            }
        case SELECT_COVER_FOR_EDIT:
            return {
                ...state,
                selectedCoverForEdit: action.payload.cover,
                selectedCoverIndexForEdit: action.payload.index
            }
        case SET_CUSTOMER_DETAIL:
            return {
                ...state,
                customerName: action.payload.name,
                customerEmail: action.payload.email,
                customerProfileImage: action.payload.profile_image_signed
            }
        case LOAD_CUSTOMER_ORDERS:
            return {
                ...state,
                customerOrders: action.payload
            }
        case REMOVE_USER_CARD:
            return {
                ...state,
                userCards: state.userCards.filter(item => item.id !== action.payload)
            }
        case REMOVE_USER_ADDRESS:
            return {
                ...state,
                userAddresses: state.userAddresses.filter(item => item.id !== action.payload)
            }
        case START_LOADING_USER_ORDERS:
            return {
                ...state,
                loadingOrders: true
            }
        case FINISH_LOADING_USER_ORDERS:
            return {
                ...state,
                loadingOrders: false
            }
        case SET_CUSTOMER_WALLET:
            return {
                ...state,
                wallet: action.payload,
            }
        case LOAD_WALLET_TRANSACTIONS:
            return {
                ...state,
                walletTransactions: action.payload
            }
        case LOAD_COVER_CATEGORIES:
            return {
                ...state,
                coverCategories: action.payload
            }
        case LOAD_COVERS:
            return {
                ...state,
                covers: action.payload
            }
        case LOAD_AVAILABLE_PRODUCTS:
            return {
                ...state,
                availableProductsGlobal: action.payload
            }
        case UPDATE_ALBUM_CONFIGURATION:
            return {
                ...state,
                selectedAlbumConfiguration: action.payload
            }
        case SAVE_PARAMS_FOR_ALBUM_CONFIGURATION:
            return {
                ...state,
                paramsForUpdateAlbumConfiguration: action.payload
            }
        case SAVE_PAGES_DIFERENCE:
            return {
                ...state, 
                pagesDiference: action.payload
            }
        default:
            return state;
    }
}