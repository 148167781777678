import React from 'react';
import elem_unchecked from '../../images/newDesignImages/elem-unchecked.png';
import elem_checked from '../../images/newDesignImages/magnet-elem-checked.png';
import hover_img from '../../images/newDesignImages/hover.png';
import add_icon from '../../images/newDesignImages/add-icon-blue-old.png';

import './MagnetDetail.scss';

function MagnetDetailItem(props) {
  const imgStyle = {
    backgroundImage: props.mouseOverImage && props.mouseOverImage.id === props.item.id ? 'url(' + hover_img + ')' : 'url(' + props.image + ')'
  }
  return (
    props.item === 'addPhoto' ?
      <div className="magnet-item_container pt-5 d-flex flex-column align-items-center justify-content-center cursor-pointer" onClick={props.handleAddImageClick}>
        <img src={add_icon} alt="" />
        <p className="futura-ltbt-gray-15 pt-2">Agregar fotos</p>
      </div>
      :
      <div className="magnet-item_container py-3 d-flex justify-content-center cursor-pointer">
        <div className="magnet-item_container-inside ">
          <div className="d-flex py-2 justify-content-center" onClick={() => props.handleSelectImage(props.item)}>
            {props.isImageSelected ? <img src={elem_checked} height="16" alt="" /> : <img src={elem_unchecked} height="16" alt="" />}
          </div>
          <div className="magnet-item_img" style={imgStyle} onMouseEnter={() => props.handleMouseEnter(props.item)} onMouseLeave={() => props.handleMouseEnter(null)} onClick={() => props.selectImageForEdit(props.item)}></div>
        </div>
      </div>
  );
}

export default MagnetDetailItem;